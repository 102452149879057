// src/reducers/commentReducer.js

import {
	FETCH_COMMENTS,
	ADD_COMMENT,
	UPDATE_COMMENT,
	DELETE_COMMENT,
	SET_COMMENT_ERROR,
	SET_EDITING_COMMENT,
	SET_USER_COMMENTS,
	FETCH_COMMENTS_COUNT,
	UPDATE_COMMENT_TRANSLATION_STATUS,
} from '../actions/commentActions'

const initialState = {
	entities: {},
	ids: [],
	byId: {},
	byPostId: {},
	allIds: [],
	error: null,
	editingComment: null,
	profileComments: [],
	profileCommentsTotalCount: 0,
}

const commentReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_COMMENTS: {
			const { postId, comments, commentsCount } = action.payload
			const normalizedComments = comments.reduce((acc, comment) => {
				acc[comment.id] = comment
				return acc
			}, {})

			return {
				...state,
				byId: { ...state.byId, ...normalizedComments },
				byPostId: {
					...state.byPostId,
					[postId]: {
						ids: comments.map((c) => c.id),
						commentsCount,
						lastUpdated: Date.now(),
					},
				},
				allIds: [...new Set([...state.allIds, ...comments.map((c) => c.id)])],
			}
		}

		case FETCH_COMMENTS_COUNT: {
			const { postId, commentsCount } = action.payload
			return {
				...state,
				byPostId: {
					...state.byPostId,
					[postId]: {
						...state.byPostId[postId],
						commentsCount,
						lastUpdated: Date.now(),
					},
				},
			}
		}

		case ADD_COMMENT: {
			const { postId, comment } = action.payload
			return {
				...state,
				byId: { ...state.byId, [comment.id]: comment },
				byPostId: {
					...state.byPostId,
					[postId]: {
						...state.byPostId[postId],
						ids: [comment.id, ...(state.byPostId[postId]?.ids || [])],
						commentsCount: (state.byPostId[postId]?.commentsCount || 0) + 1,
						lastUpdated: Date.now(),
					},
				},
			}
		}

		case UPDATE_COMMENT_TRANSLATION_STATUS: {
			const { comment, postId } = action.payload
			return {
				...state,
				byId: {
					...state.byId,
					[comment.id]: {
						...state.byId[comment.id],
						translations: comment.translations,
						translationStatus: 'completed',
					},
				},
				byPostId: {
					...state.byPostId,
					[postId]: {
						...state.byPostId[postId],
						ids: state.byPostId[postId]?.ids || [],
						lastUpdated: Date.now(),
					},
				},
			}
		}

		case UPDATE_COMMENT: {
			const { comment, postId } = action.payload
			return {
				...state,
				byId: { ...state.byId, [comment.id]: comment },
				byPostId: {
					...state.byPostId,
					[postId]: {
						...state.byPostId[postId],
						ids:
							state.byPostId[postId]?.ids?.map((id) =>
								id === comment.id ? comment.id : id
							) || [],
						lastUpdated: Date.now(),
					},
				},
			}
		}

		case DELETE_COMMENT: {
			const { commentId, postId } = action.payload
			const { [commentId]: deletedComment, ...remainingComments } = state.byId
			return {
				...state,
				byId: remainingComments,
				byPostId: {
					...state.byPostId,
					[postId]: {
						...state.byPostId[postId],
						ids: state.byPostId[postId].ids.filter((id) => id !== commentId),
						commentsCount: state.byPostId[postId].commentsCount - 1,
						lastUpdated: Date.now(),
					},
				},
				allIds: state.allIds.filter((id) => id !== commentId),
			}
		}

		case SET_COMMENT_ERROR:
			return { ...state, error: action.payload }

		case SET_EDITING_COMMENT:
			return { ...state, editingComment: action.payload }

		case SET_USER_COMMENTS:
			return {
				...state,
				profileComments:
					action.payload.offset === 0
						? action.payload.comments
						: [...state.profileComments, ...action.payload.comments],
				totalProfileComments: action.payload.totalComments,
			}

		default:
			return state
	}
}

export default commentReducer

// Selectors
export const selectCommentsCount = (state, postId) =>
	state.comments.byPostId[postId]?.commentsCount

export const selectCommentError = (state) => state.comments.error

export const selectEditingComment = (state) => state.comments.editingComment

export const selectUserComments = (state) => state.comments.userComments

export const selectUserCommentsTotalCount = (state) =>
	state.comments.userCommentsTotalCount

export const selectCommentById = (state, commentId) =>
	state.comments.byId[commentId]
