// src/utils/locationUtils.js

export const generatePlaceLink = (location) => {
	return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
		location.name
	)}&query_place_id=${location.place_id}`
}

export const generateShortUrl = (location) => {
	if (location && location.place_id) {
		return `https://maps.app/${location.place_id}`
	}
	return ''
}
