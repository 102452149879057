// CreatePostModal.jsx

import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'

import CreatePostModalHeader from './CreatePostModalHeader'
import CreatePostModalContent from './CreatePostModalContent'
import CreatePostModalFooter from './CreatePostModalFooter'
import ConfirmationModal from '../Common/ConfirmationModal'
import GooglePlaceSearchModal from '../Maps/GooglePlaceSearchModal'
import LoadingSpinner from '../Common/LoadingSpinner'
import compositeDecorator from '../../decorators/compositeDecorator'

import useCreatePostState from '../../hooks/useCreatePostState'
import useCreatePostHandlers from '../../hooks/useCreatePostHandlers'
import { useAuth } from '../../hooks/useAuth'

import { EditorState } from 'draft-js'
import { useSnackbar } from '../../context/SnackbarContext'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
	.modal-dialog {
		margin: 0rem auto;
		display: flex;
		justify-content: center;
		width: 100%;
	}

	.modal-content {
		position: relative;
		background-color: white;
		padding: 16px 16px 10px;
		overflow: hidden;
		max-height: calc(100vh - 80px);
		margin: 40px auto;
		z-index: 1100;
		display: flex;
		flex-direction: column;

		@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
			max-height: 100%;
			overflow-y: auto;
			width: 100%;
			height: 100%;
			margin: 0;
			position: fixed;
			top: 0;
			left: 0;
			border-radius: 0;
		}

		@media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
			min-width: 560px;
			min-height: 420px;
			border-radius: 20px;
		}
	}
`

const CreatePostModal = ({
	isOpen,
	onClose,
	initialText,
	post,
	draft,
	iconTop,
}) => {
	useAuth()
	const user = useSelector((state) => state.user.user)
	const { t } = useTranslation()
	const showAlert = useSnackbar()

	const [showConfirmModal, setShowConfirmModal] = useState(false)
	const [isCloseInitiated, setIsCloseInitiated] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [isContentEdited, setIsContentEdited] = useState(false)

	const [isPlaceSearchOpen, setIsPlaceSearchOpen] = useState(false)
	const [isCreatePostModalHidden, setIsCreatePostModalHidden] = useState(false)
	const [selectedLocations, setSelectedLocations] = useState([])

	const {
		editorState,
		setEditorState,
		uploadedImages,
		setUploadedImages,
		isEdited,
		setIsEdited,
		deletedImageIds,
		setDeletedImageIds,
		isQuestion,
		setIsQuestion,
		savedPostId,
		setSavedPostId,
	} = useCreatePostState(initialText, post, draft, compositeDecorator)

	const text = useMemo(
		() => editorState.getCurrentContent().getPlainText(),
		[editorState]
	)
	const isSubmitDisabled = useMemo(() => text.trim() === '', [text])

	const {
		handleSubmitPost,
		handleImageUpload,
		handleImageDelete,
		handlePlaceSelected,
	} = useCreatePostHandlers({
		editorState,
		setEditorState,
		uploadedImages,
		setUploadedImages,
		deletedImageIds,
		setDeletedImageIds,
		isQuestion,
		setIsQuestion,
		savedPostId,
		setSavedPostId,
		user,
		showAlert,
		onClose,
		post,
		draft,
		isEdited,
		setIsEdited,
		selectedLocations,
		setSelectedLocations,
		setIsLoading,
	})

	useEffect(() => {
		const currentContent = editorState.getCurrentContent()
		const hasText = currentContent.hasText()
		const hasImages = uploadedImages.length > 0
		const hasDeletedImages = deletedImageIds.length > 0

		setIsContentEdited(hasText || hasImages || hasDeletedImages)
	}, [editorState, uploadedImages, deletedImageIds])

	const onCloseHandler = () => {
		if (isContentEdited) {
			setIsCloseInitiated(true)
			showConfirmationModal()
		} else {
			onClose(text)
		}
	}

	const showConfirmationModal = () => {
		setShowConfirmModal(true)
	}

	const onRequestClose = () => {
		setShowConfirmModal(false)
	}

	const handleMapPinClick = useCallback(() => {
		setIsPlaceSearchOpen(true)
		setIsCreatePostModalHidden(true)
	}, [])

	const handlePlaceSearchClose = useCallback(() => {
		setIsPlaceSearchOpen(false)
		setTimeout(() => setIsCreatePostModalHidden(false), 0)
	}, [])

	const handlePlaceSelectedWrapper = useCallback(
		(location) => {
			const newEditorState = handlePlaceSelected(
				location,
				editorState,
				setEditorState
			)
			setEditorState(newEditorState)
		},
		[handlePlaceSelected, editorState, setEditorState]
	)

	const contentProps = useMemo(
		() => ({
			user,
			uploadedImages,
			onImageDelete: handleImageDelete,
			setIsEdited,
			post,
			draft,
			editorState,
			setEditorState: setEditorState,
			onClose: onCloseHandler,
		}),
		[
			user,
			uploadedImages,
			handleImageDelete,
			setIsEdited,
			post,
			draft,
			editorState,
			setEditorState,
			onCloseHandler,
		]
	)

	return (
		<>
			<StyledModal
				show={isOpen && !isCreatePostModalHidden}
				centered
				backdrop="static"
				keyboard={false}
				onClick={(e) => {
					e.stopPropagation()
				}}
			>
				{isLoading && (
					<div
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: 'rgba(255, 255, 255, 0.3)',
							zIndex: 1000,
						}}
					>
						<LoadingSpinner />
					</div>
				)}

				<CreatePostModalHeader
					onClose={onCloseHandler}
					onSaveDraft={() => handleSubmitPost(true)}
					onSubmitPost={() => handleSubmitPost()}
					isSubmitDisabled={isSubmitDisabled}
				/>

				<CreatePostModalContent {...contentProps} />

				<CreatePostModalFooter
					user={user}
					isQuestion={isQuestion}
					setIsQuestion={setIsQuestion}
					setIsEdited={setIsContentEdited}
					onImageUpload={handleImageUpload}
					uploadedImages={uploadedImages}
					onMapPinClick={handleMapPinClick}
					setIsLoading={setIsLoading}
				/>
			</StyledModal>

			{isPlaceSearchOpen && (
				<GooglePlaceSearchModal
					isOpen={isPlaceSearchOpen}
					onClose={handlePlaceSearchClose}
					onPlaceSelected={handlePlaceSelectedWrapper}
					selectedLocations={selectedLocations}
					iconTop={iconTop}
				/>
			)}

			<ConfirmationModal
				isOpen={showConfirmModal}
				onRequestClose={onRequestClose}
				showCancelButton={true}
				onCancel={onRequestClose}
				showDiscardButton={true}
				onDiscard={() => {
					setEditorState(EditorState.createEmpty())
					setUploadedImages([])
					setDeletedImageIds([])
					setIsEdited(false)
					setShowConfirmModal(false)
					onClose()
				}}
				title={t('discardChanges')}
				message={t('discardChangesMessage')}
			/>
		</>
	)
}

export default React.memo(CreatePostModal)
