// src/App.js

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { useAuth } from './hooks/useAuth'
import HomePageController from './containers/HomePage/HomePageController'
import PostItemByHashtag from './components/PostsByHashtag/PostItemByHashtag'
import PostDetail from './components/Post/PostDetail'
import Notifications from './components/Notifications/Notifications'
import GoogleAnalytics from './components/Analytics/GoogleAnalytics'

import ResetPassword from './components/Login/ResetPassword'
import BookmarkList from './components/Bookmark/BookmarkList'
import UserProfile from './components/Profile/UserProfile'
import { PostsProvider } from './context/PostsContext'
import { ScrollPositionProvider } from './context/ScrollPositionContext'
import { loadUserBookmarks } from './actions/bookmarkAction'
import SearchList from './components/Search/SearchList'

import Settings from './components/Settings/Settings'

import AccountInfo from './components/Settings/YourAccount/AccountInformation'
import ChangePassword from './components/Settings/ChangePassword'
import FeedbackForm from './components/Settings/FeedbackForm'
import DeleteAccount from './components/Settings/DeleteAccount'

import ImageModal from './components/Common/ImageModal'
import { closeImageModal } from './actions/modalActions'
import { SnackbarProvider } from './context/SnackbarContext'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import MaintenancePage from './components/Maintenance/MaintenancePage'
import QRRedirect from './components/QRcode/QRRedirect'

const BASE_URL = process.env.REACT_APP_API_BASE_URL

function App() {
	useAuth()

	const dispatch = useDispatch()
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
	const userId = useSelector((state) => state.auth.userId)
	const { imageModalIsOpen, images, startIndex } = useSelector(
		(state) => state.modal
	)

	const handleCloseImageModal = () => {
		dispatch(closeImageModal())
	}

	useEffect(() => {
		if (isAuthenticated && userId) {
			dispatch(loadUserBookmarks(userId))
		}
	}, [dispatch, isAuthenticated, userId])

	const [maintenance, setMaintenance] = useState(false)

	useEffect(() => {
		const fetchMaintenanceStatus = async () => {
			try {
				const response = await fetch(`${BASE_URL}/api/maintenance`, {
					method: 'GET',
					credentials: 'include',
				})
				const data = await response.json()
				setMaintenance(data.maintenance)
			} catch (error) {
				console.error('Error fetching maintenance status:', error)
			}
		}

		fetchMaintenanceStatus()
	}, [])

	useEffect(() => {
		if (isAuthenticated && userId) {
			dispatch(loadUserBookmarks(userId))
		}
	}, [dispatch, isAuthenticated, userId])

	if (maintenance) {
		return <MaintenancePage />
	}

	return (
		<Router>
			<ScrollPositionProvider>
				<PostsProvider>
					<SnackbarProvider>
						<GoogleAnalytics />
						<Routes>
							<Route path="/" element={<HomePageController />} />
							<Route path="/resetpwd" element={<ResetPassword />} />
							<Route path="/notifications" element={<Notifications />} />
							<Route path="/posts/:postId" element={<PostDetail />} />
							<Route path="/hashtag/:hashtag" element={<PostItemByHashtag />} />
							<Route path="/bookmarks" element={<BookmarkList />} />
							<Route path="/profile/:userId" element={<UserProfile />} />
							<Route path="/search" element={<SearchList />} />

							<Route path="/settings" element={<Settings />} />
							<Route path="/settings/your_account" element={<AccountInfo />} />

							<Route path="/settings/password" element={<ChangePassword />} />
							<Route path="/settings/feedback" element={<FeedbackForm />} />
							<Route path="/settings/delete" element={<DeleteAccount />} />
							<Route path="/qr-redirect" element={<QRRedirect />} />
						</Routes>
						<ImageModal
							isOpen={imageModalIsOpen}
							handleCloseModal={handleCloseImageModal}
							images={images}
							startIndex={startIndex}
						/>
					</SnackbarProvider>
				</PostsProvider>
			</ScrollPositionProvider>
		</Router>
	)
}

export default App
