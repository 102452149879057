// src/redux/reducers/modalReducer.js

import {
	OPEN_MODAL,
	CLOSE_MODAL,
	OPEN_IMAGE_MODAL,
	CLOSE_IMAGE_MODAL,
	OPEN_LIKE_MODAL,
	CLOSE_LIKE_MODAL,
	SET_ACTIVE_IMAGES,
	OPEN_REPORT_MODAL,
	CLOSE_REPORT_MODAL,
} from '../actions/modalActions'

const initialState = {
	modalIsOpen: false,
	imageModalIsOpen: false,
	likeModalIsOpen: false,
	reportModalIsOpen: false,
	images: [],
	startIndex: 0,
}

const modalReducer = (state = initialState, action) => {
	switch (action.type) {
		case OPEN_MODAL:
			return {
				...state,
				modalIsOpen: true,
			}
		case CLOSE_MODAL:
			return {
				...state,
				modalIsOpen: false,
			}

		case OPEN_IMAGE_MODAL:
			return { ...state, imageModalIsOpen: true }
		case CLOSE_IMAGE_MODAL:
			return { ...state, imageModalIsOpen: false }
		case SET_ACTIVE_IMAGES:
			return { ...state, ...action.payload }

		case OPEN_LIKE_MODAL:
			return { ...state, likeModalIsOpen: true }
		case CLOSE_LIKE_MODAL:
			return { ...state, likeModalIsOpen: false }

		case OPEN_REPORT_MODAL:
			return { ...state, reportModalIsOpen: true }
		case CLOSE_REPORT_MODAL:
			return { ...state, reportModalIsOpen: false }

		default:
			return state
	}
}

export default modalReducer
