// src/hooks/useImageManagement.js

import { useCallback } from 'react'

const useImageManagement = ({
	uploadedImages,
	setUploadedImages,
	deletedImageIds,
	setDeletedImageIds,
	setIsEdited,
}) => {
	const handleImageUpload = useCallback(
		(newImages) => {
			setUploadedImages((prevImages) => {
				const updatedImages = [...prevImages, ...newImages]
				return updatedImages
			})
			setIsEdited(true)
		},
		[setUploadedImages, setIsEdited]
	)

	const handleImageDelete = useCallback(
		(index) => {
			const imageToDelete = uploadedImages[index]
			if (imageToDelete && imageToDelete.id) {
				setDeletedImageIds((prevIds) => [...prevIds, imageToDelete.id])
			}

			const updatedImages = [...uploadedImages]
			updatedImages.splice(index, 1)
			setUploadedImages(updatedImages)
			setIsEdited(true)
		},
		[uploadedImages, setDeletedImageIds, setUploadedImages, setIsEdited]
	)

	return { handleImageUpload, handleImageDelete }
}

export default useImageManagement
