// src/services/notificationsApi.js

const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const fetchUserNotifications = async (userId) => {
	const response = await fetch(`${BASE_URL}/notifications/user/${userId}`)
	if (!response.ok) {
		throw new Error('Failed to fetch notifications')
	}
	const data = await response.json()

	return data
}

export const updateNotificationStatus = async (notificationId, newStatus) => {
	const response = await fetch(
		`${BASE_URL}/notifications/${notificationId}/status`,
		{
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ status: newStatus }),
		}
	)

	if (!response.ok) {
		throw new Error('Failed to update notification status')
	}

	const data = await response.json()
	return data
}
