// src/hooks/useExtractHashtags.js

import { useCallback } from 'react'

const useExtractHashtags = () => {
	const extractHashtags = useCallback((text) => {
		const regex = /#([^\s#]+)/g
		const matches = text.match(regex)

		// ハッシュタグがない場合は空配列を返す
		if (!matches) {
			return []
		}

		return matches.map((hashtag) => hashtag)
	}, [])

	return { extractHashtags }
}

export default useExtractHashtags
