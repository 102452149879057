const BASE_URL = process.env.REACT_APP_API_BASE_URL

// ブックマークを追加または削除するAPI関数
export const toggleBookmarkAPI = async ({ type, id, userId, isBookmarked }) => {
	const url = `${BASE_URL}/bookmarks?userId=${encodeURIComponent(userId)}`
	const body = JSON.stringify({
		postId: type === 'post' ? id : null,
		userId: userId,
	})

	const method = isBookmarked ? 'DELETE' : 'POST'

	try {
		const response = await fetch(url, {
			method,
			headers: {
				'Content-Type': 'application/json',
				// Authorization: `Bearer ${token}`, // 認証トークンを含める
			},
			body: body,
			credentials: 'include',
		})

		if (!response.ok) {
			throw new Error(`API responded with status code ${response.status}`)
		}

		const data = await response.json()
		return data
	} catch (error) {
		console.error('Error toggling the bookmark:', error)
		throw error
	}
}

// ブックマークデータの有無を取得するAPI関数
export const fetchBookmarksAPI = async (userId) => {
	const url = `${BASE_URL}/get/bookmarks?userId=${userId}`
	try {
		const response = await fetch(url, {
			headers: {
				'Content-Type': 'application/json',
				// Authorization: `Bearer ${token}`, // 必要に応じて認証トークンを含める
			},
			credentials: 'include',
		})

		if (!response.ok) {
			throw new Error(`API responded with status code ${response.status}`)
		}

		const bookmarks = await response.json()

		return bookmarks
	} catch (error) {
		console.error('Error fetching bookmarks:', error)
		throw error
	}
}

// ブックマークした詳細データを取得するAPI関数（ブックマーク画面で一覧で表示するため）
export const fetchBookmarkDataAPI = async (userId) => {
	const url = `${BASE_URL}/getBookmarkData?userId=${encodeURIComponent(userId)}`
	try {
		const response = await fetch(url, {
			headers: {
				'Content-Type': 'application/json',
				// Authorization: `Bearer ${token}`, // 必要に応じて認証トークンを含める
			},
			credentials: 'include',
		})

		if (!response.ok) {
			throw new Error(`API responded with status code ${response.status}`)
		}

		const bookmarkData = await response.json()
		return bookmarkData
	} catch (error) {
		console.error('Error fetching bookmark data:', error)
		throw error
	}
}
