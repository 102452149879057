// reducers/notificationsReducer.js

import {
	UPDATE_UNREAD_NOTIFICATIONS_COUNT,
	UPDATE_LAST_FETCHED,
	UPDATE_NOTIFICATIONS,
	UPDATE_NOTIFICATION_STATUS,
} from '../actions/notificationsActions'

const initialState = {
	unreadCount: 0,
	notifications: [],
	lastFetched: null,
}

const notificationsReducer = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_UNREAD_NOTIFICATIONS_COUNT:
			return {
				...state,
				unreadCount: action.payload,
			}

		case UPDATE_NOTIFICATIONS:
			return {
				...state,
				notifications: action.payload,
			}

		case UPDATE_LAST_FETCHED:
			return {
				...state,
				lastFetched: action.payload,
			}

		case UPDATE_NOTIFICATION_STATUS:
			return {
				...state,
				notifications: state.notifications.map((notification) =>
					notification.id === action.payload.notificationId
						? { ...notification, status: action.payload.status }
						: notification
				),
			}
		default:
			return state
	}
}

export default notificationsReducer
