// src/components/Filter/SimpleFilter.jsx

import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

const FilterContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	background-color: #ffffff;
	padding: 14px 0px;

	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		padding: 0px 0px 14px;
	}
`

const FilterOption = styled.span`
	position: relative;
	font-weight: bold;
	cursor: pointer;
	color: ${(props) => (props.$isActive ? 'black' : '#B0B0B0')};
	padding: 10px 20px 8px;
	border-radius: 4px;

	&:after {
		content: '';
		position: absolute;
		bottom: -2px;
		height: 4px;
		background-color: ${(props) =>
			props.$isActive ? '#D5004E' : 'transparent'};
		border-radius: 50px;
		left: 20px;
		right: 20px;
		width: calc(100% - 40px);
	}

	&:hover {
		// background-color: #f8f8f8;
	}
`

const SimpleFilter = ({ onFilterChange }) => {
	const location = useLocation()
	const navigate = useNavigate()
	const { t, i18n } = useTranslation()
	const searchParams = new URLSearchParams(location.search)
	const activeFilter =
		searchParams.get('filter') === 'questions' ? 'Questions' : 'All'

	const handleFilterChange = (value) => {
		const newSearchParams = new URLSearchParams(location.search)
		if (value === 'All') {
			newSearchParams.delete('filter')
		} else {
			newSearchParams.set('filter', 'questions')
		}
		navigate(`${location.pathname}?${newSearchParams.toString()}`)
		onFilterChange(value)
	}

	const options = [
		{ label: t('timelineAllPosts'), value: 'All' },
		{ label: t('timelineQuestions'), value: 'Questions' },
	]

	return (
		<FilterContainer>
			{options.map((option) => (
				<FilterOption
					key={option.value}
					$isActive={activeFilter === option.value}
					onClick={() => handleFilterChange(option.value)}
				>
					{option.label}
				</FilterOption>
			))}
		</FilterContainer>
	)
}

export default SimpleFilter
