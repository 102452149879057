// src/services/userApi.js

const BASE_URL = process.env.REACT_APP_API_BASE_URL

//
export const getUserByIdAPI = async (userId) => {
	try {
		const formData = new FormData()
		formData.append('id', userId)

		const response = await fetch(`${BASE_URL}/user/get`, {
			method: 'POST',
			body: formData,
			credentials: 'include',
		})

		if (!response.ok) {
			throw new Error('Network response was not ok')
		}

		const responseData = await response.json()

		return responseData
	} catch (error) {
		throw error
	}
}

export const updateUserAPI = async (userId, formData) => {
	try {
		const response = await fetch(`${BASE_URL}/users/${userId}`, {
			method: 'POST',
			body: formData,
			credentials: 'include',
		})
		const data = await response.json()

		if (response.ok && data.message === 'success') {
			return data
		} else {
			throw new Error(data.message || 'Unknown error occurred')
		}
	} catch (error) {
		console.error('Error in updateUserAPI:', error)
		throw error
	}
}
