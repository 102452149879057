// src/components/Common/ImageModal.jsx
import React from 'react'
import Modal from 'react-modal'
import CustomImageGallery from '../Post/CustomImageGallery'
import {
	customModalStyles,
	customCommentModalStyles,
} from '../../styles/ModalStyles'

const ImageModal = ({
	isOpen,
	handleCloseModal,
	images,
	startIndex,
	isPost,
}) => {
	const modalStyles = isPost ? customModalStyles : customCommentModalStyles

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={handleCloseModal}
			style={modalStyles}
			contentLabel="Image Gallery"
			shouldCloseOnOverlayClick={true}
		>
			<CustomImageGallery images={images} startIndex={startIndex} />
		</Modal>
	)
}

export default ImageModal
