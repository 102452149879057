// src/components/Common/CustomLink.jsx

import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { sidebarIconStyles } from '../../styles/commonStyles'

const filterProps = (propName) =>
	propName !== 'background' && propName !== 'isActive'

const CustomStyledLink = styled(Link).withConfig({
	shouldForwardProp: (prop) => filterProps(prop),
})`
	position: relative;
	display: flex;
	align-items: center;
	text-decoration: none;
	color: ${({ theme }) => theme.colors.text};
	font-weight: bold;

	&:hover {
		background-color: ${({ theme }) => theme.colors.background};
		border-radius: 50px;
	}

	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		margin-left: 0px;
		padding: 1rem 1rem;
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
		padding: 1rem 2rem;
		margin-left: 80px;
		font-size: 1.2rem;
	}
`

const IconWithBadge = styled.span.withConfig({
	shouldForwardProp: (prop) => filterProps(prop),
})`
	font-family: 'Material Symbols Rounded';
	font-variation-settings: 'FILL' 0, 'wght' 300, 'GRAD' 0, 'opsz' 24;
	${sidebarIconStyles}
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 12px;

	${({ isActive }) =>
		isActive &&
		css`
			font-variation-settings: 'FILL' 1, 'wght' 300, 'GRAD' 0, 'opsz' 24;
		`}

	/* バッジのスタイル */
    .badge {
		font-family: 'Roboto Condensed', sans-serif;
		position: absolute;
		top: 4px;
		right: 6px;
		transform: translate(50%, -50%);
		background-color: ${({ theme }) => theme.colors.primary};
		color: white;
		border: 1px solid #ffffff;
		font-size: 0.7rem;
		font-weight: bold;
		font-family: Noto Sans, sans-serif;
		display: flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		border-radius: 50%;
		min-width: 20px;
		height: 20px;
		padding: 0 6px;
		border-radius: 10px;
		text-align: center;
	}
`

const CustomLink = ({
	children,
	isActive,
	icon,
	onClick,
	unreadCount,
	...props
}) => {
	const displayCount = unreadCount > 99 ? '+99' : unreadCount

	return (
		<CustomStyledLink
			onClick={(e) => {
				e.preventDefault()
				onClick && onClick(e)
			}}
			{...props}
		>
			<IconWithBadge isActive={isActive}>
				{isActive ? icon.active : icon.default}
				{unreadCount > 0 && <span className="badge">{displayCount}</span>}
			</IconWithBadge>
			{children}
		</CustomStyledLink>
	)
}

export default CustomLink
