// src/components/Comment/CommentList.jsx

import React, { useRef, useEffect, useState } from 'react'
import CommentItem from './CommentItem'
import { useConnectorPaths } from '../../hooks/useConnectorPaths'
import styled from 'styled-components'

const CommentListWrapper = styled.div`
	padding: 16px;
`

const CommentsList = React.memo(
	({
		comments,
		postId,
		onCommentAdd,
		onCommentUpdate,
		onCommentDelete,
		userInfo,
	}) => {
		const commentRefs = useRef({})
		const replyRefs = useRef({})
		const replyCountRefs = useRef({})
		const [forceUpdate, setForceUpdate] = useState(0)

		const connectorPaths = useConnectorPaths(
			commentRefs,
			comments,
			'comment',
			replyRefs,
			replyCountRefs
		)

		useEffect(() => {
			setForceUpdate((prev) => prev + 1)
		}, [connectorPaths])

		return (
			<CommentListWrapper>
				{comments.map((comment) => (
					<CommentItem
						key={`${postId}-${comment.id}`}
						ref={(el) => {
							if (el) {
								commentRefs.current[comment.id] = el
							}
						}}
						comment={comment}
						level={comment.level}
						postId={postId}
						parentCommentId={comment.parent_comment_id}
						isDefaultReply={comment.parent_comment_id === null}
						onCommentAdd={onCommentAdd}
						onCommentUpdate={onCommentUpdate}
						onCommentDelete={onCommentDelete}
						userInfo={userInfo}
						connectorPaths={connectorPaths[comment.id] || []}
						forceUpdate={forceUpdate}
						replyRefs={replyRefs}
					/>
				))}
			</CommentListWrapper>
		)
	}
)

export default CommentsList
