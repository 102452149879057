// src/components/Button/CreatePostButton.jsx
import React from 'react'
import StyledButton from '../Button/StyledButton'
import styled from 'styled-components'

const CustomStyledButton = styled(StyledButton)`
	background-color: ${({ theme }) => theme.colors.primary};
	color: #fff;
`

const CreatePostButton = ({ children, onClick, disabled, className }) => {
	return (
		<CustomStyledButton
			className={className}
			onClick={() => !disabled && onClick && onClick()}
			variant="danger"
			disabled={disabled}
		>
			{children}
		</CustomStyledButton>
	)
}

export default CreatePostButton
