// src/components/Button/BackButton.jsx

import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { FaArrowLeft } from 'react-icons/fa'
import { iconSmallStyle, hoverIconStyle } from '../../styles/commonStyles'
import styled from 'styled-components'

const StyledBackButton = styled(FaArrowLeft)`
	${() => iconSmallStyle}
`

const BackButtonContainer = styled.div`
	${() => hoverIconStyle}
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	padding: 10px;
`

const BackButton = ({ backPath, onCustomClick }) => {
	const navigate = useNavigate()
	const location = useLocation()

	const handleBack = () => {
		if (onCustomClick) {
			if (backPath === '/search') {
				navigate('/', {
					state: { from: location.pathname },
				})
			} else {
				onCustomClick()
			}
		} else {
			if (location.state && location.state.from) {
				navigate(location.state.from, {
					state: { from: location.pathname },
				})
			} else {
				if (backPath) {
					if (backPath === '/search') {
						navigate('/', {
							state: { from: location.pathname },
						})
					} else {
						const path = backPath.startsWith('/') ? backPath : `/${backPath}`
						navigate(path, {
							state: { from: location.pathname },
						})
					}
				} else {
					navigate(-1)
				}
			}
		}
	}

	return (
		<BackButtonContainer onClick={handleBack}>
			<StyledBackButton />
		</BackButtonContainer>
	)
}

export default BackButton
