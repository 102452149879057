// src/reducers/translationReducer.js

import { CHANGE_LANGUAGE } from '../actions/translationActions'

const initialState = {
	currentLanguage: 'en',
}

const translationReducer = (state = initialState, action) => {
	switch (action.type) {
		case CHANGE_LANGUAGE:
			return {
				...state,
				currentLanguage: action.payload,
			}
		default:
			return state
	}
}

export default translationReducer
