// src/components/Post/CreatePostModalFooter.jsx

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FaQuestionCircle } from 'react-icons/fa'
import { RiMapPinLine } from 'react-icons/ri'
import { Tooltip } from 'react-tooltip'
import { hoverIconStyle } from '../../styles/commonStyles'
import ImageUploader from '../Common/ImageUploader'

const Footer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	left: 16px;
	right: 16px;
	margin-top: auto;
	position: sticky;
	bottom: 0;
`

const AdviceContainer = styled.div`
	display: flex;
	align-items: center;

	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		margin-top: 0px;
		margin-left: auto;
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
		justify-content: flex-end;
		flex-grow: 1;
		margin-left: 30px;
	}
`

const AdviceText = styled.div`
	display: flex;
	align-items: center;
	margin-right: 14px;
	font-size: ${({ theme }) => theme.fontSizes.medium};
	color: ${({ theme }) => theme.colors.text};
`

const Checkbox = styled.input`
	appearance: none;
	width: 19px;
	height: 19px;
	border-radius: 4px;
	border: 2px solid #575757;
	margin-right: 4px;
	position: relative;
	cursor: pointer;
	flex-shrink: 0;

	&:checked {
		border-color: ${({ theme }) => theme.colors.primary};
		background-color: ${({ theme }) => theme.colors.primary};

		&::before {
			content: '✓';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: white;
			font-size: 18px;
			font-weight: bold;
		}
	}
`

const ImageUploadIconContainer = styled.div`
	display: flex;
	align-items: center;

	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		margin-top: 0px;
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
		position: flex;
		align-items: center;
		bottom: 10px;
		left: 16px;
	}
`

const ImageUploadIcon = styled.div`
	${hoverIconStyle}
`

const MapPinIcon = styled(RiMapPinLine)`
	color: ${({ theme }) => theme.colors.textDescription};
	${hoverIconStyle}
	width: 38px;
	height: 38px;
	margin-left: 8px;
	cursor: pointer;
	margin-left: 16px;
`

const CreatePostModalFooter = ({
	user,
	isQuestion,
	setIsQuestion,
	setIsEdited,
	onImageUpload,
	uploadedImages,
	onMapPinClick,
	setIsLoading,
}) => {
	const { t } = useTranslation()

	useEffect(() => {
		if (user.user_type === 'tourist') {
			setIsQuestion(true)
		}
	}, [user.user_type, setIsQuestion, setIsEdited])

	const renderAdviceContainer = () => {
		if (user.user_type === 'tourist') {
			return (
				<AdviceContainer>
					<AdviceText>
						{t('needTips')}
						<FaQuestionCircle
							data-tooltip-id="tipForTourist"
							data-tooltip-place="top"
							style={{
								marginLeft: '6px',
								color: '#575757',
								cursor: 'pointer',
								fontSize: '14px',
							}}
						/>
					</AdviceText>
					<Tooltip
						id="tipForTourist"
						place="top"
						content="Check this to apply a 'Question' label, making your post more visible to locals."
						effect="solid"
						style={{
							width: '250px',
							maxWidth: '100%',
							padding: '10px',
							borderRadius: '4px',
							fontSize: '15px',
							zIndex: 1000,
						}}
					/>
					<Checkbox
						type="checkbox"
						checked={isQuestion}
						onChange={() => {
							setIsQuestion(!isQuestion)
							setIsEdited(true)
						}}
					/>
				</AdviceContainer>
			)
		}
		return null
	}

	return (
		<Footer>
			<ImageUploadIconContainer>
				<ImageUploadIcon>
					<ImageUploader
						onImageUpload={onImageUpload}
						uploadedImages={uploadedImages}
						maxImages={8}
						setIsLoading={setIsLoading}
					/>
				</ImageUploadIcon>
				<MapPinIcon onClick={onMapPinClick} />
			</ImageUploadIconContainer>
			{renderAdviceContainer()}
		</Footer>
	)
}

export default CreatePostModalFooter
