// src/utils/sseUtils.js

export const establishSSEConnection = (sseUrl, onMessage, onError) => {
	const eventSource = new EventSource(sseUrl)

	eventSource.onmessage = (event) => {
		try {
			const data = JSON.parse(event.data)
			onMessage(data)

			if (data.status === 'completed' || data.status === 'timeout') {
				eventSource.close()
			}
		} catch (error) {
			console.error('Error parsing SSE message:', error)
		}
	}

	eventSource.onerror = (error) => {
		onError(error)
		eventSource.close()
	}

	return eventSource
}

// export const establishSSEConnection = (url, onMessage, onError) => {
// 	console.log('Attempting to establish SSE connection:', url)
// 	const eventSource = new EventSource(url)
// 	let reconnectTimeout

// 	const connect = () => {
// 		eventSource.onopen = () => {
// 			console.log('SSE connection established successfully')
// 			clearTimeout(reconnectTimeout)
// 		}

// 		eventSource.onmessage = (event) => {
// 			console.log('Received SSE message:', event.data)
// 			const data = JSON.parse(event.data)
// 			onMessage(data)

// 			// 翻訳が完了したら接続を閉じる
// 			if (data.status === 'completed') {
// 				eventSource.close()
// 				console.log('SSE connection closed after completion')
// 			}
// 		}

// 		eventSource.onerror = (error) => {
// 			console.warn('SSE connection error. Attempting to reconnect...')
// 			eventSource.close()
// 			reconnectTimeout = setTimeout(connect, 5000) // 5秒後に再接続
// 			onError(error)
// 		}
// 	}

// 	connect()

// 	return {
// 		close: () => {
// 			eventSource.close()
// 			clearTimeout(reconnectTimeout)
// 			console.log('SSE connection manually closed')
// 		},
// 	}
// }
