import { useTranslation } from 'react-i18next'
import { useSnackbar } from '../context/SnackbarContext'
import useExtractHashtags from '../hooks/useExtractHashtags'

const useValidateHashtags = () => {
	const { extractHashtags } = useExtractHashtags()
	const showAlert = useSnackbar()
	const { t } = useTranslation()

	const validateHashtags = (plainText) => {
		const hashtags = extractHashtags(plainText)

		for (const hashtag of hashtags) {
			if (isMaliciousHashtag(hashtag)) {
				showAlert(t('invalidHashtag'), 'error')
				return false
			}
		}

		if (hashtags.length > 6) {
			showAlert(t('hashtagLimit'), 'error')
			return false
		}

		for (const hashtag of hashtags) {
			if (hashtag.length > 30) {
				showAlert(t('hashtagLengthLimit'), 'error')
				return false
			}
		}

		return true
	}

	return { validateHashtags }
}

const isMaliciousHashtag = (hashtag) => {
	const xssRegex =
		/<script|<\/script>|javascript:|onload=|onerror=|alert\(|eval\(|document\.|window\./i
	if (xssRegex.test(hashtag)) {
		return true
	}

	const sqlRegex =
		/select|insert|update|delete|drop|truncate|union|exec|declare|--|'/i
	if (sqlRegex.test(hashtag)) {
		return true
	}

	const redirectRegex = /https?:\/\/|www\./i
	if (redirectRegex.test(hashtag)) {
		return true
	}

	return false
}

export { useValidateHashtags, isMaliciousHashtag }
