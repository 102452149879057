// src/components/RenderTextWithLinks.jsx

import React from 'react'
import extractLinks from '../utils/extractLinks'
import styled from 'styled-components'

const Hashtag = styled.span`
	margin-right: 5px;
	color: ${({ theme }) => theme.colors.link};
	cursor: pointer;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
`

const HighlightedText = styled.span`
	font-weight: bold;
	background-color: yellow;
`

const StyledLink = styled.a`
	color: ${({ theme }) => theme.colors.link};
	word-break: break-word;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
`

const RenderTextWithLinks = ({
	text,
	shortenedUrls = [],
	onLinkClick,
	post = {},
	onHashtagClick,
	selectedHashtag,
	searchedHashtag,
	searchedKeyword,
}) => {
	if (!text) {
		return null
	}

	const getGoogleMapsUrl = (url) => {
		const googleMapsUrl = new URL(url)
		const query = googleMapsUrl.searchParams.get('q')
		if (query) {
			if (
				/iPhone|iPad|iPod/i.test(navigator.userAgent) &&
				/CriOS/i.test(navigator.userAgent)
			) {
				return `comgooglemaps://?q=${encodeURIComponent(query)}`
			}
		}
		return url
	}

	const handleClick = (e, url) => {
		if (shortenedUrls && shortenedUrls.includes(url)) {
			e.preventDefault()
			onLinkClick(e, url)
			if (
				/iPhone|iPad|iPod/i.test(navigator.userAgent) &&
				/CriOS/i.test(navigator.userAgent)
			) {
				window.location.href = getGoogleMapsUrl(url)
			}
		}
	}

	const parts = extractLinks(text, post?.locations || [])

	const renderHashtag = (hashtag, index) => {
		const hashtagText = hashtag.trim().slice(1)
		const selectedHashtagWithoutSharp = selectedHashtag
			? selectedHashtag.replace('#', '')
			: ''
		const searchedHashtagWithoutSharp = searchedHashtag
			? searchedHashtag.replace('#', '')
			: ''
		const isSelected =
			(selectedHashtag &&
				hashtagText.toLowerCase() ===
					selectedHashtagWithoutSharp.toLowerCase()) ||
			(searchedHashtag &&
				hashtagText.toLowerCase() ===
					searchedHashtagWithoutSharp.toLowerCase()) ||
			(searchedKeyword &&
				hashtagText.toLowerCase().includes(searchedKeyword.toLowerCase()))

		const key = `${hashtag}-${index}-${post.id}`

		return (
			<Hashtag
				key={key}
				onClick={(e) => onHashtagClick(e, hashtagText)}
				style={{ fontWeight: isSelected ? 'bold' : 'normal' }}
			>
				#{hashtagText}
			</Hashtag>
		)
	}

	const renderHighlightedText = (text) => {
		if (!searchedKeyword) {
			return text
		}

		const regex = new RegExp(`(${searchedKeyword})`, 'gi')
		const parts = text.split(regex)

		return parts.map((part, index) => {
			const key = `${part}-${index}`

			return part.toLowerCase() === searchedKeyword.toLowerCase() ? (
				<HighlightedText key={key}>{part}</HighlightedText>
			) : (
				part
			)
		})
	}

	const renderText = (text) => {
		const parts = text.split(/(#[^#\s]+)/g)

		return parts.map((part, index) => {
			if (part.startsWith('#')) {
				return renderHashtag(part, index)
			} else {
				return <span key={`text-${index}`}>{renderHighlightedText(part)}</span>
			}
		})
	}

	return (
		<span>
			{parts.map((part, index) => {
				const key =
					part.type === 'url'
						? `${index}-url-${post.id}`
						: `${index}-text-${post.id}`

				return part.type === 'url' ? (
					<React.Fragment key={key}>
						<StyledLink
							href={part.full}
							target="_blank"
							rel="noopener noreferrer"
							onClick={(e) => handleClick(e, part.full)}
						>
							{part.shortened}
						</StyledLink>
						{part.content && ' '}
						{part.content && part.content.endsWith('\n') && <br />}
					</React.Fragment>
				) : (
					<React.Fragment key={key}>
						{part.content.split('\n').map((line, lineIndex, linesArray) => {
							const lineKey = `${index}-${lineIndex}-${post.id}`

							return (
								<React.Fragment key={lineKey}>
									{renderText(line)}
									{lineIndex < linesArray.length - 1 && <br />}
								</React.Fragment>
							)
						})}
					</React.Fragment>
				)
			})}
		</span>
	)
}

export default RenderTextWithLinks
