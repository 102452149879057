// src/components/Settings/ChangePassword.jsx

import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import MainLayout from '../Layout/MainLayout'
import {
	verifyCurrentPasswordApi,
	changePasswordApi,
} from '../../services/changePasswordApi'
import styled from 'styled-components'
import ForgotPasswordModal from '../../components/Login/ForgotPasswordModal'
import {
	Container,
	Form,
	Button,
	InputGroup,
	FormControl,
	FormCheck,
} from 'react-bootstrap'
import { validatePassword } from '../../utils/validation'
import { useSnackbar } from '../../context/SnackbarContext'
import SaveButton from '../Button/SaveButton'

const PageTitle = styled.div`
	font-size: ${({ theme }) => theme.fontSizes.extraLarge};
	font-weight: bold;
	padding: 20px 20px 0px;
`

const ChangePasswordContainer = styled(Container)`
	padding: 20px 20px 0px;
`

const StyledFormGroup = styled(Form.Group)`
	padding: 0px 0px 14px;
	font-size: ${({ theme }) => theme.fontSizes.medium};
`

const StyledFormControl = styled(FormControl)`
	height: 52px;
	padding: 6px 12px;
	font-weight: bold;
`

const ClickableText = styled.span`
	cursor: pointer;
	display: inline-block;
	margin-top: 4px;
	color: ${({ theme }) => theme.colors.link};
	font-size: ${({ theme }) => theme.fontSizes.small};
	text-decoration: underline;
`

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end; // コンテンツをコンテナの右端に寄せる
	margin-top: 30px; // 必要に応じてマージンを調整
`

const ChangePassword = () => {
	const [showPassword, setShowPassword] = useState(false)
	const [formErrors, setFormErrors] = useState({ password: '' })
	const { t } = useTranslation()
	const location = useLocation()
	const backPath = location.state?.from || '/settings'
	const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false)

	const showAlert = useSnackbar()

	const handleSubmit = async (e) => {
		e.preventDefault()
		const currentPassword = e.target.currentPassword.value
		const newPassword = e.target.newPassword.value
		const confirmPassword = e.target.confirmPassword.value

		// 新しいパスワードと確認用パスワードが一致するかチェック
		if (newPassword !== confirmPassword) {
			setFormErrors({ confirmPassword: t('passwordsNotMatch') })
			return
		}

		// 新しいパスワードが適切な長さを持っているかチェック
		const newPasswordError = validatePassword(newPassword)
		if (newPasswordError) {
			setFormErrors((errors) => ({ ...errors, newPassword: newPasswordError }))
			return
		}

		try {
			const response = await verifyCurrentPasswordApi(currentPassword)

			if (response.isValid) {
				await changePasswordApi(currentPassword, newPassword)
				showAlert(t('changePasswordSuccessfully'), 'success')
				setFormErrors({})
			} else {
				setFormErrors({
					currentPassword: t('passwordIncorrect'),
				})
			}
		} catch (error) {
			console.error('Error during password change:', error)
			showAlert(t('Error during password change.'), 'error')
		}
	}

	// ForgotPasswordModalを開く関数
	const handleOpenForgotPasswordModal = () => setShowForgotPasswordModal(true)

	// ForgotPasswordModalを閉じる関数
	const handleCloseForgotPasswordModal = () => setShowForgotPasswordModal(false)

	return (
		<MainLayout showBackButton={true} backPath={backPath}>
			<PageTitle>{t('changePasswordTitle')}</PageTitle>
			<ChangePasswordContainer>
				<Form onSubmit={handleSubmit} noValidate>
					{/* ... (Current password group remains unchanged) */}
					<StyledFormGroup controlId="currentPassword">
						<Form.Label>{t('currentPassword')}</Form.Label>
						<InputGroup>
							<StyledFormControl
								type={showPassword ? 'text' : 'password'}
								// placeholder="Current password"
								isInvalid={!!formErrors.currentPassword}
							/>
							<Form.Control.Feedback type="invalid">
								{t(formErrors.currentPassword)}
							</Form.Control.Feedback>
						</InputGroup>
						<ClickableText onClick={handleOpenForgotPasswordModal}>
							{t('forgotPassword')}
						</ClickableText>
					</StyledFormGroup>

					{/* ... (New password group with the checkbox) */}
					<StyledFormGroup controlId="newPassword">
						<Form.Label>{t('newPassword')}</Form.Label>
						<InputGroup>
							<StyledFormControl
								type={showPassword ? 'text' : 'password'}
								// placeholder="Enter new password"
								isInvalid={!!formErrors.newPassword}
							/>
							<Form.Control.Feedback type="invalid">
								{t(formErrors.newPassword)}
							</Form.Control.Feedback>
						</InputGroup>
					</StyledFormGroup>

					{/* ... (Confirm password group with the checkbox) */}
					<StyledFormGroup controlId="confirmPassword">
						<Form.Label>{t('confirmPassword')}</Form.Label>
						<InputGroup>
							<StyledFormControl
								type={showPassword ? 'text' : 'password'}
								// placeholder="Enter confirm password"
								isInvalid={!!formErrors.confirmPassword}
							/>
							<Form.Control.Feedback type="invalid">
								{t(formErrors.confirmPassword)}
							</Form.Control.Feedback>
						</InputGroup>
					</StyledFormGroup>

					<FormCheck
						type="checkbox"
						label={t('showPassword')}
						checked={showPassword}
						onChange={() => setShowPassword(!showPassword)}
					/>
					<ButtonContainer>
						<SaveButton type="submit">{t('saveButton')}</SaveButton>
					</ButtonContainer>
				</Form>
			</ChangePasswordContainer>
			<ForgotPasswordModal
				show={showForgotPasswordModal}
				handleClose={handleCloseForgotPasswordModal}
				useCloseButton={true}
			/>
		</MainLayout>
	)
}

export default ChangePassword
