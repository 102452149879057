// src/components/PostList/PostList.jsx

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PostItem from '../Post/PostItem'
import { fetchCommentsCount } from '../../actions/commentActions'
import { EditingCommentProvider } from '../../context/EditingCommentContext'
import { Divider } from '../../styles/commonStyles'

const PostList = ({ posts = [], isHashtagView = false, selectedHashtag }) => {
	const dispatch = useDispatch()

	useEffect(() => {
		posts.forEach((post) => {
			dispatch(fetchCommentsCount(post.id))
		})
	}, [dispatch, posts])

	if (!Array.isArray(posts)) {
		return null
	}

	return (
		<EditingCommentProvider>
			<div className="timeline">
				{posts
					.filter((post) => post !== undefined)
					.map((post, index) => {
						const key = `post-${post.id}-${index}`

						return (
							<React.Fragment key={key}>
								<PostItem
									post={post}
									isHashtagView={isHashtagView}
									selectedHashtag={selectedHashtag}
								/>
								{index < posts.length - 1 && <Divider />}
							</React.Fragment>
						)
					})}
			</div>
		</EditingCommentProvider>
	)
}

// メモ化してパフォーマンスを最適化
export default React.memo(PostList)
