// src/services/commentApi.js

const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const postComment = async (formData) => {
	try {
		const response = await fetch(`${BASE_URL}/comments`, {
			method: 'POST',
			body: formData,
			credentials: 'include',
		})

		if (!response.ok) {
			const errorResponse = await response.json()
			throw new Error(`${errorResponse.messages?.comment}`)
		}

		const responseData = await response.json()

		return responseData
	} catch (error) {
		throw error
	}
}

export const fetchCommentsByPost = async (postId) => {
	try {
		const response = await fetch(`${BASE_URL}/comments/post/${postId}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
		})

		if (!response.ok) {
			throw new Error('Network response was not ok')
		}

		const commentsData = await response.json()

		return {
			comments: commentsData,
			commentsCount: commentsData.length,
		}
	} catch (error) {
		throw error
	}
}

export const getUserCommentsAPI = async (userId, offset) => {
	try {
		const url = new URL(`${BASE_URL}/getUserComments/${userId}`)
		url.searchParams.append('offset', offset)

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
		})

		if (!response.ok) {
			throw new Error('Network response was not ok')
		}

		const responseData = await response.json()

		return responseData
	} catch (error) {
		throw error
	}
}

export const fetchCommentsCountApi = async (postId) => {
	try {
		const response = await fetch(`${BASE_URL}/comments/count/${postId}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
		})

		if (!response.ok) {
			throw new Error('Network response was not ok')
		}

		const data = await response.json()
		return data.commentsCount
	} catch (error) {
		console.error('Error fetching comments count:', error)
		throw error
	}
}

// コメントを削除する関数
export const deleteCommentApi = async (commentId) => {
	try {
		const response = await fetch(`${BASE_URL}/comments/delete/${commentId}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
		})

		if (!response.ok) {
			throw new Error('API response was not ok.')
		}

		const data = await response.json()
		return data

		// return response.json();
	} catch (error) {
		console.error(error)
		return null
	}
}

export const updateCommentApi = async (commentId, formData) => {
	try {
		const response = await fetch(`${BASE_URL}/comments/update/${commentId}`, {
			method: 'POST',
			body: formData,
			credentials: 'include',
		})

		if (!response.ok) {
			throw new Error('Network response was not ok')
		}

		const updatedComment = await response.json()

		return updatedComment
	} catch (error) {
		throw error
	}
}
