// src/components/Button/CloseIcon.jsx
import React from 'react'
import { MdClose } from 'react-icons/md'

const CloseIcon = ({ onClick, size = 20, color = '#262626' }) => {
	const defaultStyle = {
		cursor: 'pointer',
		fontSize: size,
		color: color,
	}

	return <MdClose style={defaultStyle} onClick={onClick} />
}

export default CloseIcon
