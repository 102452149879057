// src/reducers/searchReducer.js

import { createReducer } from '@reduxjs/toolkit'
import * as actions from '../actions/searchActions'
import { DELETE_SEARCH_POST_SUCCESS } from '../actions/postAction'

const initialState = {
	results: [],
	cachedResults: [],
	suggestions: [],
	loadingSuggestions: false,
	errorSuggestions: null,
	loading: false,
	error: null,
	hasMore: true,
	nextOffset: 0,
	searchedHashtag: null,
	scrollPosition: 0,
	keyword: '',
	useCache: false,
}

const removeDuplicates = (results) => {
	const seen = new Set()
	return results.filter((item) => {
		const duplicate = seen.has(item._id)
		seen.add(item._id)
		return !duplicate
	})
}

const normalizeSearchResults = (hits) => {
	return hits.map((hit) => {
		return {
			...hit,
			user_data: hit.user_data,
			post_images: hit._source.post_images || [],
			likes_count: hit._source.likes_count || 0,
			comments_count: hit.comments_count || 0,
			locations: hit.locations || [],
			images: hit.images || [],
		}
	})
}

const searchReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(actions.FETCH_SUGGESTIONS_REQUEST, (state) => {
			state.loadingSuggestions = true
			state.errorSuggestions = null
		})
		.addCase(actions.FETCH_SUGGESTIONS_SUCCESS, (state, action) => {
			state.suggestions = action.payload
			state.loadingSuggestions = false
		})
		.addCase(actions.FETCH_SUGGESTIONS_FAILURE, (state, action) => {
			state.loadingSuggestions = false
			state.errorSuggestions = action.payload
				? { message: action.payload.message, status: action.payload.status }
				: { message: 'Unknown error', status: 500 }
		})
		.addCase(actions.SEARCH_REQUEST, (state) => {
			state.loading = true
			state.error = null
			state.results = []
		})
		.addCase(actions.SEARCH_SUCCESS, (state, action) => {
			state.loading = false
			state.results = action.payload.results.hits.hits
			state.cachedResults = action.payload.results.hits.hits
			state.hasMore = action.payload.hasMore
			state.nextOffset = action.payload.nextOffset
			state.error = null
		})
		.addCase(actions.SEARCH_FAILURE, (state, action) => {
			state.loading = false
			state.error = {
				message: action.payload.message,
				status: action.payload.status,
			}
		})
		.addCase(actions.LOAD_MORE_RESULTS_REQUEST, (state) => {
			state.loading = true
		})
		.addCase(actions.LOAD_MORE_RESULTS_SUCCESS, (state, action) => {
			if (
				action.payload &&
				action.payload.results &&
				action.payload.results.hits
			) {
				state.loading = false
				state.results = removeDuplicates([
					...state.results,
					...normalizeSearchResults(action.payload.results.hits.hits),
				])
				state.hasMore = action.payload.hasMore
				state.nextOffset = action.payload.nextOffset
				state.error = null
			} else {
				state.error = { message: 'Invalid response structure', status: 500 }
			}
		})
		.addCase(actions.LOAD_MORE_RESULTS_FAILURE, (state, action) => {
			state.loading = false
			state.error = action.payload
		})
		.addCase(DELETE_SEARCH_POST_SUCCESS, (state, action) => {
			state.results = state.results.filter((hit) => hit._id !== action.payload)
		})
		.addCase(actions.SEARCH_BY_HASHTAG_REQUEST, (state, action) => {
			state.loading = true
			state.error = null
			if (state.searchedHashtag !== action.payload) {
				state.results = []
			}
		})
		.addCase(actions.SEARCH_BY_HASHTAG_SUCCESS, (state, action) => {
			state.loading = false
			state.results = removeDuplicates(
				action.payload.nextOffset === 0
					? normalizeSearchResults(action.payload.results.hits.hits)
					: [
							...state.results,
							...normalizeSearchResults(action.payload.results.hits.hits),
					  ]
			)
			state.hasMore = action.payload.hasMore
			state.nextOffset = action.payload.nextOffset
			state.searchedHashtag = action.payload.hashtag
			state.error = null
		})
		.addCase(actions.SEARCH_BY_HASHTAG_FAILURE, (state, action) => {
			state.loading = false
			state.error = {
				message: action.payload.message,
				status: action.payload.status,
			}
		})
		.addCase(actions.setKeyword, (state, action) => {
			state.keyword = action.payload
		})
		.addCase(actions.setScrollPosition, (state, action) => {
			state.scrollPosition = action.payload
		})
})

export default searchReducer
