// src/components/Sidebar/SidebarLeft.jsx

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CustomLink from '../Common/CustomLink'
import MobileNavbar from '../MobileNavbar/MobileNavbar'
import { setActiveLink } from '../../actions/navigationActions'
import { fetchAndCountUnreadNotifications } from '../../actions/notificationsActions'

import styled from 'styled-components'
import { Divider } from '../../styles/commonStyles'

import {
	PiHouseBold,
	PiHouseFill,
	PiBellBold,
	PiBellFill,
	PiBookmarkSimpleBold,
	PiBookmarkSimpleFill,
	PiUserBold,
	PiUserFill,
	PiGearSixBold,
	PiGearSixFill,
} from 'react-icons/pi'

import { MdOutlineFeedback } from 'react-icons/md'
import { MdHelpOutline } from 'react-icons/md'

const TopLinksContainer = styled.div`
	margin: 0 0 28px;

	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		margin: 0 0 16px;
	}
`

const SidebarWrapper = styled.div`
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		margin-top: 0;
	}
`

const BottomLinksContainer = styled.div`
	margin: 28px 0 28px;

	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		margin: 20px 0 0;
	}
`

const BottomContents = styled.div`
	margin-top: 0px;
	font-weight: normal;
	font-size: ${({ theme }) => theme.fontSizes.medium};
`

const PolicyLinksContainer = styled.div`
	display: flex;
	justify-content: center;
	padding: 20px 20px;
	font-size: ${({ theme }) => theme.fontSizes.tiny};
	color: ${({ theme }) => theme.colors.textSecondary};
`

const PolicyLink = styled.a`
	color: ${({ theme }) => theme.colors.text};
	text-decoration: none;
	margin-right: 15px;

	&:hover {
		text-decoration: underline;
	}
`

const SidebarLeft = () => {
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
	const unreadCount = useSelector((state) => state.notifications.unreadCount)
	const currentUserId = useSelector((state) => state.auth.userId)
	const dispatch = useDispatch()
	const activeLink = useSelector((state) => state.activeLink.activeLink)
	const navigate = useNavigate()
	const location = useLocation()
	const { t } = useTranslation()

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768)
		}

		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	useEffect(() => {
		if (currentUserId) {
			dispatch(fetchAndCountUnreadNotifications(currentUserId))
		}
	}, [currentUserId, dispatch])

	const handleLinkClick = (path) => {
		if (path === '/profile' && currentUserId) {
			navigate(`/profile/${currentUserId}`)
		} else if (path === '/notifications') {
			navigate('/notifications')
		} else {
			navigate(path)
		}
		dispatch(setActiveLink(path))
		localStorage.setItem('activeLinkPath', path)
	}

	useEffect(() => {
		const profilePathRegex = /^\/profile\/(\d+)$/
		const match = profilePathRegex.exec(location.pathname)
		const profileUserId = match ? match[1] : null

		if (profileUserId && profileUserId !== currentUserId?.toString()) {
			dispatch(setActiveLink(''))
		} else {
			const currentPath = location.pathname
			const isActiveProfileLink =
				currentPath.startsWith('/profile') &&
				currentPath.endsWith(currentUserId.toString())
			const pathToSetAsActive = isActiveProfileLink ? '/profile' : currentPath
			dispatch(setActiveLink(pathToSetAsActive))
		}
	}, [location.pathname, currentUserId, dispatch])

	const helpCenterLink = () => {
		const userLang = navigator.language || navigator.userLanguage
		return userLang.startsWith('ja')
			? process.env.REACT_APP_HELP_URL_JP
			: process.env.REACT_APP_HELP_URL_EN
	}

	if (isMobile) {
		return <MobileNavbar />
	}

	// 利用規約とプライバシーポリシーのURLを環境変数から取得
	const termsUrl = navigator.language.startsWith('ja')
		? process.env.REACT_APP_TERMS_URL_JP
		: process.env.REACT_APP_TERMS_URL_EN
	const privacyPolicyUrl = navigator.language.startsWith('ja')
		? process.env.REACT_APP_PRIVACY_POLICY_URL_JP
		: process.env.REACT_APP_PRIVACY_POLICY_URL_EN

	return (
		<SidebarWrapper>
			{isAuthenticated ? (
				// ログインしている時の項目
				<TopLinksContainer>
					<CustomLink
						to="/"
						isActive={activeLink === '/'}
						onClick={() => handleLinkClick('/')}
						icon={{
							active: <PiHouseFill />,
							default: <PiHouseBold />,
						}}
					>
						{t('home')}
					</CustomLink>

					<CustomLink
						to="/notifications"
						isActive={activeLink === '/notifications'}
						onClick={() => handleLinkClick('/notifications')}
						icon={{
							active: <PiBellFill />,
							default: <PiBellBold />,
						}}
						unreadCount={unreadCount}
					>
						{t('notifications')}
					</CustomLink>

					<CustomLink
						to="/bookmarks"
						isActive={activeLink === '/bookmarks'}
						onClick={() => handleLinkClick('/bookmarks')}
						icon={{
							active: <PiBookmarkSimpleFill />,
							default: <PiBookmarkSimpleBold />,
						}}
					>
						{t('bookmarks')}
					</CustomLink>
					<CustomLink
						isActive={activeLink === '/profile'}
						onClick={() => handleLinkClick('/profile')}
						icon={{
							active: <PiUserFill />,
							default: <PiUserBold />,
						}}
					>
						{t('profile')}
					</CustomLink>
					<CustomLink
						to="/settings"
						isActive={activeLink === '/settings'}
						onClick={() => handleLinkClick('/settings')}
						icon={{
							active: <PiGearSixFill />,
							default: <PiGearSixBold />,
						}}
					>
						{t('settings')}
					</CustomLink>
				</TopLinksContainer>
			) : (
				// ログインしていない時の項目
				<TopLinksContainer>
					<CustomLink
						to="/"
						isActive={activeLink === '/'}
						onClick={() => handleLinkClick('/')}
						icon={{
							active: <PiHouseFill />,
							default: <PiHouseBold />,
						}}
					>
						{t('home')}
					</CustomLink>
					<CustomLink
						to="/bookmarks"
						isActive={activeLink === '/bookmarks'}
						onClick={() => handleLinkClick('/bookmarks')}
						icon={{
							active: <PiBookmarkSimpleFill />,
							default: <PiBookmarkSimpleBold />,
						}}
					>
						{t('bookmarks')}
					</CustomLink>
				</TopLinksContainer>
			)}
			<Divider />
			<BottomLinksContainer>
				<CustomLink
					onClick={() => window.open(helpCenterLink(), '_blank')}
					icon={{
						default: <MdOutlineFeedback size={20} />,
					}}
				>
					<BottomContents>{t('helpCenter')}</BottomContents>
				</CustomLink>

				<CustomLink
					to="/settings/feedback"
					isActive={activeLink === '/settings/feedback'}
					onClick={() => handleLinkClick('/settings/feedback')}
					icon={{
						default: <MdHelpOutline size={20} />,
					}}
				>
					<BottomContents>{t('sendFeedback')}</BottomContents>
				</CustomLink>
			</BottomLinksContainer>
			<Divider />
			<PolicyLinksContainer>
				<PolicyLink href={termsUrl} target="_blank">
					{t('termsOfService')}
				</PolicyLink>
				<PolicyLink href={privacyPolicyUrl} target="_blank">
					{t('privacyPolicy')}
				</PolicyLink>
			</PolicyLinksContainer>
		</SidebarWrapper>
	)
}

export default SidebarLeft
