// src/services/searchApi.js

const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const searchKeyword = async (keyword, offset) => {
	try {
		const response = await fetch(`${BASE_URL}/search?offset=${offset}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ keyword }),
			credentials: 'include',
		})

		if (!response.ok) {
			throw new Error(
				`Server responded with ${response.status}: ${response.statusText}`
			)
		}

		const searchResult = await response.json()

		return searchResult
	} catch (error) {
		console.error('Error during search:', error.message)
		throw new Error(error.message)
	}
}

export const searchByHashtag = async (hashtag, offset) => {
	try {
		const response = await fetch(
			`${BASE_URL}/search/hashtag?offset=${offset}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ hashtag }),
				credentials: 'include',
			}
		)

		if (!response.ok) {
			const errorData = await response.json()
			throw new Error(
				`Server responded with ${response.status}: ${errorData.error}`
			)
		}

		const searchResult = await response.json()

		return searchResult
	} catch (error) {
		console.error('Error during hashtag search:', error.message)
		throw error
	}
}
