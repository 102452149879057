// src/components/Comment/ReplySubmitter.jsx

import React, { useState, useRef, useCallback, useEffect } from 'react'
import { Editor } from 'draft-js'
import useReplySubmitter from '../../hooks/useReplySubmitter'
import CustomAvatar from '../Avatar/Avatar'
import ReplyFooterContainer from './ReplyFooterContainer'
import LoadingSpinner from '../Common/LoadingSpinner'

import deleteIcon from '../../assets/icon/deleteImage.svg'
import styled, { css } from 'styled-components'

const AvatarContainer = styled.div`
	margin-right: 4px;
`

const TextareaAndUploadContainer = styled.div.withConfig({
	shouldForwardProp: (prop) => !['isEditing'].includes(prop),
})`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: ${({ theme }) => theme.colors.background};
	flex-grow: 1;
	border-radius: 20px;
	padding: 8px 10px 8px;
	max-width: 92%;
`

const StyledEditorContainer = styled.div.withConfig({
	shouldForwardProp: (prop) => !['isFocused', 'isEditing'].includes(prop),
})`
	border: none;
	resize: none;
	background: transparent;
	flex-grow: 1;
	position: relative;
	font-size: ${({ theme }) => theme.fontSizes.mediumComment};
	word-wrap: break-word;
	overflow-wrap: break-word;

	${({ $isFocused, $isEditing }) =>
		$isFocused || $isEditing
			? css`
					margin-bottom: 8px;
					min-height: 0px;
			  `
			: css`
					margin-bottom: 0;
					min-height: 24px;
			  `}

	&:focus {
		outline: none;
	}
	.public-DraftEditorPlaceholder-root {
		position: absolute;
		color: #999;
		pointer-events: none;
	}
`

const ReplySubmitterWrapper = styled.div`
	display: flex;
	flex-direction: column;
	background: #fff;
	border-radius: 22px;
	padding: 14px 16px;
	padding-top: ${({ $isPostDetail }) => ($isPostDetail ? '14px' : '0')};
	padding-bottom: ${({ $isPostDetail }) => ($isPostDetail ? '14px' : '10px')};
	padding-left: ${({ $isReply }) => ($isReply ? '0px' : '16px')};
	padding-right: ${({ $isReply }) => ($isReply ? '0px' : '16px')};
`

const InputRow = styled.div`
	display: flex;
	align-items: flex-start;
	width: 100%;
`

const ImagePreviewContainer = styled.div`
	position: relative;
	align-items: flex-start;
	margin-top: 8px;
	margin-left: 44px;
	width: fit-content;
`

const ImagePreview = styled.img`
	max-width: 100px;
	border-radius: 4px;
	display: block;
`

const DeleteIcon = styled.img`
	position: absolute;
	top: -10px;
	right: -10px;
	cursor: pointer;
	width: 24px;
	height: 24px;
	background: #fff;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
`

const ReplySubmitter = React.memo(
	({
		placeholder,
		onSubmit,
		username,
		avatarUrl,
		initialText,
		isEditing = false,
		onCancel,
		initialImage,
		isReply,
		editingComment,
		isPostDetail = false,
		isFocused = false,
	}) => {
		// console.count('ReplySubmitter render')

		const editorRef = useRef(null)
		const [isComponentFocused, setIsComponentFocused] = useState(
			isFocused || isEditing
		)
		const {
			editorState,
			uploadedImage,
			handleEditorStateChange,
			handleImageUpload,
			handleDeleteImage,
			handleFormSubmit,
			isSubmitted,
		} = useReplySubmitter(initialText, initialImage, onSubmit, isReply)

		const [isLoading, setIsLoading] = useState(false)
		const text = editorState.getCurrentContent().getPlainText()
		const isSubmitDisabled = text.trim() === ''

		// console.count('isReply: ', isReply)

		const handleFocus = useCallback(() => {
			if (editorRef.current) {
				editorRef.current.focus()
			}
			setIsComponentFocused(true)
		}, [])

		const handleBlur = useCallback(() => {
			if (text.trim() === '' && !uploadedImage) {
				setIsComponentFocused(false)
			}
		}, [text, uploadedImage])

		useEffect(() => {
			if ((isFocused || isEditing) && editorRef.current) {
				editorRef.current.focus()
				setIsComponentFocused(true)
			}
		}, [isFocused, isEditing])

		if (isSubmitted) return null

		return (
			<>
				<ReplySubmitterWrapper
					$isPostDetail={isPostDetail}
					$isReply={isReply}
					onClick={(e) => e.stopPropagation()}
				>
					<InputRow>
						{!isSubmitted && (
							<>
								<AvatarContainer>
									<CustomAvatar
										username={username}
										avatar={avatarUrl}
										size="comment"
									/>
								</AvatarContainer>
								<TextareaAndUploadContainer $isEditing={isEditing}>
									{/* {isEditing && (
										<HeaderComponent
											username={username}
											createdAt={editingComment?.created_at}
										/>
									)} */}
									<StyledEditorContainer
										$isFocused={isComponentFocused || isEditing}
										$isEditing={isEditing}
										onClick={handleFocus}
										onBlur={handleBlur}
									>
										<Editor
											ref={editorRef}
											editorState={editorState}
											onChange={handleEditorStateChange}
											placeholder={placeholder}
										/>
									</StyledEditorContainer>
									{(isComponentFocused || isEditing) && (
										<ReplyFooterContainer
											isEditing={isEditing}
											onCancel={onCancel}
											uploadedImage={uploadedImage}
											handleImageUpload={handleImageUpload}
											setIsLoading={setIsLoading}
											handleFormSubmit={handleFormSubmit}
											isSubmitDisabled={isSubmitDisabled}
											isFocused={isComponentFocused || isEditing}
										/>
									)}
								</TextareaAndUploadContainer>
							</>
						)}
					</InputRow>
					{isLoading && <LoadingSpinner />}
					{uploadedImage && (
						<ImagePreviewContainer>
							<ImagePreview src={uploadedImage.url} alt="Uploaded" />
							<DeleteIcon src={deleteIcon} onClick={handleDeleteImage} />
						</ImagePreviewContainer>
					)}
				</ReplySubmitterWrapper>
			</>
		)
	}
)

export default ReplySubmitter
