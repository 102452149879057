// src/components/Post/CreatePostModalContent.jsx

import React, { useRef, useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ReactDOM from 'react-dom'
import { Editor, EditorState, ContentState } from 'draft-js'
import compositeDecorator from '../../decorators/compositeDecorator'
import CustomAvatar from '../Avatar/Avatar'
import ImageListModal from './ImageListModal'
import LoadingSpinner from '../Common/LoadingSpinner'
import { useValidateHashtags } from '../../hooks/useValidateHashtags'
import { useHashtagHandlers } from '../../hooks/useHashtagHandlers'

const ModalContainer = styled.div`
	position: relative;
	overflow: visible;
`

const Container = styled.div`
	overflow-y: auto;
	flex: 1;

	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		max-height: calc(100vh - 200px);
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
		max-height: calc(100vh - 200px);
	}
`

const StyledEditor = styled.div`
	width: 100%;
	margin-top: 6px;
	border: none;
	resize: none;
	overflow-y: auto;
	font-size: ${({ theme }) => theme.fontSizes.regular};
	flex-grow: 1;
	line-height: 1.5;
	padding: 8px;
	box-sizing: border-box;
	white-space: pre-wrap;

	&:focus {
		outline: none;
	}

	.public-DraftEditorPlaceholder-root {
		position: absolute;
		color: #999;
		pointer-events: none;
	}
`

const HashtagItem = styled.div`
	padding: 4px;
	cursor: pointer;

	&:hover {
		background-color: ${({ theme }) => theme.colors.background};
	}
`

const StyledDropdown = styled.div`
	position: fixed;
	background-color: white;
	border: none;
	border-radius: 4px;
	padding: 16px;
	z-index: 1500;
	min-width: 340px;
	max-height: 240px;
	overflow-y: auto;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		bottom: 0;
		left: 0;
		right: 0;
		min-width: 200px;
		border-radius: 4px;
		overflow-y: auto;
	}
`

const CreatePostModalContent = React.memo(
	({
		user,
		uploadedImages,
		onImageDelete,
		post,
		draft,
		setIsEdited,
		editorState,
		setEditorState,
	}) => {
		const [isLoading, setIsLoading] = useState(false)
		const [isInitialFocus, setIsInitialFocus] = useState(true)
		const editorRef = useRef(null)
		const dropdownRef = useRef(null)
		const { t } = useTranslation()
		const { validateHashtags } = useValidateHashtags()

		const {
			isHashtagDropdownOpen,
			hashtagDropdownPosition,
			selectedHashtagIndex,
			handleHashtagSelect,
			handleHashtagKeyDown,
			handleHashtagTextChange,
			recommendedHashtags,
		} = useHashtagHandlers(
			editorState,
			setEditorState,
			validateHashtags,
			dropdownRef
		)

		const applyCompositeDecorator = useCallback((editorState) => {
			const selectionState = editorState.getSelection()
			const newEditorState = EditorState.set(editorState, {
				decorator: compositeDecorator,
			})

			return EditorState.forceSelection(newEditorState, selectionState)
		}, [])

		useEffect(() => {
			if (editorRef.current && isInitialFocus) {
				editorRef.current.focus()
				setIsInitialFocus(false)
			}
		}, [isInitialFocus])

		useEffect(() => {
			if (post || draft) {
				const content = post?.body || draft?.body || ''
				const contentState = ContentState.createFromText(content)
				let newEditorState = EditorState.createWithContent(
					contentState,
					compositeDecorator
				)
				newEditorState = applyCompositeDecorator(newEditorState)
				setEditorState(newEditorState)
			}
		}, [post, draft, setEditorState])

		const handleTextChange = useCallback((newEditorState) => {
			try {
				const newText = newEditorState.getCurrentContent().getPlainText()
				const currentText = editorState.getCurrentContent().getPlainText()

				if (newText === currentText) {
					setEditorState(newEditorState)
					return
				}

				if (newText.length === 0) {
					setEditorState(newEditorState)
					return
				}

				if (!validateHashtags(newText)) {
					return
				}

				if (newText !== currentText) {
					if (validateHashtags(newText)) {
						const decoratedEditorState = applyCompositeDecorator(newEditorState)
						setEditorState(decoratedEditorState)
						setIsEdited(true)
						handleHashtagTextChange(decoratedEditorState)
					}
				} else {
					setIsEdited(newEditorState)
				}
			} catch (error) {
				console.error('Error in handleTextChange:', error)
			}
		})

		return (
			<ModalContainer onKeyDown={handleHashtagKeyDown}>
				<Container tabIndex={0}>
					{isLoading && <LoadingSpinner />}
					<CustomAvatar username={user?.username} avatar={user?.avatar} />
					<StyledEditor onClick={() => editorRef.current.focus()}>
						<Editor
							ref={editorRef}
							editorState={editorState}
							onChange={handleTextChange}
							placeholder={t('ShareTravelTips')}
						/>
					</StyledEditor>
					<ImageListModal
						images={uploadedImages}
						onImageDelete={onImageDelete}
					/>
				</Container>
				{isHashtagDropdownOpen &&
					ReactDOM.createPortal(
						<StyledDropdown
							ref={dropdownRef}
							style={hashtagDropdownPosition}
							className="hashtag-dropdown"
						>
							{recommendedHashtags?.data?.map((item, index) => (
								<HashtagItem
									key={item.hashtag}
									onClick={() => handleHashtagSelect(item.hashtag)}
									className={index === selectedHashtagIndex ? 'selected' : ''}
								>
									#{item.hashtag}
								</HashtagItem>
							))}
						</StyledDropdown>,
						document.body
					)}
			</ModalContainer>
		)
	}
)

export default React.memo(CreatePostModalContent)
