// src/actions/searchActions.js

import { searchKeyword, searchByHashtag } from '../services/searchApi'
import { createAction } from '@reduxjs/toolkit'

export const FETCH_SUGGESTIONS_REQUEST = 'FETCH_SUGGESTIONS_REQUEST'
export const FETCH_SUGGESTIONS_SUCCESS = 'FETCH_SUGGESTIONS_SUCCESS'
export const FETCH_SUGGESTIONS_FAILURE = 'FETCH_SUGGESTIONS_FAILURE'

export const SEARCH_REQUEST = 'SEARCH_REQUEST'
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS'
export const SEARCH_FAILURE = 'SEARCH_FAILURE'

export const LOAD_MORE_RESULTS_REQUEST = 'LOAD_MORE_RESULTS_REQUEST'
export const LOAD_MORE_RESULTS_SUCCESS = 'LOAD_MORE_RESULTS_SUCCESS'
export const LOAD_MORE_RESULTS_FAILURE = 'LOAD_MORE_RESULTS_FAILURE'

export const SEARCH_BY_HASHTAG_REQUEST = 'SEARCH_BY_HASHTAG_REQUEST'
export const SEARCH_BY_HASHTAG_SUCCESS = 'SEARCH_BY_HASHTAG_SUCCESS'
export const SEARCH_BY_HASHTAG_FAILURE = 'SEARCH_BY_HASHTAG_FAILURE'
export const SET_CACHED_RESULTS = 'SET_CACHED_RESULTS'
export const CLEAR_CACHED_RESULTS =
	'SEARCCLEAR_CACHED_RESULTSH_BY_HASHTAG_FAILURE'

export const DELETE_SEARCH_POST_SUCCESS = 'DELETE_SEARCH_POST_SUCCESS'

export const setKeyword = createAction('search/setKeyword')
export const setResults = createAction('search/setResults')
export const setLoading = createAction('search/setLoading')
export const setHasMore = createAction('search/setHasMore')
export const setNextOffset = createAction('search/setNextOffset')
export const setSearchedHashtag = createAction('search/setSearchedHashtag')
export const setScrollPosition = createAction('search/setScrollPosition')

export const searchRequestAction =
	(keyword, offset = 0) =>
	async (dispatch, getState) => {
		const { search } = getState()

		if (search.useCache && search.cachedResults.length > 0 && offset === 0) {
			dispatch(setResults(search.cachedResults))
			dispatch(setLoading(false))
			return
		}

		if (offset === 0) {
			dispatch(setLoading(true))
			dispatch(setKeyword(keyword))
			dispatch({ type: SEARCH_REQUEST })
		} else {
			dispatch({ type: LOAD_MORE_RESULTS_REQUEST })
		}

		try {
			const results = await searchKeyword(keyword, offset)
			const nextOffset = offset + results.hits.hits.length
			const normalizedResults = results.hits.hits.map((hit) => ({
				...hit,
				comments_count: hit.comments_count || 0,
			}))

			const updatedResults =
				offset === 0
					? normalizedResults
					: [...search.results, ...normalizedResults]

			dispatch(setResults(updatedResults))
			dispatch(setHasMore(results.hits.total.value > nextOffset))
			dispatch(setNextOffset(nextOffset))
			dispatch({
				type: offset === 0 ? SEARCH_SUCCESS : LOAD_MORE_RESULTS_SUCCESS,
				payload: {
					results: {
						hits: {
							hits: normalizedResults,
							total: results.hits.total,
						},
					},
					hasMore: results.hits.total.value > nextOffset,
					nextOffset,
				},
			})
		} catch (error) {
			dispatch({
				type: offset === 0 ? SEARCH_FAILURE : LOAD_MORE_RESULTS_FAILURE,
				payload: {
					message: error.message || 'An unknown error occurred',
					status: error.response ? error.response.status : 500,
				},
			})
		} finally {
			dispatch(setLoading(false))
		}
	}

export const searchByHashtagAction =
	(hashtag, offset = 0) =>
	async (dispatch, getState) => {
		const { search } = getState()
		const { searchedHashtag } = search

		if (hashtag !== searchedHashtag) {
			dispatch({
				type: SEARCH_BY_HASHTAG_REQUEST,
			})
		} else {
			dispatch({
				type:
					offset === 0 ? SEARCH_BY_HASHTAG_REQUEST : LOAD_MORE_RESULTS_REQUEST,
			})
		}

		try {
			const results = await searchByHashtag(hashtag, offset)
			const nextOffset = offset + results.hits.hits.length

			dispatch({
				type:
					offset === 0 || hashtag !== searchedHashtag
						? SEARCH_BY_HASHTAG_SUCCESS
						: LOAD_MORE_RESULTS_SUCCESS,
				payload: {
					results,
					hashtag,
					hasMore: results.hits.total.value > nextOffset,
					nextOffset,
				},
			})
		} catch (error) {
			dispatch({
				type:
					offset === 0 || hashtag !== searchedHashtag
						? SEARCH_BY_HASHTAG_FAILURE
						: LOAD_MORE_RESULTS_FAILURE,
				payload: {
					message: error.message,
					status: error.response ? error.response.status : 500,
				},
			})
		}
	}

export const setScrollPositionAction = (position) => (dispatch) => {
	dispatch(setScrollPosition(position))
}
