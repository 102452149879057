// src/hooks/useBuildFormData.js

import { useCallback } from 'react'
import useExtractHashtags from './useExtractHashtags'
import { generatePlaceLink, generateShortUrl } from '../utils/locationUtils'

const useBuildFormData = ({
	editorState,
	user,
	deletedImageIds,
	uploadedImages,
	selectedLocations,
	isQuestion,
	savedPostId,
}) => {
	const { extractHashtags } = useExtractHashtags()

	const buildFormData = useCallback(
		(isDraft) => {
			const formData = new FormData()
			const content = editorState.getCurrentContent().getPlainText()
			const hashtags = extractHashtags(content)

			formData.append('type', 'post')
			formData.append('user_id', user.id)
			formData.append('body', content)
			formData.append('visible', 1)
			formData.append('publish_status', isDraft ? 'draft' : 'published')

			deletedImageIds.forEach((id) => {
				formData.append('deleted_image_ids[]', id)
			})

			uploadedImages.forEach((image, index) => {
				if (image.file && image.file instanceof Blob) {
					formData.append(`images[${index}]`, image.file, image.file.name)
				}
			})

			if (Array.isArray(selectedLocations) && selectedLocations.length > 0) {
				selectedLocations.forEach((location, index) => {
					if (location.place_id && location.place_id !== 'undefined') {
						formData.append(`locations[${index}][name]`, location.name)
						formData.append(`locations[${index}][address]`, location.address)
						formData.append(`locations[${index}][place_id]`, location.place_id)
						formData.append(
							`locations[${index}][types]`,
							JSON.stringify(location.types)
						)
						formData.append(`locations[${index}][rating]`, location.rating)
						formData.append(
							`locations[${index}][user_ratings_total]`,
							location.user_ratings_total
						)
						formData.append(
							`locations[${index}][business_status]`,
							location.business_status
						)
						formData.append(
							`locations[${index}][price_level]`,
							location.price_level
						)
						formData.append(`locations[${index}][website]`, location.website)
						formData.append(`locations[${index}][lat]`, location.lat)
						formData.append(`locations[${index}][lng]`, location.lng)
						formData.append(
							`locations[${index}][place_link]`,
							generatePlaceLink(location)
						)
						formData.append(
							`locations[${index}][shortened_url]`,
							generateShortUrl(location)
						)
					}
				})
			}

			formData.append('hashtags', JSON.stringify(hashtags))
			formData.append('question_label', isQuestion ? 1 : 0)

			if (savedPostId) {
				formData.append('post_id', savedPostId)
			}

			return formData
		},
		[
			editorState,
			extractHashtags,
			deletedImageIds,
			uploadedImages,
			selectedLocations,
			isQuestion,
			savedPostId,
			user,
		]
	)

	return { buildFormData }
}

export default useBuildFormData
