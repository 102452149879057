// src/actions/notificationsActions.js

import { fetchUserNotifications as fetchUserNotificationsApi } from '../services/notificationsApi'

export const UPDATE_UNREAD_NOTIFICATIONS_COUNT =
	'UPDATE_UNREAD_NOTIFICATIONS_COUNT'
export const UPDATE_LAST_FETCHED = 'UPDATE_LAST_FETCHED'
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS'
export const UPDATE_NOTIFICATION_STATUS = 'UPDATE_NOTIFICATION_STATUS'

// 未読通知数を更新するアクションクリエーター
export const updateUnreadNotificationsCount = (count) => ({
	type: UPDATE_UNREAD_NOTIFICATIONS_COUNT,
	payload: count,
})

export const updateLastFetched = () => {
	return {
		type: UPDATE_LAST_FETCHED,
		payload: Date.now(),
	}
}

// 通知データを更新するアクションクリエーター
export const updateNotifications = (notifications) => {
	return {
		type: UPDATE_NOTIFICATIONS,
		payload: notifications,
	}
}

// 通知ステータスを更新するアクションクリエーター
export const updateNotificationStatusAction = (notificationId, status) => ({
	type: UPDATE_NOTIFICATION_STATUS,
	payload: { notificationId, status },
})

// ユーザーの通知をフェッチし、未読通知の数を更新する非同期アクションクリエーター
export const fetchAndCountUnreadNotifications =
	(userId) => async (dispatch) => {
		try {
			const data = await fetchUserNotificationsApi(userId)
			const unreadCount = data.notifications.filter(
				(notification) => notification.status === 'unread'
			).length
			dispatch(updateUnreadNotificationsCount(unreadCount))
		} catch (error) {
			console.error('Error fetching notifications:', error)
			// エラーハンドリングのアクションをディスパッチするか、エラー状態を更新することもできます
		}
	}
