// src/components/Common/HeaderComponent.jsx

import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import AvatarWithFlag from '../Avatar/AvatarWithFlag'
import useNavigateToProfile from '../../hooks/useNavigateToProfile'
import PostMenuPopover from './PostMenuPopover'
import styled, { css } from 'styled-components'

const HeaderContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;

	${({ type }) =>
		type === 'post' &&
		css`
			margin-bottom: 4px;
		`}

	${({ type }) =>
		type === 'comment' &&
		css`
			margin-bottom: 0px;
		`}
`

const UserInfoContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: nowrap;
`

const UserName = styled.span`
	font-weight: bold;
	margin-right: 10px;
	margin-left: 10px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	flex-grow: 1;
	max-width: 60%;
	font-size: 0.9rem;
	margin-right: 8px;

	${({ type }) =>
		type === 'post'
			? css`
					margin-left: 10px;
			  `
			: css`
					font-size: 0.8rem;
					margin-left: 0px;
			  `}
	${({ type }) =>
		type === 'draft' &&
		css`
			margin-left: 10px; // 'draft' の場合に適用される追加のスタイル
		`}
`

const PostDate = styled.span`
	white-space: nowrap;
	font-size: 0.9rem;
	color: ${({ theme }) => theme.colors.textSecondary};

	${({ type }) =>
		type === 'post'
			? css`
					// margin-left: 10px;
			  `
			: css`
					font-size: 0.8rem;
			  `}
`

const StyledPostMenuPopover = styled(PostMenuPopover)`
	cursor: pointer;
`

const QuestionLabel = styled.span`
	background-color: rgba(213, 0, 78, 0.2);
	color: ${({ theme }) => theme.colors.primary};
	border-radius: 50px;
	padding: 2px 8px;
	font-size: ${({ theme }) => theme.fontSizes.tiny};
	margin-left: 12px;
`

const HeaderComponent = React.memo(
	({
		username,
		avatar,
		userId,
		createdAt,
		postOwnerId,
		postId,
		commentId,
		draftId,
		type,
		originalType,
		onDelete,
		post,
		comment,
		draft,
		onEditComment,
		showPostMenu = true,
		countryCode,
		isEditing,
	}) => {
		const { t } = useTranslation()
		const [anchorEl, setAnchorEl] = useState(null)
		const [iconTop, setIconTop] = useState(null)
		const navigateToProfile = useCallback(useNavigateToProfile(), [])
		const handleAvatarClick = useCallback(navigateToProfile(postOwnerId), [
			postOwnerId,
		])

		const formatDate = (dateString) => {
			const date = new Date(dateString)
			if (!isNaN(date)) {
				return date.toISOString().split('T')[0]
			}
			return dateString.split(' ')[0]
		}
		const formattedCreatedAt = createdAt ? formatDate(createdAt) : ''

		const questionLabel =
			post &&
			post.question_label === '1' &&
			post.user &&
			post.user.user_type === 'tourist'

		const handleMenuClick = (event) => {
			event.stopPropagation()
			const iconRect = event.currentTarget.getBoundingClientRect()
			const iconTop = iconRect.top
			setIconTop(iconTop)
			setAnchorEl(event.currentTarget)
		}

		const handleClose = (e) => {
			e && e.stopPropagation()
			setAnchorEl(null)
		}

		const isAvatarWithFlag =
			type === 'post' ||
			originalType === 'bookmark' ||
			originalType === 'profile'

		return (
			<HeaderContainer type={type}>
				<UserInfoContainer>
					{isAvatarWithFlag && (
						<AvatarWithFlag
							username={username}
							avatar={avatar}
							size={type === 'profile' ? 'profile' : 'default'}
							onClick={handleAvatarClick}
							countryCode={countryCode}
						/>
					)}
					<UserName type={type}>{username}</UserName>
					<PostDate type={type}>{formattedCreatedAt}</PostDate>
					{questionLabel && <QuestionLabel>{t('questionLabel')}</QuestionLabel>}
				</UserInfoContainer>
				{showPostMenu && (
					<StyledPostMenuPopover
						userId={userId}
						postOwnerId={postOwnerId}
						handleMenuClick={handleMenuClick}
						anchorEl={anchorEl}
						handleClose={handleClose}
						postId={postId}
						commentId={commentId}
						type={type}
						onDelete={onDelete}
						post={post}
						comment={comment}
						draft={draft}
						draftId={draftId}
						onEditComment={onEditComment}
						originalType={originalType}
						iconTop={iconTop}
					/>
				)}
			</HeaderContainer>
		)
	}
)

export default HeaderComponent
