// src/hooks/useHashtagHandlers.js

import { useState, useCallback, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchRecommendedHashtags } from '../actions/hashtagActions'
import { EditorState, Modifier } from 'draft-js'

export const useHashtagHandlers = (
	editorState,
	setEditorState,
	validateHashtags,
	dropdownRef,
	theme
) => {
	const dispatch = useDispatch()
	const [isHashtagDropdownOpen, setIsHashtagDropdownOpen] = useState(false)
	const [hashtagDropdownPosition, setHashtagDropdownPosition] = useState({
		top: 0,
		left: 0,
	})
	const [selectedHashtagIndex, setSelectedHashtagIndex] = useState(0)
	const { recommendedHashtags } = useSelector((state) => state.hashtag)

	const handleHashtagSelect = useCallback(
		(hashtag) => {
			const contentState = editorState.getCurrentContent()
			const selectionState = editorState.getSelection()

			let startOffset = selectionState.getStartOffset()
			let blockText = contentState
				.getBlockForKey(selectionState.getStartKey())
				.getText()
			while (startOffset > 0 && blockText[startOffset - 1] !== '#') {
				startOffset--
			}

			const hashtagSelectionState = selectionState.merge({
				anchorOffset: startOffset,
				focusOffset: selectionState.getEndOffset(),
			})

			const hashtagText =
				typeof hashtag === 'object' ? hashtag.hashtag : hashtag

			const newContentState = Modifier.replaceText(
				contentState,
				hashtagSelectionState,
				`${hashtagText} `
			)

			let newEditorState = EditorState.push(
				editorState,
				newContentState,
				'insert-characters'
			)

			const newSelection = newEditorState.getSelection().merge({
				anchorOffset: startOffset + hashtagText.length + 1, // ハッシュタグの長さ + スペース
				focusOffset: startOffset + hashtagText.length + 1,
			})
			newEditorState = EditorState.forceSelection(newEditorState, newSelection)

			const plainText = newEditorState.getCurrentContent().getPlainText()

			if (validateHashtags(plainText)) {
				setEditorState(newEditorState)
			}
			setIsHashtagDropdownOpen(false)
		},
		[editorState, validateHashtags]
	)

	const calculateDropdownPosition = useCallback(
		(selectedBlockRect, windowHeight, dropdownHeight) => {
			if (
				window.innerWidth <=
				(theme?.breakpoints?.mobile || '768px').replace('px', '')
			) {
				return {
					top: selectedBlockRect.bottom + window.pageYOffset + 10 + 'px',
					left: '0',
					right: '0',
				}
			}

			let adjustedTop = selectedBlockRect.top + 120
			if (adjustedTop + dropdownHeight > windowHeight) {
				adjustedTop = windowHeight - dropdownHeight - 20
			}

			if (selectedBlockRect.bottom + dropdownHeight > windowHeight) {
				adjustedTop = selectedBlockRect.top - dropdownHeight - 20
			} else {
				adjustedTop = selectedBlockRect.bottom + 10
			}

			return {
				top: adjustedTop + 'px',
				left: selectedBlockRect.left + 'px',
			}
		},
		[theme]
	)

	const handleHashtagTextChange = useCallback(
		(newEditorState) => {
			const selectionState = newEditorState.getSelection()
			const anchorOffset = selectionState.getAnchorOffset()
			const contentState = newEditorState.getCurrentContent()
			const blockText = contentState
				.getBlockForKey(selectionState.getStartKey())
				.getText()

			// カーソルがハッシュタグの直後にあるか、またはハッシュタグの途中であるかを確認
			const hashtagRegex = /#([^\s]*)$/
			const match = hashtagRegex.exec(blockText.slice(0, anchorOffset))

			if (match) {
				const partialHashtag = match[1]
				const blockKey = selectionState.getStartKey()
				const selectedBlockElement = document.querySelector(
					`[data-offset-key="${blockKey}-0-0"]`
				)
				const selectedBlockRect = selectedBlockElement.getBoundingClientRect()

				const dropdownHeight = 240
				const windowHeight = window.innerHeight

				const position = calculateDropdownPosition(
					selectedBlockRect,
					windowHeight,
					dropdownHeight
				)
				setHashtagDropdownPosition(position)
				setIsHashtagDropdownOpen(true)
				setSelectedHashtagIndex(0)
				dispatch(fetchRecommendedHashtags(partialHashtag))
			} else {
				setIsHashtagDropdownOpen(false)
			}
		},
		[calculateDropdownPosition, dispatch]
	)

	const handleHashtagKeyDown = useCallback(
		(event) => {
			if (isHashtagDropdownOpen) {
				if (event.key === 'Enter') {
					event.preventDefault()
					const selectedHashtag =
						recommendedHashtags?.data?.[selectedHashtagIndex]
					if (selectedHashtag) {
						handleHashtagSelect(selectedHashtag)
					}
				} else if (event.key === 'ArrowUp') {
					event.preventDefault()
					setSelectedHashtagIndex((prevIndex) =>
						prevIndex > 0 ? prevIndex - 1 : prevIndex
					)
				} else if (event.key === 'ArrowDown') {
					event.preventDefault()
					setSelectedHashtagIndex((prevIndex) =>
						prevIndex < recommendedHashtags?.data?.length - 1
							? prevIndex + 1
							: prevIndex
					)
				} else if (event.key === 'Escape') {
					event.preventDefault()
					setIsHashtagDropdownOpen(false)
				}
			}
		},
		[
			isHashtagDropdownOpen,
			selectedHashtagIndex,
			recommendedHashtags,
			handleHashtagSelect,
		]
	)

	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsHashtagDropdownOpen(false)
			}
		}

		const handleEscKey = (event) => {
			if (event.key === 'Escape' && isHashtagDropdownOpen) {
				setIsHashtagDropdownOpen(false)
			}
		}

		const handleInputChange = (event) => {
			// メニューを閉じたあと、テキストが入力されてもメニューが再表示されないようにする
			if (isHashtagDropdownOpen) {
				setIsHashtagDropdownOpen(false)
			}
		}

		if (isHashtagDropdownOpen) {
			document.addEventListener('mousedown', handleOutsideClick)
			document.addEventListener('touchstart', handleOutsideClick)
			document.addEventListener('keydown', handleEscKey)
			document.addEventListener('input', handleInputChange)
		}

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick)
			document.removeEventListener('touchstart', handleOutsideClick)
			document.removeEventListener('keydown', handleEscKey)
			document.removeEventListener('input', handleInputChange)
		}
	}, [isHashtagDropdownOpen, dropdownRef])

	return {
		isHashtagDropdownOpen,
		hashtagDropdownPosition,
		selectedHashtagIndex,
		handleHashtagSelect,
		handleHashtagKeyDown,
		handleHashtagTextChange,
		handleHashtagKeyDown,
		setIsHashtagDropdownOpen,
		recommendedHashtags,
	}
}
