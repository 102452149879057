// src/hooks/useReplySubmitter.js

import { useState, useEffect, useCallback } from 'react'
import { EditorState, ContentState } from 'draft-js'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from '../context/SnackbarContext'

const useReplySubmitter = (
	initialText,
	initialImage,
	onSubmit,
	isReply,
	isEditing
) => {
	const [editorState, setEditorState] = useState(() =>
		EditorState.createWithContent(
			ContentState.createFromText(initialText || '')
		)
	)
	const [uploadedImage, setUploadedImage] = useState(
		initialImage ? initialImage : null
	)
	const [isTextareaFocused, setIsTextareaFocused] = useState(false)
	const [isSubmitted, setIsSubmitted] = useState(false)
	const showAlert = useSnackbar()
	const { t } = useTranslation()

	const handleEditorStateChange = useCallback((newEditorState) => {
		setEditorState(newEditorState)
	}, [])

	const handleImageUpload = (newImages) => {
		if (newImages.length > 0) {
			setUploadedImage(newImages[0])
		}
	}

	const handleDeleteImage = (e) => {
		e.stopPropagation()
		setUploadedImage(null)
	}

	// フォーカスの状態を管理する
	const handleFocus = (e) => {
		e.stopPropagation()
		setIsTextareaFocused(true)
	}

	const handleBlur = (e) => {
		e.stopPropagation()
		if (!e.currentTarget.contains(e.relatedTarget)) {
			setTimeout(() => {
				if (!editorState.getCurrentContent().hasText()) {
					setIsTextareaFocused(false)
				}
			}, 200)
		}
	}

	// 編集中のコメントが変更されたときにテキストを更新
	useEffect(() => {
		setEditorState(
			EditorState.createWithContent(
				ContentState.createFromText(initialText || '')
			)
		)
		setUploadedImage(initialImage ? { url: initialImage } : null)
	}, [initialText, initialImage, isEditing])

	const handleFormSubmit = async (e) => {
		e.preventDefault()
		e.stopPropagation()

		const trimmedText = editorState.getCurrentContent().getPlainText().trim()

		if (trimmedText.length > 2000) {
			showAlert(t('exceedComment'), 'error')
			return
		}

		const formData = {
			text: trimmedText,
			image: uploadedImage?.file || null,
		}

		if (!uploadedImage && initialImage) {
			formData.imageDeleted = true
		}

		try {
			await onSubmit(formData)
			setEditorState(EditorState.createEmpty())
			setUploadedImage(null)
			if (isReply) {
				setIsSubmitted(true)
			}
			showAlert(t('submittedSuccessfully'), 'success')
		} catch (error) {
			showAlert(error.message || t('submissionFailed'), 'error')
		}
	}

	return {
		editorState,
		setEditorState,
		uploadedImage,
		isTextareaFocused,
		handleEditorStateChange,
		handleImageUpload,
		handleDeleteImage,
		handleFocus,
		handleBlur,
		handleFormSubmit,
		isSubmitted,
	}
}

export default useReplySubmitter
