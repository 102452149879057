// src/hooks/useCreateFormDataComment.js

import { useCallback } from 'react'

export const useCreateFormDataComment = (postId, userInfo) => {
	return useCallback(
		(data, isReply = false, editingCommentId = null) => {
			const formData = new FormData()
			formData.append('postId', postId)
			formData.append('userId', userInfo.id)
			formData.append('comment', data.text)
			formData.append('username', userInfo.username)
			formData.append('avatarUrl', userInfo.avatar)
			formData.append('type', isReply ? 'reply' : 'comment')

			if (data.parentCommentId) {
				formData.append('parentCommentId', data.parentCommentId)
			}

			if (data.image instanceof Blob) {
				formData.append('image', data.image)
			}

			if (editingCommentId) {
				formData.append('id', editingCommentId)
			}

			return formData
		},
		[postId, userInfo]
	)
}
