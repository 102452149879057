import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

const BASE_URL = process.env.REACT_APP_API_BASE_URL
const fpPromise = FingerprintJS.load()

function QRRedirect() {
	const navigate = useNavigate()
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const qrId = searchParams.get('qr')

	useEffect(() => {
		async function logQRScan() {
			if (!qrId) {
				navigate('/')
				return
			}

			localStorage.setItem('qrReferrerId', qrId)

			try {
				const fp = await fpPromise
				const result = await fp.get()

				const response = await fetch(`${BASE_URL}/log-qr-scan`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						qr_id: qrId,
						device_fingerprint: result,
					}),
					credentials: 'include',
				})
				if (!response.ok) {
					const errorText = await response.text()
					throw new Error(
						`Failed to log QR scan: ${response.status} ${errorText}`
					)
				}
				await response.json()

				navigate('/')
			} catch (error) {
				navigate('/')
			}
		}

		logQRScan()
	}, [qrId, navigate])

	return <div>Redirecting...</div>
}

export default QRRedirect
