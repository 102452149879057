// src/components/Button/Button.jsx

import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css' // Bootstrapのスタイルをインポート

const Button = ({ children, variant, className, onClick, disabled }) => {
	// Bootstrapのクラスに加えて、追加のカスタムクラスを適用する
	const buttonClass = `btn btn-${variant} ${className}`

	return (
		<button className={buttonClass} onClick={onClick} disabled={disabled}>
			{children}
		</button>
	)
}

export default Button
