// src/hooks/useModal.js

import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'

export const useModal = () => {
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [clickedImageIndex, setClickedImageIndex] = useState(0)
	const dispatch = useDispatch()

	const openModal = (index = 0) => {
		setClickedImageIndex(index)
		setModalIsOpen(true)
	}

	// const closeModal = () => {
	// 	setModalIsOpen(false)
	// }
	const closeModal = useCallback(() => {
		setModalIsOpen(false)
		// モーダルを閉じる際にスクロール位置を復元するアクションをディスパッチ
		dispatch({ type: 'SET_SHOULD_RESTORE_SCROLL', payload: true })
	}, [dispatch])

	// const handleCloseModal = (e) => {
	// 	if (e && e.stopPropagation) e.stopPropagation()
	// 	closeModal()
	// }
	const handleCloseModal = useCallback(
		(e) => {
			if (e && e.stopPropagation) e.stopPropagation()
			closeModal()
		},
		[closeModal]
	)

	return [
		modalIsOpen,
		openModal,
		closeModal,
		setModalIsOpen,
		clickedImageIndex,
		setClickedImageIndex,
		handleCloseModal,
	]
}
