// src/components/Avatar/CountryFlag.jsx

import React from 'react'
import styled from 'styled-components'
import 'flag-icon-css/css/flag-icons.min.css'

const CountryFlagIcon = styled.div`
	line-height: 0;
	border-radius: 50%;
	overflow: hidden;
	border: 1px solid #f0edf0;
	width: ${({ size }) => (size === 'large' ? '17px' : '15px')};
	height: ${({ size }) => (size === 'large' ? '17px' : '15px')};
	background-color: white;

	.flag-icon {
		display: block;
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
`

const CountryFlag = ({ countryCode, size }) => {
	return (
		<CountryFlagIcon size={size}>
			<span
				className={`flag-icon flag-icon-${countryCode.toLowerCase()} flag-icon-squared`}
			></span>
		</CountryFlagIcon>
	)
}

export default CountryFlag
