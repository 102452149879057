// src/components/Profile/ProfileContent.jsx

import React from 'react'
import { ProfileInfo } from './ProfileInfo'
import styled from 'styled-components'

const ProfileContentWrapper = styled.div`
	width: 100%;
	padding: 0px 20px 0px;

	@media (min-width: 768px) {
		// flex: 1;
	}
`

const ProfileContent = React.memo(({ user }) => {
	return (
		<ProfileContentWrapper>
			<ProfileInfo user={user} />
		</ProfileContentWrapper>
	)
})

export default ProfileContent
