// src/components/Profile/ProfileEditForm.jsx

import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { countries } from 'countries-list'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { updateUserProfile } from '../../actions/userActions'
import { setUserType } from '../../actions/userActions'

import ImageUploader from '../Common/ImageUploader'
import { Modal } from 'react-bootstrap'
import noScroll from 'no-scroll'

import SaveButton from '../Button/SaveButton'
import { FaTrashAlt } from 'react-icons/fa'
import { RiCamera2Fill } from 'react-icons/ri'
import { IoIosArrowDown } from 'react-icons/io'

import CustomAvatar from '../Avatar/Avatar'
import ConfirmationModal from '../Common/ConfirmationModal'
import styled from 'styled-components'
import { hoverIconStyle } from '../../styles/commonStyles'
import { useSnackbar } from '../../context/SnackbarContext'

const StyledModal = styled(Modal)`
	.modal-dialog {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.modal-content {
		// スマートフォン用スタイル
		@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			border-radius: 0;
			padding: 0px 0px 10px;
		}

		// PCブラウザ用スタイル
		@media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
			border-radius: 20px;
			padding: 12px 12px 20px;
			min-width: 560px;
			min-height: 360px;
			max-height: 560px;
		}
	}
`

const TitleBar = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 20px;
`

const Title = styled.div`
	flex: 1;
	text-align: center;
	font-weight: bold;
	font-size: ${({ theme }) => theme.fontSizes.extraLarge};
`

const fieldMarginBottom = '4px'
const inputMarginBottom = '20px'

const CloseButton = styled.button`
	${hoverIconStyle}
	background: none;
	border: none;
	cursor: pointer;
`

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: auto;
	max-height: 80%;
	padding: 16px;
`

const ProfileAvatarWrapper = styled.div`
	position: relative;
	margin: 10px 0px 30px;
`

const StyledIconBackground = styled.div`
	position: absolute;
	bottom: 10px;
	right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	border-radius: 50%;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
	padding: 10px;
	width: 34px;
	height: 34px;
`

const StyledCameraIcon = styled(RiCamera2Fill)`
	font-size: 20px;
	color: black;
	cursor: pointer;
`

const StyledTrashIcon = styled(FaTrashAlt)`
	font-size: 20px;
	color: black;
	cursor: pointer;
`

const StyledUsernameArea = styled.label`
	margin-bottom: 10px;
	width: 100%;
	margin-bottom: ${inputMarginBottom};
`

const StyledBioArea = styled.label`
	margin-bottom: 10px;
	width: 100%;
	margin-bottom: ${inputMarginBottom};
`
const StyledNationalityArea = styled.label`
	margin-bottom: 10px;
	width: 100%;
	margin-bottom: ${inputMarginBottom};
`
const StyledRegidenceArea = styled.label`
	margin-bottom: 10px;
	width: 100%;
	margin-bottom: ${inputMarginBottom};
`

const InputLabel = styled.label`
	display: block;
	font-size: 16px;
	color: #333;
	margin: 10px 0 5px;
	font-weight: bold;
`

const Input = styled.input`
	width: 100%;
	height: 56px;
	margin-bottom: ${fieldMarginBottom};
	padding: 10px;
	border-radius: 5px;
	border: 1px solid #ccc;
`

const BioAreaStyled = styled.textarea`
	width: 100%;
	height: auto;
	min-height: 180px;
	margin-bottom: ${fieldMarginBottom};
	padding: 10px;
	border-radius: 5px;
	border: 1px solid #ccc;
	resize: none;
`

const UsernameCounter = styled.div`
	text-align: right;
	flex: 1;
	font-size: 14px;
`

const ErrorMessage = styled.p`
	color: red;
	flex: 1;
	font-size: 14px;
`

const BioCounter = styled.div`
	text-align: right;
	font-size: 14px;
`

const BioFlexRow = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
`

const FlexRow = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`

const StyledUserTypeArea = styled.div`
	margin-bottom: 10px;
	width: 100%;
	margin-bottom: ${inputMarginBottom};
`

const customSelectStyles = {
	control: (base) => ({
		...base,
		width: '260px',
		height: '56px',
		marginBottom: fieldMarginBottom,
		cursor: 'pointer',
	}),
	menuPortal: (base) => ({
		...base,
		zIndex: 1200,
	}),
}

const TouristDescription = styled.label`
	display: flex;
	align-items: center;
	margin-top: 10px;
	font-size: ${({ theme }) => theme.fontSizes.small};
	cursor: pointer;
`

const countryOptions = countries
	? [
			{ value: '', label: 'Select None' },
			...Object.keys(countries).map((countryCode) => {
				const country = countries[countryCode]
				return { value: countryCode, label: country.name }
			}),
	  ]
	: [{ value: '', label: 'Select None' }]

const ProfileEditForm = React.memo(({ user, isOpen, handleCloseModal }) => {
	const dispatch = useDispatch()
	const { t, i18n } = useTranslation()
	const currentLanguage = i18n.language
	const [username, setUsername] = useState(user?.username)
	const [bio, setBio] = useState('')
	const [nationality, setNationality] = useState(null)
	const [residence, setResidence] = useState('')
	const [avatarFile, setAvatarFile] = useState(null)
	const [avatarUrl, setAvatarUrl] = useState(user?.avatar)
	const [initialStateSet, setInitialStateSet] = useState(false)
	const [isEdited, setIsEdited] = useState(false)
	const [showConfirmModal, setShowConfirmModal] = useState(false)
	const [avatarRemoved, setAvatarRemoved] = useState(false)
	const [countryCode, setCountryCode] = useState(user?.country_code || '')
	const [userType, setUserTypeState] = useState(user?.user_type || '')
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const showAlert = useSnackbar()

	const userTypeOptions = [
		{ value: 'tourist', label: t('userTypeTourist') },
		{ value: 'resident', label: t('userTypeResident') },
		{ value: 'other', label: t('userTypeOther') },
	]

	useEffect(() => {
		if (user?.profiles) {
			const profile =
				user.profiles[currentLanguage] ||
				user.profiles[user.default_language] ||
				{}

			setBio(profile.bio || '')
			setResidence(profile.residence || '')

			const initialNationality = user.country_code
				? countryOptions.find((option) => option.value === user.country_code)
				: null
			setNationality(initialNationality)

			setCountryCode(user?.country_code || '')
			setUserType(user?.user_type || '')
			setAvatarUrl(user?.avatar || '')

			setInitialStateSet(true)
		}
	}, [user, currentLanguage])

	useEffect(() => {
		if (!initialStateSet) return

		const profile =
			user?.profiles?.[currentLanguage] ||
			user?.profiles?.[user.default_language] ||
			{}

		const hasNationalityChanged =
			nationality === null || profile.nationality === undefined
				? nationality !== profile.nationality
				: nationality.value !== user.country_code
		const hasAvatarChanged =
			avatarFile !== null || (avatarUrl !== user?.avatar && avatarRemoved)

		if (
			username !== user?.username ||
			bio !== profile.bio ||
			hasNationalityChanged ||
			residence !== profile.residence ||
			hasAvatarChanged ||
			userType !== user?.user_type
		) {
			setIsEdited(true)
		} else {
			setIsEdited(false)
		}
	}, [
		username,
		bio,
		nationality,
		residence,
		avatarFile,
		avatarRemoved,
		avatarUrl,
		userType,
		user,
		currentLanguage,
		countryCode,
		initialStateSet,
	])

	const handleNationalityChange = (selectedOption) => {
		setNationality(selectedOption)
		setCountryCode(selectedOption ? selectedOption.value : '')
	}

	const handleDiscard = () => {
		setUsername(user?.username)
		const profile =
			user?.profiles?.[currentLanguage] ||
			user?.profiles?.[user.default_language] ||
			{}
		setBio(profile.bio || '')
		setResidence(profile.residence || '')
		setAvatarFile(null)
		setAvatarUrl(user?.avatar)
		setAvatarRemoved(false)
		setIsEdited(false)
		handleCloseModal()
	}

	const handleImageUpload = (newImages) => {
		if (newImages?.length > 0) {
			const newAvatarFile = newImages[0].file
			setAvatarFile(newAvatarFile)
			setAvatarUrl(URL.createObjectURL(newAvatarFile))
		}
	}

	const handleRemoveAvatar = () => {
		setAvatarUrl('')
		setAvatarFile(null)
		setAvatarRemoved(true)
	}

	const handleUsernameChange = (e) => {
		const newUsername = e.target.value
		if (newUsername?.length <= 20) {
			setUsername(newUsername)
		}
	}

	const handleBioChange = (e) => {
		const newBio = e.target.value
		if (newBio?.length <= 500) {
			setBio(newBio)
		}
	}

	const handleUserTypeChange = (selectedOption) => {
		const newUserType = selectedOption.value
		setUserTypeState(newUserType)
		dispatch(setUserType(newUserType))
	}

	const handleSaveChanges = () => {
		if (!username.trim()) {
			setErrorMessage(t('nameRequired'))
		} else {
			setErrorMessage('')

			const formData = new FormData()
			formData.append('username', username)
			formData.append(`profiles[${currentLanguage}][bio]`, bio || '')
			formData.append(
				`profiles[${currentLanguage}][nationality]`,
				nationality ? nationality.label : ''
			)
			formData.append(
				`profiles[${currentLanguage}][residence]`,
				residence || ''
			)
			formData.append('country_code', countryCode)
			formData.append('user_type', userType)

			if (avatarFile) {
				formData.append('avatar', avatarFile)
				formData.append('remove_avatar', false)
			} else if (avatarRemoved) {
				formData.append('remove_avatar', true)
			}

			dispatch(updateUserProfile(user.id, formData))
				.then(() => {
					showAlert(t('profileUpdatedSuccessfully'), 'success')
					handleCloseModal()
				})
				.catch((error) => {
					// エラーハンドリング
				})
		}
	}

	const handleRequestClose = () => {
		if (isEdited) {
			setShowConfirmModal(true)
		} else {
			handleCloseModal()
		}
	}

	useEffect(() => {
		if (isOpen) {
			noScroll.on()
		} else {
			noScroll.off()
		}

		return () => {
			noScroll.off()
		}
	}, [isOpen])

	useEffect(() => {
		const adjustModalHeight = () => {
			const viewportHeight = window.innerHeight
			const modalElement = document.querySelector('.modal-content')

			if (modalElement) {
				modalElement.style.height = `${viewportHeight}px`
			}
		}

		window.addEventListener('resize', adjustModalHeight)

		return () => {
			window.removeEventListener('resize', adjustModalHeight)
		}
	}, [isOpen])

	return (
		<>
			<StyledModal show={isOpen} centered backdrop="static" keyboard={false}>
				<TitleBar>
					<CloseButton onClick={handleRequestClose}>✕</CloseButton>
					<Title>{t('editProfileTitle')}</Title>
					<SaveButton disabled={!isEdited} onClick={handleSaveChanges}>
						{t('saveButton')}
					</SaveButton>
				</TitleBar>

				<ModalContent>
					<ProfileAvatarWrapper size="profileMin">
						<CustomAvatar
							username={user?.username}
							avatar={avatarUrl}
							size="profile"
						/>

						<StyledIconBackground>
							{avatarUrl ? (
								<StyledTrashIcon onClick={handleRemoveAvatar} />
							) : (
								<ImageUploader
									onImageUpload={handleImageUpload}
									uploadedImages={[]}
									maxImages={1}
									icon={<StyledCameraIcon />}
									setIsLoading={setIsLoading}
								/>
							)}
						</StyledIconBackground>
					</ProfileAvatarWrapper>

					<StyledUsernameArea>
						<InputLabel htmlFor="username">{t('username')}</InputLabel>
						<Input
							id="username"
							type="text"
							value={username}
							onChange={handleUsernameChange}
						/>
						<FlexRow>
							{errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
							<UsernameCounter>{`${
								username ? username.length : 0
							}/20`}</UsernameCounter>
						</FlexRow>
					</StyledUsernameArea>

					<StyledBioArea>
						<InputLabel htmlFor="bio">{t('bio')}</InputLabel>
						<BioAreaStyled
							id="bio"
							type="text"
							value={bio}
							onChange={handleBioChange}
							placeholder={t('bioPlaceholder')}
						/>
						<BioFlexRow>
							<BioCounter>{`${bio ? bio.length : 0}/500`}</BioCounter>
						</BioFlexRow>
					</StyledBioArea>

					<StyledNationalityArea>
						<InputLabel htmlFor="nationality">{t('nationality')}</InputLabel>
						<Select
							id="nationality"
							styles={customSelectStyles}
							options={countryOptions}
							value={nationality}
							onChange={handleNationalityChange}
							components={{ DropdownIndicator: () => <IoIosArrowDown /> }}
							menuPortalTarget={document?.body}
							menuPlacement="top"
							menuPosition="fixed"
						/>
					</StyledNationalityArea>

					<StyledRegidenceArea>
						<InputLabel htmlFor="residence">{t('currentHome')}</InputLabel>
						<Input
							id="residence"
							type="text"
							value={residence}
							onChange={(e) => setResidence(e.target.value)}
							placeholder={t('currentHomePlaceholder')}
						/>
					</StyledRegidenceArea>

					<StyledUserTypeArea>
						<InputLabel>{t('userType')}</InputLabel>
						<Select
							styles={customSelectStyles}
							options={userTypeOptions}
							value={userTypeOptions.find(
								(option) => option.value === userType
							)}
							onChange={handleUserTypeChange}
							isSearchable={false}
							components={{ DropdownIndicator: () => <IoIosArrowDown /> }}
							menuPortalTarget={document?.body}
							menuPlacement="top"
							menuPosition="fixed"
						/>
						<TouristDescription>{t('touristDescription')}</TouristDescription>
					</StyledUserTypeArea>
				</ModalContent>
			</StyledModal>

			<ConfirmationModal
				isOpen={showConfirmModal}
				onRequestClose={() => setShowConfirmModal(false)}
				onConfirm={handleDiscard}
				title={t('discardChanges')}
				message={t('discardChangesMessage')}
				showCancelButton={true}
				showDiscardButton={true}
				onDiscard={handleDiscard}
				onCancel={() => setShowConfirmModal(false)}
			/>
		</>
	)
})

export default ProfileEditForm
