// src/components/PostsByHashtag/PostItemByHashtag.jsx

import React, { useState, useEffect, useMemo } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PostList from '../Post/PostList'
import MainLayout from '../Layout/MainLayout'
import { fetchPostsByHashtag } from '../../actions/hashtagActions'
import LoadingSpinner from '../Common/LoadingSpinner'
import styled from 'styled-components'

const PostsByHashtagContainer = styled.div`
	display: flex;
	justify-content: center;
`

const PostsByHashtagContent = styled.div`
	flex: 1;

	@media (max-width: 768px) {
		width: 90%;
	}
	@media (min-width: 768px) {
		width: 580px;
	}
`

const PostItemByHashtag = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const { hashtag } = useParams()
	const dispatch = useDispatch()
	const decodedHashtag = decodeURIComponent(hashtag).replace(/[\n\r]/g, '')
	const { posts, loading } = useSelector((state) => state.hashtag)
	const backPath = location.state?.from || '/'
	const [searchBarHashtag, setSearchBarHashtag] = useState(decodedHashtag)

	useEffect(() => {
		dispatch(fetchPostsByHashtag(decodedHashtag))
	}, [dispatch, decodedHashtag])

	const handleHashtagClick = (hashtag) => {
		setSearchBarHashtag(hashtag)
	}

	const handleBackButtonClick = () => {
		const prevPaths = location.state?.prevPaths

		if (prevPaths && prevPaths.length > 0) {
			const prevPath = prevPaths[prevPaths.length - 1]
			navigate(prevPath, {
				state: {
					from: location.state?.from,
					prevPaths: prevPaths.slice(0, -1),
				},
			})
		} else {
			const postDetailPath = location.state?.from?.pathname
			if (postDetailPath && postDetailPath.startsWith('/posts/')) {
				navigate(postDetailPath)
			} else {
				navigate('/')
			}
		}
	}

	return (
		<MainLayout
			showBackButton={true}
			backPath={backPath}
			onBackButtonClick={handleBackButtonClick}
			searchBarHashtag={decodedHashtag}
		>
			<PostsByHashtagContainer>
				<PostsByHashtagContent>
					{loading ? (
						<LoadingSpinner />
					) : (
						<PostList
							posts={posts}
							isHashtagView={true}
							selectedHashtag={decodedHashtag}
							onHashtagClick={handleHashtagClick}
						/>
					)}
				</PostsByHashtagContent>
			</PostsByHashtagContainer>
		</MainLayout>
	)
}

export default PostItemByHashtag
