// src/components/FeedbackForm.jsx

import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import MainLayout from '../Layout/MainLayout'
import { useAuth } from '../../hooks/useAuth'
import styled from 'styled-components'
import { Container, Button, Form, FormControl } from 'react-bootstrap'
import { sendFeedbackApi } from '../../services/feedbackApi'
import { useSnackbar } from '../../context/SnackbarContext'
import SaveButton from '../Button/SaveButton'

const PageTitle = styled.div`
	font-size: ${({ theme }) => theme.fontSizes.extraLarge};
	font-weight: bold;
	padding: 20px 20px 20px;
`

const FeedbackContainer = styled(Container)`
	padding: 0px 20px 0px;
`

const StyledFormGroup = styled(Form.Group)`
	font-size: ${({ theme }) => theme.fontSizes.medium};
`

const StyledFormControl = styled(Form.Control)`
	resize: none;
	border-color: ${({ theme }) => theme.colors.border};
	min-height: 200px;
	padding: 10px;
`

const ImproveDescription = styled.div`
	display: flex;
	font-size: ${({ theme }) => theme.fontSizes.small};
	color: ${({ theme }) => theme.colors.textSecondary};
	margin-top: 4px;
`

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 30px;
`

const FeedbackForm = () => {
	useAuth()

	const user = useSelector((state) => state.user.user)
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm()
	const location = useLocation()
	const backPath = location.state?.from || '/settings'
	const { t } = useTranslation()

	const showAlert = useSnackbar()

	const onSubmit = async (data) => {
		try {
			const response = await sendFeedbackApi({ ...data, userId: user.id })
			if (response.message) {
				showAlert(t('feedbackSentSuccessfully'), 'success')
				reset() // フォームのリセット
			}
		} catch (error) {
			showAlert(error.message || 'Feedback sending failed.', 'error') // エラーメッセージを表示
		}
	}

	return (
		<MainLayout showBackButton={true} backPath={backPath}>
			<PageTitle>{t('sendFeedbackTitle')}</PageTitle>
			<FeedbackContainer>
				<form onSubmit={handleSubmit(onSubmit)}>
					<StyledFormGroup>
						{/* <Form.Label>{t('describeYourFeedback')}</Form.Label> */}
						<StyledFormControl
							as="textarea"
							{...register('content', { required: true })}
							placeholder={t('describeYourFeedback')}
							style={{ width: '100%' }} // 横幅をいっぱいに広げる
						/>
						{errors.content && (
							<span className="text-danger">{t('fieldIsRequired')}</span>
						)}
					</StyledFormGroup>
					<ImproveDescription>{t('improveOurServices')}</ImproveDescription>
					<ButtonContainer>
						<SaveButton type="submit">{t('sendButton')}</SaveButton>
					</ButtonContainer>
				</form>
			</FeedbackContainer>
		</MainLayout>
	)
}

export default FeedbackForm
