// src/components/Post/PostDetail.jsx

import React, { useEffect, useCallback, useMemo, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { useTranslation } from 'react-i18next'
import { fetchPostDetails } from '../../actions/postAction'
import {
	fetchComments,
	addComment,
	updateComment,
	deleteComment,
} from '../../actions/commentActions'

import UnifiedCommentSystem from '../Comment/UnifiedCommentSystem'
import { sortComments } from '../../utils/sortComments'
import MainLayout from '../Layout/MainLayout'
import PostItem from '../Post/PostItem'
import { selectUserInfo } from '../../selectors/userSelectors'
import LoadingSpinner from '../Common/LoadingSpinner'
import styled from 'styled-components'

const NoDataWrapper = styled.div`
	text-align: center;
	margin: 50px 40px 0;
	background-color: ${({ theme }) => theme.colors.background};
	padding: 20px;
	border-radius: 10px;
`

const NoDataText = styled.div`
	font-size: ${({ theme }) => theme.fontSizes.regular};
`

const selectPostById = createSelector(
	[
		(state) => state.posts.posts,
		(state) => state.posts.currentPostDetail,
		(_, postId) => postId,
	],
	(posts, currentPostDetail, postId) => {
		if (currentPostDetail && currentPostDetail.id === postId) {
			return currentPostDetail
		}
		return posts.find((post) => post.id === postId.toString()) || null
	}
)

const selectComments = createSelector(
	(state) => state.comments.byPostId,
	(state) => state.comments.byId,
	(_, postId) => postId,
	(byPostId, byId, postId) => {
		const commentIds = byPostId[postId]?.ids || []
		return commentIds.map((id) => byId[id]).filter(Boolean)
	}
)

const PostDetail = React.memo(() => {
	const { postId } = useParams()
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const userInfo = useSelector(selectUserInfo)

	const post = useSelector((state) => selectPostById(state, postId))
	const postComments = useSelector((state) => selectComments(state, postId))
	const sortedComments = useMemo(
		() => sortComments(postComments),
		[postComments]
	)
	const backPath = location.state?.from || '/'

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	useEffect(() => {
		if (postId && !post) {
			dispatch(fetchPostDetails(postId))
		}
	}, [dispatch, postId, post])

	useEffect(() => {
		if (postId && postComments.length === 0) {
			dispatch(fetchComments(postId))
		}
	}, [dispatch, postId, postComments.length])

	const handleCommentAdd = useCallback(
		(newComment) => dispatch(addComment(postId, newComment)),
		[dispatch, postId]
	)

	const handleCommentUpdate = useCallback(
		(commentId, updatedComment) =>
			dispatch(updateComment(commentId, updatedComment)),
		[dispatch]
	)

	const handleCommentDelete = useCallback(
		(commentId) => dispatch(deleteComment(commentId)),
		[dispatch]
	)

	const handleBackButtonClick = useCallback(() => {
		const backPath = location.state?.from || '/'
		navigate(backPath, {
			state: {
				returnedFromPostDetail: true,
				scrollPosition: window.scrollY,
			},
		})
	}, [navigate, location.state])

	const handleHashtagClick = useCallback(
		(e, hashtag) => {
			e.stopPropagation()
			navigate(`/hashtag/${hashtag}`, { state: { from: location } })
		},
		[navigate, location]
	)

	const isLoading = !post

	return (
		<MainLayout
			showBackButton={true}
			backPath={backPath}
			onBackButtonClick={handleBackButtonClick}
			hideMobileNavbar={true}
		>
			{isLoading ? (
				<LoadingSpinner />
			) : post ? (
				<>
					<PostItem
						key={postId}
						post={post}
						isDetailView={true}
						commentsCount={sortedComments.length}
						onHashtagClick={handleHashtagClick}
						fromPath={location.pathname}
					/>
					<UnifiedCommentSystem
						userInfo={userInfo}
						postId={postId}
						initialComments={sortedComments}
						isDetailView={true}
						onCommentAdd={handleCommentAdd}
						onCommentUpdate={handleCommentUpdate}
						onCommentDelete={handleCommentDelete}
					/>
				</>
			) : (
				<NoDataWrapper>
					<NoDataText>{t('postNotFound')}</NoDataText>
				</NoDataWrapper>
			)}
		</MainLayout>
	)
})

export default PostDetail
