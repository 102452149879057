// src/services/postApi.js

const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const postToDatabase = async (postData) => {
	const response = await fetch(`${BASE_URL}/posts/create`, {
		method: 'POST',
		headers: {},
		body: postData,
		credentials: 'include',
	})

	const responseText = await response.text()

	try {
		// レスポンスの先頭から最初の有効なJSONを抽出
		const jsonMatch = responseText.match(/^\s*(\{[\s\S]*\}|\[[\s\S]*\])\s*/)
		if (jsonMatch) {
			const jsonResponse = JSON.parse(jsonMatch[1])
			return jsonResponse
		} else {
			throw new Error('No valid JSON found in the response')
		}
	} catch (error) {
		throw new Error('Invalid JSON response from server')
	}
}

// 投稿をフェッチする関数
export const fetchPostsApi = async (offset) => {
	try {
		const url = new URL(`${BASE_URL}/posts/get`)
		url.searchParams.append('offset', offset)

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
		})

		if (!response.ok) {
			throw new Error('API response was not ok.')
		}

		const data = await response.json()

		return data
	} catch (error) {
		return null
	}
}

export const fetchQuestionPostsApi = async (offset) => {
	try {
		const url = new URL(`${BASE_URL}/posts/get-question-posts`)
		url.searchParams.append('offset', offset)

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
		})

		if (!response.ok) {
			throw new Error('API response was not ok.')
		}

		const data = await response.json()

		return data
	} catch (error) {
		return null
	}
}

export const fetchUpdatedPostApi = async (postId) => {
	try {
		const response = await fetch(`${BASE_URL}/posts/${postId}`)
		if (!response.ok) {
			throw new Error('Failed to fetch updated post')
		}
		const data = await response.json()

		return data
	} catch (error) {
		console.error('Error fetching updated post:', error)
		return null
	}
}

// 特定のidのポストデータを取得する
export const fetchPostByIdApi = async (postId) => {
	try {
		const response = await fetch(`${BASE_URL}/posts/${postId}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
		})

		if (!response.ok) {
			throw new Error('API response was not ok.')
		}

		const data = await response.json()

		return data
	} catch (error) {
		console.error(error)
		return null
	}
}

export const getUserPostsAPI = async (userId, offset) => {
	try {
		const url = new URL(`${BASE_URL}/getUserPosts/${userId}`)
		url.searchParams.append('offset', offset)

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
		})

		if (!response.ok) {
			throw new Error('Network response was not ok')
		}

		const responseData = await response.json()

		return responseData
	} catch (error) {
		throw error
	}
}

export const fetchPostsByHashtagApi = async (hashtag) => {
	try {
		const response = await fetch(`${BASE_URL}/posts/hashtag/${hashtag}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
		})

		if (!response.ok) {
			throw new Error('Network response was not ok')
		}

		const responseData = await response.json()

		return responseData
	} catch (error) {
		throw error
	}
}

// ポストを削除する関数
export const deletePostApi = async (postId) => {
	try {
		const response = await fetch(`${BASE_URL}/posts/delete/${postId}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
		})

		if (!response.ok) {
			throw new Error('API response was not ok.')
		}

		const data = await response.json()
		return data

		// return response.json();
	} catch (error) {
		console.error(error)
		return null
	}
}

// 画像を一時的にサーバーにアップロードする関数
export const uploadImageApi = async (imageFile) => {
	const formData = new FormData()
	formData.append('image', imageFile)

	try {
		const response = await fetch(`${BASE_URL}/images/upload`, {
			method: 'POST',
			body: formData,
			credentials: 'include',
		})

		if (!response.ok) {
			throw new Error('API response was not ok.')
		}

		const data = await response.json()
		return data
	} catch (error) {
		console.error('Failed to upload image:', error)
		throw error
	}
}

// サーバーから画像を削除する関数
export const deleteImageApi = async (imageId) => {
	try {
		const response = await fetch(`${BASE_URL}/images/delete/${imageId}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
		})

		if (!response.ok) {
			throw new Error('API response was not ok.')
		}

		const data = await response.json()
		return data
	} catch (error) {
		throw error
	}
}

export const updatePostApi = async (postId, formData) => {
	try {
		const response = await fetch(`${BASE_URL}/posts/update/${postId}`, {
			method: 'POST',
			body: formData,
			credentials: 'include',
		})

		if (!response.ok) {
			throw new Error('Network response was not ok')
		}

		const updatedPost = await response.json()

		return updatedPost
	} catch (error) {
		throw error
	}
}
