// src/config.js

// 環境変数からURLを読み込む
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
const TERMS_URL_JP = process.env.REACT_APP_TERMS_URL_JP
const PRIVACY_POLICY_URL_JP = process.env.REACT_APP_PRIVACY_POLICY_URL_JP
const TIP_POLICY_URL_JP = process.env.REACT_APP_TIP_POLICY_URL_JP
const TERMS_URL_EN = process.env.REACT_APP_TERMS_URL_EN
const PRIVACY_POLICY_URL_EN = process.env.REACT_APP_PRIVACY_POLICY_URL_EN
const TIP_POLICY_URL_EN = process.env.REACT_APP_TIP_POLICY_URL_EN

// ブラウザの言語設定をチェック
const browserLang = navigator.language || navigator.userLanguage
const isJapanese = browserLang.startsWith('ja')
// const isTraditionalChinese = browserLang.startsWith('zhTW')

// 使用するURLを言語に応じて設定
const CONFIG = {
	API_BASE_URL,
	TERMS_URL: isJapanese ? TERMS_URL_JP : TERMS_URL_EN,
	PRIVACY_POLICY_URL: isJapanese
		? PRIVACY_POLICY_URL_JP
		: PRIVACY_POLICY_URL_EN,
	TIP_POLICY_URL: isJapanese ? TIP_POLICY_URL_JP : TIP_POLICY_URL_EN,
}

export default CONFIG

export const LIBRARIES = ['places']
