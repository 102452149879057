// src/components/HomePage/HomePage.jsx

import React from 'react'
import MainLayout from '../Layout/MainLayout'
import SimpleFilter from '../Filter/SimpleFilter'
import PostList from '../Post/PostList'
import QuestionPostList from '../Post/QuestionPostList'
import { Divider } from '../../styles/commonStyles'
import LoadingSpinner from '../../components/Common/LoadingSpinner'

const HomePage = React.memo(
	({ isLoggedIn, posts, isLoading, activeFilter, onFilterChange }) => {
		return (
			<MainLayout showHeroSection={!isLoggedIn}>
				<SimpleFilter onFilterChange={onFilterChange} />
				<Divider />
				{isLoading ? (
					<LoadingSpinner />
				) : activeFilter === 'Questions' ? (
					<QuestionPostList posts={posts} />
				) : (
					<PostList posts={posts} />
				)}
			</MainLayout>
		)
	}
)

export default HomePage
