import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Container, Form, Button, InputGroup, FormCheck } from 'react-bootstrap'
import iconEyeOpen from '../../assets/icon/eye-open.svg'
import iconEyeClose from '../../assets/icon/eye-close.svg'
import { resetUserPassword } from '../../actions/authActions'
import { validatePassword } from '../../utils/validation'
import styled from 'styled-components'
import StyledButton from '../Button/StyledButton'

const StyledContainerWrapper = styled.div`
	display: flex; // Flexbox を有効化
	justify-content: center; // 水平方向の中央揃え
	align-items: center; // 垂直方向の中央揃え
	height: 100vh; // ビューポートの高さを全体に
	padding: 20px; // 小さな画面でのスクロールを許容
`

const StyledContainer = styled(Container)`
	max-width: 500px;
	margin: 0 auto; // 中央寄せ
	margin-top: 20px; // 画面上部からのマージン
	padding: 30px; // 内側のパディング
	background-color: #fff; // 背景色
	border-radius: 5px; // 角丸
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); // 影をつける
	min-height: 520px; // 最小高さを指定
`

// タイトルのスタイル
const Title = styled.h2`
	font-size: ${({ theme }) => theme.fontSizes.extraLarge};
	font-weight: bold;
	margin: 30px auto;
	text-align: center;
`

// フォームグループのスタイル
const StyledFormGroup = styled(Form.Group)`
	font-size: ${({ theme }) => theme.fontSizes.medium};
	margin-bottom: 30px;
`

// フォームコントロールのスタイル
const StyledFormControl = styled(Form.Control)`
	height: 50px;
	padding: 6px 12px;
	&:focus {
		box-shadow: none !important;
	}
`

const StyledFormCheck = styled(FormCheck)`
	// エラーメッセージがある場合のマージンを調整するためのスタイルをここに追加します。
	margin-top: -20px;
	margin-bottom: 20px;
`

const StyledResetButton = styled(StyledButton)`
	&&& {
		border-radius: 6px !important;
		width: 100% !important;
		padding: 12px !important;
		margin-top: 30px;

		&:focus {
			box-shadow: none !important;
		}
	}
`

const ResetPassword = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { t } = useTranslation()
	const errorMessage = useSelector((state) => state.auth.error)

	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)
	const token = queryParams.get('token')

	const [formErrors, setFormErrors] = useState({})
	const [showPassword, setShowPassword] = useState({
		newPassword: false,
		confirmPassword: false,
	})

	const handleSubmit = async (e) => {
		e.preventDefault()
		const newPassword = e.target.newPassword.value
		const confirmPassword = e.target.confirmPassword.value

		// 新しいパスワードのバリデーション
		const newPasswordError = validatePassword(newPassword)
		// 確認用パスワードのバリデーション（新しいパスワードと一致するかどうかのみチェック）
		const confirmPasswordError =
			newPassword !== confirmPassword ? t('Passwords do not match.') : ''

		let errors = {}
		if (newPasswordError) {
			errors.newPassword = newPasswordError
		}
		// 確認用パスワードに対するエラーがある場合、または新しいパスワードにエラーがあり、
		// かつ確認用パスワードが空ではない場合（一致しないエラーを表示）
		if (confirmPasswordError || (newPasswordError && confirmPassword)) {
			errors.confirmPassword =
				confirmPasswordError || t('Please confirm your password.')
		}

		setFormErrors(errors)

		if (Object.keys(errors).length === 0) {
			// エラーがない場合の処理
			const isSuccess = await dispatch(
				resetUserPassword(newPassword, confirmPassword, token)
			)
			if (isSuccess) {
				navigate('/?loginModal=open')
			} else {
				setFormErrors({ general: errorMessage })
			}
		}
	}

	return (
		<StyledContainerWrapper>
			<StyledContainer>
				<Title>{t('resetPasswordTitle')}</Title>
				<Form onSubmit={handleSubmit} noValidate>
					<StyledFormGroup controlId="newPassword">
						<Form.Label>{t('newPassword')}</Form.Label>
						<StyledFormControl
							type={showPassword ? 'text' : 'password'}
							placeholder={t('password')}
							name="newPassword"
							// isInvalid={!!formErrors.newPassword}
						/>
						{/* {formErrors.newPassword && (
							<Form.Text className="text-danger">
								{t(formErrors.newPassword)}
							</Form.Text>
						)} */}
					</StyledFormGroup>
					<StyledFormGroup controlId="confirmPassword">
						<Form.Label>{t('confirmPassword')}</Form.Label>
						<StyledFormControl
							type={showPassword ? 'text' : 'password'}
							placeholder={t('confirmPassword')}
							name="confirmPassword"
							isInvalid={!!formErrors.confirmPassword}
						/>
						{formErrors.confirmPassword && (
							<Form.Text className="text-danger">
								{t(formErrors.confirmPassword)}
							</Form.Text>
						)}
					</StyledFormGroup>
					<StyledFormCheck
						type="checkbox"
						label={t('showPassword')}
						checked={showPassword}
						onChange={() => setShowPassword(!showPassword)}
					/>
					{formErrors.matchingError && (
						<div className="text-danger">{t(formErrors.matchingError)}</div>
					)}
					<StyledResetButton variant="primary" type="submit">
						{t('resetPasswordButton')}
					</StyledResetButton>
				</Form>
			</StyledContainer>
		</StyledContainerWrapper>
	)
}

export default ResetPassword
