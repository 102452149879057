// src/decorators/compositeDecorator.js

import { CompositeDecorator } from 'draft-js'
import { urlDecorator, hashtagDecorator } from './LinkDecorator'

const compositeDecorator = new CompositeDecorator([
	urlDecorator,
	hashtagDecorator,
])

export default compositeDecorator
