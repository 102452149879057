// src/services/draftApi.js

const BASE_URL = process.env.REACT_APP_API_BASE_URL

// ドラフトデータをサーバーに送信して保存する関数
export const saveDraftApi = async (draftData) => {
	const response = await fetch(`${BASE_URL}/drafts/saveDraft`, {
		method: 'POST',
		body: draftData,
		credentials: 'include',
	})
	if (!response.ok) {
		throw new Error('Network response was not ok')
	}

	const responseData = await response.json()

	return responseData
}

// ドラフトデータをサーバーから取得する関数
export const fetchDraftApi = async (userId, offset) => {
	try {
		const url = new URL(`${BASE_URL}/getDrafts/${userId}`)
		url.searchParams.append('offset', offset)

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
		})

		if (!response.ok) {
			throw new Error('Network response was not ok')
		}

		const responseData = await response.json()

		return responseData
	} catch (error) {
		throw error
	}
}

// src/services/draftApi.js
export const deleteDraftApi = async (draftId) => {
	try {
		const response = await fetch(`${BASE_URL}/drafts/delete/${draftId}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
		})

		if (!response.ok) {
			throw new Error('API response was not ok.')
		}

		const data = await response.json()
		return data
	} catch (error) {
		console.error(error)
		return null
	}
}
