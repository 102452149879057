// src/components/Post/QuestionPostList.jsx

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PostItem from '../Post/PostItem'
import { Divider } from '../../styles/commonStyles'
import { fetchCommentsCount } from '../../actions/commentActions'

const QuestionPostList = ({ posts = [] }) => {
	const dispatch = useDispatch()

	useEffect(() => {
		posts.forEach((post) => {
			dispatch(fetchCommentsCount(post.id))
		})
	}, [dispatch, posts])

	if (!Array.isArray(posts)) {
		return null
	}

	return (
		<div className="timeline">
			{posts.map((post, index) => (
				<React.Fragment key={post?.id || index}>
					<PostItem post={post} fromPath="/?filter=questions" />
					{index < posts.length - 1 && <Divider />}
				</React.Fragment>
			))}
		</div>
	)
}

export default React.memo(QuestionPostList)
