// src/components/Login/ForgotPasswordModal.jsx

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Form, Button } from 'react-bootstrap'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { resetPasswordUrlRequest } from '../../actions/authActions'
import { validateEmail } from '../../utils/validation'
import { hoverIconStyle } from '../../styles/commonStyles'
import StyledButton from '../Button/StyledButton'
import { useSnackbar } from '../../context/SnackbarContext'

const StyledModal = styled(Modal)`
	.modal-dialog {
		max-width: 500px;
		.modal-content {
			min-height: 520px; // 最小高さを指定
		}
	}
	.modal-content {
		padding: 20px;
	}
`

const ModalHeader = styled.div`
	display: flex;
	align-items: center;
	position: relative; // タイトルとクローズボタンの配置に必要
	padding: 0 20px;
	margin-bottom: 10px;
`

const Title = styled.div`
	font-size: ${({ theme }) => theme.fontSizes.extraLarge};
	font-weight: bold;
	margin: 20px auto;
	text-align: center;
`

const StyledFormGroup = styled(Form.Group)`
	font-size: ${({ theme }) => theme.fontSizes.medium};
	margin-bottom: 30px;
`

const InputWrapper = styled.div`
	position: relative;
`

const StyledFormControl = styled(Form.Control)`
	height: 50px;
	padding: 12px;
	border: 1px solid
		${({ isInvalid, theme }) =>
			isInvalid ? theme.colors.danger : theme.colors.outlineGray};
	border-radius: 4px;
	font-size: ${({ theme }) => theme.fontSizes.regular};

	&:focus {
		box-shadow: none;
		border-color: ${({ isInvalid, theme }) =>
			isInvalid ? theme.colors.danger : theme.colors.black};
	}

	&:hover {
		border-color: ${({ isInvalid, theme }) =>
			isInvalid ? theme.colors.danger : theme.colors.darkGray};
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.colors.white} inset !important;
		-webkit-text-fill-color: ${({ theme }) => theme.colors.text} !important;
	}
`

const FloatingLabel = styled.label`
	position: absolute;
	left: 12px;
	top: ${({ isFocused, hasValue }) => (isFocused || hasValue ? '0px' : '50%')};
	transform: ${({ isFocused, hasValue }) =>
		isFocused || hasValue
			? 'translateY(-50%) scale(0.85)'
			: 'translateY(-50%)'};
	transition: all 0.1s ease-out;
	color: ${({ isFocused, theme }) =>
		isFocused ? theme.colors.black : theme.colors.text};
	background-color: ${({ theme }) => theme.colors.white};
	padding: 0 4px;
	pointer-events: none;
`

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 40px;
`

const StyledForgotButton = styled(StyledButton)`
	border-radius: 50px;
	width: 30%;
	padding: 10px;
`

const ArrowIcon = styled.div`
	${hoverIconStyle}
	font-size: 20px;
	&:after {
		content: '<';
	}
`

const CustomFeedback = styled(Form.Control.Feedback)`
	display: block;
	font-size: ${({ theme }) => theme.fontSizes.small};
`

const ForgotPasswordModal = ({
	show,
	handleClose,
	onBackToLogin,
	useCloseButton = false,
}) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const errorMessage = useSelector((state) => state.auth.error)
	const [email, setEmail] = useState('')
	const [formErrors, setFormErrors] = useState({})
	const [focusedField, setFocusedField] = useState(null)
	const showAlert = useSnackbar()

	const handleSubmit = async (e) => {
		e.preventDefault()
		const emailErrors = validateEmail(email)

		if (emailErrors) {
			setFormErrors({ email: t('enterValidEmail') })
		} else {
			const isSuccess = await dispatch(resetPasswordUrlRequest(email))

			if (isSuccess) {
				handleClose()
				showAlert(t('resetLinkSent'), 'success')
				onBackToLogin()
			} else {
				setFormErrors({ general: errorMessage })
			}
		}
	}

	return (
		<StyledModal show={show} onHide={handleClose} centered>
			<ModalHeader>
				{useCloseButton ? (
					<button onClick={handleClose}>✕</button>
				) : (
					<ArrowIcon onClick={handleClose} />
				)}
				<Title>{t('resetPasswordTitle')}</Title>
				<div style={{ width: '40px' }} />
			</ModalHeader>
			<Modal.Body>
				<Form onSubmit={handleSubmit} noValidate>
					<div className="terms-text">{t('enterEmailForResetPwd')}</div>
					<br />
					<StyledFormGroup controlId="email">
						<InputWrapper>
							<StyledFormControl
								type="email"
								name="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								onFocus={() => setFocusedField('email')}
								onBlur={() => setFocusedField(null)}
								isInvalid={!!formErrors.email}
							/>
							<FloatingLabel
								isFocused={focusedField === 'email'}
								hasValue={email.length > 0}
							>
								{t('email')}
							</FloatingLabel>
						</InputWrapper>
						{formErrors.email && (
							<CustomFeedback type="invalid">{formErrors.email}</CustomFeedback>
						)}
					</StyledFormGroup>
					<ButtonContainer>
						<StyledForgotButton variant="primary" type="submit">
							{t('sendButton')}
						</StyledForgotButton>
					</ButtonContainer>
					{formErrors.general && (
						<div className="text-danger mt-3">{formErrors.general}</div>
					)}
				</Form>
			</Modal.Body>
		</StyledModal>
	)
}

export default ForgotPasswordModal
