// src/reducers/uiReducer.js

const initialState = {
	scrollPosition: 0,
	isLoading: false,
}

const uiReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_LOADING':
			return { ...state, isLoading: action.payload }
		case 'SET_SHOULD_RESTORE_SCROLL':
			return {
				...state,
				shouldRestoreScroll: action.payload,
			}
		default:
			return state
	}
}

export default uiReducer
