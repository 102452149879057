// src/actions/translationActions.js

import i18n from '../i18n'

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'

export const changeLanguage = (language) => (dispatch) => {
	i18n.changeLanguage(language)
	localStorage.setItem('i18nextLng', language) // 言語設定をローカルストレージに保存
	dispatch({
		type: CHANGE_LANGUAGE,
		payload: language,
	})
}
