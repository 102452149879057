// src/actions/uiActions.js

export const setLoading = (isLoading) => ({
	type: 'SET_LOADING',
	payload: isLoading,
})

export const setShouldRestoreScroll = (shouldRestore) => ({
	type: 'SET_SHOULD_RESTORE_SCROLL',
	payload: shouldRestore,
})
