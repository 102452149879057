// src/containers/HomePage/HomePageController.jsx

import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import {
	fetchPosts,
	fetchQuestionPosts,
	updateLastFetched,
	fetchUpdatedPost,
	resetQuestionPosts,
} from '../../actions/postAction'
import { setLoading, setShouldRestoreScroll } from '../../actions/uiActions'
import HomePage from '../../components/HomePage/HomePage'
import useInfiniteScroll from '../../hooks/useInfiniteScroll'
import { useAuth } from '../../hooks/useAuth'

const HomePageController = React.memo(() => {
	useAuth()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const [shouldRestoreScroll] = useState(false)

	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
	const posts = useSelector((state) => state.posts.posts)
	const questionPosts = useSelector((state) => state.posts.questionPosts)
	const hasMore = useSelector((state) => state.posts.hasMore)
	const hasMoreQuestions = useSelector((state) => state.posts.hasMoreQuestions)
	const nextOffset = useSelector((state) => state.posts.nextOffset)
	const nextQuestionOffset = useSelector(
		(state) => state.posts.nextQuestionOffset
	)
	const lastUpdated = useSelector((state) => state.posts.lastUpdated)
	const lastFetched = useSelector((state) => state.posts.lastFetched)
	const scrollPosition = useSelector((state) => state.ui.scrollPosition)
	const isLoading = useSelector((state) => state.ui.isLoading)

	const postsLength = useMemo(() => {
		return posts.length
	}, [posts])

	const questionPostsLength = useMemo(() => {
		return questionPosts.length
	}, [questionPosts])

	const searchParams = new URLSearchParams(location.search)
	const filterParam = searchParams.get('filter')
	const [activeFilter, setActiveFilter] = useState(
		filterParam === 'questions' ? 'Questions' : 'All'
	)

	const fetchPostsData = useCallback(async () => {
		dispatch(setLoading(true))
		try {
			if (activeFilter === 'All') {
				await dispatch(fetchPosts())
			} else if (activeFilter === 'Questions' && questionPosts.length === 0) {
				await dispatch(fetchQuestionPosts())
			}
			await dispatch(updateLastFetched())
		} catch (error) {
			console.error('Error fetching posts:', error)
		} finally {
			dispatch(setLoading(false))
		}
	}, [dispatch, activeFilter, questionPosts.length])

	const fetchMorePosts = useCallback(async () => {
		if (activeFilter === 'All' && hasMore) {
			await dispatch(fetchPosts(nextOffset))
		} else if (activeFilter === 'Questions' && hasMoreQuestions) {
			await dispatch(fetchQuestionPosts(nextQuestionOffset))
		}
	}, [
		dispatch,
		activeFilter,
		hasMore,
		hasMoreQuestions,
		nextOffset,
		nextQuestionOffset,
	])

	useEffect(() => {
		if (
			!lastFetched ||
			(activeFilter === 'Questions' && questionPosts.length === 0)
		) {
			fetchPostsData()
		}
	}, [fetchPostsData, lastFetched, activeFilter, questionPosts.length])

	useEffect(() => {
		if (lastUpdated) {
			dispatch(setLoading(true))
			dispatch(fetchUpdatedPost(lastUpdated)).finally(() =>
				dispatch(setLoading(false))
			)
		}
	}, [dispatch, lastUpdated])

	useEffect(() => {
		if (shouldRestoreScroll) {
			const originalScrollBehavior =
				document.documentElement.style.scrollBehavior
			document.documentElement.style.scrollBehavior = 'auto'
			window.scrollTo(0, scrollPosition)
			requestAnimationFrame(() => {
				document.documentElement.style.scrollBehavior = originalScrollBehavior
			})
			dispatch(setShouldRestoreScroll(false))
		}
	}, [shouldRestoreScroll, scrollPosition, dispatch])

	useInfiniteScroll(fetchMorePosts, [
		activeFilter,
		hasMore,
		hasMoreQuestions,
		nextOffset,
		nextQuestionOffset,
	])

	const handleFilterChange = useCallback(
		(filter) => {
			setActiveFilter(filter)
			if (filter === 'Questions') {
				navigate('/?filter=questions', { replace: true })
			} else {
				navigate('/', { replace: true })
			}
			if (filter === 'All') {
				dispatch(resetQuestionPosts())
			}
		},
		[dispatch, navigate]
	)

	const memoizedHomePage = useMemo(() => {
		return (
			<HomePage
				isLoggedIn={isAuthenticated}
				posts={activeFilter === 'All' ? posts : questionPosts}
				isLoading={isLoading}
				activeFilter={activeFilter}
				onFilterChange={handleFilterChange}
			/>
		)
	}, [
		isAuthenticated,
		activeFilter === 'All' ? postsLength : questionPostsLength,
		isLoading,
		activeFilter,
		handleFilterChange,
	])

	return <>{memoizedHomePage}</>
})

export default HomePageController
