// src/selectors/userSelectors.js

import { createSelector } from 'reselect'

export const selectUserInfo = createSelector(
	(state) => state.user.user,
	(user) => ({
		id: user.id,
		username: user.username,
		avatar: user.avatar,
	})
)
