// src/reducers/likeReducer.js

import {
	LIKE_ACTION_SUCCESS,
	FETCH_LIKES_COUNT,
	SET_LIKES_DATA,
} from '../actions/likeActions'

const initialState = {
	posts: {},
	comments: {},
}

const likeReducer = (state = initialState, action) => {
	switch (action.type) {
		case LIKE_ACTION_SUCCESS:
		case FETCH_LIKES_COUNT:
			const { type, id, likesCount, isLiked, isLikedByUser } = action.payload
			const isLikeAction = action.type === LIKE_ACTION_SUCCESS
			const newLikesData = {
				likesCount: isLikeAction ? likesCount : state[type][id]?.likesCount,
				isLikedByUser: isLikeAction ? isLiked : isLikedByUser,
			}

			if (type === 'post') {
				return {
					...state,
					posts: {
						...state.posts,
						[id]: newLikesData,
					},
				}
			} else if (type === 'comment') {
				return {
					...state,
					comments: {
						...state.comments,
						[id]: newLikesData,
					},
				}
			}
			return state

		case SET_LIKES_DATA:
			return {
				...state,
				...action.payload,
			}

		default:
			return state
	}
}

export default likeReducer
