// src/hooks/useEditingComment.js

import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
// 必要に応じて、コメント更新のアクションをインポート
// import { updateComment } from '../actions/commentActions'

export const useEditingComment = () => {
	const [editingCommentId, setEditingCommentId] = useState(null)
	const dispatch = useDispatch()

	const handleEditComment = useCallback((commentId) => {
		setEditingCommentId(commentId)
	}, [])

	const handleUpdateComment = useCallback(
		(postId, commentId, updatedContent) => {
			// ここで実際の更新ロジックを実装する
			// 例: dispatch(updateComment(postId, commentId, updatedContent))
			setEditingCommentId(null) // 編集モードを終了
		},
		[dispatch]
	)

	const handleCancelEdit = useCallback(() => {
		setEditingCommentId(null)
	}, [])

	return {
		editingCommentId,
		handleEditComment,
		handleUpdateComment,
		handleCancelEdit,
	}
}
