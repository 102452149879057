import {
	registerUserApi,
	verifyCheckApi,
	resendVerificationCodeApi,
	loginAPI,
	resetPasswordUrlRequestApi,
	resetPasswordApi,
	logoutApi,
	checkAuthStatusApi,
} from '../services/authApi'

export const SET_EMAIL = 'SET_EMAIL'

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE'

// サインアップモーダルの表示・非表示を制御
export const SHOW_SIGNUP_MODAL = 'SHOW_SIGNUP_MODAL'
export const HIDE_SIGNUP_MODAL = 'HIDE_SIGNUP_MODAL'
export const SHOW_VERIFICATION_MODAL = 'SHOW_VERIFICATION_MODAL'

export const VERIFY_REQUEST = 'VERIFY_REQUEST'
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS'
export const VERIFY_FAILURE = 'VERIFY_FAILURE'

export const RESEND_CODE_REQUEST = 'RESEND_CODE_REQUEST'
export const RESEND_CODE_SUCCESS = 'RESEND_CODE_SUCCESS'
export const RESEND_CODE_FAILURE = 'RESEND_CODE_FAILURE'

export const SHOW_LOGIN_MODAL = 'SHOW_LOGIN_MODAL'
export const HIDE_LOGIN_MODAL = 'HIDE_LOGIN_MODAL'

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'

// Search
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

// Redux Action Types
export const AUTH_CHECK = 'AUTH_CHECK'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAILURE = 'AUTH_FAILURE'

export const CLEAR_ERROR = 'CLEAR_ERROR'

// トークンの確認に関するアクションクリエイター
export const authCheck = () => ({ type: AUTH_CHECK })
export const authSuccess = (user) => ({
	type: AUTH_SUCCESS,
	payload: {
		userId: user.userId,
		username: user.username,
		isAuthenticated: true,
	},
})

export const authFailure = () => ({ type: AUTH_FAILURE })

// actions/sessionActions.js
export const setSession = (session) => {
	return {
		type: 'SET_SESSION',
		payload: session,
	}
}

export const setEmail = (email) => ({
	type: SET_EMAIL,
	payload: email,
})

export const signupRequest = (userData) => ({
	type: SIGNUP_REQUEST,
	payload: userData,
})

export const signupSuccess = (user) => ({
	type: SIGNUP_SUCCESS,
	payload: {
		userId: user.userId,
		username: user.username,
	},
})

export const signupFailure = (error) => ({
	type: SIGNUP_FAILURE,
	payload: error,
})

export const showSignupModal = () => ({
	type: SHOW_SIGNUP_MODAL,
})

export const hideSignupModal = () => ({
	type: HIDE_SIGNUP_MODAL,
})

export const showVerificationModal = (show) => {
	return {
		type: SHOW_VERIFICATION_MODAL,
		payload: show,
	}
}

export const verifyRequest = (code) => ({
	type: VERIFY_REQUEST,
	payload: code,
})

export const verifySuccess = (userId, username) => ({
	type: VERIFY_SUCCESS,
	payload: { userId, username }, // payloadにuserIdとusernameを含める
})

export const verifyFailure = (error) => ({
	type: VERIFY_FAILURE,
	payload: error,
})

export const showLoginModal = () => ({
	type: SHOW_LOGIN_MODAL,
})

export const hideLoginModal = () => ({
	type: HIDE_LOGIN_MODAL,
})

// Login success action creator
export const loginSuccess = (user) => ({
	type: LOGIN_SUCCESS,
	payload: {
		userId: user.userId,
		username: user.username,
		isAuthenticated: true,
	},
})

export const resendCodeRequest = (email) => ({
	type: RESEND_CODE_REQUEST,
	payload: email,
})

export const resendCodeSuccess = () => ({
	type: RESEND_CODE_SUCCESS,
})

export const resendCodeFailure = (error) => ({
	type: RESEND_CODE_FAILURE,
	payload: error,
})

export const logoutRequest = () => ({
	type: LOGOUT_REQUEST,
})

export const logoutSuccess = () => ({
	type: LOGOUT_SUCCESS,
})

export const logoutFailure = (error) => ({
	type: LOGOUT_FAILURE,
	payload: error,
})

export const clearError = () => ({
	type: CLEAR_ERROR,
})

// 非同期処理を実行するためのThunkアクション
export const registerUser = (userData) => async (dispatch) => {
	dispatch(signupRequest(userData))
	return new Promise(async (resolve, reject) => {
		try {
			const qrReferrerId = localStorage.getItem('qrReferrerId')
			const updatedUserData = {
				...userData,
				...(qrReferrerId && { qrReferrerId }),
			}

			const data = await registerUserApi(updatedUserData)
			if (data.success) {
				dispatch(signupSuccess(data.user))
				resolve(data)
			} else {
				dispatch(signupFailure(data.errors))
				reject(data.errors)
			}
		} catch (error) {
			dispatch(signupFailure(error))
			reject(error)
		}
	})
}

export const verifyCode = (code, userId) => {
	return async (dispatch) => {
		dispatch(verifyRequest(code))

		try {
			const response = await verifyCheckApi(code, userId)
			if (response.status === 'success') {
				dispatch(verifySuccess(response.userId, response.username))
				dispatch(
					loginSuccess({ userId: response.userId, username: response.username })
				)
			} else {
				dispatch(verifyFailure('invalidCode'))
			}
		} catch (error) {
			dispatch(verifyFailure('verificationError'))
		}
	}
}

export const resendVerificationCode = (email) => {
	return async (dispatch) => {
		dispatch(resendCodeRequest(email))

		try {
			const response = await resendVerificationCodeApi(email)

			if (response.success) {
				dispatch(resendCodeSuccess())
			} else {
				dispatch(resendCodeFailure('resend_code_error'))
			}
		} catch (error) {
			dispatch(resendCodeFailure('resend_code_error'))
		}
	}
}

export const loginUser = (email, password) => async (dispatch) => {
	try {
		const response = await loginAPI({ email, password })

		if (response.success) {
			dispatch(loginSuccess(response.user))
		} else {
			// バックエンドからの errorKey を使用して LOGIN_FAIL を dispatch
			dispatch({
				type: LOGIN_FAIL,
				payload: response.errorKey || 'unknown_error',
			})
		}
	} catch (error) {
		// ネットワークエラーまたはその他の予期しないエラーが発生した場合
		dispatch({
			type: LOGIN_FAIL,
			payload: error.message,
		})
	}
}

export const resetPasswordUrlRequest = (email) => async (dispatch) => {
	dispatch({ type: RESET_PASSWORD_REQUEST })
	try {
		const data = await resetPasswordUrlRequestApi(email) // この関数を使用

		if (data.status === 'success') {
			dispatch({ type: RESET_PASSWORD_SUCCESS })
			return true
		} else {
			dispatch({
				type: RESET_PASSWORD_FAILURE,
				payload: data.error_msg,
			})
			return false
		}
	} catch (error) {
		dispatch({
			type: RESET_PASSWORD_FAILURE,
			payload: 'An unexpected error occurred.',
		})
		return false
	}
}

export const resetUserPassword =
	(password, confirmPassword, token) => async (dispatch) => {
		dispatch({ type: RESET_PASSWORD_REQUEST })

		try {
			const response = await resetPasswordApi(password, confirmPassword, token)

			if (response.success) {
				dispatch({ type: RESET_PASSWORD_SUCCESS })
				return true
			} else {
				dispatch({ type: RESET_PASSWORD_FAILURE, payload: response.message })
				return false
			}
		} catch (error) {
			dispatch({
				type: RESET_PASSWORD_FAILURE,
				payload: 'An unexpected error occurred.',
			})
			return false
		}
	}

// アクション：
export const logoutAction = () => {
	return async (dispatch) => {
		dispatch(logoutRequest())
		try {
			const result = await logoutApi()
			dispatch(logoutSuccess())
		} catch (error) {
			dispatch(logoutFailure(error))
		}
	}
}

export const checkAuthStatus = () => {
	return async (dispatch) => {
		dispatch(authCheck())
		try {
			const data = await checkAuthStatusApi()

			if (data.isAuthenticated) {
				dispatch(authSuccess(data.user))
			} else {
				dispatch(authFailure())
			}
		} catch (error) {
			console.error('Error checking auth status:', error)
			dispatch(authFailure())
		}
	}
}
