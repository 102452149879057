import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Header from '../Header/Header'
import SidebarLeft from '../Sidebar/SidebarLeft'
import SidebarRight from '../Sidebar/SidebarRight'
import HeroSection from '../HeroSection/HeroSection'
import MobileNavbar from '../MobileNavbar/MobileNavbar'
import BackButton from '../Button/BackButton'
import styled from 'styled-components'
import { borderVerticalStyle } from '../../styles/commonStyles'

const LayoutContainer = styled.div`
	display: flex;
	flex-direction: column;
`

const ContentWrapper = styled.div.withConfig({
	shouldForwardProp: (prop) =>
		!['showHeroSection', 'showSidebarLeft', 'showSidebarRight'].includes(prop),
})`
	display: flex;
	width: 100%;
	padding-top: ${(props) => (props.showHeroSection ? '0px' : '80px')};

	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		padding-top: ${(props) => (props.showHeroSection ? '0px' : '74px')};
	}
`

const MainContent = styled.div`
	max-width: 580px;
	margin: 0 auto;
	flex-grow: 1;
	width: 100%;
	${(props) => borderVerticalStyle(props.theme)}
`

const SidebarContainer = styled.div`
	position: sticky;
	top: 80px;
	left: 0;
	width: 380px;
	height: calc(100vh - 80px);
	overflow-y: auto;
	z-index: 100;

	@media (max-width: 960px) {
		display: none;
	}
`

const SidebarLeftWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	overscroll-behavior: contain;
`

const SidebarRightWrapper = styled.div`
	position: sticky;
	top: 80px;
	right: 0;
	width: 420px;
	height: calc(100vh - 80px);
	overflow-y: auto;
	overscroll-behavior: contain;
	z-index: 100;

	@media (max-width: 1024px) {
		display: none;
	}
`

const MobileNavbarWrapper = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1000;

	@media (min-width: 769px) {
		display: none;
	}
`

const MainLayout = ({
	children,
	showBackButton = false,
	backPath = '/',
	onBackButtonClick,
	showSidebarRight = true,
	showHeroSection = false,
	searchBarHashtag,
	hideMobileNavbar = false,
}) => {
	const showSidebarLeft = useSelector((state) => state.layout.showSidebarLeft)
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768)
		}

		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return (
		<>
			<Header searchBarHashtag={searchBarHashtag} />
			{showHeroSection && <HeroSection />}
			<LayoutContainer>
				<ContentWrapper
					showHeroSection={showHeroSection}
					showSidebarLeft={showSidebarLeft}
					showSidebarRight={showSidebarRight}
				>
					{showSidebarLeft && (
						<SidebarContainer>
							<SidebarLeftWrapper>
								<SidebarLeft />
							</SidebarLeftWrapper>
						</SidebarContainer>
					)}
					<MainContent>
						{showBackButton && (
							<BackButton
								backPath={backPath}
								onCustomClick={onBackButtonClick}
							/>
						)}
						{children}
					</MainContent>
					{showSidebarRight && (
						<SidebarRightWrapper>
							<SidebarRight />
						</SidebarRightWrapper>
					)}
				</ContentWrapper>
			</LayoutContainer>
			{isMobile && (
				<MobileNavbarWrapper>
					<MobileNavbar hide={hideMobileNavbar} />
				</MobileNavbarWrapper>
			)}
		</>
	)
}

export default MainLayout
