// src/components/Avatar/AvatarWithFlagComment.jsx

import React, { memo } from 'react'
import styled from 'styled-components'
import CustomAvatar from './Avatar'
import CountryFlag from './CountryFlag'
import { AVATAR_CONFIG } from './avatarConfig'

const AvatarWithFlagCommentWrapper = styled.div`
	position: relative;
	display: inline-block;
	cursor: default;
`

const FlagWrapper = styled.div`
	position: absolute;
	${({ $position }) => $position};
`

const AvatarWithFlagComment = memo(
	({ username, avatar, avatarSize, userId, countryCode, onAvatarClick }) => {
		const flagPosition = AVATAR_CONFIG.comment.flagPosition(avatarSize)

		return (
			<AvatarWithFlagCommentWrapper>
				<CustomAvatar
					username={username}
					avatar={avatar}
					size={avatarSize}
					onClick={(e) => onAvatarClick(e, userId)}
				/>
				{countryCode && (
					<FlagWrapper $position={flagPosition}>
						<CountryFlag
							countryCode={countryCode.toLowerCase()}
							size={AVATAR_CONFIG.comment.flagSize}
						/>
					</FlagWrapper>
				)}
			</AvatarWithFlagCommentWrapper>
		)
	}
)

export default AvatarWithFlagComment
