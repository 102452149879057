// src/services/changePasswordApi.js

const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const verifyCurrentPasswordApi = async (currentPassword) => {
	const response = await fetch(`${BASE_URL}/verifyCurrentPassword`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ password: currentPassword }),
		credentials: 'include',
	})

	if (!response.ok) {
		throw new Error(`HTTP error! Status: ${response.status}`)
	}

	return await response.json()
}

export const changePasswordApi = async (currentPassword, newPassword) => {
	const response = await fetch(`${BASE_URL}/changePassword`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			current_password: currentPassword,
			new_password: newPassword,
		}),

		credentials: 'include',
	})

	if (!response.ok) {
		throw new Error(`HTTP error! Status: ${response.status}`)
	}

	return await response.json()
}
