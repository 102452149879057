// src/components/Post/CustomImageGallery.jsx

import React from 'react'
import ImageGallery from 'react-image-gallery'
import styled from 'styled-components'
import 'react-image-gallery/styles/css/image-gallery.css'

const StyledGalleryContainer = styled.div`
	.image-gallery-svg {
		width: 60px;
		height: 60px;
	}

	.image-gallery-left-nav,
	.image-gallery-right-nav {
		top: 50%;
		transform: translateY(-50%);
	}
`

const GalleryImage = styled.img`
	max-width: 100%;
	max-height: 100vh;
	margin: auto;
	display: block;
`

const CustomImageGallery = ({ images, startIndex }) => {
	// 画像データを React Image Gallery 形式に変換
	const galleryImages = images.map((image) => {
		// TODO imageの取り方を統一する
		const imageUrl = image.src || image.image_path || image.image_url

		return {
			original: imageUrl,
			thumbnail: image.thumbnail || imageUrl, // サムネイルがなければ元の画像を使用
		}
	})

	const handleImageClick = (e) => {
		e.stopPropagation() // ここでイベントの伝播を止める
	}

	const renderCustomSlide = ({ original }) => {
		return (
			<div onClick={(e) => e.stopPropagation()}>
				<GalleryImage src={original} />
			</div>
		)
	}

	return (
		<StyledGalleryContainer>
			<ImageGallery
				items={galleryImages}
				startIndex={startIndex}
				showPlayButton={false}
				showFullscreenButton={false}
				showThumbnails={false}
				stopPropagation={true}
				renderItem={renderCustomSlide}
				onClick={handleImageClick}
				showNav={true}
			/>
		</StyledGalleryContainer>
	)
}

export default CustomImageGallery
