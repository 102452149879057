// src/actions/userActions.js

import { getUserByIdAPI, updateUserAPI } from '../services/userApi'

export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'

export const ADD_USER_DATA = 'ADD_USER_DATA'

export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'

export const SET_PROFILE_USER = 'SET_PROFILE_USER'

export const UPDATE_USER_INFO_IN_POSTS = 'UPDATE_USER_INFO_IN_POSTS'
export const SET_USER_TYPE = 'SET_USER_TYPE'

// 非同期アクションクリエーター
export const fetchUserAction = (userId) => {
	return async (dispatch) => {
		try {
			const data = await getUserByIdAPI(userId)
			dispatch(fetchUserSuccess(data.user))
		} catch (error) {
			dispatch(fetchUserFailure(error.message))
		}
	}
}

export const fetchUserProfileAction = (userId) => {
	return async (dispatch) => {
		try {
			const data = await getUserByIdAPI(userId)
			dispatch(setProfileUser(data.user))
			dispatch(addUserData(data.user))
		} catch (error) {
			dispatch(fetchUserFailure(error.message))
		}
	}
}

// updateUser 非同期アクションクリエーター
export const updateUserProfile = (userId, formData) => {
	return async (dispatch) => {
		try {
			const data = await updateUserAPI(userId, formData)
			dispatch(updateUserSuccess(data.user))
			dispatch(updateUserInfoInPosts())
		} catch (error) {
			console.error('Error in updateUserProfile action:', error)
			dispatch(updateUserFailure(error.message))
		}
	}
}

export const fetchUserSuccess = (user) => {
	return {
		type: FETCH_USER_SUCCESS,
		payload: user,
	}
}

export const fetchUserFailure = (error) => {
	return {
		type: FETCH_USER_FAILURE,
		payload: error,
	}
}

export const updateUserSuccess = (response) => {
	return {
		type: UPDATE_USER_SUCCESS,
		payload: response,
	}
}

export const updateUserFailure = (error) => {
	return {
		type: UPDATE_USER_FAILURE,
		payload: error,
	}
}

// プロフィール画面用のユーザー情報をセットするアクションクリエーター
export const setProfileUser = (user) => {
	return {
		type: SET_PROFILE_USER,
		payload: user,
	}
}

export const updateUserInfoInPosts = (updatedUserInfo) => ({
	type: UPDATE_USER_INFO_IN_POSTS,
	payload: updatedUserInfo,
})

export function setUserType(userType) {
	return {
		type: SET_USER_TYPE,
		payload: userType,
	}
}

export const addUserData = (user) => ({
	type: ADD_USER_DATA,
	payload: user,
})
