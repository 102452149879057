// src/utils/validation.js

export const validateUsername = (username) => {
	if (!username) {
		return 'enterValidUsername'
	}
	return null
}

export const validateEmail = (email) => {
	if (!email || !/\S+@\S+\.\S+/.test(email)) {
		return 'enterValidEmail'
	}
	return null
}

export const validatePassword = (password) => {
	// 8文字以上であること
	if (!password || password.length < 8) {
		return 'passwordMinLength'
	}

	// 半角アルファベットと数字の組み合わせであること
	if (!/[A-Za-z]/.test(password) || !/[0-9]/.test(password)) {
		return 'passwordAlphanumericRequirement'
	}

	return null
}
