// src/components/Settings/YourAccount/DeleteAccount.jsx

import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import MainLayout from '../Layout/MainLayout'
import { verifyCurrentPasswordApi } from '../../services/changePasswordApi'
import { closeAccountApi } from '../../services/authApi'
import { logoutAction } from '../../actions/authActions'
import styled from 'styled-components'
import {
	Container,
	Form,
	Button,
	InputGroup,
	FormControl,
} from 'react-bootstrap'
import { useSnackbar } from '../../context/SnackbarContext'

const PageTitle = styled.div`
	font-size: ${({ theme }) => theme.fontSizes.extraLarge};
	font-weight: bold;
	padding: 20px 20px 0px;
`

const ChangePasswordContainer = styled(Container)`
	padding: 20px 20px 0px;
`

const SubTitle = styled.div`
	font-size: ${({ theme }) => theme.fontSizes.medium};
	padding: 8px 20px 10px;
`

const EnterPasswordTitle = styled.div`
	font-size: ${({ theme }) => theme.fontSizes.medium};
	padding: 0px 0px 6px;
`

const StyledFormGroup = styled(Form.Group)`
	padding: 0px 0px 14px;
	font-size: ${({ theme }) => theme.fontSizes.medium};
`

const StyledFormControl = styled(FormControl)`
	height: 52px;
	padding: 6px 12px;
	font-weight: bold;
`

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end; // コンテンツをコンテナの右端に寄せる
	margin-top: 30px; // 必要に応じてマージンを調整
`

const StyledButton = styled(Button)`
	border-radius: 50px;
	padding: 10px 20px;
	font-weight: bold;
`

const DeleteAccount = () => {
	const dispatch = useDispatch()
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
	const [showPassword, setShowPassword] = useState(false)
	const [formErrors, setFormErrors] = useState({ password: '' })
	const { t } = useTranslation()
	const navigate = useNavigate()
	const location = useLocation()
	const backPath = location.state?.from || '/settings'
	const showAlert = useSnackbar()

	useEffect(() => {
		if (!isAuthenticated) {
			navigate('/')
		}
	}, [isAuthenticated, navigate])

	const handleSubmit = async (e) => {
		e.preventDefault()
		const password = e.target.password.value

		try {
			const response = await verifyCurrentPasswordApi(password)

			if (response.isValid) {
				await closeAccountApi(password)
				dispatch(logoutAction())
				showAlert(t('deletedSuccessfully'), 'success')
				navigate('/')
				setFormErrors({})
			} else {
				setFormErrors({
					password: t('passwordIncorrect'),
				})
			}
		} catch (error) {
			console.error('Error during password change:', error)
			showAlert('Failed to delete the account. Please try again.', 'error')
		}
	}

	return (
		<MainLayout showBackButton={true} backPath={backPath}>
			<PageTitle>{t('deleteAccountTitle')}</PageTitle>
			<SubTitle>{t('sorryToGo')}</SubTitle>
			<ChangePasswordContainer>
				<EnterPasswordTitle>{t('enterYourPassword')}</EnterPasswordTitle>
				<Form onSubmit={handleSubmit} noValidate>
					<StyledFormGroup controlId="Password">
						{/* <Form.Label>Password</Form.Label> */}
						<InputGroup>
							<StyledFormControl
								type={showPassword ? 'text' : 'password'}
								// placeholder="Password"
								name="password"
								isInvalid={!!formErrors.password}
							/>
							<Form.Control.Feedback type="invalid">
								{t(formErrors.password)}
							</Form.Control.Feedback>
						</InputGroup>
					</StyledFormGroup>
					<ButtonContainer>
						<StyledButton variant="danger" type="submit">
							{t('deleteAccountButton')}
						</StyledButton>
					</ButtonContainer>
				</Form>
			</ChangePasswordContainer>
		</MainLayout>
	)
}

export default DeleteAccount
