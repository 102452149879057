// src/components/Report/ReportForm.jsx

import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { Radio, FormControlLabel } from '@mui/material'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { sendReportApi } from '../../services/reportApi'

import SaveButton from '../Button/SaveButton'
import { hoverIconStyle } from '../../styles/commonStyles'
import { useSnackbar } from '../../context/SnackbarContext'

const StyledModal = styled(Modal)`
	.modal-dialog {
		margin: 1.75rem auto;
		display: flex;
		justify-content: center;
		width: 100%;
	}

	.modal-content {
		border-radius: 20px;

		// スマートフォン用スタイル
		@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
			padding: 12px 12px 40px;
		}

		// PCブラウザ用スタイル
		@media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
			padding: 12px 12px 40px;
			min-width: 560px;
			height: 600px;
		}
	}
`

const StyledModalBody = styled.div`
	overflow-y: auto;
	flex-grow: 1;
	padding: 16px 30px 10px;
`

const FixedFooter = styled.div`
	padding: 10px 0 0px;
	display: flex;
	justify-content: center;
	align-items: center;
`

const TitleBar = styled.div`
	padding: 10px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`

const Title = styled.div`
	flex: 1;
	text-align: center;
	font-weight: bold;
	font-size: ${({ theme }) => theme.fontSizes.extraLarge};
`

const CloseButton = styled.button`
	${hoverIconStyle}
	background: none;
	border: none;
	cursor: pointer;
`

const Message = styled.div`
	font-size: ${({ theme }) => theme.fontSizes.subTitle};
	margin: 0px 0 30px;
	font-weight: bold;
`

const OptionContainer = styled.div`
	display: block;
	margin-bottom: 1rem;
`

const StyledLabel = styled.label`
	font-size: ${({ theme }) => theme.fontSizes.large};
	font-weight: bold;
	display: block;
`

const Description = styled.div`
	margin-top: 0.1rem;
	margin-bottom: 2rem;
	width: 100%;
`
const OptionLabelAndButton = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const Dummy = styled.div`
	width: 48px; // CloseButton と同じ幅に設定
	height: 48px; // 必要に応じて高さを設定
`

const ReportForm = ({
	contentId,
	contentType,
	isOpen,
	handleClose,
	reporterUserId,
	reportedUserId,
}) => {
	const [selectedCategory, setSelectedCategory] = useState('')
	const { t } = useTranslation()
	const showAlert = useSnackbar()
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)

	const reportOptions = [
		{
			value: 'violence',
			label: t('violence'),
			description: t('violenceDescription'),
		},
		{
			value: 'hateSpeech',
			label: t('hateSpeech'),
			description: t('hateSpeechDescription'),
		},
		{
			value: 'harassment',
			label: t('harassment'),
			description: t('harassmentDescription'),
		},
		{
			value: 'spam',
			label: t('spam'),
			description: t('spamDescription'),
		},
		{
			value: 'copyright',
			label: t('copyright'),
			description: t('copyrightDescription'),
		},
		{
			value: 'privacy',
			label: t('privacy'),
			description: t('privacyDescription'),
		},
		{
			value: 'falseInformation',
			label: t('falseInformation'),
			description: t('falseInformationDescription'),
		},
		{
			value: 'threat',
			label: t('threat'),
			description: t('threatDescription'),
		},
		{
			value: 'nudity',
			label: t('nudity'),
			description: t('nudityDescription'),
		},
		{
			value: 'illegalActivities',
			label: t('illegalActivities'),
			description: t('illegalActivitiesDescription'),
		},
	]

	const handleSubmitReport = async () => {
		const reportData = {
			reported_content_id: contentId,
			content_type: contentType,
			report_category: selectedCategory,
			reporter_user_id: reporterUserId,
			reported_user_id: reportedUserId,
		}

		try {
			const response = await sendReportApi(reportData)
			showAlert(t('reportedSuccessfully'), 'success')
			handleClose()
		} catch (error) {
			console.error('Failed to submit report', error)
		}
	}

	return (
		<StyledModal show={isOpen} onHide={handleClose} centered>
			<TitleBar>
				<CloseButton onClick={handleClose}>✕</CloseButton>
				<Title>{t('reportTitle')}</Title>
				<Dummy></Dummy>
			</TitleBar>
			<StyledModalBody>
				<Message>{t('reportMessage')}</Message>
				{reportOptions.map((option) => (
					<OptionContainer key={option.value}>
						<OptionLabelAndButton>
							<StyledLabel htmlFor={`report-${option.value}`}>
								{option.label}
							</StyledLabel>
							<FormControlLabel
								control={
									<Radio
										checked={selectedCategory === option.value}
										onChange={(e) => {
											setSelectedCategory(e.target.value)
										}}
										value={option.value}
										name="reportCategory"
										sx={{
											color: '#757575',
											'&.Mui-checked': {
												color: '#D5004E',
											},
											'&:hover': {
												bgcolor: 'rgba(213,0,78,0.04)',
											},
										}}
									/>
								}
								label=""
								onClick={(e) => {
									e.stopPropagation()
								}}
							/>
						</OptionLabelAndButton>
						<Description>{option.description}</Description>
					</OptionContainer>
				))}
			</StyledModalBody>
			<FixedFooter>
				<SaveButton
					onClick={handleSubmitReport}
					disabled={!isAuthenticated || selectedCategory === ''}
				>
					{t('Submit')}
				</SaveButton>
			</FixedFooter>
		</StyledModal>
	)
}

export default ReportForm
