// src/components/Comment/CommentItem.jsx

import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import AvatarWithFlagComment from '../Avatar/AvatarWithFlagComment'
import CommentContent from './CommentContent'
import CommentFooter from './CommentFooter'
import CommentImageModal from './CommentImageModal'
import CommentComposer from './CommentComposer'
import ReplyCreationForm from '../Comment/ReplyCreationForm'
import { useEditingComment } from '../../hooks/useEditingComment'
import { useModal } from '../../hooks/useModal'
import SVGConnector from './SVGConnector'
import { isLanguageMatch } from '../../utils/languageService'

const INDENT_WIDTH = 28
const MAX_NEST_LEVEL = 20

const CommentContainer = styled.div`
	width: 100%;
	padding-left: ${({ $level }) =>
		Math.min($level, MAX_NEST_LEVEL) * INDENT_WIDTH}px;
	box-sizing: border-box;
`

const CommentItemWrapper = styled.div`
	display: flex;
	position: ${({ $position }) => $position};
	align-items: flex-start;
	width: 100%;
	margin-left: 0px;
`

const CommentContentWrapper = styled.div`
	flex: 1;
	margin-left: 4px; // この値を調整
`

const makeSelectCommentTranslations = () =>
	createSelector(
		(state, commentId) => state.comments.byId[commentId],
		(comment) => ({
			translationStatus: comment?.translationStatus,
			translations: comment?.translations || [],
		})
	)

const CommentItem = React.forwardRef(
	(
		{
			userInfo,
			comment,
			postId,
			level,
			isDefaultReply,
			onCommentAdd,
			onCommentUpdate,
			onCommentDelete,
			connectorPaths,
			forceUpdate,
			replyRefs,
		},
		ref
	) => {
		const navigate = useNavigate()
		const commentRef = useRef(null)
		const { t } = useTranslation()
		const replyRef = useRef(null)
		const userLanguage = useSelector(
			(state) => state.translation.currentLanguage
		)
		const avatarSize = 'comment'

		const [showReplyForm, setShowReplyForm] = useState(false)
		const [showOriginal, setShowOriginal] = useState(false)
		const [modalIsOpen, openModal, handleCloseModal] = useModal()

		const { editingCommentId, handleEditComment, handleCancelEdit } =
			useEditingComment()
		const isEditing = comment.id === editingCommentId

		const commentUser = useMemo(() => comment.user, [comment.user])
		const username = useMemo(
			() => commentUser?.username,
			[commentUser?.username]
		)

		const selectCommentTranslations = useMemo(
			() => makeSelectCommentTranslations(),
			[]
		)
		const { translationStatus, translations } = useSelector(
			(state) => selectCommentTranslations(state, comment.id),
			(prev, next) =>
				prev.translationStatus === next.translationStatus &&
				prev.translations.length === next.translations.length
		)
		useEffect(() => {
			if (ref) {
				if (typeof ref === 'function') {
					ref(commentRef.current)
				} else {
					ref.current = commentRef.current
				}
			}
		}, [ref, comment.id])

		useEffect(() => {
			if (showReplyForm && replyRef.current) {
				replyRefs.current[comment.id] = replyRef.current
			} else {
				delete replyRefs.current[comment.id]
			}
		}, [showReplyForm, comment.id])

		const isTranslationNeeded = !isLanguageMatch(comment.language, userLanguage)
		const isTranslationAvailable = translations.some(
			(t) => t.language === userLanguage
		)

		const displayText = useMemo(() => {
			if (isTranslationNeeded && isTranslationAvailable) {
				const translation = translations.find(
					(t) => t.language === userLanguage
				)
				return translation ? translation.translated_content : comment.comment
			}
			return comment.comment
		}, [
			comment,
			userLanguage,
			isTranslationNeeded,
			isTranslationAvailable,
			translations,
		])

		const toggleOriginal = () => setShowOriginal(!showOriginal)

		const handleAvatarClick = useCallback(
			(e, userId) => {
				e.stopPropagation()
				navigate(`/profile/${userId}`)
			},
			[navigate]
		)

		const handleReplyClick = useCallback((e) => {
			e.stopPropagation()
			setShowReplyForm(true)
		}, [])

		const handleShowReplies = useCallback(() => {
			navigate(`/posts/${postId}#comment-${comment?.id}`)
		}, [navigate, postId, comment?.id])

		const handleCommentAdd = useCallback(
			async (newCommentData) => {
				await onCommentAdd(newCommentData)
				setShowReplyForm(false)
			},
			[onCommentAdd]
		)

		const handleUpdateComment = useCallback(
			async (updatedComment) => {
				await onCommentUpdate(comment.id, updatedComment)
				handleCancelEdit()
			},
			[comment.id, onCommentUpdate, handleCancelEdit]
		)

		const handleDeleteComment = useCallback(() => {
			onCommentDelete(comment.id)
		}, [comment.id, onCommentDelete])

		const renderContent = () => {
			return (
				<CommentContent
					comment={comment}
					userId={comment?.user_id}
					postId={postId}
					showOriginalAndTranslatedText={
						isTranslationNeeded && isTranslationAvailable
					}
					showOriginal={showOriginal}
					displayText={displayText}
					toggleOriginal={toggleOriginal}
					onEditComment={handleEditComment}
					isEditing={isEditing}
					isTranslating={
						isTranslationNeeded &&
						(translationStatus === 'in_progress' || !isTranslationAvailable)
					}
					translatingText={t('translating')}
				/>
			)
		}

		if (isEditing) {
			return (
				<CommentComposer
					postId={postId}
					commentId={comment?.id}
					isReply={true}
					isEditing={true}
					editingComment={comment}
					onCancel={handleCancelEdit}
					onSubmit={handleUpdateComment}
					userInfo={userInfo}
				/>
			)
		}

		return (
			<CommentContainer $level={level}>
				<CommentItemWrapper
					id={`comment-${comment.id}`}
					$level={isDefaultReply ? 0 : level}
					ref={commentRef}
					$position="relative"
				>
					<AvatarWithFlagComment
						username={username}
						avatar={commentUser?.avatar}
						avatarSize={avatarSize}
						userId={comment?.user_id}
						countryCode={commentUser?.country_code}
						onAvatarClick={handleAvatarClick}
					/>
					{((comment.children && comment.children.length > 0) ||
						showReplyForm) && (
						<SVGConnector
							key={`${forceUpdate}-${showReplyForm}`}
							connectorPaths={connectorPaths || []}
						/>
					)}
					<CommentContentWrapper>
						{renderContent()}
						{comment.image_url && (
							<CommentImageModal
								comment={comment}
								imageUrl={comment.image_url}
								modalIsOpen={modalIsOpen}
								handleImageClick={openModal}
								handleCloseModal={handleCloseModal}
							/>
						)}
						<CommentFooter
							onReplyClick={handleReplyClick}
							onShowRepliesClick={handleShowReplies}
							onDeleteClick={handleDeleteComment}
							commentId={comment?.id}
							userId={comment?.user_id}
							type="comment"
						/>
						{showReplyForm && (
							<ReplyCreationForm
								postId={postId}
								commentId={comment.id}
								username={username}
								userId={comment?.user_id}
								focus={showReplyForm}
								ref={replyRef}
								isFocused={showReplyForm}
								onSubmit={handleCommentAdd}
							/>
						)}
					</CommentContentWrapper>
				</CommentItemWrapper>
			</CommentContainer>
		)
	},
	(prevProps, nextProps) => {
		return (
			prevProps.comment.id === nextProps.comment.id &&
			prevProps.comment.created_at === nextProps.comment.created_at &&
			prevProps.comment.comment === nextProps.comment.comment &&
			prevProps.comment.user?.username === nextProps.comment.user?.username &&
			prevProps.comment.user?.avatar === nextProps.comment.user?.avatar &&
			prevProps.postId === nextProps.postId &&
			prevProps.level === nextProps.level &&
			prevProps.isDefaultReply === nextProps.isDefaultReply &&
			prevProps.connectorPath === nextProps.connectorPath &&
			prevProps.forceUpdate === nextProps.forceUpdate
		)
	}
)

export default CommentItem
