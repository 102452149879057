// src/hooks/useNavigateToProfile.js

import { useNavigate } from 'react-router-dom'

const useNavigateToProfile = () => {
	const navigate = useNavigate()

	const navigateToProfile = (postOwnerId) => {
		const handleAvatarClick = (e) => {
			e.stopPropagation()
			navigate(`/profile/${postOwnerId}`)
		}

		return handleAvatarClick
	}

	return navigateToProfile
}

export default useNavigateToProfile
