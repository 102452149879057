// src/actions/postAction.js

import {
	postToDatabase,
	fetchPostsApi,
	fetchPostByIdApi,
	fetchQuestionPostsApi,
	deletePostApi,
	updatePostApi,
	getUserPostsAPI,
	fetchUpdatedPostApi,
} from '../services/postApi'
import { establishSSEConnection } from '../utils/sseUtils'

export const POST_REQUEST = 'POST_REQUEST'
export const POST_SUCCESS = 'POST_SUCCESS'
export const POST_FAILURE = 'POST_FAILURE'
export const SET_POSTS = 'SET_POSTS'
export const UPDATE_POST_TRANSLATION = 'UPDATE_POST_TRANSLATION'
export const UPDATE_TRANSLATION_STATUS = 'UPDATE_TRANSLATION_STATUS'

export const SET_POST_DETAILS = 'SET_POST_DETAILS'
export const FETCH_POST_ERROR = 'FETCH_POST_ERROR'
export const FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS'
export const FETCH_MORE_POSTS_SUCCESS = 'FETCH_MORE_POSTS_SUCCESS'
export const FETCH_POSTS_FAILURE = 'FETCH_POSTS_FAILURE'

export const FETCH_QUESTION_POSTS_REQUEST = 'FETCH_QUESTION_POSTS_REQUEST'
export const FETCH_QUESTION_POSTS_SUCCESS = 'FETCH_QUESTION_POSTS_SUCCESS'
export const FETCH_MORE_QUESTION_POSTS_SUCCESS =
	'FETCH_MORE_QUESTION_POSTS_SUCCESS'
export const FETCH_QUESTION_POSTS_FAILURE = 'FETCH_QUESTION_POSTS_FAILURE'
export const RESET_QUESTION_POSTS = 'RESET_QUESTION_POSTS'

export const ADD_NEW_POST = 'ADD_NEW_POST'
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS'
export const DELETE_POST_FAILURE = 'DELETE_POST_FAILURE'
export const DELETE_SEARCH_POST_SUCCESS = 'DELETE_SEARCH_POST_SUCCESS'

export const UPDATE_POST_SUCCESS = 'UPDATE_POST_SUCCESS'
export const UPDATE_POST_FAILURE = 'UPDATE_POST_FAILURE'

export const SET_PROFILE_POSTS = 'SET_PROFILE_POSTS'

export const UPDATE_LAST_FETCHED = 'UPDATE_LAST_FETCHED'
export const RESET_PROFILE_POSTS = 'RESET_PROFILE_POSTS'

export const ADD_PROFILE_POSTS = 'ADD_PROFILE_POSTS'

export const postRequest = () => ({ type: POST_REQUEST })
export const postSuccess = (data) => ({ type: POST_SUCCESS, payload: data })
export const postFailure = (error) => ({ type: POST_FAILURE, payload: error })
export const addNewPost = (post) => ({
	type: ADD_NEW_POST,
	payload: post,
})

export const submitPost = (postData) => async (dispatch) => {
	dispatch(postRequest())
	try {
		const response = await postToDatabase(postData)
		dispatch(postSuccess(response.post))

		if (response.sseUrl) {
			establishSSEConnection(
				response.sseUrl,
				(data) => {
					dispatch(handleSSETranslation(response.post.id, data))
				},
				(error) => {
					console.error('SSE error:', error)
					dispatch(updateTranslationStatus(response.post.id, 'error'))
				}
			)
		} else {
			console.warn('No SSE URL provided for post:', response.post.id)
		}

		return response
	} catch (error) {
		console.error('Error in submitPost:', error)
		dispatch(postFailure(error.message))
		throw error
	}
}

export const handleSSETranslation = (postId, data) => (dispatch) => {
	const { translations, status } = data

	if (status === 'completed' && translations) {
		dispatch({
			type: UPDATE_TRANSLATION_STATUS,
			payload: {
				postId,
				status: 'completed',
				translations,
			},
		})
	} else if (status === 'in_progress') {
		dispatch({
			type: UPDATE_TRANSLATION_STATUS,
			payload: { postId, status: 'in_progress' },
		})
	} else if (status === 'timeout') {
		dispatch({
			type: UPDATE_TRANSLATION_STATUS,
			payload: { postId, status: 'timeout' },
		})
	}
}

// 投稿をフェッチするアクション
export const fetchPosts =
	(offset = 0) =>
	async (dispatch) => {
		dispatch(postRequest())
		try {
			const data = await fetchPostsApi(offset)

			if (data.message === 'success') {
				const postsWithLikes = data.posts.map((post) => ({
					...post,
					isLiked: post.isLiked,
				}))

				if (offset === 0) {
					dispatch({
						type: FETCH_POSTS_SUCCESS,
						payload: {
							posts: postsWithLikes,
							hasMore: data.hasMore,
							nextOffset: data.nextOffset,
						},
					})
				} else {
					dispatch({
						type: FETCH_MORE_POSTS_SUCCESS,
						payload: {
							posts: postsWithLikes,
							hasMore: data.hasMore,
							nextOffset: data.nextOffset,
						},
					})
				}
			} else {
				dispatch({
					type: FETCH_POSTS_FAILURE,
					payload: 'Failed to fetch posts',
				})
			}

			const posts = data.posts
			return posts
		} catch (error) {
			dispatch({ type: FETCH_POSTS_FAILURE, payload: error.toString() })
			return []
		}
	}

export const fetchUpdatedPost = (postId) => async (dispatch) => {
	try {
		const data = await fetchUpdatedPostApi(postId)
		if (data.message === 'success') {
			dispatch({ type: UPDATE_POST_SUCCESS, payload: data.post })
		} else {
			dispatch({
				type: UPDATE_POST_FAILURE,
				payload: 'Failed to fetch updated post',
			})
		}
	} catch (error) {
		dispatch({ type: UPDATE_POST_FAILURE, payload: error.toString() })
	}
}

export const fetchPostDetails = (postId) => async (dispatch, getState) => {
	try {
		const response = await fetchPostByIdApi(postId)
		const postData = response.post || response

		if (postData) {
			dispatch({ type: SET_POST_DETAILS, payload: postData })
			return postData
		} else {
			throw new Error('Post not found')
		}
	} catch (error) {
		console.error('Error fetching post details:', error)
		dispatch({ type: FETCH_POST_ERROR, payload: error.message })
		return null
	}
}

export const fetchQuestionPosts =
	(offset = 0) =>
	async (dispatch) => {
		dispatch({ type: FETCH_QUESTION_POSTS_REQUEST })

		try {
			const data = await fetchQuestionPostsApi(offset)

			if (data.message === 'success') {
				dispatch({
					type:
						offset === 0
							? FETCH_QUESTION_POSTS_SUCCESS
							: FETCH_MORE_QUESTION_POSTS_SUCCESS,
					payload: {
						posts: data.posts,
						hasMore: data.hasMore,
						nextOffset: data.nextOffset,
					},
				})
			} else {
				dispatch({
					type: FETCH_QUESTION_POSTS_FAILURE,
					payload: 'Failed to fetch question posts',
				})
			}
		} catch (error) {
			// console.error('[DEBUG] Error fetching question posts:', error)
			dispatch({
				type: FETCH_QUESTION_POSTS_FAILURE,
				payload: error.toString(),
			})
		}
	}

// 該当ユーザーのポストを取得するアクション
export const fetchUserPostsAction = (userId, offset) => async (dispatch) => {
	try {
		const userPosts = await getUserPostsAPI(userId, offset)
		const { posts, totalPosts } = userPosts

		if (offset === 0) {
			dispatch({
				type: SET_PROFILE_POSTS,
				payload: { posts, totalPosts },
			})
		} else {
			// 追加読み込み時
			dispatch(addProfilePosts(posts, totalPosts))
		}
	} catch (error) {
		console.error('Error fetching user data:', error)
	}
}

export const deletePost = (postId) => async (dispatch, getState) => {
	try {
		const data = await deletePostApi(postId)
		if (data.status === 'success') {
			dispatch({
				type: DELETE_POST_SUCCESS,
				payload: postId,
			})
			dispatch({
				type: DELETE_SEARCH_POST_SUCCESS,
				payload: `post_${postId}`,
			})

			const profilePosts = getState().posts.profilePosts
			const updatedProfilePosts = profilePosts.filter(
				(post) => post.id !== postId
			)
			dispatch({
				type: SET_PROFILE_POSTS,
				payload: {
					posts: updatedProfilePosts,
					totalPosts: updatedProfilePosts.length,
				},
			})
		} else {
			dispatch({ type: DELETE_POST_FAILURE, payload: data.message })
		}
	} catch (error) {
		dispatch({ type: DELETE_POST_FAILURE, payload: error.toString() })
	}
}

export const updatePostAction =
	(postId, formData) => async (dispatch, getState) => {
		dispatch(postRequest())
		let sseConnection = null

		try {
			const updatedPost = await updatePostApi(postId, formData)

			if (updatedPost.status === 'success') {
				dispatch(fetchUpdatedPost(postId))
				dispatch({ type: SET_POST_DETAILS, payload: updatedPost.post })

				// SSE接続を確立
				if (updatedPost.sseUrl) {
					sseConnection = establishSSEConnection(
						updatedPost.sseUrl,
						(data) => {
							dispatch(handleSSETranslation(postId, data))
						},
						(error) => {
							console.error('SSE error for updated post:', error)
							dispatch(updateTranslationStatus(postId, 'error'))
						}
					)
				} else {
					console.warn('No SSE URL received for updated post:', postId)
				}

				const { posts, profilePosts } = getState().posts
				const updatedPosts = posts.map((post) =>
					post.id === postId ? { ...post, ...updatedPost.post } : post
				)
				const updatedProfilePosts = profilePosts.map((post) =>
					post.id === postId ? { ...post, ...updatedPost.post } : post
				)

				dispatch({
					type: SET_POSTS,
					payload: updatedPosts,
				})
				dispatch({
					type: SET_PROFILE_POSTS,
					payload: {
						posts: updatedProfilePosts,
						totalPosts: updatedProfilePosts.length,
					},
				})

				return updatedPost
			} else {
				throw new Error('Failed to update post')
			}
		} catch (error) {
			console.error('Error in updatePost:', error)
			dispatch({
				type: UPDATE_POST_FAILURE,
				payload: error.toString(),
			})
			if (sseConnection) {
				sseConnection.close()
			}
			throw error
		}
	}

export const updateLastFetched = () => {
	return {
		type: UPDATE_LAST_FETCHED,
		payload: Date.now(),
	}
}

export const resetProfilePosts = () => {
	return {
		type: RESET_PROFILE_POSTS,
	}
}

export const addProfilePosts = (posts, totalPosts) => {
	return {
		type: ADD_PROFILE_POSTS,
		payload: {
			posts,
			totalPosts,
		},
	}
}

export const resetQuestionPosts = () => {
	return {
		type: RESET_QUESTION_POSTS,
	}
}

export const updatePostTranslation = (
	postId,
	translationType,
	translation
) => ({
	type: UPDATE_POST_TRANSLATION,
	payload: { postId, translationType, translation },
})

export const updateTranslationStatus = (
	postId,
	status,
	translation = null
) => ({
	type: UPDATE_TRANSLATION_STATUS,
	payload: {
		postId,
		status,
		translation: translation
			? {
					language: translation.language,
					translated_content: translation.translated_content,
			  }
			: null,
	},
})
