// replyActions.js

// Action Types
export const ADD_REPLY = "ADD_REPLY";
export const REPLY_ERROR = "REPLY_ERROR";

// Action Creators
export const addReply = (reply) => ({
  type: ADD_REPLY,
  payload: reply,
});

export const replyError = (error) => ({
  type: REPLY_ERROR,
  payload: error,
});
