// src/components/Avatar/avatarConfig.js

import { sizes } from './Avatar'

export const AVATAR_CONFIG = {
	default: {
		flagSize: 'large',
		flagPosition: { left: '-5px', bottom: '-1px' },
	},
	comment: {
		flagSize: 'small',
		flagPosition: (size) => ({
			right: `${sizes[size] - 12}px`,
			bottom: '-2px',
		}),
	},
	// 他の設定があればここに追加
}
