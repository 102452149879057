// src/components/Button/PostButton.jsx

import React from 'react'
import StyledButton from '../Button/StyledButton'

const PostButton = ({ children, onClick, disabled }) => {
	return (
		<StyledButton
			onClick={() => !disabled && onClick && onClick()}
			variant="danger"
			disabled={disabled}
		>
			{children}
		</StyledButton>
	)
}

export default PostButton
