// src/reducers/userReducer.js

import {
	FETCH_USER_SUCCESS,
	FETCH_USER_FAILURE,
	ADD_USER_DATA,
	UPDATE_USER_SUCCESS,
	UPDATE_USER_FAILURE,
	SET_PROFILE_USER,
	SET_USER_TYPE,
} from '../actions/userActions'

import { LOGOUT_SUCCESS } from '../actions/authActions'

import {
	FETCH_POSTS_SUCCESS,
	FETCH_MORE_POSTS_SUCCESS,
} from '../actions/postAction'

const initialState = {
	loading: false,
	user: {}, // タイムライン用のユーザー情報
	profileUser: {}, // プロフィール画面用のユーザー情報
	users: {}, // 投稿に含まれるユーザー情報を保持する

	posts: [],
	user_type: null,
	error: '',
}

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_USER_SUCCESS:
			return {
				...state,
				loading: false,
				user: action.payload,
				error: '',
			}
		case FETCH_USER_FAILURE:
			return {
				loading: false,
				user: {},
				error: action.payload,
			}

		case ADD_USER_DATA:
			return {
				...state,
				users: {
					...state.users,
					[action.payload.id]: action.payload,
				},
			}

		case UPDATE_USER_SUCCESS:
			const updatedUser = action.payload
			let updatedPosts = state.posts
			if (state.posts.length > 0) {
				updatedPosts = state.posts.map((post) => {
					if (post && post.user && post.user.id === updatedUser.id) {
						return {
							...post,
							user: {
								...post.user,
								...updatedUser,
							},
						}
					}
					return post
				})
			}

			const updatedUsers = {
				...state.users,
				[updatedUser.id]: updatedUser,
			}

			return {
				...state,
				profileUser: updatedUser,
				posts: updatedPosts,
				users: updatedUsers,
				lastUpdated: Date.now(),
			}

		case UPDATE_USER_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			}

		case SET_PROFILE_USER:
			return {
				...state,
				profileUser: action.payload, // プロフィール用のユーザー情報を更新
			}

		case LOGOUT_SUCCESS:
			// ログアウト成功時にユーザー関連のステートをリセット
			return {
				...initialState,
			}
		case SET_USER_TYPE:
			return {
				...state,
				user: {
					...state.user,
					user_type: action.payload,
				},
			}

		case FETCH_POSTS_SUCCESS:
		case FETCH_MORE_POSTS_SUCCESS:
			const newUsers = action.payload.posts.reduce((acc, post) => {
				if (post.user && !acc[post.user.id]) {
					acc[post.user.id] = post.user
				}
				return acc
			}, {})
			return {
				...state,
				users: {
					...state.users,
					...newUsers,
				},
			}

		default:
			return state
	}
}

export default userReducer
