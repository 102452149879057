// replyReducer.js

import { ADD_REPLY, REPLY_ERROR } from "../actions/replyActions";

const initialState = {
  replies: [],
  error: null,
};

const replyReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_REPLY:
      return {
        ...state,
        replies: [...state.replies, action.payload],
      };
    case REPLY_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default replyReducer;
