// src/utils/languageService.js

export const getUserLanguage = () => {
	return navigator.language.split('-')[0] // e.g., 'en' from 'en-US'
}

export const getDisplayText = (contentItem, userLanguage) => {
	if (!contentItem) {
		return { text: '', shortenedUrls: [] }
	}

	const originalText =
		typeof contentItem.body === 'string' ? contentItem.body : ''
	const translations = contentItem.translations || []
	const translation = translations.find(
		(t) => t.language === userLanguage
	)?.translated_content

	const displayText = translation || originalText
	const shortenedUrls = originalText
		? originalText.match(/https:\/\/maps\.app\/[A-Za-z0-9_-]+/g)
		: null

	return {
		text: displayText,
		shortenedUrls: shortenedUrls ? [...new Set(shortenedUrls)] : [],
	}
}

export const getDisplayTextForNotification = (notification, userLanguage) => {
	const relatedData = notification.related_data || {}
	const commentText = relatedData.comment?.comment || ''
	const bodyText = relatedData.body || ''
	const text = commentText || bodyText || 'No content available'
	const language = relatedData.language || relatedData.comment?.language
	let translations = notification.translations || []

	if (!Array.isArray(translations)) {
		translations = []
	}

	if (userLanguage === language) {
		return text
	}

	const translation =
		translations.find((t) => t.language === userLanguage) ||
		translations.find((t) => t.language === 'en')

	return translation ? translation.translated_content : text
}

export const isLanguageMatch = (postLanguage, userLanguage) => {
	return postLanguage === userLanguage
}
