// src/utils/urlUtils.js

export const handleShortenedUrlClick = (e, shortenedUrl, locations) => {
	e.preventDefault()

	const location = locations.find(
		(location) => location.shortened_url === shortenedUrl
	)
	if (location) {
		window.open(location.place_link, '_blank')
	}
}
