// src/actions/hashtagActions.js

import { fetchPostsByHashtagApi } from '../services/postApi'
import { fetchRecommendedHashtagsApi } from '../services/hashtagApi'

export const FETCH_POSTS_BY_HASHTAG_REQUEST = 'FETCH_POSTS_BY_HASHTAG_REQUEST'
export const FETCH_POSTS_BY_HASHTAG_SUCCESS = 'FETCH_POSTS_BY_HASHTAG_SUCCESS'
export const FETCH_POSTS_BY_HASHTAG_FAILURE = 'FETCH_POSTS_BY_HASHTAG_FAILURE'

export const FETCH_POPULAR_HASHTAGS_REQUEST = 'FETCH_POPULAR_HASHTAGS_REQUEST'
export const FETCH_POPULAR_HASHTAGS_SUCCESS = 'FETCH_POPULAR_HASHTAGS_SUCCESS'
export const FETCH_POPULAR_HASHTAGS_FAILURE = 'FETCH_POPULAR_HASHTAGS_FAILURE'

export const FETCH_RECOMMENDED_HASHTAGS_REQUEST =
	'FETCH_RECOMMENDED_HASHTAGS_REQUEST'
export const FETCH_RECOMMENDED_HASHTAGS_SUCCESS =
	'FETCH_RECOMMENDED_HASHTAGS_SUCCESS'
export const FETCH_RECOMMENDED_HASHTAGS_FAILURE =
	'FETCH_RECOMMENDED_HASHTAGS_FAILURE'

export const fetchPostsByHashtag = (hashtag) => async (dispatch) => {
	dispatch({ type: FETCH_POSTS_BY_HASHTAG_REQUEST })

	try {
		const response = await fetchPostsByHashtagApi(hashtag)

		dispatch({
			type: FETCH_POSTS_BY_HASHTAG_SUCCESS,
			payload: response,
		})
	} catch (error) {
		dispatch({
			type: FETCH_POSTS_BY_HASHTAG_FAILURE,
			payload: error.message,
		})
	}
}

export const fetchPopularHashtags = () => ({
	type: FETCH_POPULAR_HASHTAGS_REQUEST,
})

export const fetchPopularHashtagsSuccess = (hashtags) => ({
	type: FETCH_POPULAR_HASHTAGS_SUCCESS,
	payload: hashtags,
})

export const fetchPopularHashtagsFailure = (error) => ({
	type: FETCH_POPULAR_HASHTAGS_FAILURE,
	payload: error,
})

export const fetchRecommendedHashtags =
	(partialHashtag) => async (dispatch) => {
		dispatch({ type: FETCH_RECOMMENDED_HASHTAGS_REQUEST })

		try {
			const response = await fetchRecommendedHashtagsApi(partialHashtag)

			dispatch({
				type: FETCH_RECOMMENDED_HASHTAGS_SUCCESS,
				payload: response,
			})
		} catch (error) {
			dispatch({
				type: FETCH_RECOMMENDED_HASHTAGS_FAILURE,
				payload: error.message,
			})
		}
	}
