// src/components/Comment/CommentComposer.jsx

import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ReplySubmitter from '../../components/Comment/ReplySubmitter'
import { useAuth } from '../../hooks/useAuth'

const CommentComposer = ({
	commentId = null,
	avatarSize,
	isReply = false,
	replyToUsername = null,
	replyToUserId = null,
	isFocused,
	isPostDetail,
	isEditing = false,
	editingComment = null,
	onSubmit,
	onCancel,
}) => {
	useAuth()
	const navigate = useNavigate()
	const user = useSelector((state) => state.user.user)
	const { t } = useTranslation()

	const initialText = editingComment ? editingComment?.comment : ''
	const initialImage =
		isEditing && editingComment?.image_url ? editingComment?.image_url : null

	const handleCommentSubmit = useCallback(
		(commentData) => {
			if (onSubmit) {
				onSubmit({
					...commentData,
					isReply,
					parentCommentId: isReply
						? isEditing
							? editingComment?.parent_comment_id
							: commentId
						: null,
				})
			}
		},
		[onSubmit, isReply, isEditing, editingComment, commentId]
	)

	const handleUserClick = (userId) => {
		navigate(`/profile/${userId}`)
	}

	return (
		<ReplySubmitter
			placeholder={
				isReply
					? `Reply to ${replyToUsername || user?.username}`
					: t('addComment')
			}
			isReply={isReply}
			onSubmit={handleCommentSubmit}
			avatarSize={avatarSize}
			username={user?.username}
			avatarUrl={user?.avatar}
			initialText={initialText}
			initialImage={initialImage}
			isEditing={isEditing}
			onCancel={onCancel}
			editingComment={editingComment}
			replyToUsername={replyToUsername}
			replyToUserId={replyToUserId}
			onUserClick={handleUserClick}
			isFocused={isFocused}
			isPostDetail={isPostDetail}
		/>
	)
}

export default CommentComposer
