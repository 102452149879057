// src/reducers/navigationReducer.js

import { SET_ACTIVE_LINK } from '../actions/navigationActions'

const initialState = {
	activeLink: '',
}

const activeLinkReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_ACTIVE_LINK:
			return {
				...state,
				activeLink: action.payload,
			}
		default:
			return state
	}
}

export default activeLinkReducer
