// src/components/Maintenance/MaintenancePage.jsx

import React from 'react'
import styled from 'styled-components'
import maintenanceImage from '../../assets/image/maintenance.jpeg'

const MaintenanceContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
	text-align: center;
	background-image: url(${maintenanceImage});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
`

const TextWrapper = styled.div`
	background-color: rgba(0, 0, 0, 0.7); // 文字の背景を透過
	padding: 40px;
	border-radius: 20px;
`

const MaintenanceTitle = styled.h1`
	font-size: 1.4em;
	margin-bottom: 6px;
	color: #fff;
`

const MaintenanceText = styled.p`
	font-size: 1em;
	color: #fff;
`

const MaintenancePage = () => {
	return (
		<MaintenanceContainer>
			<TextWrapper>
				<MaintenanceTitle>メンテナンス中...</MaintenanceTitle>
				<MaintenanceTitle>正在維修...</MaintenanceTitle>
				<MaintenanceTitle>Maintenance in Progress...</MaintenanceTitle>
				{/* <MaintenanceText>
					We are currently undergoing maintenance. Please wait a moment.
				</MaintenanceText> */}
			</TextWrapper>
		</MaintenanceContainer>
	)
}

export default MaintenancePage
