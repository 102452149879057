import styled from 'styled-components'

export const customModalStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'black',
		border: 'none',
		padding: 0,
		overflow: 'none',
		zIndex: 1000,
	},
	overlay: {
		backgroundColor: 'rgba(0, 0, 0)',
		zIndex: 1010,
		position: 'fixed',
	},
}

export const customCommentModalStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'black',
		border: 'none',
		padding: '0%',
		overflow: 'none',
		zIndex: 1000,
		display: 'flex', // Flexbox を有効にする
		alignItems: 'center', // 中央に寄せる
		justifyContent: 'center', // 中央に寄せる
	},
	overlay: {
		backgroundColor: 'rgba(0, 0, 0)',
		zIndex: 1010,
		position: 'fixed',
	},
}

// キャンセルアイコンのスタイリング
export const CancelIconWrapper = styled.img`
	position: fixed;
	top: 40px;
	left: 40px;
	width: 32px;
	cursor: pointer;
	z-index: 1020; // モーダルのコンテンツより高いz-index
`
