// src/components/Post/CreatePostModalHeader.jsx

import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { hoverIconStyle } from '../../styles/commonStyles'
import SaveDraft from '../Button/SaveDraft'
import PostButton from '../Button/PostButton'

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
`

const CloseButton = styled.button`
	${hoverIconStyle}
	background: none;
	border: none;
	cursor: pointer;
	margin-right: auto;
`

const SaveButtonWrapper = styled.div`
	margin-right: 12px;
`

const PostButtonWrapper = styled.div`
	// Add any specific styles for the post button wrapper if needed
`

const CreatePostModalHeader = ({
	onClose,
	onSaveDraft,
	onSubmitPost,
	isSubmitDisabled,
}) => {
	const { t } = useTranslation()

	return (
		<Header>
			<CloseButton onClick={onClose}>✕</CloseButton>
			<SaveButtonWrapper>
				<SaveDraft onClick={onSaveDraft} disabled={isSubmitDisabled}>
					{t('saveButton')}
				</SaveDraft>
			</SaveButtonWrapper>
			<PostButtonWrapper>
				<PostButton onClick={onSubmitPost} disabled={isSubmitDisabled}>
					{t('postButton')}
				</PostButton>
			</PostButtonWrapper>
		</Header>
	)
}

export default CreatePostModalHeader
