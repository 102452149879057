// src/components/Common/ConfirmationModal.jsx

import React, { useState, useEffect, useMemo } from 'react'
import Modal from 'react-modal'
import { disableBodyScroll, enableBodyScroll } from '../../utils/scrollControl'
import { useTranslation } from 'react-i18next'
import DiscardButton from '../Button/DiscardButton'
import SaveButton from '../Button/SaveButton'
import DeleteButton from '../Button/DeleteButton'
import CancelButton from '../Button/CancelButton'

import styled from 'styled-components'

const Title = styled.h4`
	margin: 0;
	padding-bottom: 20px;
	text-align: left;
	font-weight: bold;
`

const Message = styled.div`
	padding-bottom: 10px;
	text-align: left;
`

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-around;
	padding: 10px;
`

const ConfirmationModalStyles = {
	overlay: {
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		zIndex: '2000',
	},
	content: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		width: '380px',
		height: '240px',
		overflow: 'visible',
		border: '1px solid #ccc',
		borderRadius: '20px',
		padding: '0px',
		zIndex: '2000',
	},
}

const ConfirmationModal = ({
	isOpen,
	onRequestClose,
	title,
	message,
	showCancelButton = false,
	onCancel,
	showSaveButton = false,
	onSave,
	showDiscardButton = false,
	onDiscard,
	showDeleteButton = false,
	onDelete,
}) => {
	const { t } = useTranslation()

	const stopPropagation = (e) => {
		e.stopPropagation()
	}

	// const { disableScroll, enableScroll } = useScroll()

	useEffect(() => {
		if (isOpen) {
			disableBodyScroll()
		} else {
			enableBodyScroll()
		}
	}, [isOpen])

	// TODO モーダルの内側をクリックしてから外側をクリックしたら画面遷移するバグを直す
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			style={ConfirmationModalStyles}
		>
			<div onClick={stopPropagation}>
				{/* パディングを適用する内側の<div> */}
				<div style={{ padding: '30px' }}>
					<Title>{title}</Title>
					<Message>{message}</Message>
					<ButtonContainer>
						{showCancelButton && (
							<CancelButton
								onClick={(e) => {
									onCancel()
								}}
							>
								{t('cancelButton')}
							</CancelButton>
						)}
						{showSaveButton && (
							<SaveButton onClick={onSave}>{t('saveButton')}</SaveButton>
						)}
						{showDiscardButton && (
							<DiscardButton onClick={onDiscard}>
								{t('discardButton')}
							</DiscardButton>
						)}
						{showDeleteButton && (
							<DeleteButton onClick={onDelete}>
								{t('deleteButton')}
							</DeleteButton>
						)}
					</ButtonContainer>
				</div>
			</div>
		</Modal>
	)
}

export default ConfirmationModal
