// src/components/Notifications/NotificationItem.jsx

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { updateNotificationStatusAction } from '../../actions/notificationsActions'
import { updateNotificationStatus } from '../../services/notificationsApi'
import styled from 'styled-components'

import AvatarWithFlag from '../Avatar/AvatarWithFlag'
import { useTranslation } from 'react-i18next'
import { TranslationComponent } from '../TranslateContent/TranslationControls'
import {
	getDisplayTextForNotification,
	isLanguageMatch,
} from '../../utils/languageService'
import useNavigateToProfile from '../../hooks/useNavigateToProfile'

const NotificationWrapper = styled.div`
	cursor: pointer;
	transition: background-color 0.3s;

	&:hover,
	&:active {
		background-color: ${({ theme }) => theme.colors.background};
	}
`

const NotificationItemContainer = styled.div`
	display: flex;
	align-items: top;
	padding: 16px 20px 12px;
`

const NotificationContent = styled.div`
	flex-grow: 1;
	margin-left: 12px;
	display: flex;
	flex-direction: column;
`

const AvatarContainer = styled.div`
	flex-shrink: 0;
	margin-right: 12px;
`

const NotificationRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 4px;
`

const Date = styled.span`
	font-size: 0.9rem;
	color: ${({ theme }) => theme.colors.textSecondary};
`

const NotificationText = styled.div`
	font-size: 0.9rem;
`

const BoldText = styled.span`
	font-weight: bold;
`

const UnreadIndicator = styled.span`
	height: 8px;
	width: 8px;
	background-color: #cb1b45;
	border-radius: 50%;
	display: inline-block;
	margin-left: 10px;
`

const NotificationItem = ({ notification }) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const userLanguage = useSelector((state) => state.translation.currentLanguage)

	const contentItem = notification?.related_data
	const detectedLanguage = contentItem ? contentItem?.language : null

	const navigateToProfile = useNavigateToProfile()
	const handleAvatarClick = navigateToProfile(notification?.action_user_id)

	const [showOriginal, setShowOriginal] = React.useState(true)
	const toggleOriginal = () => {
		setShowOriginal(!showOriginal)
	}

	const relatedText = getDisplayTextForNotification(notification, userLanguage)
	const displayText =
		relatedText && relatedText.length > 50
			? relatedText.substring(0, 50) + '...'
			: relatedText

	const formatDate = (dateString) => {
		if (!dateString) return ''
		const [datePart] = dateString.split(' ')
		const [year, month, day] = datePart.split('-')
		return `${year}-${month}-${day}`
	}

	const renderNotificationMessage = () => {
		const { type, username } = notification
		let messageKey = ''

		switch (type) {
			case 'post_like':
				messageKey = 'notifLikedYourPost'
				break
			case 'comment_like':
				messageKey = 'notifLikedYourComment'
				break
			case 'post_comment':
			case 'comment_reply':
				messageKey = 'notifCommentReply'
				break
			default:
				messageKey = 'unknownNotification'
		}

		return (
			<>
				<BoldText>{username}</BoldText> {t(messageKey)}
			</>
		)
	}

	const handleNotificationClick = async () => {
		let postId
		let commentId

		if (notification?.status === 'unread') {
			try {
				await updateNotificationStatus(notification?.id, 'read')
				dispatch(updateNotificationStatusAction(notification?.id, 'read'))
			} catch (error) {
				console.error('Failed to update notification status:', error)
			}
		}

		switch (notification?.type) {
			case 'post_like':
				postId = notification?.related_data?.id
				if (postId) {
					navigate(`/posts/${postId}`, { state: { from: '/notifications' } })
				}
				break

			case 'comment_like':
			case 'comment_reply':
				postId = notification?.related_data?.post?.id
				commentId = notification?.related_data?.comment?.id
				if (postId && commentId) {
					navigate(`/posts/${postId}#comment-${commentId}`, {
						state: { from: '/notifications' },
					})
				}
				break

			case 'post_comment':
				postId =
					notification?.related_data?.post_id || notification?.related_data?.id
				commentId = notification?.associated_record_id
				if (postId && commentId) {
					navigate(`/posts/${postId}#comment-${commentId}`, {
						state: { from: '/notifications' },
					})
				}
				break

			default:
				break
		}
	}

	return (
		<NotificationWrapper onClick={handleNotificationClick}>
			<NotificationItemContainer>
				<AvatarContainer>
					<AvatarWithFlag
						username={notification?.username}
						avatar={notification?.avatar}
						size="default"
						onClick={handleAvatarClick}
						countryCode={notification?.country_code}
					/>
				</AvatarContainer>
				<NotificationContent>
					<NotificationRow>
						<Date>{formatDate(notification?.created_at)}</Date>
						{notification?.status === 'unread' && <UnreadIndicator />}
					</NotificationRow>
					<NotificationText>
						{renderNotificationMessage()}
						{displayText && (
							<div>
								{displayText}
								{!isLanguageMatch(detectedLanguage, userLanguage) && (
									<TranslationComponent
										detectedLanguage={detectedLanguage}
										showOriginal={showOriginal}
										toggleText={toggleOriginal}
										showControls={false}
									/>
								)}
							</div>
						)}
					</NotificationText>
				</NotificationContent>
			</NotificationItemContainer>
		</NotificationWrapper>
	)
}

export default NotificationItem
