// src/hooks/useReadMore.js

import { useState } from 'react'

function useReadMore(
	text = '',
	userLanguage,
	isDetailView,
	isHashtagView,
	defaultMaxLength = 400,
	japaneseMaxLength = 250
) {
	const [isReadMore, setIsReadMore] = useState(true)

	const toggleReadMore = () => {
		setIsReadMore(!isReadMore)
	}

	const isJapaneseText = userLanguage === 'ja'

	const maxLength = isDetailView
		? text.length
		: isJapaneseText
		? japaneseMaxLength
		: defaultMaxLength

	const isTruncated = !isDetailView && !isHashtagView && text.length > maxLength

	const resultText =
		isReadMore && isTruncated ? text.slice(0, maxLength) + '...' : text

	return { resultText, isTruncated, toggleReadMore, isReadMore, maxLength }
}

export default useReadMore
