// src/components/Profile/ActivityContent.jsx

import React, { useState } from 'react'
import { UserActivity } from './UserActivity'
import styled from 'styled-components'

const ActivityContentWrapper = styled.div`
	@media (min-width: 768px) {
		// flex: 1;
	}
`

const ActivityContent = React.memo(
	({
		user,
		currentUserId,
		postCount,
		commentCount,
		draftCount,
		posts = [],
		comments = [],
		drafts = [],
		isLoading,
		isLoadingPosts,
	}) => {
		const [tabValue, setTabValue] = useState(0)

		return (
			<ActivityContentWrapper>
				<UserActivity
					user={user}
					currentUserId={currentUserId}
					tabValue={tabValue}
					setTabValue={setTabValue}
					postCount={postCount}
					commentCount={commentCount}
					draftCount={draftCount}
					posts={posts}
					comments={comments}
					drafts={drafts}
					isLoading={isLoading}
					isLoadingPosts={isLoadingPosts}
				/>
			</ActivityContentWrapper>
		)
	}
)

export default ActivityContent
