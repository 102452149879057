// ImageListModal.jsx

import React from 'react'
import styled from 'styled-components'
import { FaCircleXmark } from 'react-icons/fa6'

const ImageListContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	padding: 10px 0;
`

const ImageContainer = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
`

const UploadedImage = styled.img`
	max-width: 100%;
	margin-bottom: 10px;
	border-radius: 8px;
`

const CloseButton = styled.button`
	background: none;
	border: none;
	cursor: pointer;
	margin-right: auto;
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: white;
	padding: 0;
	outline: none;
`

const ImageListModal = ({ images, onImageDelete }) => {
	if (!Array.isArray(images)) {
		return null
	}

	const handleDeleteImage = (index) => {
		if (images[index]) {
			URL.revokeObjectURL(images[index].url)
		}
		onImageDelete(index)
	}

	return (
		<ImageListContainer className="image-list-container">
			{images.map((image, index) => (
				<ImageContainer key={index}>
					<CloseButton onClick={() => handleDeleteImage(index)}>
						<FaCircleXmark size="30px" />
					</CloseButton>
					<UploadedImage src={image.url} alt={`uploaded image ${index + 1}`} />
				</ImageContainer>
			))}
		</ImageListContainer>
	)
}

export default ImageListModal
