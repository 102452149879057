// src/hooks/useCreatePostHandlers.js

import useImageManagement from './useImageManagement'
import usePostActions from './usePostActions'
import useGooglePlaceHandlers from './useGooglePlaceHandlers'

const useCreatePostHandlers = ({
	editorState,
	setEditorState,
	setSelectedLocations,
	...otherProps
}) => {
	const { handleImageUpload, handleImageDelete } =
		useImageManagement(otherProps)
	const { handleSubmitPost } = usePostActions({
		...otherProps,
		editorState,
		setEditorState,
		setSelectedLocations,
	})
	const { handlePlaceSelected } = useGooglePlaceHandlers({
		setSelectedLocations,
		setIsEdited: otherProps.setIsEdited,
	})

	return {
		handleSubmitPost,
		handleImageUpload,
		handleImageDelete,
		handlePlaceSelected: (location) =>
			handlePlaceSelected(location, editorState, setEditorState),
	}
}

export default useCreatePostHandlers
