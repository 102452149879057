// src/components/Common/PostMenuPopover.jsx

import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import Popover from '@mui/material/Popover'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { useTranslation } from 'react-i18next'
import ConfirmationModal from './ConfirmationModal'
import { deletePost } from '../../actions/postAction'
import { deleteComment } from '../../actions/commentActions'
import { deleteDraft } from '../../actions/draftAction'
import CreatePostModal from '../../components/Post/CreatePostModal'
import ReplySubmitter from '../../components/Comment/ReplySubmitter'
import { enableBodyScroll } from '../../utils/scrollControl'
import ReportForm from '../Report/ReportForm'
import { openReportModal, closeReportModal } from '../../actions/modalActions'
import { useSnackbar } from '../../context/SnackbarContext'

import styled, { css } from 'styled-components'
import {
	iconSmallStyle,
	hoverIconStyle,
	hoverCommentIconStyle,
} from '../../styles/commonStyles'

import { FaPen } from 'react-icons/fa6'
import { FaEllipsis } from 'react-icons/fa6'
import { FaTrashAlt } from 'react-icons/fa'
import { FaFlag } from 'react-icons/fa6'

const IconContainer = styled.div`
	${(props) => hoverIconStyle(props)}
	${({ type }) =>
		type === 'comment' &&
		css`
			${hoverCommentIconStyle}
		`}
`

const PopupText = styled.div`
	margin-left: 16px;
`

const PostMenuPopover = ({
	userId,
	postOwnerId,
	handleMenuClick,
	anchorEl,
	handleClose,
	postId,
	commentId,
	draftId,
	type, // 'post' または 'comment'
	onDelete,
	post,
	comment,
	draft,
	originalType,
	iconTop,
	onEditComment,
}) => {
	const open = Boolean(anchorEl)
	const id = open ? 'post-menu-popover' : undefined
	const dispatch = useDispatch()
	const [isEditModalOpen, setIsEditModalOpen] = useState(false)
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
	const [isReportModalOpen, setIsReportModalOpen] = useState(false)
	const { t } = useTranslation()
	const showAlert = useSnackbar()

	const handleEditClick = useCallback(
		(e) => {
			e.stopPropagation()
			if (type === 'post') {
				setIsEditModalOpen(true)
			} else if (type === 'comment') {
				onEditComment(comment.id)
			} else if (type === 'draft') {
				setIsEditModalOpen(true)
			}
			handleClose()
		},
		[type, comment, dispatch, handleClose]
	)

	// 削除の確認モーダルを表示
	const handleDeleteClick = useCallback(
		(e) => {
			e.stopPropagation()
			setIsDeleteModalOpen(true)
			handleClose()
		},
		[handleClose]
	)

	// モーダルを閉じる
	const handleModalClose = (e) => {
		if (e) e.stopPropagation()
		setIsDeleteModalOpen(false)
		enableBodyScroll()
	}

	// 削除処理を行う
	const handleConfirmDelete = () => {
		if (type === 'post') {
			dispatch(deletePost(postId))
			showAlert(t('deletedContentSuccessfully'), 'success')
		} else if (type === 'comment') {
			dispatch(deleteComment(commentId, postId))
			showAlert(t('deletedContentSuccessfully'), 'success')
		} else if (type === 'draft') {
			dispatch(deleteDraft(draftId))
			showAlert(t('deletedContentSuccessfully'), 'success')
		}
		setIsDeleteModalOpen(false)

		if (originalType) {
			onDelete()
		}

		enableBodyScroll()
	}

	const handleOpenReportForm = useCallback(() => {
		dispatch(openReportModal())
		setIsReportModalOpen(true)
		handleClose()
	}, [dispatch, handleClose])

	const handleCloseReportForm = () => {
		dispatch(closeReportModal())
		setIsReportModalOpen(false)
		enableBodyScroll()
	}

	return (
		<div>
			<IconContainer type={type} onClick={handleMenuClick}>
				<FaEllipsis style={iconSmallStyle} />
			</IconContainer>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				PaperProps={{
					elevation: 2,
					style: {
						borderRadius: '10px',
						width: '160px',
						maxWidth: 'none',
					},
				}}
				onClick={(e) => e.stopPropagation()}
			>
				<List>
					{userId === postOwnerId ? (
						<>
							<ListItem button onClick={handleEditClick}>
								<FaPen style={iconSmallStyle} />
								<PopupText>{t('edit')}</PopupText>
							</ListItem>
							<ListItem button onClick={handleDeleteClick}>
								<FaTrashAlt style={iconSmallStyle} />
								<PopupText>{t('delete')}</PopupText>
							</ListItem>
						</>
					) : (
						<>
							<ListItem button onClick={handleOpenReportForm}>
								<FaFlag style={iconSmallStyle} />
								<PopupText>{t('report')}</PopupText>
							</ListItem>
						</>
					)}
				</List>
			</Popover>
			{/* 編集モーダル (CreatePostModal) の表示 */}
			{isEditModalOpen && (
				<>
					{type === 'post' ? (
						<CreatePostModal
							isOpen={isEditModalOpen}
							onClose={() => setIsEditModalOpen(false)}
							post={post}
							iconTop={iconTop}
						/>
					) : type === 'comment' ? (
						<ReplySubmitter
							isOpen={isEditModalOpen}
							onClose={() => setIsEditModalOpen(false)}
							postId={comment.postId}
							commentId={comment.id}
							comment={comment}
						/>
					) : type === 'draft' ? (
						// Draft の場合のコンポーネントを追加
						<CreatePostModal
							isOpen={isEditModalOpen}
							onClose={() => setIsEditModalOpen(false)}
							draft={draft}
							iconTop={iconTop}
						/>
					) : null}
				</>
			)}
			{/* 削除確認モーダル (ConfirmationModal) の表示 */}
			{isDeleteModalOpen && (
				<ConfirmationModal
					isOpen={isDeleteModalOpen}
					onRequestClose={handleModalClose}
					title={t('DeletePost?')}
					message={t('irreversible')}
					// confirmButtonText={t('delete')}
					showCancelButton={true}
					showDeleteButton={true}
					onCancel={handleModalClose}
					onDelete={handleConfirmDelete}
				/>
			)}
			{/* レポート機能を追加 */}
			{isReportModalOpen && (
				<ReportForm
					contentId={postId || commentId}
					contentType={type}
					isOpen={isReportModalOpen}
					handleClose={handleCloseReportForm}
					reporterUserId={userId}
					reportedUserId={postOwnerId}
				/>
			)}
		</div>
	)
}

export default React.memo(PostMenuPopover)
