// src/components/Bookmark/BookmarkList.jsx

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { fetchBookmarkDetailAction } from '../../actions/bookmarkAction'
import BookmarkItem from './BookmarkItem'
import MainLayout from '../Layout/MainLayout'
import LoadingSpinner from '../Common/LoadingSpinner'
import { Divider } from '../../styles/commonStyles'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Castle from '../../assets/icon/castle.png'

const BookmarkListWrapper = styled.div`
	padding-bottom: 70px; // MobileNavbarの高さに応じて調整

	@media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
		padding-bottom: 0; // タブレット以上のサイズでは余分なパディングを削除
	}
`

const NoDataWrapper = styled.div`
	text-align: center;
	margin-top: 50px;
`

const MessageText = styled.p`
	margin: 20px 0 40px;
	font-size: ${({ theme }) => theme.fontSizes.large};
	font-weight: bold;
`

const StyledBookmarkIcon = styled.img`
	width: 120px; // 適切なサイズに調整してください
	height: auto;
	margin: 20px auto;
	// filter: drop-shadow(2px 4px 8px rgba(0, 0, 0, 0.2)) brightness(1.15);
`

const BookmarkList = () => {
	const dispatch = useDispatch()
	const userId = useSelector((state) => state.auth.userId)
	const bookmarkDetail = useSelector((state) => state.bookmarks.bookmarkDetail)
	const postBookmarks = useSelector((state) => state.bookmarks.postBookmarks)

	const location = useLocation()
	const backPath = location.state?.from === '/' ? '/' : '/'

	const [visibleBookmarks, setVisibleBookmarks] = useState([])
	const [isLoading, setIsLoading] = useState(false)

	const { t } = useTranslation()

	useEffect(() => {
		if (userId) {
			setIsLoading(true)
			dispatch(fetchBookmarkDetailAction(userId)).finally(() =>
				setIsLoading(false)
			)
		}
	}, [dispatch, userId])

	useEffect(() => {
		setVisibleBookmarks(
			bookmarkDetail.filter((item) => postBookmarks.includes(item.id))
		)
	}, [bookmarkDetail, postBookmarks])

	const handleDeleteBookmark = (bookmarkId) => {
		setVisibleBookmarks(
			visibleBookmarks.filter((bookmark) => bookmark.id !== bookmarkId)
		)
	}

	return (
		<MainLayout showBackButton={true} backPath={backPath}>
			<BookmarkListWrapper>
				{isLoading ? (
					<LoadingSpinner />
				) : visibleBookmarks.length > 0 ? (
					visibleBookmarks.map((detail, index) => (
						<React.Fragment key={detail.id}>
							<BookmarkItem bookmark={detail} onDelete={handleDeleteBookmark} />
							{index < visibleBookmarks.length - 1 && <Divider />}
						</React.Fragment>
					))
				) : (
					<NoDataWrapper>
						<MessageText>{t('noBookmarksMessage')}</MessageText>
						<StyledBookmarkIcon src={Castle} alt="Castle" />
					</NoDataWrapper>
				)}
			</BookmarkListWrapper>
		</MainLayout>
	)
}

export default BookmarkList
