// src/components/Header/LanguageModal.jsx

import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { iconStyles, hoverIconStyle } from '../../styles/commonStyles'
import { changeLanguage } from '../../actions/translationActions'
import SaveButton from '../Button/SaveButton'
import CloseIcon from '../Button/CloseIcon'

const filterProps = (propName) =>
	propName !== 'background' && propName !== 'isActive'

const ModalBackground = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		width: 100%;
	}
`

const ModalContainer = styled.div`
	background-color: white;
	border-radius: 20px;
	position: relative;

	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		width: 90%;
		height: auto;
		padding: 20px;
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
		min-width: 560px;
		padding: 30px;
		width: 40%;
		max-height: 400px;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 10px 0 rgba(0, 0, 0, 0.1);
	}
`

const ModalHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
`

const DisplayLanguageTitle = styled.h2`
	margin: 0;
	font-size: ${({ theme }) => theme.fontSizes.extraLarge};
	font-weight: bold;
	margin-bottom: 10px;
`

const CloseButtonWrapper = styled.div`
	${() => iconStyles}
	${() => hoverIconStyle}
`

const LanguageOptionsContainer = styled.div`
	display: flex;
	flex-direction: row; // 水平方向に要素を並べる
	justify-content: space-evenly; // 中央寄せ
	flex-wrap: wrap; // 要素が多い場合は折り返す
	gap: 10px; // オプション間の隙間
	margin-bottom: 30px;
`

const LanguageOption = styled.div.withConfig({
	shouldForwardProp: filterProps,
})`
	margin: 10px 0;
	cursor: pointer;
	border: ${(props) => (props.isActive ? '1px solid #2E2F31' : 'none')};
	border-radius: 8px;
	padding: 20px 40px;
	background-color: #f9f9fb;

	&:hover {
		background-color: ${({ theme }) => theme.colors.background};
	}
`

const LanguageModal = ({ isOpen, onClose }) => {
	const { t, i18n } = useTranslation()
	const dispatch = useDispatch()
	const [selectedLanguage, setSelectedLanguage] = useState(i18n.language)

	const handleLanguageOptionClick = (language) => {
		setSelectedLanguage(language)
	}

	const saveLanguageChanges = () => {
		dispatch(changeLanguage(selectedLanguage))
		onClose()
	}

	if (!isOpen) return null

	return (
		<ModalBackground onClick={onClose}>
			<ModalContainer onClick={(e) => e.stopPropagation()}>
				<ModalHeader>
					<CloseButtonWrapper>
						<CloseIcon onClick={onClose} />
					</CloseButtonWrapper>
					<SaveButton onClick={saveLanguageChanges}>
						{t('saveButton')}
					</SaveButton>
				</ModalHeader>
				<DisplayLanguageTitle>{t('displayLanguage')}</DisplayLanguageTitle>
				<LanguageOptionsContainer>
					<LanguageOption
						onClick={() => handleLanguageOptionClick('ja')}
						isActive={selectedLanguage === 'ja'}
					>
						{t('langJapanese')}
					</LanguageOption>

					<LanguageOption
						onClick={() => handleLanguageOptionClick('zh-TW')}
						isActive={selectedLanguage === 'zh-TW'}
					>
						{t('langTaiwanse')}
					</LanguageOption>

					<LanguageOption
						onClick={() => handleLanguageOptionClick('en')}
						isActive={selectedLanguage === 'en'}
					>
						{t('langEnglish')}
					</LanguageOption>
				</LanguageOptionsContainer>
			</ModalContainer>
		</ModalBackground>
	)
}

export default LanguageModal
