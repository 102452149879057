// utils/sortComments.js
export function sortComments(comments) {
  const map = new Map(
    comments.map((comment) => [comment.id, { ...comment, children: [] }])
  );

  // 親子関係を構築する
  comments.forEach((comment) => {
    if (comment.parent_comment_id && map.has(comment.parent_comment_id)) {
      const parent = map.get(comment.parent_comment_id);
      if (parent.id === comment.parent_comment_id) {
        // この条件を追加
        parent.children.push(map.get(comment.id)); // マップ内の拡張されたコメントを追加
      }
    }
  });

  const result = [];
  const addCommentsWithChildren = (commentId, level = 0) => {
    const comment = map.get(commentId);
    if (!comment) {
      return;
    }
    result.push({ ...comment, level });

    comment.children.forEach((child) => {
      addCommentsWithChildren(child.id, level + 1); // 子のIDを渡す
    });
  };

  // 親コメントから処理を開始する
  comments
    .filter((comment) => !comment.parent_comment_id)
    .forEach((parent) => {
      addCommentsWithChildren(parent.id); // 親のIDを渡す
    });

  return result;
}
