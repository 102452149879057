// src/reducers/authReducer.js

import {
	SHOW_VERIFICATION_MODAL,
	SHOW_LOGIN_MODAL,
	HIDE_LOGIN_MODAL,
	SHOW_SIGNUP_MODAL,
	HIDE_SIGNUP_MODAL,
} from '../actions/authActions'

export const SET_EMAIL = 'SET_EMAIL'

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE'

export const VERIFY_REQUEST = 'VERIFY_REQUEST'
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS'
export const VERIFY_FAILURE = 'VERIFY_FAILURE'

export const RESEND_CODE_REQUEST = 'RESEND_CODE_REQUEST'
export const RESEND_CODE_SUCCESS = 'RESEND_CODE_SUCCESS'
export const RESEND_CODE_FAILURE = 'RESEND_CODE_FAILURE'

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'

// Search
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

// Redux Action Types
export const AUTH_CHECK = 'AUTH_CHECK'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAILURE = 'AUTH_FAILURE'

export const CLEAR_ERROR = 'CLEAR_ERROR'

const initialState = {
	user: null,
	userId: null,
	username: null,
	loading: false,
	email: null,
	isVerified: false,
	isAuthenticated: false,
	showVerificationModal: false,
	isLoginModalOpen: false,
	isSignupModalOpen: false,
	error: null,
}

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case SIGNUP_REQUEST:
		case VERIFY_REQUEST:
		case RESEND_CODE_REQUEST:
		case RESET_PASSWORD_REQUEST:
		case LOGOUT_REQUEST:
			return { ...state, loading: true, error: null }

		case SIGNUP_SUCCESS:
			return {
				...state,
				userId: action.payload.userId,
				username: action.payload.username, // ユーザー名を更新
				isAuthenticated: false,
				loading: false,
			}
		case RESEND_CODE_SUCCESS:
		case VERIFY_SUCCESS:
			return {
				...state,
				loading: false,
				isAuthenticated: true,
				isVerified: true,
				userId: action.payload.userId,
				username: action.payload.username,
			}

		case SIGNUP_FAILURE:
		case VERIFY_FAILURE:
		case RESEND_CODE_FAILURE:
		case LOGIN_FAIL:
		case RESET_PASSWORD_FAILURE:
		case LOGOUT_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			}

		case SHOW_SIGNUP_MODAL:
			return {
				...state,
				isSignupModalOpen: true, // サインアップモーダルを表示
			}
		case HIDE_SIGNUP_MODAL:
			return {
				...state,
				isSignupModalOpen: false, // サインアップモーダルを非表示
			}

		case SHOW_VERIFICATION_MODAL:
			return {
				...state,
				showVerificationModal: action.payload,
			}

		case SHOW_LOGIN_MODAL:
			return {
				...state,
				isLoginModalOpen: true, // ログインモーダルを表示
			}

		case HIDE_LOGIN_MODAL:
			return {
				...state,
				isLoginModalOpen: false, // ログインモーダルを非表示
			}

		case LOGIN_SUCCESS:
			return {
				...state,
				userId: action.payload.userId,
				username: action.payload.username, // ユーザー名を更新
				isAuthenticated: true,
				error: null,
			}

		case RESET_PASSWORD_SUCCESS:
			return {
				...state,
				loading: false,
			}

		case LOGOUT_SUCCESS:
			return {
				...initialState,
				// isAuthenticated: false,
				// userId: null,
				// username: null,
			}

		case CLEAR_ERROR:
			return { ...state, error: null }

		case AUTH_SUCCESS:
			return {
				...state,
				isAuthenticated: true,
				userId: action.payload.userId,
				username: action.payload.username, // ユーザー名を更新
			}
		case AUTH_FAILURE:
			return {
				...state,
				isAuthenticated: false,
				user: null,
			}

		case SET_EMAIL:
			return { ...state, email: action.payload }

		default:
			return state
	}
}

export default authReducer
