// src/components/Post/PostItem.jsx

import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'
import { useTranslation } from 'react-i18next'
import {
	openImageModal,
	closeImageModal,
	openLikeModal,
	closeLikeModal,
	setActiveImages,
} from '../../actions/modalActions'
import { useNavigate, useLocation } from 'react-router-dom'
import HeaderComponent from '../Common/HeaderComponent'
import ImageGridComponent from './ImageGridComponent'
import FooterComponent from './FooterComponent'
import { TranslationComponent } from '../TranslateContent/TranslationControls'
import { getDisplayText, isLanguageMatch } from '../../utils/languageService'
import useReadMore from '../../hooks/useReadMore'
import RenderTextWithLinks from '../RenderTextWithLinks'
import { selectCommentsCountByPostId } from '../../selectors/commentSelectors'
import { handleHashtagClick } from '../../utils/hashtagUtils'
import { handleShortenedUrlClick } from '../../utils/urlUtils'
import { StyledReadMore } from '../../styles/commonStyles'
import { RxCross2 } from 'react-icons/rx'
import styled from 'styled-components'

export const TimelineItemWrapper = styled.div`
	width: 100%;
`

export const PostAreaWrapper = styled.div`
	cursor: ${(props) => (props.$isDetailView ? 'default' : 'pointer')};
	width: 100%;

	@media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
		padding: 10px 16px 4px;
	}

	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		padding: 10px 14px 4px;
	}

	${(props) =>
		!props.$isDetailView &&
		`
    &:hover,
    &:active {
      background-color: #F9F9FB;
    }
  `}
`

export const TranslatedText = styled.div`
	margin-bottom: 10px;
	font-size: ${({ theme }) => theme.fontSizes.medium};
`

const FooterWrapper = styled.div`
	${({ theme, $isDetailView }) =>
		$isDetailView &&
		`
		padding: 0px 0;
		margin: 10px 0 0px;
	`}
`

const StyledCancelIcon = styled(RxCross2)`
	color: white;
	position: fixed;
	top: 40px;
	left: 40px;
	width: 32px;
	height: 32px;
	cursor: pointer;
	z-index: 1020;
`

const makeSelectPostTranslations = () =>
	createSelector(
		(state) => state.posts.posts,
		(_, postId) => postId,
		(posts, postId) => {
			const updatedPost = posts.find((p) => p.id === postId)
			return {
				translationStatus: updatedPost?.translationStatus || 'completed',
				translations: updatedPost?.translations || [],
			}
		}
	)

const PostItem = React.memo(
	({
		post,
		isDetailView = false,
		isHashtagView = false,
		fromPath = '/',
		selectedHashtag,
		searchedHashtag,
		searchedKeyword,
		isSearchResult = false,
	}) => {
		// console.log('PostItem: ', post)

		const dispatch = useDispatch()
		const navigate = useNavigate()
		const { t } = useTranslation()
		const location = useLocation()
		const userId = String(useSelector((state) => state.auth.userId))
		const postOwnerId = String(post?.user?.id)
		const postId = String(post?.id)
		const commentsCount = useSelector((state) =>
			selectCommentsCountByPostId(state, postId)
		)

		const [showOriginal, setShowOriginal] = useState(true)

		const userLanguage = useSelector(
			(state) => state.translation.currentLanguage
		)
		const modalState = useSelector((state) => state.modal)
		const imageModalIsOpen = useSelector(
			(state) => state.modal.imageModalIsOpen
		)

		const { text: originalText, shortenedUrls } = useMemo(
			() => getDisplayText(post, userLanguage),
			[post, userLanguage]
		)

		const { isTruncated, toggleReadMore, isReadMore, maxLength } = useReadMore(
			originalText,
			userLanguage,
			isDetailView,
			isHashtagView
		)

		const selectPostTranslations = useMemo(
			() => makeSelectPostTranslations(),
			[]
		)
		const { translationStatus, translations } = useSelector(
			(state) => selectPostTranslations(state, post.id),
			(prev, next) =>
				prev.translationStatus === next.translationStatus &&
				prev.translations.length === next.translations.length
		)

		useEffect(() => {}, [post.id, translationStatus, translations])

		const resultText = useMemo(
			() =>
				isReadMore && isTruncated
					? originalText.slice(0, maxLength) + '...'
					: originalText,
			[isReadMore, isTruncated, originalText, maxLength]
		)

		const toggleOriginal = useCallback((e) => {
			e.stopPropagation()
			setShowOriginal((prev) => !prev)
		}, [])

		const handleAvatarClick = useCallback(
			(e, userId) => {
				e.stopPropagation()
				navigate(`/profile/${userId}`)
			},
			[navigate]
		)

		const handleImageClick = useCallback(
			(e, index) => {
				e.stopPropagation()
				dispatch(setActiveImages(post?.images, index))
				dispatch(openImageModal())
			},
			[dispatch, post?.images]
		)

		const handleLikeOrSignupModal = useCallback(
			(isOpen) => {
				if (isOpen) {
					dispatch(openLikeModal())
				} else {
					dispatch(closeLikeModal())
				}
			},
			[dispatch]
		)

		const onLinkClickShortenedUrl = useCallback(
			(e, shortenedUrl) => {
				handleShortenedUrlClick(e, shortenedUrl, post?.locations)
			},
			[post?.locations]
		)

		const navigateToPostDetail = useCallback(
			(e) => {
				const selection = window.getSelection()
				if (
					e.target.tagName === 'A' ||
					e.target.closest('.gm-style') !== null
				) {
					return
				}
				if (
					!isDetailView &&
					!modalState.modalIsOpen &&
					!modalState.imageModalIsOpen &&
					!modalState.likeModalIsOpen &&
					!modalState.reportModalIsOpen &&
					selection.toString().length === 0
				) {
					navigate(`/posts/${post?.id}`, {
						state: {
							from: fromPath,
						},
					})
				}
			},
			[isDetailView, modalState, navigate, post?.id, fromPath]
		)

		const onHashtagClick = useCallback(
			(e, hashtag) => {
				handleHashtagClick(dispatch, navigate, e, hashtag, location, post?.id)
			},
			[dispatch, navigate, location, post?.id]
		)

		let showReadMoreButton = isTruncated

		const isTranslationNeeded = !isLanguageMatch(post.language, userLanguage)
		const isTranslationAvailable = translations.some(
			(t) => t.language === userLanguage
		)

		const renderContent = () => {
			if (
				isSearchResult &&
				isTranslationNeeded &&
				(translationStatus === 'in_progress' || !isTranslationAvailable)
			) {
				return <TranslatedText>{t('translating')}</TranslatedText>
			}

			return (
				<TranslatedText>
					{typeof post?.body === 'string' ? (
						<>
							<RenderTextWithLinks
								text={resultText}
								post={post}
								shortenedUrls={shortenedUrls}
								onLinkClick={onLinkClickShortenedUrl}
								onHashtagClick={onHashtagClick}
								selectedHashtag={selectedHashtag}
								searchedHashtag={searchedHashtag}
								searchedKeyword={searchedKeyword}
							/>
							{showReadMoreButton && (
								<StyledReadMore
									onClick={(e) => {
										e.stopPropagation()
										toggleReadMore()
									}}
								>
									{isReadMore ? 'Read more' : ''}
								</StyledReadMore>
							)}
						</>
					) : (
						post?.body
					)}
				</TranslatedText>
			)
		}

		return (
			<TimelineItemWrapper>
				<PostAreaWrapper
					$isDetailView={isDetailView}
					onClick={isDetailView ? undefined : navigateToPostDetail}
				>
					<HeaderComponent
						username={post?.user?.username}
						userId={userId}
						avatar={post?.user?.avatar}
						createdAt={post?.created_at}
						postOwnerId={postOwnerId}
						postId={post?.id}
						post={post}
						type="post"
						onAvatarClick={handleAvatarClick}
						countryCode={post?.user?.country_code}
					/>

					{renderContent()}

					<TranslationComponent
						detectedLanguage={post?.language}
						showOriginal={showOriginal}
						toggleText={toggleOriginal}
					/>

					{post?.images && post?.images.length > 0 && (
						<div>
							{imageModalIsOpen && (
								<StyledCancelIcon
									onClick={(e) => {
										e.stopPropagation()
										dispatch(closeImageModal())
									}}
								/>
							)}
							<ImageGridComponent
								images={post?.images}
								onImageClick={handleImageClick}
							/>
						</div>
					)}

					<FooterWrapper $isDetailView={isDetailView}>
						<FooterComponent
							type="post"
							id={post?.id}
							post={post}
							userId={userId}
							onModalChange={handleLikeOrSignupModal}
							likes_count={post?.likes_count}
							commentsCount={
								isSearchResult ? post?.comments_count : commentsCount
							}
						/>
					</FooterWrapper>
				</PostAreaWrapper>
			</TimelineItemWrapper>
		)
	},
	(prevProps, nextProps) => {
		return (
			prevProps.post?.id === nextProps.post?.id &&
			prevProps.isDetailView === nextProps.isDetailView &&
			prevProps.fromPath === nextProps.fromPath &&
			prevProps.post.updated_at === nextProps.post.updated_at &&
			prevProps.post.comments_count === nextProps.post.comments_count &&
			prevProps.post.likes_count === nextProps.post.likes_count &&
			prevProps.commentsCount === nextProps.commentsCount &&
			prevProps.post.translationStatus === nextProps.post.translationStatus &&
			prevProps.post.translations?.length ===
				nextProps.post.translations?.length
		)
	}
)

export default PostItem
