// src/components/LikeButton/LikeButton.jsx

import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchLikesCount, toggleLike } from '../../actions/likeActions'
import { useTranslation } from 'react-i18next'
import { createSelector } from 'reselect'
import SignupModal from '../Signup/SignupModal'
import styled from 'styled-components'
import {
	footerIconWrapperStyle,
	footerIconContainerStyle,
	footerIconStyle,
	footerTextStyles,
	hoverIconStyle,
} from '../../styles/commonStyles'

import { PiHeartBold } from 'react-icons/pi'
import { PiHeartFill } from 'react-icons/pi'

const IconWrapper = styled.div`
	${footerIconWrapperStyle};
`

const IconContainer = styled.div`
	${footerIconContainerStyle};
	${hoverIconStyle}
`

const LikeIcon = styled.div`
	${footerIconStyle}
	color: ${({ $isLiked, theme }) =>
		$isLiked ? theme.colors.primary : theme.colors.textSecondary};
	transform: ${({ $isComment }) => ($isComment ? 'scale(0.9)' : 'none')};
	padding-bottom: 6px;
`

const LikeText = styled.div`
	${footerTextStyles};
	color: ${({ $isLiked, theme }) =>
		$isLiked ? theme.colors.primary : theme.colors.textSecondary};
`

// いいねのデータを取得するセレクターをメモ化
const selectLikesData = createSelector(
	[
		(state) => state.like.posts,
		(state) => state.like.comments,
		(_, type, id) => type,
		(_, type, id) => id,
	],
	(posts, comments, type, id) => {
		const data = type === 'post' ? posts[id] : comments[id]
		return data || { likesCount: 0, isLikedByUser: false } // 適切な初期値を提供
	}
)

const LikeButton = React.memo(({ type, id, onModalChange, likes_count }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
	const userId = useSelector((state) => state.auth.userId)
	const [showSignupModal, setShowSignupModal] = useState(false)

	// いいねのデータを取得するReducerを切り替える
	const likesData = useSelector((state) => selectLikesData(state, type, id))
	const likesCount = likesData.likesCount
	const isLiked = likesData.isLikedByUser

	useEffect(() => {
		dispatch(fetchLikesCount(type, id, userId))
	}, [type, id, userId, dispatch])

	// 認証されていない場合にサインアップモーダルを表示する関数
	const showSignupModalIfNeeded = () => {
		if (!isAuthenticated) {
			onModalChange(true)
			setShowSignupModal(true)
			return true
		}
		return false
	}

	const handleLikeToggle = (e) => {
		e.stopPropagation()

		if (showSignupModalIfNeeded()) return
		dispatch(toggleLike(type, id, !isLiked, userId))
	}

	return (
		<>
			<IconWrapper onClick={handleLikeToggle}>
				<IconContainer>
					<LikeIcon $isLiked={isLiked} $isComment={type === 'comment'}>
						{isLiked ? <PiHeartFill /> : <PiHeartBold />}
					</LikeIcon>
				</IconContainer>
				{(likesCount > 0 || likes_count > 0) && (
					<LikeText $isLiked={isLiked}>
						{likes_count !== undefined ? likes_count : likesCount}
					</LikeText>
				)}
			</IconWrapper>
			<SignupModal
				show={showSignupModal}
				handleClose={() => {
					setShowSignupModal(false)
					onModalChange(false)
				}}
				backdrop="static"
				keyboard={false}
			/>
		</>
	)
})

export default LikeButton
