// src/components/Search/SearchList.jsx

import React, { useEffect, useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import {
	searchRequestAction,
	searchByHashtagAction,
} from '../../actions/searchActions'
import SearchItem from './SearchItem'
import MainLayout from '../Layout/MainLayout'
import { useTranslation } from 'react-i18next'
import useInfiniteScroll from '../../hooks/useInfiniteScroll'
import { useScrollPosition } from '../../context/ScrollPositionContext'
import styled from 'styled-components'
import Daruma from '../../assets/icon/daruma.png'
import { Divider } from '../../styles/commonStyles'
import LoadingSpinner from '../Common/LoadingSpinner'

const SearchContainer = styled.div`
	display: flex;
	justify-content: center;
	min-height: calc(100vh - 80px);
`

const SearchContent = styled.div`
	flex: 1;
	width: 90%;
	@media (min-width: 768px) {
		width: 580px;
	}
`

const NoFoundContent = styled.div`
	text-align: center;
	margin-top: 50px;
`

const MessageText = styled.p`
	margin: 20px 0 40px;
	font-size: ${({ theme }) => theme.fontSizes.large};
	font-weight: bold;
`

const StyledSearchIcon = styled.img`
	width: 120px;
	height: auto;
	margin: 20px auto;
`

const SearchList = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { saveScrollPosition, getScrollPosition } = useScrollPosition()
	const [isInitialLoading, setIsInitialLoading] = useState(true)

	const {
		results: searchResults,
		loading: isLoading,
		hasMore,
		nextOffset,
		searchedHashtag,
	} = useSelector((state) => state.search)

	const listRef = useRef(null)

	const fetchMoreResults = useCallback(() => {
		const params = new URLSearchParams(location.search)
		const keyword = params.get('keyword')
		const hashtag = params.get('hashtag')

		if (hasMore) {
			const action = keyword
				? searchRequestAction(keyword, nextOffset)
				: searchByHashtagAction(hashtag, nextOffset)
			return dispatch(action)
		}
		return Promise.resolve()
	}, [dispatch, hasMore, location.search, nextOffset])

	useInfiniteScroll(fetchMoreResults, [hasMore, nextOffset])

	useEffect(() => {
		const params = new URLSearchParams(location.search)
		const keyword = params.get('keyword')
		const hashtag = params.get('hashtag')

		if (keyword || hashtag) {
			setIsInitialLoading(true)
			const action = keyword
				? searchRequestAction(keyword, 0)
				: searchByHashtagAction(hashtag, 0)
			dispatch(action).then(() => {
				setIsInitialLoading(false)
			})
		} else {
			setIsInitialLoading(false)
		}
	}, [dispatch, location.search])

	useEffect(() => {
		const savedPosition = getScrollPosition(location.pathname + location.search)
		if (savedPosition && listRef.current) {
			window.scrollTo(0, savedPosition)
		}
	}, [getScrollPosition, location])

	useEffect(() => {
		return () => {
			if (listRef.current) {
				saveScrollPosition(location.pathname + location.search, window.scrollY)
			}
		}
	}, [saveScrollPosition, location])

	const renderSearchResults = () =>
		searchResults.map((hit, index) => (
			<React.Fragment key={hit._id}>
				<SearchItem
					data={hit._source}
					user_data={hit.user_data}
					likes_count={hit.likes_count}
					comments_count={hit.comments_count}
					locations={hit.locations}
					images={hit.images}
					selectedHashtag={searchedHashtag}
				/>
				{index < searchResults.length - 1 && <Divider />}
			</React.Fragment>
		))

	const handleBackButtonClick = useCallback(() => {
		if (location.state && location.state.from) {
			navigate(location.state.from)
		} else {
			navigate('/')
		}
	}, [navigate, location])

	return (
		<MainLayout showBackButton onBackButtonClick={handleBackButtonClick}>
			<SearchContainer>
				<SearchContent ref={listRef}>
					{isInitialLoading ? (
						<LoadingSpinner />
					) : searchResults.length === 0 ? (
						<NoFoundContent>
							<MessageText>{t('noResults')}</MessageText>
							<StyledSearchIcon src={Daruma} alt="No results" />
						</NoFoundContent>
					) : (
						renderSearchResults()
					)}
					{isLoading && !isInitialLoading && <LoadingSpinner />}
				</SearchContent>
			</SearchContainer>
		</MainLayout>
	)
}

export default SearchList
