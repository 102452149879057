// reducers/bookmarksReducer.js

import {
	FETCH_BOOKMARK_DETAIL_SUCCESS,
	FETCH_BOOKMARK_DETAIL_FAILURE,
} from '../actions/bookmarkAction'

const initialState = {
	postBookmarks: [],
	commentBookmarks: [],
	bookmarkDetail: [],
	isLoading: false,
	error: null,
}

const bookmarkReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'LOAD_BOOKMARKS_SUCCESS':
			const { postBookmarks } = action.payload.reduce(
				(acc, bookmark) => {
					if (bookmark.post_id) {
						acc.postBookmarks.push(bookmark.post_id)
					}
					return acc
				},
				{ postBookmarks: [] }
			)
			return { ...state, postBookmarks }

		case 'TOGGLE_POST_BOOKMARK_SUCCESS':
			const { postId } = action.payload
			const newPostBookmarks = state.postBookmarks.includes(postId)
				? state.postBookmarks.filter((id) => id !== postId)
				: [...state.postBookmarks, postId]
			return { ...state, postBookmarks: newPostBookmarks }

		case FETCH_BOOKMARK_DETAIL_SUCCESS:
			return { ...state, isLoading: false, bookmarkDetail: action.payload }

		case FETCH_BOOKMARK_DETAIL_FAILURE:
			return { ...state, isLoading: false, error: action.error }

		default:
			return state
	}
}

export default bookmarkReducer
