// src/components/Profile/ActivityDraft.jsx

import React from 'react'
import { useTranslation } from 'react-i18next'
import ActivityDraftItem from './ActivityDraftItem'
import { Divider } from '../../styles/commonStyles'
import styled from 'styled-components'

const ProfileDraftWrapper = styled.div`
	padding-bottom: 70px; // MobileNavbarの高さに応じて調整

	@media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
		padding-bottom: 0; // タブレット以上のサイズでは余分なパディングを削除
	}
`

const NoDataMessage = styled.p`
	color: ${({ theme }) => theme.colors.textSecondary};
	font-size: ${({ theme }) => theme.fontSizes.large};
	text-align: center;
	margin: 50px 0;
`

const ActivityDraft = React.memo(({ drafts, user }) => {
	const { t } = useTranslation()
	const noDataMessage = t('noDataYet')

	return (
		<ProfileDraftWrapper>
			{drafts?.length > 0 ? (
				drafts.map((draft, index) => (
					<React.Fragment key={draft.id}>
						<ActivityDraftItem draft={draft} user={user} />
						{index !== drafts.length - 1 && <Divider />}
					</React.Fragment>
				))
			) : (
				<NoDataMessage>{noDataMessage}</NoDataMessage>
			)}
		</ProfileDraftWrapper>
	)
})

export default ActivityDraft
