// src/components/Maps/GooglePlaceSearch.jsx

import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Autocomplete, GoogleMap } from '@react-google-maps/api'
import styled, { createGlobalStyle, css } from 'styled-components'
import { hoverIconStyle } from '../../styles/commonStyles'
import { RiSearchLine } from 'react-icons/ri'
import { MdOutlineAddLocationAlt } from 'react-icons/md'
import { IoClose } from 'react-icons/io5'
import { BsX } from 'react-icons/bs'

const GlobalStyle = createGlobalStyle`
  .pac-container {
    z-index: 1200;
    ${({ isProfilePage, iconTop }) =>
			isProfilePage
				? css`
						top: ${iconTop
							? `${1234 - iconTop}px !important`
							: '1230px !important'};
				  `
				: css`
						top: '';
				  `}
  }

  .pac-logo:after {
    content: none;
  }
`

const StyledModal = styled(Modal)`
	.modal-dialog {
		margin: 0rem auto;
		display: flex;
		justify-content: center;
		width: 100%;
	}

	.modal-content {
		position: relative;
		background-color: white;
		padding: 0px;
		overflow: hidden;
		height: 100vh;
		z-index: 1100;

		@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
			max-height: 100%;
			overflow-y: auto;
			width: 100%;
			margin: 0;
			position: fixed;
			top: 0;
			left: 0;
			border-radius: 0;
		}

		@media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
			min-width: 560px;
			min-height: 370px;
			max-height: 420px;
			border-radius: 20px;
		}
	}
	.modal-body {
		padding: 0;
	}
`

const SearchWrapper = styled.div`
	position: absolute;
	top: 20px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 2;
	width: 100%;
	max-width: 520px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		margin-left: 10px;
	}
`

const IconsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	& > *:not(:last-child) {
		margin-bottom: 10px;
	}

	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		margin-right: 20px;
	}
`

const SearchContainer = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
	margin-right: 10px;
`

const SearchInputContainer = styled.div`
	position: relative;
	flex: 1;
	background: #fff;
	border-radius: 50px;
`

const SearchInput = styled.div`
	position: relative;
	width: 100%;
	height: 46px;
	font-size: 16px;
	border: none;
	border-radius: 50px;
	box-shadow: 0 4px 18px rgba(0, 0, 0, 0.2);
	display: flex;
	align-items: center;
`

const SearchIcon = styled(RiSearchLine)`
	position: absolute;
	left: 16px;
	font-size: 18px;
`

const Input = styled.input`
	width: 100%;
	height: 100%;
	padding: 0 30px 0 48px;
	border: none;
	outline: none;
	border-radius: 50px;
	font-size: 16px;
`

const LocationIconContainer = styled.div`
	width: 46px;
	height: 46px;
	background-color: ${({ theme, disabled }) =>
		disabled ? theme.colors.primaryDisabled : theme.colors.primary};
	border-radius: 50%;
	box-shadow: 0 4px 18px rgba(0, 0, 0, 0.3);
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

const LocationIcon = styled(MdOutlineAddLocationAlt)`
	font-size: 24px;
	color: #fff;
`

const CloseButton = styled.button`
	${hoverIconStyle}
	width: 46px;
	height: 46px;
	background: #fff;
	border: none;
	box-shadow: 0 4px 18px rgba(0, 0, 0, 0.2);
	cursor: pointer;
	margin-left: 10px;
	display: flex;
	align-items: center;
`

const CloseIcon = styled(IoClose)`
	font-size: 24px;
	color: ${({ theme }) => theme.colors.text};
`

const MapContainer = styled.div`
	height: 100%;
	width: 100%;
`

const ClearButton = styled.button`
	background-color: transparent;
	border: none;
	cursor: pointer;
	font-size: 1.5rem;
	color: ${({ theme }) => theme.colors.text};
	line-height: 1;
	padding: 0px;
	margin-right: 10px;
`

const GooglePlaceSearchModal = ({
	isOpen,
	onClose,
	onPlaceSelected,
	selectedLocations,
	iconTop,
}) => {
	const [marker, setMarker] = useState(null)
	const [autocomplete, setAutocomplete] = useState(null)
	const [selectedPlace, setSelectedPlace] = useState(null)
	const [map, setMap] = useState(null)
	const inputRef = useRef(null)
	const [keyword, setKeyword] = useState('')
	const { t } = useTranslation()

	const isProfilePage = window.location.href.includes('profile')

	const onMapLoad = useCallback(
		(map) => {
			setMap(map)
			if (selectedPlace) {
				map.panTo(selectedPlace)
				map.setZoom(16)
			}
		},
		[selectedPlace]
	)

	const isAdvancedMarkerAvailable = () => {
		return (
			window.google &&
			window.google.maps &&
			window.google.maps.marker &&
			window.google.maps.marker.AdvancedMarkerElement
		)
	}

	useEffect(() => {
		if (map && selectedPlace) {
			if (marker) {
				marker.setMap(null)
			}

			let newMarker
			if (isAdvancedMarkerAvailable()) {
				newMarker = new window.google.maps.marker.AdvancedMarkerElement({
					position: selectedPlace,
					map: map,
				})
			} else {
				newMarker = new window.google.maps.Marker({
					position: selectedPlace,
					map: map,
				})
			}

			setMarker(newMarker)
		}
	}, [map, selectedPlace])

	useEffect(() => {
		if (isOpen && iconTop) {
			// 何かの処理があればここに記述
		}
	}, [isOpen, iconTop])

	useEffect(() => {
		if (isOpen && inputRef.current) {
			inputRef.current.focus()
		}
	}, [isOpen])

	useEffect(() => {
		if (selectedLocations && selectedLocations.lat && selectedLocations.lng) {
			const newSelectedPlace = {
				lat: parseFloat(selectedLocations.lat),
				lng: parseFloat(selectedLocations.lng),
			}
			setSelectedPlace(newSelectedPlace)

			if (map) {
				map.panTo(newSelectedPlace)
				map.setZoom(16)
			}
		}
	}, [selectedLocations?.lat, selectedLocations?.lng, map])

	const handleLoad = (autocomplete) => {
		setAutocomplete(autocomplete)
	}

	const handlePlaceChanged = () => {
		if (autocomplete) {
			const place = autocomplete.getPlace()
			if (place.geometry) {
				const locationData = {
					lat: place.geometry.location.lat(),
					lng: place.geometry.location.lng(),
					name: place.name,
				}
				setSelectedPlace(locationData)
				setKeyword(place.name)
				onPlaceSelected(locationData)
				if (map) {
					map.panTo(locationData)
					map.setZoom(16)
				}
			}
		}
	}

	const mapOptions = {
		streetViewControl: false,
		fullscreenControl: false,
		mapTypeControl: false,
	}

	const onCloseHandler = (e) => {
		e.stopPropagation()
		onClose()
	}

	const handleClear = (e) => {
		e.stopPropagation()
		setKeyword('')
	}

	const handlePlaceSelected = (locationData) => {
		onPlaceSelected(locationData)
		onClose()
	}

	const getLocationData = (place) => {
		if (place && place.name) {
			return {
				name: place.name,
				address: place.formatted_address,
				place_id: place.place_id,
				types: place.types,
				rating: place.rating,
				user_ratings_total: place.user_ratings_total,
				business_status: place.business_status,
				price_level: place.price_level,
				website: place.website,
				lat: place.geometry.location.lat(),
				lng: place.geometry.location.lng(),
			}
		} else {
			return null
		}
	}

	return (
		<>
			<StyledModal show={isOpen} onHide={onClose} centered backdrop="static">
				<Modal.Body>
					<MapContainer>
						<GoogleMap
							onLoad={onMapLoad}
							mapContainerStyle={{ width: '100%', height: '100%' }}
							center={selectedPlace || { lat: 35.6809591, lng: 139.7673068 }}
							zoom={selectedPlace ? 16 : 14}
							options={mapOptions}
							onClick={(e) => {
								if (e.domEvent) {
									e.domEvent.stopPropagation()
								}
							}}
						>
							{/* マーカーはここで直接レンダリングしない */}
						</GoogleMap>
					</MapContainer>
					<SearchWrapper>
						<SearchContainer>
							<SearchInputContainer>
								<Autocomplete
									onLoad={handleLoad}
									onPlaceChanged={handlePlaceChanged}
								>
									<SearchInput>
										<SearchIcon />
										<Input
											type="text"
											placeholder={t('searchPlaces')}
											ref={inputRef}
											value={keyword}
											onChange={(e) => {
												e.stopPropagation()
												setKeyword(e.target.value)
											}}
											onClick={(e) => {
												e.stopPropagation()
											}}
										/>
										<ClearButton onClick={handleClear}>
											<BsX />
										</ClearButton>
									</SearchInput>
								</Autocomplete>
							</SearchInputContainer>
						</SearchContainer>
						<IconsContainer>
							<CloseButton onClick={onCloseHandler}>
								<CloseIcon />
							</CloseButton>
							<LocationIconContainer disabled={!keyword}>
								<LocationIcon
									onClick={(e) => {
										e.stopPropagation()
										if (keyword && autocomplete) {
											const place = autocomplete.getPlace()
											const locationData = getLocationData(place)

											if (locationData) {
												handlePlaceSelected(locationData)
											}
										}
									}}
								/>
							</LocationIconContainer>
						</IconsContainer>
					</SearchWrapper>
				</Modal.Body>
			</StyledModal>
			<GlobalStyle isProfilePage={isProfilePage} iconTop={iconTop} />
		</>
	)
}

export default React.memo(GooglePlaceSearchModal)
