// src/components/Profile/UserActivity.jsx

import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import TabPanel from './TabPanel'
import styled from 'styled-components'
import ActivityPost from './ActivityPost'
import ActivityComment from './ActivityComment'
import ActivityDraft from './ActivityDraft'
import LoadingSpinner from '../Common/LoadingSpinner'

const TabWrapper = styled.div`
	margin-bottom: 300px;
`

export const UserActivity = React.memo(
	({
		user,
		currentUserId,
		tabValue,
		setTabValue,
		postCount,
		commentCount,
		draftCount,
		posts = [],
		comments = [],
		drafts = [],
		isLoadingPosts,
	}) => {
		const isCurrentUserProfile = user?.id === currentUserId
		const { t } = useTranslation()
		const userLanguage = useSelector(
			(state) => state.translation.currentLanguage
		)

		const handleTabChange = (event, newValue) => {
			setTabValue(newValue)
			localStorage.setItem('userProfileTab', newValue)
		}

		return (
			<>
				{!isLoadingPosts ? (
					<TabWrapper>
						<Tabs
							value={tabValue}
							onChange={handleTabChange}
							aria-label="activity tabs"
							indicatorColor="#2E2F31"
							sx={{
								borderBottom: 1,
								borderColor: '#ecf0f1',
								'.MuiTabs-indicator': {
									height: '3px',
									backgroundColor: '#2E2F31',
									borderRadius: '20px',
								},
								'.MuiTab-root': {
									color: '#757575',
									fontWeight: 'normal',
									fontSize: '0.9rem',
									minWidth: '120px',
									width: 'auto',
									padding: '6px 24px',
									fontFamily: "'Noto Sans', sans-serif",
									textTransform: 'none',
									'&:hover': {
										backgroundColor: '#F5F5F6',
									},
									'&.Mui-selected': {
										color: '#2E2F31',
										fontWeight: 'bold',
										fontSize: '0.9rem',
									},
								},
								'.MuiTabs-flexContainer': {
									justifyContent: 'flex-start',
									marginLeft: '20px',
								},
							}}
						>
							<Tab label={`${t('postsActivity')} ${postCount}`} />
							<Tab label={`${t('commentsActivity')} ${commentCount}`} />
							{isCurrentUserProfile && (
								<Tab label={`${t('draftsActivity')} ${draftCount}`} />
							)}
						</Tabs>

						<TabPanel value={tabValue} index={0}>
							<ActivityPost
								posts={posts}
								user={user}
								userLanguage={userLanguage}
								isLoadingPosts={isLoadingPosts}
							/>
						</TabPanel>

						<TabPanel value={tabValue} index={1}>
							<ActivityComment
								comments={comments}
								user={user}
								userLanguage={userLanguage}
							/>
						</TabPanel>

						<TabPanel value={tabValue} index={2}>
							<ActivityDraft drafts={drafts} user={user} />
						</TabPanel>
					</TabWrapper>
				) : (
					<LoadingSpinner />
				)}
			</>
		)
	}
)
