// src/components/Comment/ReplyFooterContainer.jsx

import React from 'react'
import ImageUploader from '../Common/ImageUploader'
import { IoSend } from 'react-icons/io5'
import styled from 'styled-components'

const FooterWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	transition: all 0.3s ease-in-out;
`

const ImageUploadContainer = styled.div`
	display: flex;
	flex-grow: 1;
	justify-content: flex-start;
`

const SendButtonContainer = styled.div`
	display: flex;
	margin-left: auto;
`

const StyledSendButton = styled(IoSend)`
	color: ${({ theme, disabled }) =>
		disabled ? theme.colors.primaryDisabled : theme.colors.primary};
	width: 20px;
	height: 20px;
	margin-right: 10px;
	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

const CancelButton = styled.button`
	background: transparent;
	border: none;
	color: #007bff;
	cursor: pointer;
	padding: 0;
	margin-right: 30px;
	font-size: 0.9rem;
`

const ReplyFooterContainer = ({
	isEditing,
	onCancel,
	uploadedImage,
	handleImageUpload,
	setIsLoading,
	handleFormSubmit,
	isSubmitDisabled,
	isFocused,
}) => {
	return (
		<FooterWrapper>
			<ImageUploadContainer>
				{!uploadedImage && (
					<ImageUploader
						onImageUpload={handleImageUpload}
						uploadedImages={uploadedImage ? [uploadedImage] : []}
						maxImages={1}
						type="comment"
						setIsLoading={setIsLoading}
					/>
				)}
			</ImageUploadContainer>
			{isEditing && <CancelButton onClick={onCancel}>Cancel</CancelButton>}
			<SendButtonContainer>
				<StyledSendButton
					onClick={handleFormSubmit}
					disabled={isSubmitDisabled}
				/>
			</SendButtonContainer>
		</FooterWrapper>
	)
}

export default ReplyFooterContainer
