// src/hooks/useConnectorPaths.js

import { useState, useEffect, useCallback, useRef } from 'react'
import { sizes } from '../components/Avatar/Avatar'

export const useConnectorPaths = (
	commentRefs,
	comments,
	avatarSize,
	replyRefs,
	replyCountRefs
) => {
	const [connectorPaths, setConnectorPaths] = useState({})
	const calculatePathsRef = useRef()

	const calculatePaths = useCallback(() => {
		const newPaths = {}

		const processComment = (comment) => {
			const commentRef = commentRefs.current[comment.id]
			if (commentRef) {
				const paths = []
				const commentRect = commentRef.getBoundingClientRect()
				const avatarSizeInPixels = sizes[avatarSize] || 0

				// コメント間のアバターの接続
				if (
					(comment.children && comment.children.length > 0) ||
					comment.parent_comment_id
				) {
					comment.children.forEach((childComment) => {
						const childRef = commentRefs.current[childComment.id]
						if (childRef) {
							const childRect = childRef.getBoundingClientRect()
							const path = calculatePath(
								commentRect,
								childRect,
								avatarSizeInPixels,
								comment.level,
								childComment.level
							)
							paths.push(path)
						}
					})
				}

				// 返信フォームまたは返信数表示への接続（どちらか一方のみ）
				const replyRef = replyRefs.current[comment.id]
				const replyCountRef = replyCountRefs.current[comment.id]
				if (replyRef) {
					const replyRect = replyRef.getBoundingClientRect()
					const path = calculateReplyPath(
						commentRect,
						replyRect,
						avatarSizeInPixels,
						comment.level
					)
					paths.push(path)
				} else if (comment.repliesCount > 0 && replyCountRef) {
					const replyCountRect = replyCountRef.getBoundingClientRect()
					const path = calculateReplyCountPath(
						commentRect,
						replyCountRect,
						avatarSizeInPixels,
						comment.level
					)
					paths.push(path)
				}

				if (paths.length > 0) {
					newPaths[comment.id] = paths
				}
			} else {
				//
			}
		}

		comments.forEach(processComment)

		setConnectorPaths(newPaths)
	}, [commentRefs, comments, avatarSize, replyRefs, replyCountRefs])

	calculatePathsRef.current = calculatePaths

	useEffect(() => {
		const currentCalculatePaths = () => calculatePathsRef.current()

		const initialCalculationTimeout = setTimeout(() => {
			currentCalculatePaths()
		}, 100)

		const resizeObserver = new ResizeObserver(currentCalculatePaths)

		Object.values(commentRefs.current).forEach((ref) => {
			if (ref) resizeObserver.observe(ref)
		})

		Object.values(replyRefs.current).forEach((ref) => {
			if (ref) resizeObserver.observe(ref)
		})

		window.addEventListener('resize', currentCalculatePaths)

		return () => {
			clearTimeout(initialCalculationTimeout)
			resizeObserver.disconnect()
			window.removeEventListener('resize', currentCalculatePaths)
		}
	}, [comments, commentRefs, replyRefs, replyCountRefs])

	return connectorPaths
}

const calculatePath = (parentRect, childRect, avatarSize) => {
	const startX = avatarSize / 2
	const startY = avatarSize + 2
	const endX = 26
	const endY = childRect.top - parentRect.top + avatarSize / 2
	const cornerRadius = 10

	return `
    M ${startX} ${startY}
    L ${startX} ${endY - cornerRadius}
    Q ${startX} ${endY}, ${startX + cornerRadius} ${endY}
    L ${endX} ${endY}
  `
}

const calculateReplyPath = (parentRect, replyRect, avatarSize) => {
	const startX = avatarSize / 2
	const startY = avatarSize + 5
	const endX = 34
	const endY = replyRect.top - parentRect.top + avatarSize / 2

	return `
    M ${startX} ${startY}
    L ${startX} ${endY - avatarSize / 2}
    Q ${startX} ${endY}, ${startX + avatarSize / 4} ${endY}
    L ${endX} ${endY}
  `
}

const calculateReplyCountPath = (commentRect, replyCountRect, avatarSize) => {
	const startX = avatarSize / 2
	const startY = avatarSize + 5
	const endX = 34
	const endY = replyCountRect.top - commentRect.top + replyCountRect.height / 2

	return `
    M ${startX} ${startY}
    L ${startX} ${endY - 10}
    Q ${startX} ${endY}, ${startX + 10} ${endY}
    L ${endX} ${endY}
  `
}
