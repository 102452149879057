// src/components/Avatar/Avatar.jsx

import React, { css } from 'react'
import StyledAvatar from 'react-avatar'
import styled from 'styled-components'
import { PiUser } from 'react-icons/pi'
import CountryFlag from './CountryFlag'

export const sizes = {
	default: 40, // デフォルトのサイズ
	comment: 34, // コメントのサイズ
	profileMobile: 120, // マイページのサイズ
	profile: 200, // マイページのサイズ
}

const CustomAvatarWrapper = styled.div`
	display: inline-block;
	width: ${(props) => sizes[props.size] || sizes.default}px;
	height: ${(props) => sizes[props.size] || sizes.default}px;
	border-radius: 50%;
	color: #ffffff;
	background-color: ${({ theme }) => theme.colors.textSecondary};
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`

const CustomAvatar = ({
	username,
	avatar,
	size,
	onClick,
	countryCode,
	type = 'post',
}) => {
	const avatarSize = sizes[size] || sizes.default

	const getInitials = (name) => {
		return name ? name.charAt(0) : ''
	}

	const handleClick = (e) => {
		if (onClick) {
			onClick(e)
		}
	}

	const renderAvatarContent = () => {
		if (avatar) {
			return <StyledAvatar src={avatar} size={avatarSize} round={true} />
		} else if (username) {
			return (
				<StyledAvatar
					name={username}
					size={avatarSize}
					round={true}
					initials={getInitials}
					onClick={handleClick}
				/>
			)
		} else {
			return <PiUser size={20} onClick={handleClick} />
		}
	}

	return (
		<CustomAvatarWrapper size={size} onClick={onClick}>
			{renderAvatarContent()}
			{countryCode && (
				<CountryFlag countryCode={countryCode.toLowerCase()} type={type} />
			)}
		</CustomAvatarWrapper>
	)
}

export default CustomAvatar
