// useCreatePostState.js

import React, { useState, useEffect, useMemo } from 'react'
import { EditorState, ContentState } from 'draft-js'

const useCreatePostState = (
	initialText = '',
	post = null,
	draft = null,
	compositeDecorator
) => {
	const [editorState, setEditorState] = useState(() =>
		EditorState.createWithContent(
			ContentState.createFromText(initialText),
			compositeDecorator
		)
	)
	const [uploadedImages, setUploadedImages] = useState([])
	const [isEdited, setIsEdited] = useState(false)
	const [deletedImageIds, setDeletedImageIds] = useState([])
	const [isQuestion, setIsQuestion] = useState(false)
	const [savedPostId, setSavedPostId] = useState(null)

	useEffect(() => {
		setSavedPostId(post?.id || draft?.post_id || null)
	}, [post, draft])

	const content = useMemo(() => {
		if (post) return post.body || ''
		if (draft) return draft.body || ''
		return initialText || ''
	}, [post, draft, initialText])

	const images = useMemo(() => {
		if (post) {
			return post.images.map((img) => ({
				id: img.image_id,
				url: img.image_url,
				file: null,
			}))
		}
		if (draft) {
			return draft.images.map((img) => ({
				id: img.id,
				url: img.image_path,
				file: null,
			}))
		}
		return []
	}, [post, draft])

	useEffect(() => {
		setEditorState(
			EditorState.createWithContent(
				ContentState.createFromText(content),
				compositeDecorator
			)
		)
		setUploadedImages(images)
		setIsQuestion(
			post?.question_label === '1' || draft?.question_label === '1' || false
		)
	}, [content, images, compositeDecorator])

	return {
		editorState,
		setEditorState,
		uploadedImages,
		setUploadedImages,
		isEdited,
		setIsEdited,
		deletedImageIds,
		setDeletedImageIds,
		isQuestion,
		setIsQuestion,
		savedPostId,
		setSavedPostId,
	}
}

export default useCreatePostState
