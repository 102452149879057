// src/components/Common/LoadingSpinner.jsx

import React from 'react'
import styled, { keyframes } from 'styled-components'

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const SpinnerContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0);
	z-index: 1000;
`

const Spinner = styled.div`
	border: 4px solid #f3f3f3;
	border-top: 4px solid #3498db;
	border-radius: 50%;
	width: 32px;
	height: 32px;
	animation: ${spin} 1s linear infinite;
`

const LoadingSpinner = () => {
	return (
		<SpinnerContainer>
			<Spinner />
		</SpinnerContainer>
	)
}

export default LoadingSpinner
