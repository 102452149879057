// src/components/Comment/UnifiedCommentSystem.jsx

import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { shallowEqual } from 'react-redux'
import CommentList from './CommentList'
import CommentComposer from './CommentComposer'
import {
	addComment,
	updateComment,
	deleteComment,
	fetchComments,
} from '../../actions/commentActions'
import { useCreateFormDataComment } from '../../hooks/useCreateFormDataComment'
import { sortComments } from '../../utils/sortComments'

const UnifiedCommentSystem = React.memo(
	({ postId, isDetailView, limit, userInfo }) => {
		// console.count('UnifiedCommentSystem:')
		const dispatch = useDispatch()

		const comments = useSelector((state) => {
			const commentIds = state.comments.byPostId[postId]?.ids || []
			return commentIds.map((id) => state.comments.byId[id]).filter(Boolean)
		}, shallowEqual)
		const sortedComments = useMemo(() => sortComments(comments), [comments])

		const createFormData = useCreateFormDataComment(postId, userInfo)

		useEffect(() => {
			if (!isDetailView) {
				dispatch(fetchComments(postId))
			}
		}, [dispatch, postId])

		const handleCommentAdd = useCallback(
			async (newCommentData) => {
				const formData = createFormData(newCommentData, newCommentData.isReply)
				await dispatch(addComment(postId, formData))
				dispatch(fetchComments(postId))
			},
			[dispatch, postId, createFormData]
		)

		const handleCommentUpdate = useCallback(
			async (commentId, updatedCommentData) => {
				const formData = createFormData(updatedCommentData, false, commentId)
				await dispatch(updateComment(commentId, formData))
				dispatch(fetchComments(postId))
			},
			[dispatch, postId, createFormData]
		)

		const handleCommentDelete = useCallback(
			async (commentId) => {
				await dispatch(deleteComment(commentId))
				dispatch(fetchComments(postId))
			},
			[dispatch, postId]
		)

		return (
			<div>
				<CommentComposer postId={postId} onSubmit={handleCommentAdd} />
				<CommentList
					userInfo={userInfo}
					comments={sortedComments}
					postId={postId}
					onCommentAdd={handleCommentAdd}
					onCommentUpdate={handleCommentUpdate}
					onCommentDelete={handleCommentDelete}
				/>
			</div>
		)
	},
	(prevProps, nextProps) => {
		return (
			prevProps.postId === nextProps.postId &&
			prevProps.isDetailView === nextProps.isDetailView &&
			prevProps.limit === nextProps.limit
		)
	}
)

export default UnifiedCommentSystem
