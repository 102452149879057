// src/components/Button/DiscardButton.jsx

import React from 'react'
import StyledButton from '../Button/StyledButton'

const DiscardButton = ({ children, onClick, disabled }) => {
	return (
		<StyledButton
			className="btn btn-danger"
			onClick={() => !disabled && onClick && onClick()}
			// variant="secondary"
			disabled={disabled}
		>
			{children}
		</StyledButton>
	)
}

export default DiscardButton
