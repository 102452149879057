// src/components/common/StyledButton.jsx

import styled from 'styled-components'
import Button from './Button'

const StyledButton = styled(Button)`
	&&& {
		background-color: ${(props) =>
			props.variant === 'outline-primary'
				? 'transparent'
				: props.theme.colors[props.variant] || 'default color'};
		color: ${(props) =>
			props.variant === 'outline-primary'
				? props.theme.colors['primary']
				: '#fff'};
		border-color: ${(props) =>
			props.variant === 'outline-primary'
				? props.theme.colors['primary']
				: props.theme.colors[props.variant] || 'default border color'};

		&:hover {
			background-color: ${(props) =>
				props.variant === 'outline-primary'
					? props.theme.colors['outlinePrimaryHover']
					: props.theme.colors[`${props.variant}Hover`] ||
					  'hover background color'};
			color: ${(props) =>
				props.variant === 'outline-primary'
					? props.theme.colors['primary']
					: ''};
			border-color: ${(props) =>
				props.variant === 'outline-primary'
					? props.theme.colors['primary']
					: props.theme.colors[`${props.variant}HoverBorder`] ||
					  'hover border color'};
		}

		font-size: 0.9rem;
		font-weight: bold;
		padding: 8px 20px;
		border-radius: 50px;
		white-space: nowrap;
	}
`

export default StyledButton
