// src/hooks/usePostActions.js

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { submitPost, addNewPost, updatePostAction } from '../actions/postAction'
import { saveDraftAction, deleteDraft } from '../actions/draftAction'
import { useTranslation } from 'react-i18next'
import { EditorState } from 'draft-js'
import useBuildFormData from './useBuildFormData'
import useExtractHashtags from './useExtractHashtags'

const usePostActions = (props) => {
	const {
		setIsLoading,
		showAlert,
		onClose,
		setIsEdited,
		setUploadedImages,
		setDeletedImageIds,
		setIsQuestion,
		setSelectedLocations,
		savedPostId,
		setSavedPostId,
		post,
		draft,
		uploadedImages,
		deletedImageIds,
		isQuestion,
		selectedLocations,
		user,
		editorState,
		setEditorState,
	} = props

	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { extractHashtags } = useExtractHashtags()

	const { buildFormData } = useBuildFormData({
		editorState,
		user,
		deletedImageIds,
		uploadedImages,
		selectedLocations,
		isQuestion,
		savedPostId,
	})

	const handleSaveDraft = async (postId, formData) => {
		formData.append('editing_status', 'editing')
		const response = await dispatch(saveDraftAction(postId, formData))
		setIsEdited(false)
		showAlert(t('draftedSuccessfully'), 'success')
		return response
	}

	const handleUpdatePost = async (postId, formData) => {
		await dispatch(updatePostAction(postId, formData))
		setEditorState(EditorState.createEmpty())
		onClose()
		showAlert(t('submittedSuccessfully'), 'success')
	}

	const handleSubmitNewPost = async (formData) => {
		const response = await dispatch(submitPost(formData))
		dispatch(addNewPost(response.post))
		setEditorState(EditorState.createEmpty())
		onClose()
		showAlert(t('submittedSuccessfully'), 'success')
		return response
	}

	const handleSubmitPost = useCallback(
		async (isDraft = false) => {
			setIsLoading(true)
			const formData = buildFormData(isDraft)

			const qrReferrerId = localStorage.getItem('qrReferrerId')

			if (qrReferrerId) {
				formData.append('qrReferrerId', qrReferrerId)
			}

			// ハッシュタグの重複チェック
			const content = editorState.getCurrentContent().getPlainText()
			const hashtags = extractHashtags(content)
			const uniqueHashtags = [...new Set(hashtags)]
			if (hashtags.length !== uniqueHashtags.length) {
				showAlert(t('duplicateHashTag'), 'error')
				setIsLoading(false)
				return // エラーメッセージを表示し、処理を中断
			}

			try {
				if (post && isDraft) {
					post.images.forEach((image, index) => {
						formData.append(`existing_images[${index}]`, image.image_id)
					})
					await handleSaveDraft(post.id, formData)
				} else if (post) {
					await handleUpdatePost(post.id, formData)
				} else if (isDraft) {
					if (savedPostId) {
						await handleSaveDraft(savedPostId, formData)
					} else {
						const response = await dispatch(submitPost(formData))

						if (response && response.post) {
							setSavedPostId(response.post.id)
							formData.append('post_id', response.post.id)
							await handleSaveDraft(response.post.id, formData)
						}
					}
				} else if (savedPostId) {
					if (isDraft) {
						await handleSaveDraft(savedPostId, formData)
					} else {
						const updateResponse = await dispatch(
							updatePostAction(savedPostId, formData)
						)
						if (updateResponse && updateResponse.status === 'success') {
							await dispatch(deleteDraft(draft.id))
							dispatch(addNewPost(updateResponse.post))
						} else {
							showAlert('Failed to submit post', 'error')
						}
						setIsEdited(false)
						onClose()
						showAlert(t('submittedSuccessfully'), 'success')
					}
				} else {
					await handleSubmitNewPost(formData)
					setEditorState(EditorState.createEmpty())
					setUploadedImages([])
					setDeletedImageIds([])
					setIsEdited(false)
					setIsQuestion(false)
					setSelectedLocations([])
				}
			} catch (error) {
				console.error('Error in submitting post or saving draft:', error)
			} finally {
				setIsLoading(false)
			}
		},
		[
			setIsLoading,
			buildFormData,
			handleSaveDraft,
			handleUpdatePost,
			handleSubmitNewPost,
			onClose,
			showAlert,
			setIsEdited,
			setUploadedImages,
			setDeletedImageIds,
			setIsQuestion,
			setSelectedLocations,
			post,
			savedPostId,
			draft,
			setEditorState,
			editorState,
			extractHashtags,
			t,
		]
	)

	return { handleSubmitPost }
}

export default usePostActions
