import {
	SET_POSTS,
	SET_POST_DETAILS,
	FETCH_POSTS_SUCCESS,
	FETCH_MORE_POSTS_SUCCESS,
	FETCH_POSTS_FAILURE,
	FETCH_QUESTION_POSTS_REQUEST,
	FETCH_QUESTION_POSTS_SUCCESS,
	FETCH_MORE_QUESTION_POSTS_SUCCESS,
	FETCH_QUESTION_POSTS_FAILURE,
	RESET_QUESTION_POSTS,
	ADD_NEW_POST,
	DELETE_POST_SUCCESS,
	DELETE_POST_FAILURE,
	UPDATE_POST_SUCCESS,
	UPDATE_POST_FAILURE,
	SET_PROFILE_POSTS,
	UPDATE_LAST_FETCHED,
	RESET_PROFILE_POSTS,
	ADD_PROFILE_POSTS,
	UPDATE_POST_TRANSLATION,
	UPDATE_TRANSLATION_STATUS,
} from '../actions/postAction'

import { UPDATE_USER_INFO_IN_POSTS } from '../actions/userActions'

const initialState = {
	posts: [],
	questionPosts: [],
	profilePosts: [],
	totalProfilePosts: 0,
	loading: false,
	error: null,
	deleteSuccess: null,
	deleteError: null,
	lastFetched: null,
	currentPostDetail: null,
	translationStatus: null,
	gptTranslation: null,
}

const postsReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_POSTS:
			return {
				...state,
				posts: action.payload,
			}

		case SET_POST_DETAILS:
			return {
				...state,
				currentPostDetail: action.payload,
				posts: state.posts.some((post) => post.id === action.payload.id)
					? state.posts.map((post) =>
							post.id === action.payload.id ? action.payload : post
					  )
					: [...state.posts, action.payload],
			}

		case UPDATE_POST_TRANSLATION:
			return {
				...state,
				posts: state.posts.map((post) =>
					post.id === action.payload.postId
						? {
								...post,
								translations: {
									...post.translations,
									[action.payload.translationType]: action.payload.translation,
								},
						  }
						: post
				),
			}

		case UPDATE_TRANSLATION_STATUS:
			return {
				...state,
				posts: state.posts.map((post) =>
					post.id === action.payload.postId
						? {
								...post,
								translationStatus: action.payload.status,
								translations: action.payload.translations
									? [
											...post.translations.filter(
												(t) =>
													!action.payload.translations.some(
														(newT) => newT.language === t.language
													)
											),
											...action.payload.translations,
									  ]
									: post.translations,
						  }
						: post
				),
			}

		case FETCH_POSTS_SUCCESS:
			return {
				...state,
				posts: action.payload.posts,
				hasMore: action.payload.hasMore,
				nextOffset: action.payload.nextOffset,
				loading: false,
				error: null,
			}

		case FETCH_MORE_POSTS_SUCCESS:
			return {
				...state,
				posts: [...state.posts, ...action.payload.posts],
				hasMore: action.payload.hasMore,
				nextOffset: action.payload.nextOffset,
				loading: false,
				error: null,
			}

		case FETCH_POSTS_FAILURE:
			return {
				...state,
			}

		case FETCH_QUESTION_POSTS_REQUEST:
			return {
				...state,
				questionPostsLoading: true,
				questionPostsError: null,
			}

		case FETCH_QUESTION_POSTS_SUCCESS:
			return {
				...state,
				questionPosts: action.payload.posts,
				hasMoreQuestions: action.payload.hasMore,
				nextQuestionOffset: action.payload.nextOffset,
				loading: false,
			}
		case FETCH_MORE_QUESTION_POSTS_SUCCESS:
			return {
				...state,
				questionPosts: [...state.questionPosts, ...action.payload.posts],
				hasMoreQuestions: action.payload.hasMore,
				nextQuestionOffset: action.payload.nextOffset,
				loading: false,
			}
		case FETCH_QUESTION_POSTS_FAILURE:
			return {
				...state,
				questionPostsLoading: false,
				questionPostsError: action.payload,
			}

		case RESET_QUESTION_POSTS:
			return {
				...state,
				questionPosts: [],
				hasMoreQuestionPosts: true,
				nextQuestionPostsOffset: 0,
			}

		case ADD_NEW_POST:
			return {
				...state,
				posts: [action.payload, ...state.posts],
			}

		case DELETE_POST_SUCCESS:
			const updatedPosts = state.posts.filter(
				(post) => post.id !== action.payload
			)
			return {
				...state,
				posts: updatedPosts,
				totalPosts: updatedPosts.length,
				deleteSuccess: true,
				deleteError: null,
			}

		case DELETE_POST_FAILURE:
			return {
				...state,
				deleteSuccess: false,
				deleteError: action.payload,
			}

		case UPDATE_POST_SUCCESS:
			const updatedPostIndex = state.posts.findIndex(
				(post) => post.id === action.payload.id
			)
			if (updatedPostIndex !== -1) {
				return {
					...state,
					posts: [
						...state.posts.slice(0, updatedPostIndex),
						action.payload,
						...state.posts.slice(updatedPostIndex + 1),
					],
				}
			}
			return state

		case UPDATE_POST_FAILURE:
			// エラー処理
			return {
				...state,
				// エラーに関する状態を更新
			}

		case SET_PROFILE_POSTS:
			// プロフィール専用のポストデータを更新
			return {
				...state,
				profilePosts: action.payload.posts,
				totalProfilePosts: action.payload.totalPosts,
			}

		case UPDATE_LAST_FETCHED:
			return {
				...state,
				lastFetched: action.payload,
			}

		case RESET_PROFILE_POSTS:
			return {
				...state,
				profilePosts: [],
				totalProfilePosts: 0,
			}

		case ADD_PROFILE_POSTS:
			const existingPostIds = state.profilePosts.map((post) => post.id)
			const newPosts = action.payload.posts.filter(
				(post) => !existingPostIds.includes(post.id)
			)
			return {
				...state,
				profilePosts: [...state.profilePosts, ...newPosts],
				totalProfilePosts: action.payload.totalPosts,
			}

		case UPDATE_USER_INFO_IN_POSTS:
			return {
				...state,
				posts: state.posts.map((post) => {
					if (post.userId === action.payload.userId) {
						// 対象ユーザーのポストのみ更新
						return {
							...post,
							user: {
								...post.user,
								username: action.payload.username,
								avatar: action.payload.avatar,
							},
						}
					}
					return post
				}),
			}

		default:
			return state
	}
}

export default postsReducer
