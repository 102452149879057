// src/components/Search/SearchBar.jsx

import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	searchRequestAction,
	searchByHashtagAction,
	setKeyword,
} from '../../actions/searchActions'
import { commonStyles } from '../../styles/commonStyles'
import { RiSearchLine } from 'react-icons/ri'
import { BsX } from 'react-icons/bs'

const filterProps = (propName) =>
	propName !== 'background' && propName !== 'isActive'

const SearchBarWrapper = styled.div.withConfig({
	shouldForwardProp: filterProps,
})`
	display: flex;
	align-items: center;
	border-radius: 50px;
	flex-grow: 1;
	padding: 10px 20px 10px;
	background-color: #fff;
	box-shadow: 0 4px 18px rgba(0, 0, 0, 0.1);
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;

	@media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
		padding: 8px 16px;
	}

	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		// width: 100%;
	}
`

const SearchInput = styled.input`
	flex-grow: 1;
	margin-left: 0.5rem;
	border: none;
	outline: none;
	background-color: #fff;
	padding-left: 0.5rem;
	color: ${({ theme }) => theme.colors.text};

	&::placeholder {
		color: ${({ theme }) => theme.colors.placeholderText};
	}
`

const ClearButton = styled.button`
	background-color: transparent;
	border: none;
	cursor: pointer;
	font-size: 1.5rem;
	color: ${({ theme }) => theme.colors.text};
	line-height: 1;
	padding: 0px;
`

const SearchBar = React.memo(({ searchBarHashtag }) => {
	const location = useLocation()
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const searchPlaceholder = t('keywordSearch')
	const navigate = useNavigate()
	const reduxKeyword = useSelector((state) => state.search.keyword)
	const [localKeyword, setLocalKeyword] = useState(reduxKeyword)

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search)
		const keywordFromUrl = searchParams.get('keyword')
		const hashtagFromUrl = searchParams.get('hashtag') || searchBarHashtag

		if (keywordFromUrl && decodeURIComponent(keywordFromUrl) !== localKeyword) {
			setLocalKeyword(decodeURIComponent(keywordFromUrl))
			dispatch(setKeyword(decodeURIComponent(keywordFromUrl)))
		} else if (
			hashtagFromUrl &&
			`#${decodeURIComponent(hashtagFromUrl)}` !== localKeyword
		) {
			setLocalKeyword(`#${decodeURIComponent(hashtagFromUrl)}`)
			dispatch(setKeyword(`#${decodeURIComponent(hashtagFromUrl)}`))
		} else if (location.pathname === '/' && localKeyword) {
			handleClear()
		}
	}, [location.search, location.pathname, dispatch, searchBarHashtag])

	const handleSearch = useCallback(() => {
		const searchType = localKeyword.startsWith('#') ? 'hashtag' : 'keyword'
		const searchValue =
			searchType === 'hashtag' ? localKeyword.slice(1) : localKeyword

		if (searchValue.trim()) {
			dispatch(
				searchType === 'hashtag'
					? searchByHashtagAction(searchValue)
					: searchRequestAction(searchValue)
			)
			dispatch(setKeyword(localKeyword))

			const newUrl = `/search?${searchType}=${encodeURIComponent(searchValue)}`
			navigate(newUrl, { replace: true, state: { fromSearch: true } })
		}
	}, [localKeyword, dispatch, navigate])

	const handleClear = useCallback(() => {
		setLocalKeyword('')
		dispatch(setKeyword(''))
	}, [dispatch])

	const handleInputChange = useCallback((e) => {
		setLocalKeyword(e.target.value)
	}, [])

	const handleKeyDown = useCallback(
		(e) => {
			if (e.key === 'Enter' && !e.nativeEvent.isComposing) {
				handleSearch()
			}
		},
		[handleSearch]
	)

	return (
		<SearchBarWrapper backgroundColor={commonStyles.backgroundColor}>
			<RiSearchLine size="1.2em" />
			<SearchInput
				placeholder={searchPlaceholder}
				value={localKeyword}
				onChange={handleInputChange}
				onKeyDown={handleKeyDown}
			/>
			{localKeyword && (
				<ClearButton onClick={handleClear}>
					<BsX />
				</ClearButton>
			)}
		</SearchBarWrapper>
	)
})

export default SearchBar
