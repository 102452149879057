// src/components/Avatar/AvatarWithFlag.jsx

import React from 'react'
import styled from 'styled-components'
import CustomAvatar from './Avatar'
import CountryFlag from './CountryFlag'
import { AVATAR_CONFIG } from './avatarConfig'

const AvatarWithFlagContainer = styled.div`
	position: relative;
	display: inline-block;
`

const FlagWrapper = styled.div`
	position: absolute;
	${() => AVATAR_CONFIG.default.flagPosition};
`

const AvatarWithFlag = ({
	username,
	avatar,
	size = 'default',
	onClick,
	countryCode,
}) => {
	return (
		<AvatarWithFlagContainer>
			<CustomAvatar
				username={username}
				avatar={avatar}
				size={size}
				onClick={onClick}
			/>
			{countryCode && (
				<FlagWrapper>
					<CountryFlag
						countryCode={countryCode.toLowerCase()}
						size={AVATAR_CONFIG.default.flagSize}
					/>
				</FlagWrapper>
			)}
		</AvatarWithFlagContainer>
	)
}

export default AvatarWithFlag
