// src/components/Comment/CommentImageModal.jsx

import React from 'react'
import styled from 'styled-components'
import ImageModal from '../Common/ImageModal'
import { CancelIconWrapper } from '../../styles/ModalStyles'
import CancelIcon from '../../assets/icon/cancel-modal.svg'

const CommentImage = styled.img`
	max-width: 28%;
	border-radius: 20px;
	margin-top: 10px;
	cursor: pointer;
`

const CommentImageModal = ({
	imageUrl,
	modalIsOpen,
	handleImageClick,
	handleCloseModal,
}) => {
	return (
		<>
			<CommentImage src={imageUrl} alt="Comment" onClick={handleImageClick} />
			{modalIsOpen && (
				<>
					<CancelIconWrapper
						src={CancelIcon}
						alt="Close"
						onClick={handleCloseModal}
					/>
					<ImageModal
						isOpen={modalIsOpen}
						onRequestClose={handleCloseModal}
						images={[{ src: imageUrl }]}
						startIndex={0}
						className="comment-image-gallery"
						isPost={false}
					/>
				</>
			)}
		</>
	)
}

export default CommentImageModal
