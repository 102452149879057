// src/components/Common/ImageUploader.jsx

import React, { useRef, useState, useEffect } from 'react'
import imageCompression from 'browser-image-compression'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { hoverCommentIconStyle } from '../../styles/commonStyles'
import { useSnackbar } from '../../context/SnackbarContext'

import { HiOutlinePhotograph } from 'react-icons/hi'

const IconContainer = styled.div`
	${hoverCommentIconStyle}
`

const ImageUploadIcon = styled.div`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	color: ${({ theme }) => theme.colors.textDescription};
`

const ImageUploader = ({
	onImageUpload,
	uploadedImages,
	maxImages,
	icon,
	setIsLoading,
}) => {
	const fileInputRef = useRef()
	const [errorMessage, setErrorMessage] = useState('')
	const { t } = useTranslation()
	const showAlert = useSnackbar()

	const handleClick = () => {
		if (uploadedImages && uploadedImages.length >= maxImages) {
			const errorMessage = t('maxImagesForPost', { maxImages: maxImages })
			showAlert(errorMessage, 'error')
			return
		}
		fileInputRef.current.click()
	}

	const handleFileChange = async (event) => {
		setIsLoading(true)

		const files = Array.from(event.target.files)

		if (files.length + uploadedImages.length > maxImages) {
			const errorMessage = t('maxImagesForPost', { maxImages: maxImages })
			showAlert(errorMessage, 'error')
			return
		}

		const options = {
			maxSizeMB: 1.0,
			maxWidthOrHeight: 1920,
			useWebWorker: true,
		}

		try {
			const compressedFiles = await Promise.all(
				files.map((file) => imageCompression(file, options))
			)

			const newImages = compressedFiles
				.map((compressed, index) => {
					if (!(compressed instanceof Blob)) {
						console.error('The compressed file is not a Blob:', compressed)
						return null
					}

					const originalFile = files[index]
					const newFile = new File([compressed], originalFile.name, {
						type: originalFile.type,
						lastModified: originalFile.lastModified,
					})

					return {
						file: newFile,
						url: URL.createObjectURL(newFile),
					}
				})
				.filter(Boolean)

			onImageUpload(newImages) // 更新された状態を親コンポーネントに通知
		} catch (error) {
			console.error('Error in processing images:', error)
			setErrorMessage('Error in processing images.')
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<IconContainer onClick={handleClick}>
			<ImageUploadIcon>
				{icon || <HiOutlinePhotograph size="23px" />}
			</ImageUploadIcon>
			{errorMessage && <p>{errorMessage}</p>}
			<input
				type="file"
				ref={fileInputRef}
				onChange={handleFileChange}
				style={{ display: 'none' }}
				accept="image/jpeg, image/jpg, image/png"
				multiple={maxImages > 1}
			/>
		</IconContainer>
	)
}

export default ImageUploader
