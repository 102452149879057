// src/context/EditingCommentContext.js

import React, {
	createContext,
	useState,
	useContext,
	useCallback,
	useMemo,
} from 'react'
import { useDispatch } from 'react-redux'

const EditingCommentContext = createContext()

export const EditingCommentProvider = ({ children }) => {
	const [editingCommentId, setEditingCommentId] = useState(null)
	const dispatch = useDispatch()

	const handleEditComment = useCallback((commentId) => {
		setEditingCommentId(commentId)
	}, [])

	const handleUpdateComment = useCallback(
		(postId, commentId, updatedContent) => {
			// ここで実際の更新ロジックを実装する
			// 例: dispatch(updateComment(postId, commentId, updatedContent))
			setEditingCommentId(null) // 編集モードを終了
		},
		[dispatch]
	)

	const handleCancelEdit = useCallback(() => {
		setEditingCommentId(null)
	}, [])

	const contextValue = useMemo(
		() => ({
			editingCommentId,
			handleEditComment,
			handleUpdateComment,
			handleCancelEdit,
		}),
		[editingCommentId, handleEditComment, handleUpdateComment, handleCancelEdit]
	)

	return (
		<EditingCommentContext.Provider value={contextValue}>
			{children}
		</EditingCommentContext.Provider>
	)
}

export const useEditingComment = () => {
	const context = useContext(EditingCommentContext)
	if (context === undefined) {
		throw new Error(
			'useEditingComment must be used within a EditingCommentProvider'
		)
	}
	return context
}
