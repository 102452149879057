// src/selectors/bookmarkSelectors.js
import { createSelector } from 'reselect';

// ポストのブックマーク状態を選択するセレクター
export const selectPostBookmarksData = createSelector(
  [(state) => state.bookmarks.postBookmarks, (_, postId) => postId],
  (postBookmarks, postId) => {
    const isBookmarked = postBookmarks.includes(String(postId));
    return { isBookmarked };
  }
);

// コメントのブックマーク状態を選択するセレクター
export const selectCommentBookmarksData = createSelector(
  [(state) => state.bookmarks.commentBookmarks, (_, commentId) => commentId],
  (commentBookmarks, commentId) => {
    const isBookmarked = commentBookmarks.includes(String(commentId));
    return { isBookmarked };
  }
);
