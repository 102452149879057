// actions/bookmarkActions.js
import {
	toggleBookmarkAPI,
	fetchBookmarksAPI,
	fetchBookmarkDataAPI,
} from '../services/bookmarkApi'

export const FETCH_BOOKMARKS_SUCCESS = 'FETCH_BOOKMARKS_SUCCESS'
export const TOGGLE_BOOKMARK_SUCCESS = 'TOGGLE_BOOKMARK_SUCCESS'
export const TOGGLE_BOOKMARK_FAILURE = 'TOGGLE_BOOKMARK_FAILURE'

export const FETCH_BOOKMARK_DETAIL_SUCCESS = 'FETCH_BOOKMARK_DETAIL_SUCCESS'
export const FETCH_BOOKMARK_DETAIL_FAILURE = 'FETCH_BOOKMARK_DETAIL_FAILURE'

// Action Creator（引数の形式を修正）
export const toggleBookmarkAction = (type, id, userId, isBookmarked) => {
	return async (dispatch) => {
		try {
			const data = await toggleBookmarkAPI({
				type,
				id,
				userId,
				isBookmarked,
			})

			if (type === 'post') {
				dispatch({
					type: 'TOGGLE_POST_BOOKMARK_SUCCESS',
					payload: { postId: id },
				})
			}

			dispatch(loadUserBookmarks(userId))
		} catch (error) {
			dispatch({ type: 'TOGGLE_BOOKMARK_FAILURE', error: error.message })
		}
	}
}

// ブックマークデータをフェッチしてグローバルステートに保存するアクションクリエーター
export const loadUserBookmarks = (userId) => async (dispatch) => {
	try {
		const bookmarks = await fetchBookmarksAPI(userId)
		dispatch({
			type: 'LOAD_BOOKMARKS_SUCCESS',
			payload: bookmarks,
		})
	} catch (error) {
		dispatch({
			type: 'LOAD_BOOKMARKS_FAILURE',
			error,
		})
	}
}

// ブックマーク詳細データをフェッチするアクションクリエーター
export const fetchBookmarkDetailAction = (userId) => async (dispatch) => {
	try {
		const bookmarkDetail = await fetchBookmarkDataAPI(userId)
		dispatch({
			type: FETCH_BOOKMARK_DETAIL_SUCCESS,
			payload: bookmarkDetail,
		})
	} catch (error) {
		dispatch({
			type: FETCH_BOOKMARK_DETAIL_FAILURE,
			error: error.message,
		})
	}
}
