// src/components/Settings/YourAccount/AccountInformation.jsx
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import MainLayout from '../../Layout/MainLayout'
import BackButton from '../../Button/BackButton'
import styled from 'styled-components'

const BackButtonContainer = styled.div`
	position: absolute;
	top: 90px;
	left: 0;
	z-index: 10;
	padding-left: 340px;
`

const PageTitle = styled.div`
	//
`

const SettingsContainer = styled.div`
	display: flex;
	flex-direction: column;
`

const StyledLink = styled(Link)`
	padding: 10px;
	border-bottom: 1px solid #e0e0e0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;

	&:hover,
	&:active {
		background-color: #f5f5f5;
	}
`

const Username = styled.div`
	//
`

const Email = styled.div`
	//
`

const AccountCreation = styled.div`
	//
`

const ArrowIcon = styled.span`
	font-size: 24px;
	// Use a proper icon or character here for the "greater than" symbol
	&:after {
		content: '>';
	}
`

const SettingsItem = ({ to, children }) => (
	<StyledLink to={to}>
		{children}
		<ArrowIcon />
	</StyledLink>
)

const AccountInformation = () => {
	const user = useSelector((state) => state.user.user)

	return (
		<MainLayout showSidebarRight={false}>
			<BackButtonContainer>
				<BackButton backPath="" />
			</BackButtonContainer>
			<PageTitle>
				<h3>Account information</h3>
			</PageTitle>
			<SettingsContainer>
				<Username>
					Username
					<div>{user?.username}</div>
				</Username>
				<Email>
					Email
					<div>{user?.email}</div>
				</Email>

				{/* <SettingsItem to="/settings/language">Language</SettingsItem> */}
			</SettingsContainer>
		</MainLayout>
	)
}

export default AccountInformation
