// src/components/Profile/ActivityComment.jsx

import React from 'react'
import { useTranslation } from 'react-i18next'
import ActivityCommentItem from './ActivityCommentItem'
import styled from 'styled-components'
import ImageModal from '../Common/ImageModal'
import CommentFooter from '../Comment/CommentFooter'
import { useModal } from '../../hooks/useModal'
import { CancelIconWrapper } from '../../styles/ModalStyles'
import CancelIcon from '../../assets/icon/cancel-modal.svg'

const ProfileCommentWrapper = styled.div`
	padding-bottom: 70px; // MobileNavbarの高さに応じて調整

	@media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
		padding-bottom: 0; // タブレット以上のサイズでは余分なパディングを削除
	}
`

const CommentAreaWrapper = styled.div`
	padding: 10px 20px 0px;
`

const ImageFooterWrapper = styled.div`
	margin-left: 40px;
`

const CommentImage = styled.img`
	max-width: 28%;
	border-radius: 20px;
	margin-top: 6px;
`

const NoDataMessage = styled.p`
	color: ${({ theme }) => theme.colors.textSecondary};
	font-size: ${({ theme }) => theme.fontSizes.large};
	text-align: center;
	margin: 50px 0;
`

const ActivityComment = React.memo(({ comments, user, userLanguage }) => {
	const { t } = useTranslation()
	const noDataMessage = t('noDataYet')

	const [modalIsOpen, openModal, closeModal, setModalIsOpen, handleCloseModal] =
		useModal()

	const handleImageClick = (e, imageUrl) => {
		e.stopPropagation()
		openModal(0)
	}

	return (
		<ProfileCommentWrapper>
			{comments?.length > 0 ? (
				comments.map((comment) => (
					<React.Fragment key={comment.id}>
						<CommentAreaWrapper>
							<ActivityCommentItem
								comment={comment}
								userLanguage={userLanguage}
							/>
							<ImageFooterWrapper>
								{comment?.image_url && (
									<CommentImage
										src={comment?.image_url}
										alt="Comment"
										onClick={(e) => handleImageClick(e, comment?.image_url)}
									/>
								)}
								<CommentFooter
									commentId={comment.id}
									type="comment"
									showReply={false}
								/>
							</ImageFooterWrapper>
						</CommentAreaWrapper>
					</React.Fragment>
				))
			) : (
				<NoDataMessage>{noDataMessage}</NoDataMessage>
			)}

			{modalIsOpen && (
				<CancelIconWrapper
					src={CancelIcon}
					alt="Close"
					onClick={(e) => {
						e.stopPropagation()
						closeModal(setModalIsOpen)
					}}
				/>
			)}

			{modalIsOpen && (
				<ImageModal
					isOpen={modalIsOpen}
					onRequestClose={handleCloseModal}
					images={[{ src: comments[0]?.image_url }]}
					startIndex={0}
					className="comment-image-gallery"
					isPost={false}
				/>
			)}
		</ProfileCommentWrapper>
	)
})

export default ActivityComment
