import React from 'react'
import { useTranslation } from 'react-i18next'

import styled, { css } from 'styled-components'
import {
	footerIconWrapperStyle,
	footerIconContainerStyle,
	footerIconStyle,
	footerTextStyles,
	hoverIconStyle,
} from '../../styles/commonStyles'

import { TbMessageCircle } from 'react-icons/tb'

const IconWrapper = styled.div`
	${footerIconWrapperStyle};
`

const IconContainer = styled.div`
	${footerIconContainerStyle};
	${hoverIconStyle}
`

const CommentIcon = styled.div`
	${footerIconStyle}
	${({ theme }) =>
		css`
			${footerIconStyle(theme)}
		`}
	transform: ${({ $isComment }) => ($isComment ? 'scale(0.9)' : 'none')};
	font-size: 22px;
`

const CommentText = styled.div`
	${({ theme }) =>
		css`
			${footerTextStyles(theme)}
		`}
	min-width: 20px;
`

function CommentButton({ onClick, type, postId, post, commentsCount }) {
	const { t } = useTranslation()
	const comments_count =
		commentsCount !== undefined ? commentsCount : post?.comments?.length || ''

	return (
		<IconWrapper>
			<IconContainer>
				<CommentIcon onClick={onClick} $isComment={type === 'comment'}>
					<TbMessageCircle />
				</CommentIcon>
			</IconContainer>
			<CommentText>{comments_count === 0 ? t('') : comments_count}</CommentText>
		</IconWrapper>
	)
}

export default CommentButton
