// src/hooks/useAuth.js

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserAction } from '../actions/userActions'

export function useAuth() {
	const dispatch = useDispatch()
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
	const userId = useSelector((state) => state.auth.userId)

	useEffect(() => {
		if (isAuthenticated && userId) {
			dispatch(fetchUserAction(userId))
		}
	}, [isAuthenticated, userId, dispatch])
}
