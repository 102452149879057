// src/hooks/useInfiniteScroll.js

import { useEffect, useState, useCallback, useRef } from 'react'
import { throttle } from 'lodash'

const useInfiniteScroll = (callback, deps = []) => {
	const [isFetching, setIsFetching] = useState(false)
	const prevScrollY = useRef(0)

	const handleScroll = useCallback(
		throttle(() => {
			const currentScrollY = window.scrollY
			const isScrollingDown = currentScrollY > prevScrollY.current
			const isNearBottom =
				window.innerHeight + currentScrollY >=
				document.documentElement.offsetHeight - 500

			if (isScrollingDown && isNearBottom && !isFetching) {
				setIsFetching(true)
			}

			prevScrollY.current = currentScrollY
		}, 600),
		[isFetching]
	)

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)
		return () => window.removeEventListener('scroll', handleScroll)
	}, [handleScroll])

	useEffect(() => {
		if (!isFetching) return
		callback().then(() => setIsFetching(false))
	}, [isFetching, callback, ...deps])

	return [isFetching, setIsFetching]
}

export default useInfiniteScroll
