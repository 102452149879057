// src/services/likeApi.js
const BASE_URL = process.env.REACT_APP_API_BASE_URL

const handleApiResponse = async (response) => {
	if (!response.ok) {
		const errorData = await response.json()
		throw new Error(
			`API call failed: ${response.statusText}, Details: ${JSON.stringify(
				errorData
			)}`
		)
	}
	return await response.json()
}

const getEndpoint = (type, action, id) => {
	const baseEndpoint = action === 'count' ? 'likes/count' : 'likes/toggle'
	if (type === 'comment' || type === 'post') {
		return `${baseEndpoint}/${type}/${id}`
	}
	throw new Error(`Unsupported type: ${type}`)
}

export const fetchLikesCountFromApi = async (type, id, userId) => {
	// idがundefinedの場合はここで処理を中止する
	if (!id) {
		return Promise.reject(new Error('Invalid id'))
	}

	const endpoint = getEndpoint(type, 'count', id)
	const response = await fetch(`${BASE_URL}/${endpoint}?userId=${userId}`)
	return handleApiResponse(response)
}

export const toggleLikeInDatabase = async (type, id, isLiked, userId) => {
	const endpoint = getEndpoint(type, 'toggle', id)
	const response = await fetch(`${BASE_URL}/${endpoint}`, {
		method: isLiked ? 'POST' : 'DELETE',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ id, userId }),
		credentials: 'include',
	})
	const data = await handleApiResponse(response)
	return data
}
