// src/styles/commonStyles.js

import styled, { css } from 'styled-components'

export const modalOpenStyle = css`
	overflow: hidden;
`

export const commonStyles = (theme) => `
	color: ${theme?.colors?.background};
	color: ${theme?.border?.border};
	borderWidth: '1px',
`

export const iconStyles = {
	color: '#2E2F31', // sumi iro
	fontSize: '20px',
}

export const iconFooterStyles = (theme) => `
	color: ${theme?.colors?.black};
	font-size: 19px;
`

export const textFooterStyles = (theme) => `
	color: ${theme?.colors?.black};
	font-size: 0.85rem;
	font-weight: bold;
`

export const iconSmallStyle = (theme) => `
	color: ${theme?.colors?.black};
	font-size: 18px;
`

export const borderTopStyle = (theme) => `
    border-top: 1px solid ${theme?.colors?.border};
`

export const borderBottomStyle = (theme) => `
    border-bottom: 1px solid ${theme?.colors?.border};
`

export const borderVerticalStyle = (theme) => `
    border-left: 1px solid ${theme?.colors?.border};
    border-right: 1px solid ${theme?.colors?.border};
`

export const sidebarIconStyles = {
	color: '#2E2F31',
	fontSize: '28px',
	marginRight: '10px',
}

export const hoverIconStyle = (
	{ theme } = { theme: { colors: { primary: 'defaultColor' } } }
) => {
	return `
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 8px;
    transition: background-color 0.2s;
    background-color: transparent;
	cursor: pointer;
    &:hover {
      background-color: ${theme?.colors?.background || 'fallbackColor'};
    }
  `
}

export const hoverCommentIconStyle = (
	{ theme } = { theme: { colors: { primary: 'defaultColor' } } }
) => {
	return `
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 34px;
	height: 34px;
	border-radius: 50%;
	padding: 8px;
	transition: background-color 0.2s;
	background-color: transparent;
	cursor: pointer;
	&:hover {
		background-color: ${theme.colors.backgroundSecondary || 'fallbackColor'};
	}
  `
}

export const hoverTextStyle = ({ theme }) => {
	return `
    display: inline;
    cursor: pointer;
    transition: color 0.2s;
	    padding: 4px;
    &:hover {
      color: ${theme?.colors?.textSecondary || 'fallbackColor'};
      border-radius: 20%;
    }
  `
}

export const footerIconAndTextContainerStyle = css`
	display: flex;
	align-items: center;
	justify-content: flex-start;
`

export const footerIconWrapperStyle = css`
	display: flex;
	align-items: center;
	justify-content: flex-start;
`

export const footerIconContainerStyle = css`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-shrink: 0;
	width: 24px;
	height: 24px;
`

export const footerIconStyle = (theme) => `
	color: ${theme?.colors?.textSecondary};
	font-size: 22px;
	padding-bottom: 5px;
`

export const footerTextStyles = (theme) => `
  color: ${theme?.colors?.textSecondary};
  font-size: 0.9rem;
  margin-left: -5px;
  font-weight: bold;
  display: inline-block;
`

export const Divider = styled.div`
	border-bottom-color: ${({ theme }) => theme.colors.border};
	border-bottom-style: solid;
	border-bottom-width: 1px;
	width: 100%;
`

/* モーダルのバックグラウンド色 */
// export const modalBackdropStyle = css`
// 	.modal-backdrop.show {
// 		background-color: rgba(255, 255, 255, 0.85) !important; // 白色の背景に変更
// 	}
// `

export const modalContentStyle = css`
	.modal-content {
		border-radius: 20px; // モーダルのコンテンツのボーダーラジウスを設定
		// 他のスタイルもここに追加可能
	}
`

// Read moreのスタイル
export const StyledReadMore = styled.span`
	color: ${({ theme }) => theme.colors.text};
	font-size: ${({ theme }) => theme.fontSizes.small};
	cursor: pointer;
	// text-decoration: underline;
	font-weight: bold;
`
