// src/styles/theme.js
// 使う色は３色を基本にする。

export const theme = {
	colors: {
		primary: '#D5004E', // 主色 #AC0023
		secondary: '#004D40', // 副色
		primaryHover: '#D11230', // 主色ホバー
		secondaryHover: '#003D35',
		outlinePrimaryHover: '#F5F5F6', // outline-primaryホバー背景色
		outlinePrimaryHoverBorder: '#F5F5F6',
		outlineGray: '#CACDD1',
		background: '#F5F5F6', // 背景色
		backgroundSecondary: '#EDEDED',
		text: '#2E2F31', // 墨色
		textSecondary: '#B0B0B0', // 二次テキスト色
		textDescription: '#575757', // 副色
		textTranslation: '#808080', // 二次テキスト色
		border: '#ecf0f1', // 境界線色
		chigusanezu: '#6C8270', //ちぐさねず
		white: '#ffffff',
		black: '#2E2F31',
		link: '#2196F3', //blue500
		primaryDisabled: '#E56875',
		link: '#007bff',
	},
	fonts: {
		primary: 'Roboto, sans-serif', // 主フォント
		secondary: 'Arial, sans-serif', // 副フォント
	},
	fontSizes: {
		tiny10: '10px', // 小サイズテキスト
		tiny11: '11px', // 小サイズテキスト
		tiny: '12px', // 小サイズテキスト
		small: '14px', // 小サイズテキスト
		mediumComment: '15px', // 中サイズテキスト
		medium: '15px', // 中サイズテキスト
		regular: '16px', // 中サイズテキスト
		large: '18px', // 中サイズテキスト
		extraLarge: '20px', // 大サイズテキスト
		subTitle: '22px', // タイトル用サイズ
		title: '40px', // タイトル用サイズ
		titleLarge: '60px', // タイトル用サイズ
	},
	spacing: {
		small: '8px', // 小間隔
		medium: '16px', // 中間隔
		large: '24px', // 大間隔
	},
	breakpoints: {
		// mobile: '320px', // モバイルビュー
		mobile: '768px', // モバイルビュー
		desktop: '1024px', // デスクトップビュー
		wideScreen: '1280px', //
	},
}

// export const theme = {
// 	colors: {
// 		primary: '#CB1B45', //主要な色（例：ボタン、リンク）
// 		secondary: '#CB1B45', //補助的な色（例：背景、サブテキスト）,
// 		success: '#CB1B45', //成功や肯定的なアクションを示す色（例：成功メッセージ）,
// 		danger: '#CB1B45', //警告や否定的なアクションを示す色（例：エラーメッセージ）,
// 		dangerHover: '#D95C7A', // ホバー時の色
// 		dangerHoverBorder: '#D95C7A', // ホバー時の境界線の色
// 		warning: '#C53D43', //赤紅　注意を促す色（例：警告メッセージ）,
// 		info: '#CB1B45', //情報表示用の色（例：情報メッセージ）,
// 		light: '#CB1B45', //軽い背景や境界線用の色,
// 		black: '#2E2F31', // 墨色
// 		delete: '#C53D43', //赤紅
// 		liked: '#6C8270', //ちぐさねず
// 		icon: '#494949', // 灰色
// 	},
// 	commonStyles: {
// 		backgroundColor: '#f5f5f5', //共通の背景色。
// 		hoverBackgroundColor: '#f5f5f5', //共通の背景色。
// 		hoverBackgroundColorComment: '#DFE0E7', //共通の背景色。
// 		backgroundColorText: '#696A6B', //共通の背景色。
// 		borderColor: '#ecf0f1',
// 		borderWidth: '1px',
// 	},
// 	text: {
// 		color: '#2E2F31',
// 	},
// 	border: {
// 		defaultColor: '#ecf0f1',
// 		defaultWidth: '1px',
// 		thickWidth: '3px',
// 		thinWidth: '0.5px',
// 	},
// }
