// src/decorators/LinkDecorator.js

import React from 'react'
import styled from 'styled-components'

const StyledLinkSpan = styled.span`
	color: ${({ theme }) => theme.colors.link};
	cursor: pointer;
`

const LinkSpan = ({ children }) => {
	return <StyledLinkSpan>{children}</StyledLinkSpan>
}

const createDecorator = (regex, component) => {
	return {
		strategy: (contentBlock, callback) => {
			const text = contentBlock.getText()
			let matchArr, start
			while ((matchArr = regex.exec(text)) !== null) {
				start = matchArr.index
				callback(start, start + matchArr[0].length)
			}
		},
		component,
	}
}

const urlRegex = /(https?:\/\/[^\s]+)/g
const hashtagRegex = /#([^\s#]+)/g

export const urlDecorator = createDecorator(urlRegex, LinkSpan)
export const hashtagDecorator = createDecorator(hashtagRegex, LinkSpan)
