// src/components/Comment/CommentContent.jsx

import React from 'react'
import styled from 'styled-components'
import HeaderComponent from '../Common/HeaderComponent'
import RenderTextWithLinks from '../RenderTextWithLinks'
import { TranslationComponent } from '../TranslateContent/TranslationControls'

const CommentContentWrapper = styled.div`
	width: 100%;
	max-width: 100%;
	background: ${({ theme }) => theme.colors.background};
	border-radius: 20px;
	padding: 0px 12px 14px;
	box-sizing: border-box;
	overflow: hidden;
`

const TranslatedText = styled.div`
	font-size: ${({ theme }) => theme.fontSizes.medium};
	margin-bottom: 8px;
	word-wrap: break-word;
	overflow-wrap: break-word;
	word-break: break-word;
	max-width: 100%;
`

const StyledTranslationComponent = styled(TranslationComponent)`
	margin-bottom: -4px;
`

const CommentContent = ({
	comment,
	userId,
	postId,
	showOriginalAndTranslatedText,
	showOriginal,
	displayText,
	toggleOriginal,
	onEditComment,
	isEditing,
	isTranslating,
	translatingText,
}) => {
	return (
		<CommentContentWrapper>
			<HeaderComponent
				username={comment.user.username}
				createdAt={comment.created_at}
				userId={userId}
				postOwnerId={comment.user.id}
				postId={postId}
				commentId={comment.id}
				comment={comment}
				type="comment"
				onEditComment={onEditComment}
				isEditing={isEditing}
			/>

			<TranslatedText>
				{isTranslating ? (
					translatingText
				) : (
					<RenderTextWithLinks
						text={
							showOriginalAndTranslatedText
								? showOriginal
									? comment.comment
									: displayText
								: comment.comment
						}
					/>
				)}
			</TranslatedText>

			{!isTranslating && (
				<StyledTranslationComponent
					detectedLanguage={comment.language}
					showOriginal={showOriginal}
					toggleText={toggleOriginal}
				/>
			)}
		</CommentContentWrapper>
	)
}

export default CommentContent
