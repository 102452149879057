// /Users/user/Ritatrip/react-app/src/i18n.js

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enTranslation from './locales/en/translation.json'
import jaTranslation from './locales/ja/translation.json'
import zhTWTranslation from './locales/zh-TW/translation.json'

// ローカルストレージから言語設定を取得
const savedLanguage = localStorage.getItem('i18nextLng')

i18n
	.use(initReactI18next) // initReactI18next ミドルウェアを使用
	.init({
		resources: {
			en: {
				translation: enTranslation,
			},
			ja: {
				translation: jaTranslation,
			},
			'zh-TW': {
				translation: zhTWTranslation,
			},
		},
		lng: savedLanguage || 'en', // 保存された言語設定、またはデフォルトの 'en' を使用
		fallbackLng: 'en', // フォールバック言語設定
		interpolation: {
			escapeValue: false, // XSS防止のためのエスケープ処理を無効化
		},
	})

export default i18n
