// src/components/Ads/AdComponent.js

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const AdContainer = styled.div`
	width: 100%;
	min-height: 250px; // 最小の高さを設定
	display: block;
`

const AdComponent = ({ adSlot, adFormat }) => {
	const [isMobile, setIsMobile] = useState(false)

	useEffect(() => {
		const checkMobile = () => {
			setIsMobile(window.innerWidth < 768)
		}
		checkMobile()
		window.addEventListener('resize', checkMobile)
		return () => window.removeEventListener('resize', checkMobile)
	}, [])

	useEffect(() => {
		if (!isMobile) {
			const loadAd = () => {
				try {
					if (typeof window.adsbygoogle !== 'undefined') {
						;(window.adsbygoogle = window.adsbygoogle || []).push({})
					} else {
						// AdSenseスクリプトがまだロードされていない場合、再試行
						setTimeout(loadAd, 500)
					}
				} catch (err) {
					console.error('AdSense error:', err)
				}
			}
			loadAd()
		}
	}, [isMobile])

	if (isMobile) {
		return null
	}

	return (
		<AdContainer>
			<ins
				className="adsbygoogle"
				style={{ display: 'block', width: '100%' }} // 幅を100%に設定
				data-ad-client="ca-pub-6711425288471531"
				data-ad-slot={adSlot}
				data-ad-format={adFormat}
			/>
		</AdContainer>
	)
}

export default AdComponent
