// src/utils/hashtagUtils.js

import { fetchPostsByHashtag } from '../actions/hashtagActions'

export const handleHashtagClick = (
	dispatch,
	navigate,
	e,
	hashtag,
	location,
	postId
) => {
	e.stopPropagation()
	const sanitizedHashtag = hashtag.trim()
	const encodedHashtag = encodeURIComponent(sanitizedHashtag)
	dispatch(fetchPostsByHashtag(encodedHashtag))
	navigate(`/hashtag/${encodedHashtag}`, {
		state: {
			from: location.state?.from || location.pathname,
			postId: postId,
			prevPaths: location.state?.prevPaths
				? [...location.state.prevPaths, location.pathname]
				: [location.pathname],
		},
	})
}
