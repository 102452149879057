// src/components/Profile/ActivityDraftItem.jsx

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import HeaderComponent from '../Common/HeaderComponent'
import ImageGridComponent from '../Post/ImageGridComponent'
import RenderTextWithLinks from '../RenderTextWithLinks'
import {
	TimelineItemWrapper,
	PostAreaWrapper,
} from '../../components/Post/PostItem'
import { disableBodyScroll, enableBodyScroll } from '../../utils/scrollControl'
import { useModal } from '../../hooks/useModal'
import { CancelIconWrapper } from '../../styles/ModalStyles'
import CancelIcon from '../../assets/icon/cancel-modal.svg'
import ImageModal from '../Common/ImageModal'
import styled from 'styled-components'

const DraftContent = styled.div`
	padding: 0px;
`

const ActivityDraftItem = React.memo(({ draft, user }) => {
	const userId = useSelector((state) => state.auth.userId)
	const draftId = draft.id
	const images = draft.images || []
	const [
		modalIsOpen,
		openModal,
		closeModal,
		setModalIsOpen,
		clickedImageIndex,
		setClickedImageIndex,
		handleCloseModal,
	] = useModal()

	const handleImageClick = (e, index) => {
		e.stopPropagation()
		openModal(index)
	}

	const handleDraftDelete = () => {
		enableBodyScroll()
	}

	return (
		<TimelineItemWrapper>
			<PostAreaWrapper>
				<HeaderComponent
					username={user?.username}
					avatar={user?.avatar}
					createdAt={draft?.created_at}
					userId={userId}
					postOwnerId={user?.id}
					onDelete={handleDraftDelete}
					draftId={draftId}
					draft={draft}
					type="draft"
					originalType="profile"
				/>
				<DraftContent>
					<RenderTextWithLinks text={draft?.body} />
				</DraftContent>
				{images?.length > 0 && (
					<>
						{modalIsOpen && (
							<CancelIconWrapper
								src={CancelIcon}
								alt="Close"
								onClick={(e) => {
									e.stopPropagation()
									closeModal(setModalIsOpen)
								}}
							/>
						)}
						<ImageGridComponent
							images={images}
							onImageClick={(e, index) => handleImageClick(e, index)}
						/>
						<ImageModal
							isOpen={modalIsOpen}
							onRequestClose={handleCloseModal}
							images={images.map((image) => ({ src: image?.image_path }))}
							startIndex={clickedImageIndex}
							isPost={true}
						/>
					</>
				)}
			</PostAreaWrapper>
		</TimelineItemWrapper>
	)
})

export default ActivityDraftItem
