// src/components/Profile/ActivityCommentItem.jsx

import React, { useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import CustomAvatar from '../Avatar/AvatarWithFlag'
import HeaderComponent from '../Common/HeaderComponent'
import { TranslationComponent } from '../TranslateContent/TranslationControls'
import CommentFooter from '../Comment/CommentFooter'
import RenderTextWithLinks from '../RenderTextWithLinks'
import styled from 'styled-components'

const CommentItemWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	width: 100%;
`

const ProfileCommnetItem = styled.div`
	display: inline-block;
	width: auto;
	max-width: 100%;
	padding: 12px;
	border-radius: 20px;
	background-color: ${({ theme }) => theme.colors.background};
	margin-left: 6px;
	cursor: pointer;
`

const CommentContent = styled.div``

const StyledTranslationComponent = styled(TranslationComponent)`
	margin-top: 5px;
	margin-bottom: -2px;
`

const ActivityCommentItem = React.memo(({ comment, userLanguage }) => {
	const navigate = useNavigate()
	const postId = comment?.post_id
	const commentId = comment?.id
	const userId = comment?.user?.id
	const userName = comment?.user?.username
	const countryCode = comment?.user?.country_code
	const createdAt = comment?.created_at
	const postOwnerId = comment?.user?.id
	const avatarUrl = comment?.user?.avatar
	const avatarSize = 'default'

	const [showOriginal, setShowOriginal] = useState(false)
	const toggleOriginal = () => setShowOriginal(!showOriginal)

	const translatedComment = useMemo(() => {
		const translation = comment.translations.find(
			(t) => t.language === userLanguage
		)
		return translation ? translation.translated_content : comment.comment
	}, [comment, userLanguage])

	const handleItemClick = (e) => {
		e.stopPropagation()
		if (comment) {
			const postId = comment?.post_id
			navigate(`/posts/${postId}#comment-${commentId}`, {
				state: { from: `/profile/${userId}` },
			})
		}
	}

	return (
		<CommentItemWrapper>
			<CustomAvatar
				username={userName}
				avatar={avatarUrl}
				size={avatarSize}
				countryCode={countryCode}
			/>
			<ProfileCommnetItem onClick={handleItemClick}>
				<CommentContent>
					<HeaderComponent
						username={userName}
						avatar={avatarUrl}
						createdAt={createdAt}
						userId={userId}
						postOwnerId={postOwnerId}
						postId={postId}
						commentId={commentId}
						comment={comment}
						type="comment"
						originalType=""
						showPostMenu={false}
					/>
					<RenderTextWithLinks
						text={showOriginal ? comment.comment : translatedComment}
					/>
					<StyledTranslationComponent
						detectedLanguage={comment.language}
						showOriginal={showOriginal}
						toggleText={toggleOriginal}
					/>
				</CommentContent>
			</ProfileCommnetItem>
		</CommentItemWrapper>
	)
})

export default ActivityCommentItem
