// src/store/index.js

import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import authReducer from '../reducers/authReducer'
import likeReducer from '../reducers/likeReducer'
import fetchPostsReducer from '../reducers/postReducer'
import modalReducer from '../reducers/modalReducer'
import commentReducer from '../reducers/commentReducer'
import replyReducer from '../reducers/replyReducer'
import bookmarkReducer from '../reducers/bookmarkReducer'
import layoutReducer from '../reducers/layoutReducer'
import userReducer from '../reducers/userReducer'
import draftReducer from '../reducers/draftReducer'
import navigationReducer from '../reducers/navigationReducer'
import notificationsReducer from '../reducers/notificationsReducer'
import uiReducer from '../reducers/uiReducer'
import translationReducer from '../reducers/translationReducer'
import hashtagReducer from '../reducers/hashtagReducer'
import searchReducer from '../reducers/searchReducer'

const rootReducer = combineReducers({
	auth: authReducer,
	like: likeReducer,
	posts: fetchPostsReducer,
	modal: modalReducer,
	comments: commentReducer,
	replies: replyReducer,
	bookmarks: bookmarkReducer,
	layout: layoutReducer,
	user: userReducer,
	drafts: draftReducer,
	search: searchReducer,
	activeLink: navigationReducer,
	notifications: notificationsReducer,
	ui: uiReducer,
	translation: translationReducer,
	usersData: userReducer,
	hashtag: hashtagReducer,
})

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['auth', 'translation'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [
					'persist/PERSIST',
					'persist/REHYDRATE',
					'persist/PURGE',
					'persist/REGISTER',
				],
			},
			immutableCheck: false,
		}),
})

export const persistor = persistStore(store)
