// src/components/MobileNavbar/MobileNavbar.jsx

import React, { useState, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { setActiveLink } from '../../actions/navigationActions'
import CreatePostModal from '../Post/CreatePostModal'
import LoginModal from '../Login/LoginModal'
import SignupModal from '../Signup/SignupModal'
import {
	PiHouseBold,
	PiHouseFill,
	PiBellBold,
	PiBellFill,
	PiBookmarkSimpleBold,
	PiBookmarkSimpleFill,
	PiUserBold,
	PiUserFill,
} from 'react-icons/pi'
import { IoIosCloseCircleOutline } from 'react-icons/io'

const NavbarWrapper = styled.nav`
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: space-around;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.95);
	border-top: 1px solid ${({ theme }) => theme.colors.border};
	padding: 5px 0;
	padding-bottom: env(safe-area-inset-bottom, 5px);
	backdrop-filter: blur(5px);
	transition: transform 0.3s ease-in-out;
	transform: ${({ hide }) => (hide ? 'translateY(100%)' : 'translateY(0)')};
`

const NavItem = styled.button`
	background: none;
	border: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: ${({ theme }) => theme.colors.text};
	font-size: ${({ theme }) => theme.fontSizes.tiny11};
	cursor: pointer;
`

const IconWrapper = styled.span`
	font-size: 22px;
	position: relative;
`

const Badge = styled.span`
	position: absolute;
	top: 2px;
	right: -8px;
	background-color: ${({ theme }) => theme.colors.primary};
	color: white;
	border-radius: 50%;
	min-width: 16px;
	height: 16px;
	padding: 0 2px;
	font-size: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 1;
	font-weight: bold;
`

const CreatePostIcon = styled(IoIosCloseCircleOutline)`
	color: ${({ theme }) => theme.colors.text};
	font-size: 40px;
	transform: rotate(45deg);
	transition: transform 0.3s ease; // アニメーションを追加（オプション）

	&:hover {
		transform: rotate(90deg); // ホバー時に更に回転させる（オプション）
	}
`

const MobileNavbar = ({ hide = false }) => {
	const [showCreatePostModal, setShowCreatePostModal] = useState(false)
	const [showSignupModal, setShowSignupModal] = useState(false)
	const [showLoginModal, setShowLoginModal] = useState(false)

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { t } = useTranslation()
	const activeLink = useSelector((state) => state.activeLink.activeLink)
	const unreadCount = useSelector((state) => state.notifications.unreadCount)
	const currentUserId = useSelector((state) => state.auth.userId)
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)

	const handleTitleClick = () => {
		navigate('/')
		window.scrollTo(0, 0)
	}

	const handleNavigation = (path) => {
		if (path === '/profile') {
			if (isAuthenticated && currentUserId) {
				navigate(`/profile/${currentUserId}`)
			} else {
				setShowSignupModal(true)
				return
			}
		} else {
			navigate(path)
		}
		dispatch(setActiveLink(path))
	}

	const handleOpenCreatePostModal = useCallback(() => {
		setShowCreatePostModal(true)
	}, [])

	const handleCloseCreatePostModal = useCallback(() => {
		setShowCreatePostModal(false)
	}, [])

	const handleCloseSignupModal = useCallback(() => {
		setShowSignupModal(false)
	}, [])

	const handleCloseLoginModal = useCallback(() => {
		setShowLoginModal(false)
	}, [])

	const openLoginModal = useCallback(() => {
		setShowSignupModal(false)
		setShowLoginModal(true)
	}, [])

	const openSignupModal = useCallback(() => {
		setShowLoginModal(false)
		setShowSignupModal(true)
	}, [])

	return (
		<>
			<NavbarWrapper hide={hide}>
				<NavItem
					isActive={activeLink === '/'}
					onClick={() => handleNavigation('/')}
				>
					<IconWrapper onClick={handleTitleClick}>
						{activeLink === '/' ? <PiHouseFill /> : <PiHouseBold />}
					</IconWrapper>
					{t('home')}
				</NavItem>
				<NavItem
					isActive={activeLink === '/bookmarks'}
					onClick={() => handleNavigation('/bookmarks')}
				>
					<IconWrapper>
						{activeLink === '/bookmarks' ? (
							<PiBookmarkSimpleFill />
						) : (
							<PiBookmarkSimpleBold />
						)}
					</IconWrapper>
					{t('bookmarks')}
				</NavItem>
				{isAuthenticated && (
					<NavItem onClick={handleOpenCreatePostModal}>
						<IconWrapper>
							<CreatePostIcon />
						</IconWrapper>
					</NavItem>
				)}
				{isAuthenticated && (
					<NavItem
						isActive={activeLink === '/notifications'}
						onClick={() => handleNavigation('/notifications')}
					>
						<IconWrapper>
							{activeLink === '/notifications' ? (
								<PiBellFill />
							) : (
								<PiBellBold />
							)}
							{unreadCount > 0 && (
								<Badge>{unreadCount > 99 ? '99+' : unreadCount}</Badge>
							)}
						</IconWrapper>
						{t('notifications')}
					</NavItem>
				)}
				<NavItem
					isActive={activeLink === '/profile'}
					onClick={() => handleNavigation('/profile')}
				>
					<IconWrapper>
						{activeLink === '/profile' ? <PiUserFill /> : <PiUserBold />}
					</IconWrapper>
					{isAuthenticated ? t('profile') : t('login')}
				</NavItem>
			</NavbarWrapper>

			<SignupModal
				show={showSignupModal}
				handleClose={handleCloseSignupModal}
				isMobile={true}
				openLoginModal={openLoginModal}
			/>
			<LoginModal
				show={showLoginModal}
				handleClose={handleCloseLoginModal}
				openSignupModal={openSignupModal}
			/>
			<CreatePostModal
				isOpen={showCreatePostModal}
				onClose={handleCloseCreatePostModal}
			/>
		</>
	)
}

export default MobileNavbar
