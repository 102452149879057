// src/components/Header/Header.jsx

import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {
	showSignupModal,
	hideSignupModal,
	showLoginModal,
	hideLoginModal,
	showVerificationModal,
} from '../../actions/authActions'
import CreatePostModal from '../Post/CreatePostModal'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import SearchBar from '../Search/SearchBar'
import { useNavigate, useLocation } from 'react-router-dom'
import LanguageModal from './LanguageModal'
import LoginModal from '../Login/LoginModal'
import ForgotPasswordModal from '../Login/ForgotPasswordModal'
import SignupModal from '../Signup/SignupModal'
import VerifyModal from '../Signup/VerifyModal'

import StyledButton from '../Button/StyledButton'
import CreatePostButton from '../Button/CreatePostButton'
import { hoverIconStyle } from '../../styles/commonStyles'

import { ReactComponent as RitatripLogo } from '../../Ritatrip_logo.svg'
import WavePattern from '../../Japanese_Wave_Pattern.svg'
import { RiSearchLine } from 'react-icons/ri'
import { PiGlobeSimple } from 'react-icons/pi'
import { FaArrowLeft } from 'react-icons/fa'
import { PiGearSixBold } from 'react-icons/pi'

const HeaderWrapper = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 32px;
	color: #262626;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	border-bottom: 1px solid #ecf0f1;
	background-color: rgba(255, 255, 255, 0.9);
	background-image: url(${WavePattern});
	background-position: center;
	z-index: 900;
	transition: background-color 0.3s;

	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		flex-direction: flex-start;
		padding: 12px 0px;
	}
`

const HeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	
	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		justify-content: flex-start; 
`

const TopRow = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: flex-start;
`

const SiteTitleContainer = styled.div`
	flex: 0 0 auto;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	min-width: 150px;

	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		min-width: 200px;
	}
`

const StyledLogo = styled(RitatripLogo)`
	cursor: pointer;

	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		height: 36px;
		width: 120px;
		margin-left: 8px;
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
		height: 44px;
		width: 147px;
		margin-left: 20px;
	}
`

const StyledBackIcon = styled(FaArrowLeft)`
	cursor: pointer;
	margin-right: 0px;
	font-size: 20px;
`

const BackIconWrapper = styled.div`
	${hoverIconStyle}
	display: flex;
	align-items: center;
	margin-left: 10px;
	cursor: pointer;
`

const SearchBarContainer = styled.div`
	flex: 0 0 auto;
	display: flex;

	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		display: ${({ $show }) => ($show ? 'flex' : 'none')};
		width: 100%;
		margin-left: 10px;
		margin-right: 10px;
		width: 80%;
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
		margin-left: 180px;
		margin-right: 40px;
		width: 45%;
		justify-content: center;
	}
`

const SearchIconContainer = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: 1.4rem;
	margin-right: 20px;

	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		margin-left: auto;
		margin-right: 0px;
	}
`

const LanguageIcon = styled.span`
	${() => hoverIconStyle}
	cursor: pointer;
	justify-content: flex-end;
	display: flex;

	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		font-size: 1.5rem;
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
		margin-right: 50px;
		font-size: 1.4rem;
	}
`

const ButtonGroupContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-right: 10px;

	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		display: none;
	}
`

const ButtonGroup = styled.div`
	display: flex;
	gap: 1rem;
`

const DesktopCreatePostButton = styled(CreatePostButton)`
	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		display: none;
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
		margin-right: 140px;
	}
`

const SettingsIcon = styled(PiGearSixBold)`
	font-size: 22px;
	display: flex;
	justify-content: flex-end;
`

const MobileIconsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;

	/* アイコンの数に応じて間隔を調整 */
	${({ iconCount }) =>
		iconCount === 2
			? `
		gap: 26px;
	`
			: `
		gap: 26px;
	`}

	@media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
		display: none;
	}

	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		margin-right: 14px;
	}
`

const Header = React.memo(({ searchBarHashtag }) => {
	const navigate = useNavigate()
	const location = useLocation()
	const dispatch = useDispatch()
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
	const { t } = useTranslation()

	const searchKeyword = t('keywordSearch')

	const [showCreatePostModal, setShowCreatePostModal] = useState(false)
	const [showLanguageModal, setShowLanguageModal] = useState(false)

	const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false)
	const showVerification = useSelector(
		(state) => state.auth.showVerificationModal
	)
	const isLoginModalOpen = useSelector((state) => state.auth.isLoginModalOpen)
	const isSignupModalOpen = useSelector((state) => state.auth.isSignupModalOpen)

	// モバイル用設定
	const [showMobileSearchBar, setShowMobileSearchBar] = useState(false)
	const isDesktop = useMediaQuery({ minWidth: 992 })

	const isSearchPage = location.pathname === '/search'

	useEffect(() => {
		if (isSearchPage || searchBarHashtag) {
			setShowMobileSearchBar(true)
		}
	}, [isSearchPage, searchBarHashtag])

	const toggleMobileSearchBar = useCallback(() => {
		setShowMobileSearchBar((prev) => !prev)
	}, [])

	// const toggleMobileSearchBar = () =>
	// 	setShowMobileSearchBar(!showMobileSearchBar)

	const handleTitleClick = () => {
		navigate('/')
		window.scrollTo(0, 0)
	}

	const handleBackClick = useCallback(() => {
		if (isSearchPage) {
			navigate('/')
		}
		setShowMobileSearchBar(false)
	}, [isSearchPage, navigate])

	const handleSettingsClick = useCallback(() => {
		navigate('/settings')
	}, [navigate])

	const handleOpenCreatePostModal = useCallback(() => {
		setShowCreatePostModal(true)
	}, [])

	const handleCloseCreatePostModal = useCallback(() => {
		setShowCreatePostModal(false)
	}, [])

	const handleCloseLoginModal = useCallback(() => {
		dispatch(hideLoginModal())
	}, [dispatch])

	const handleCloseSignupModal = useCallback(() => {
		dispatch(hideSignupModal())
	}, [dispatch])

	const openLoginModal = useCallback(() => {
		dispatch(showLoginModal())
	}, [dispatch])

	const closeLoginModal = useCallback(() => {
		dispatch(hideLoginModal())
	}, [dispatch])

	const openSignupModal = useCallback(() => {
		dispatch(showSignupModal())
	}, [dispatch])

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search)
		if (queryParams.get('loginModal') === 'open') {
			openLoginModal()
		}
	}, [openLoginModal])

	const handleShowForgotPasswordModal = useCallback(() => {
		closeLoginModal()
		setShowForgotPasswordModal(true)
	}, [closeLoginModal])

	const handleCloseForgotPasswordModal = useCallback(() => {
		setShowForgotPasswordModal(false)
	}, [])

	const handleChangeLanguage = useCallback((language) => {
		setShowLanguageModal(false)
	}, [])

	const renderDesktopHeader = () => (
		<>
			<SiteTitleContainer onClick={handleTitleClick}>
				<StyledLogo aria-label="Ritatrip Logo" />
			</SiteTitleContainer>

			<SearchBarContainer $show={true}>
				<SearchBar text={searchKeyword} searchBarHashtag={searchBarHashtag} />
			</SearchBarContainer>

			<LanguageIcon onClick={() => setShowLanguageModal(true)}>
				<PiGlobeSimple />
			</LanguageIcon>

			{!isAuthenticated && (
				<ButtonGroupContainer>
					<ButtonGroup>
						<StyledButton variant="outline-primary" onClick={openLoginModal}>
							{t('login')}
						</StyledButton>
						<StyledButton variant="primary" onClick={openSignupModal}>
							{t('signup')}
						</StyledButton>
					</ButtonGroup>
				</ButtonGroupContainer>
			)}

			{isAuthenticated && (
				<DesktopCreatePostButton onClick={handleOpenCreatePostModal}>
					{t('createPost')}
				</DesktopCreatePostButton>
			)}
		</>
	)

	const renderMobileHeader = () => (
		<>
			{showMobileSearchBar ? (
				<>
					<BackIconWrapper onClick={handleBackClick}>
						<StyledBackIcon />
					</BackIconWrapper>
					<SearchBarContainer $show={true}>
						<SearchBar
							text={searchKeyword}
							searchBarHashtag={searchBarHashtag}
						/>
					</SearchBarContainer>
				</>
			) : (
				<>
					<SiteTitleContainer onClick={handleTitleClick}>
						<StyledLogo aria-label="Ritatrip Logo" />
					</SiteTitleContainer>

					<MobileIconsContainer>
						<SearchIconContainer onClick={toggleMobileSearchBar}>
							<RiSearchLine />
						</SearchIconContainer>

						<LanguageIcon onClick={() => setShowLanguageModal(true)}>
							<PiGlobeSimple />
						</LanguageIcon>

						{isAuthenticated && <SettingsIcon onClick={handleSettingsClick} />}
					</MobileIconsContainer>
				</>
			)}
		</>
	)

	return (
		<HeaderWrapper>
			<HeaderContainer>
				<TopRow>
					{isDesktop ? renderDesktopHeader() : renderMobileHeader()}
				</TopRow>
			</HeaderContainer>

			<CreatePostModal
				isOpen={showCreatePostModal}
				onClose={handleCloseCreatePostModal}
			/>

			<LanguageModal
				isOpen={showLanguageModal}
				onClose={() => setShowLanguageModal(false)}
				onChangeLanguage={handleChangeLanguage}
			/>

			<LoginModal
				show={isLoginModalOpen}
				handleClose={handleCloseLoginModal}
				onForgotPassword={handleShowForgotPasswordModal}
			/>
			<ForgotPasswordModal
				show={showForgotPasswordModal}
				handleClose={handleCloseForgotPasswordModal}
				onBackToLogin={() => {
					setShowForgotPasswordModal(false)
					openLoginModal(true)
				}}
			/>
			<SignupModal
				show={isSignupModalOpen}
				handleClose={handleCloseSignupModal}
			/>
			{showVerification && (
				<VerifyModal
					show={showVerification}
					onHide={() => dispatch(showVerificationModal(false))}
				/>
			)}
		</HeaderWrapper>
	)
})

export default Header
