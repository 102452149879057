// src/components/Profile/UserProfile.jsx

import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchUserProfileAction } from '../../actions/userActions'
import { fetchUserPostsAction } from '../../actions/postAction'
import { fetchUserCommentsAction } from '../../actions/commentActions'
import { fetchUserDraftAction } from '../../actions/draftAction'
import MainLayout from '../Layout/MainLayout'
import ProfileEditForm from './ProfileEditForm'
import LoadingSpinner from '../Common/LoadingSpinner'

import useInfiniteScroll from '../../hooks/useInfiniteScroll'
import ProfileHeader from './ProfileHeader'
import ProfileContent from './ProfileContent'
import ActivityContent from './ActivityContent'

const UserProfile = React.memo(() => {
	const dispatch = useDispatch()
	const { userId: targetUserId } = useParams()

	const currentUserId = useSelector((state) => state.auth.userId, shallowEqual)
	const profileUser = useSelector(
		(state) => state.user.profileUser,
		shallowEqual
	)
	const posts =
		useSelector((state) => state.posts?.profilePosts, shallowEqual) || []
	const comments =
		useSelector((state) => state.comments?.profileComments, shallowEqual) || []
	const drafts =
		useSelector((state) => state.drafts?.profileDrafts, shallowEqual) || []
	const postCount =
		useSelector((state) => state.posts.totalProfilePosts, shallowEqual) || 0
	const commentCount =
		useSelector((state) => state.comments.totalProfileComments, shallowEqual) ||
		0
	const draftCount =
		useSelector((state) => state.drafts?.totalProfileDrafts, shallowEqual) || 0
	const profileLastUpdated = useSelector(
		(state) => state.user.lastUpdated,
		shallowEqual
	)
	const postsLastUpdated = useSelector(
		(state) => state.posts.lastUpdated,
		shallowEqual
	)

	const postDeleted = useSelector(
		(state) => state.posts?.deleteSuccess,
		shallowEqual
	)
	const draftDeleted = useSelector(
		(state) => state.drafts?.draftDeleted,
		shallowEqual
	)
	const isCurrentUserProfile = Number(currentUserId) === Number(targetUserId)

	const [isLoading, setIsLoading] = useState(false)
	const [isLoadingPosts, setIsLoadingPosts] = useState(true)
	const [isInitialPostsLoaded, setIsInitialPostsLoaded] = useState(false)
	const [modalIsOpen, setModalIsOpen] = useState(false)

	const openModal = useCallback(() => setModalIsOpen(true), [])
	const handleCloseModal = useCallback(() => setModalIsOpen(false), [])

	const [isGetPost, setIsGetPost] = useInfiniteScroll(getMorePosts)
	const [isGetComment, setIsGetComment] = useInfiniteScroll(getMoreComments)
	const [isGetDrafts, setIsGetDrafts] = useInfiniteScroll(getMoreDrafts)

	const [error, setError] = useState(null)

	function getMorePosts() {
		const userProfileId = targetUserId ? targetUserId : currentUserId
		const offset = posts.length
		return dispatch(fetchUserPostsAction(userProfileId, offset))
	}

	function getMoreComments() {
		const userProfileId = targetUserId ? targetUserId : currentUserId
		const offset = comments.length
		return dispatch(fetchUserCommentsAction(userProfileId, offset))
	}

	function getMoreDrafts() {
		const userProfileId = targetUserId ? targetUserId : currentUserId
		const offset = drafts.length
		return dispatch(fetchUserDraftAction(userProfileId, offset))
	}

	const fetchUserProfile = useCallback(() => {
		if (targetUserId) {
			setIsLoading(true)
			dispatch(fetchUserProfileAction(targetUserId))
				.then(() => setIsLoading(false))
				.catch((error) => {
					setIsLoading(false)
					setError(error.message)
				})
		}
	}, [dispatch, targetUserId])

	const fetchUserPosts = useCallback(() => {
		if (targetUserId) {
			setIsLoadingPosts(true)
			Promise.all([
				dispatch(fetchUserPostsAction(targetUserId, 0)),
				dispatch(fetchUserCommentsAction(targetUserId, 0)),
				dispatch(fetchUserDraftAction(targetUserId, 0)),
			]).finally(() => {
				setIsLoadingPosts(false)
				setIsInitialPostsLoaded(true)
			})
		}
	}, [targetUserId, dispatch])

	useEffect(() => {
		fetchUserProfile()
	}, [fetchUserProfile])

	useEffect(() => {
		fetchUserPosts()
	}, [fetchUserPosts])

	const handlePostDeleted = useCallback(() => {
		if (postDeleted) {
			dispatch(fetchUserPostsAction(profileUser.id, 0))
		}
	}, [postDeleted, dispatch, profileUser.id])

	const handleDraftDeleted = useCallback(() => {
		if (draftDeleted) {
			dispatch(fetchUserDraftAction(currentUserId, 0))
		}
	}, [draftDeleted, dispatch, currentUserId])

	useEffect(() => {
		handlePostDeleted()
	}, [handlePostDeleted])

	useEffect(() => {
		handleDraftDeleted()
	}, [handleDraftDeleted])

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<MainLayout>
			{!isLoading ? (
				<>
					<ProfileHeader
						isCurrentUserProfile={isCurrentUserProfile}
						openModal={openModal}
						username={profileUser?.username}
						avatar={profileUser?.avatar}
					/>
					<ProfileContent user={profileUser} />

					<ActivityContent
						user={profileUser}
						currentUserId={currentUserId}
						postCount={postCount}
						commentCount={commentCount}
						draftCount={draftCount}
						posts={posts}
						comments={comments}
						drafts={drafts}
						isLoading={isLoading}
						isLoadingPosts={isLoadingPosts}
					></ActivityContent>
				</>
			) : (
				<LoadingSpinner />
			)}

			{modalIsOpen && (
				<ProfileEditForm
					user={profileUser}
					isOpen={modalIsOpen}
					handleCloseModal={handleCloseModal}
				/>
			)}
		</MainLayout>
	)
})

export default UserProfile
