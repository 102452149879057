// src/redux/actions/modalActions.js

export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'

export const OPEN_IMAGE_MODAL = 'OPEN_IMAGE_MODAL'
export const CLOSE_IMAGE_MODAL = 'CLOSE_IMAGE_MODAL'
export const SET_ACTIVE_IMAGES = 'SET_ACTIVE_IMAGES'

export const OPEN_LIKE_MODAL = 'OPEN_LIKE_MODAL'
export const CLOSE_LIKE_MODAL = 'CLOSE_LIKE_MODAL'

export const OPEN_REPORT_MODAL = 'OPEN_REPORT_MODAL'
export const CLOSE_REPORT_MODAL = 'CLOSE_REPORT_MODAL'

export const openModal = () => ({
	type: OPEN_MODAL,
})

export const closeModal = () => ({
	type: CLOSE_MODAL,
})

export const openImageModal = () => ({
	type: OPEN_IMAGE_MODAL,
})
export const closeImageModal = () => ({
	type: CLOSE_IMAGE_MODAL,
})
export const setActiveImages = (images, startIndex) => ({
	type: SET_ACTIVE_IMAGES,
	payload: { images, startIndex },
})

export const openLikeModal = () => ({
	type: OPEN_LIKE_MODAL,
})
export const closeLikeModal = () => ({
	type: CLOSE_LIKE_MODAL,
})

export const openReportModal = () => ({
	type: OPEN_REPORT_MODAL,
})

export const closeReportModal = () => ({
	type: CLOSE_REPORT_MODAL,
})
