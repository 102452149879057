// src/components/Comment/SVGConnector.jsx

import React from 'react'
import styled from 'styled-components'

const INDENT_WIDTH = 28

const StyledSVGConnector = styled.svg`
	position: absolute;
	top: 0;
	left: 0;
	width: ${INDENT_WIDTH}px;
	height: 100%;
	pointer-events: none;
	overflow: visible;
`

const SVGConnector = ({ connectorPaths }) => {
	if (!connectorPaths || connectorPaths.length === 0) {
		return null
	}

	return (
		<StyledSVGConnector>
			{connectorPaths.map((path, index) => (
				<path
					key={index}
					d={path}
					fill="none"
					stroke="#ecf0f1"
					strokeWidth="1.5"
				/>
			))}
		</StyledSVGConnector>
	)
}

export default SVGConnector
