import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { loginUser, clearError } from '../../actions/authActions'
import { validateEmail, validatePassword } from '../../utils/validation'
import styled from 'styled-components'
import StyledButton from '../Button/StyledButton'
import { hoverIconStyle } from '../../styles/commonStyles'
import ForgotPasswordModal from './ForgotPasswordModal'

const StyledModal = styled(Modal)`
	.modal-dialog {
		max-width: 500px;
		.modal-content {
			min-height: 520px;
			padding: 20px;
		}
	}
`

const ModalHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 20px 0px 20px;
`

const Title = styled.div`
	font-size: ${({ theme }) => theme.fontSizes.extraLarge};
	font-weight: bold;
	text-align: center;
	flex-grow: 1;
`

const StyledFormGroup = styled(Form.Group)`
	margin-bottom: 40px;
`

const InputWrapper = styled.div`
	position: relative;
`

const StyledFormControl = styled(Form.Control)`
	height: 50px;
	padding: 12px;
	border: 1px solid
		${({ isInvalid, theme }) =>
			isInvalid ? theme.colors.danger : theme.colors.outlineGray};
	border-radius: 4px;
	font-size: ${({ theme }) => theme.fontSizes.regular};

	&:focus {
		box-shadow: none;
		border-color: ${({ isInvalid, theme }) =>
			isInvalid ? theme.colors.danger : theme.colors.black};
	}

	&:hover {
		border-color: ${({ isInvalid, theme }) =>
			isInvalid ? theme.colors.danger : theme.colors.darkGray};
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.colors.white} inset !important;
		-webkit-text-fill-color: ${({ theme }) => theme.colors.text} !important;
	}
`

const FloatingLabel = styled.label`
	position: absolute;
	left: 12px;
	top: ${({ isFocused, hasValue }) => (isFocused || hasValue ? '0px' : '50%')};
	transform: ${({ isFocused, hasValue }) =>
		isFocused || hasValue
			? 'translateY(-50%) scale(0.85)'
			: 'translateY(-50%)'};
	transition: all 0.1s ease-out;
	color: ${({ isFocused, theme }) =>
		isFocused ? theme.colors.black : theme.colors.text};
	background-color: ${({ theme }) => theme.colors.white};
	padding: 0 4px;
	pointer-events: none;
`

const CustomFeedback = styled(Form.Control.Feedback)`
	display: block;
	font-size: ${({ theme }) => theme.fontSizes.small};
`

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 30px;
`

const StyledLoginButton = styled(StyledButton)`
	border-radius: 50px;
	width: 30%;
	padding: 10px;
	display: flex;
	justify-content: center; /* 横方向の中央揃え */
	align-items: center; /* 縦方向の中央揃え */
`

const CloseButton = styled.button`
	${hoverIconStyle}
	background: none;
	border: none;
	cursor: pointer;
`

const ForgotPasswordLink = styled.span`
	color: ${({ theme }) => theme.colors.link};
	cursor: pointer;
	font-size: ${({ theme }) => theme.fontSizes.small};
	&:hover {
		text-decoration: underline;
	}
`

const PasswordCheckboxWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-top: 16px;
`

const PasswordCheckbox = styled.input`
	appearance: none;
	width: 18px;
	height: 18px;
	border: 2px solid ${({ theme }) => theme.colors.outlineGray};
	border-radius: 3px;
	margin-right: 8px;
	cursor: pointer;
	position: relative;
	transition: all 0.2s ease-in-out;

	&:checked {
		background-color: ${({ theme }) => theme.colors.black};
		border-color: ${({ theme }) => theme.colors.black};
	}

	&:checked::after {
		content: '✓';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: white;
		font-size: ${({ theme }) => theme.fontSizes.small};
	}

	&:focus {
		outline: none;
	}

	&:hover {
		border-color: ${({ theme }) => theme.colors.black};
	}
`

const PasswordCheckboxLabel = styled.label`
	font-size: ${({ theme }) => theme.fontSizes.small};
	color: ${({ theme }) => theme.colors.text};
`

const LoginModal = ({ show, handleClose, onForgotPassword }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { t } = useTranslation()
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
	const authError = useSelector((state) => state.auth.error)

	const [formData, setFormData] = useState({ email: '', password: '' })
	const [formErrors, setFormErrors] = useState({
		email: '',
		password: '',
		auth: '',
	})
	const [showPassword, setShowPassword] = useState(false)
	const [focusedField, setFocusedField] = useState(null)
	const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false)

	const handleCloseMemoized = useCallback(handleClose, [handleClose])

	useEffect(() => {
		dispatch(clearError())
		if (isAuthenticated && show) {
			handleCloseMemoized()
			navigate('/')
		}
	}, [
		dispatch,
		isAuthenticated,
		navigate,
		authError,
		handleCloseMemoized,
		show,
		t,
	])

	const validateField = (name, value) => {
		const validators = { email: validateEmail, password: validatePassword }
		return validators[name] ? t(validators[name](value) || '') : ''
	}

	const handleInputChange = (e) => {
		const { name, value } = e.target
		setFormData((prev) => ({ ...prev, [name]: value }))
		setFormErrors((prev) => ({
			...prev,
			[name]: validateField(name, value),
			auth: '',
		}))
	}

	const handleLogin = async (e) => {
		e.preventDefault()
		dispatch(clearError())
		const errors = {
			email: validateField('email', formData.email),
			password: validateField('password', formData.password),
		}
		if (Object.values(errors).every((error) => !error)) {
			await dispatch(loginUser(formData.email, formData.password))
		} else {
			setFormErrors((prev) => ({ ...prev, ...errors }))
		}
	}

	const togglePasswordVisibility = (e) => {
		e.preventDefault() // フォーム送信を防ぐ
		setShowPassword((prev) => !prev)
	}

	const handlePasswordVisibilityChange = (e) => {
		setShowPassword(e.target.checked)
	}

	const handleForgotPasswordClick = (e) => {
		e.preventDefault()
		setShowForgotPasswordModal(true)
	}

	const handleForgotPasswordClose = () => {
		setShowForgotPasswordModal(false)
	}

	return (
		<>
			<StyledModal show={show} centered backdrop="static" keyboard={false}>
				<ModalHeader>
					<CloseButton onClick={handleClose}>✕</CloseButton>
					<Title>{t('login')}</Title>
					<div style={{ width: '40px' }} />
				</ModalHeader>
				<Modal.Body>
					<Form onSubmit={handleLogin}>
						{['email', 'password'].map((field) => (
							<StyledFormGroup key={field}>
								<InputWrapper>
									<StyledFormControl
										type={
											field === 'password'
												? showPassword
													? 'text'
													: 'password'
												: 'text'
										}
										name={field}
										value={formData[field]}
										onChange={handleInputChange}
										onFocus={() => setFocusedField(field)}
										onBlur={() => setFocusedField(null)}
										isInvalid={!!formErrors[field]}
										autoComplete={
											field === 'password' ? 'current-password' : 'off'
										}
									/>
									<FloatingLabel
										isFocused={focusedField === field}
										hasValue={formData[field].length > 0}
									>
										{t(field)}
									</FloatingLabel>
								</InputWrapper>
								{formErrors[field] && (
									<CustomFeedback type="invalid">
										{formErrors[field]}
									</CustomFeedback>
								)}
								{field === 'password' && (
									<PasswordCheckboxWrapper>
										<PasswordCheckbox
											type="checkbox"
											id="showPassword"
											checked={showPassword}
											onChange={handlePasswordVisibilityChange}
										/>
										<PasswordCheckboxLabel htmlFor="showPassword">
											{t('showPassword')}
										</PasswordCheckboxLabel>
									</PasswordCheckboxWrapper>
								)}
							</StyledFormGroup>
						))}
						{formErrors.auth && (
							<div className="text-danger">{formErrors.auth}</div>
						)}
						<ForgotPasswordLink onClick={handleForgotPasswordClick}>
							{t('forgotPassword')}
						</ForgotPasswordLink>
						<ButtonContainer>
							<StyledLoginButton variant="primary" type="submit">
								{t('login')}
							</StyledLoginButton>
						</ButtonContainer>
					</Form>
				</Modal.Body>
			</StyledModal>
			<ForgotPasswordModal
				show={showForgotPasswordModal}
				handleClose={handleForgotPasswordClose}
				onBackToLogin={() => {
					handleForgotPasswordClose()
				}}
			/>
		</>
	)
}

export default LoginModal
