// src/services/authApi.js

const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const registerUserApi = (userData) => {
	return fetch(`${BASE_URL}/registerUser`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(userData),
		credentials: 'include',
	})
		.then((response) => {
			if (!response.ok) {
				return response.text().then((text) => {
					const error = text
						? JSON.parse(text)
						: { message: response.statusText }
					throw error
				})
			}
			return response.json()
		})
		.catch((error) => {
			console.error('Error during fetch:', error)
			throw error
		})
}

export const resendVerificationCodeApi = async (email) => {
	const response = await fetch(`${BASE_URL}/sendVerificationCode`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ email }),
		credentials: 'include',
	})

	if (!response.ok) {
		throw new Error(`HTTP error! Status: ${response.status}`)
	}

	return await response.json()
}

export const verifyCheckApi = async (code, userId) => {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ code, userId }),
		credentials: 'include', // クッキーをリクエストに含める
	}

	const response = await fetch(`${BASE_URL}/verifycheck`, requestOptions)

	if (!response.ok) {
		throw new Error(`HTTP error! Status: ${response.status}`)
	}

	const data = await response.json()

	if (data.status === 'success') {
		// 成功した場合、userIdとusernameを含むデータを返す
		return {
			status: data.status,
			userId: data.data.userId,
			username: data.data.username,
		}
	} else {
		// 失敗した場合はエラーメッセージを含むレスポンスを返す
		return { status: data.status, message: data.message }
	}
}

export const loginAPI = async (loginData) => {
	const response = await fetch(`${BASE_URL}/submitLogin`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			// 'X-CSRF-TOKEN': csrfToken,
		},
		body: JSON.stringify(loginData),
		credentials: 'include', // HTTP Only Cookieを使用する場合
	})

	if (!response.ok) {
		const errorData = await response.json()
		throw new Error(errorData.errorKey || 'unknown_error')
	}

	const data = await response.json()
	return data
}

export const checkTokenValidity = async () => {
	const token = localStorage.getItem('token')
	const response = await fetch(`${BASE_URL}/verifyToken`, {
		method: 'GET',
		headers: {
			Authorization: token,
		},
		credentials: 'include',
	})

	if (!response.ok) {
		throw new Error('Network response was not ok')
	}

	const responseData = await response.json()

	return responseData
}

export const resetPasswordUrlRequestApi = async (email) => {
	// const BASE_URL = process.env.REACT_APP_API_BASE_URL;
	const response = await fetch(`${BASE_URL}/resetPasswordUrlSend`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ email }),
		credentials: 'include',
	})

	if (!response.ok) {
		throw new Error(`HTTP error! Status: ${response.status}`)
	}

	return await response.json()
}

export const resetPasswordApi = async (password, confirmPassword, token) => {
	const response = await fetch(`${BASE_URL}/resetPassword`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			// トークンをAuthorizationヘッダーに追加します。
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify({ password, confirm_password: confirmPassword }),
		credentials: 'include',
	})

	if (!response.ok) {
		throw new Error(`HTTP error! Status: ${response.status}`)
	}

	return await response.json()
}

// ログアウトAPIのエンドポイントへのリクエストを行う関数
export const logoutApi = async () => {
	try {
		const response = await fetch(`${BASE_URL}/logout`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
		})

		if (response.ok) {
			const result = await response.json()
			return { success: true, message: result.message }
		} else {
			const errorData = await response.json()

			return { success: false, error: errorData }
		}
	} catch (error) {
		console.error('Logout error:', error)
		return { success: false, error: error.message }
	}
}

export const checkAuthStatusApi = async () => {
	try {
		const response = await fetch(`${BASE_URL}/auth/check`, {
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
		})
		if (!response.ok) {
			throw new Error('Auth check failed: ' + response.statusText)
		}
		const data = await response.json()
		return data
	} catch (error) {
		console.error('Error fetching auth status:', error)
		throw error
	}
}

export const closeAccountApi = async (password) => {
	// Assuming the endpoint for closing an account is '/closeAccount'
	const response = await fetch(`${BASE_URL}/closeAccount`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ password }),
		credentials: 'include', // Include credentials if necessary
	})

	if (!response.ok) {
		const errorData = await response.json()
		throw new Error(errorData.errorKey || 'Account closure failed')
	}

	return await response.json()
}
