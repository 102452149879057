// src/components/Signup/SignupModal.jsx

import React, { useState } from 'react'
import CONFIG from '../../config'
import { useDispatch } from 'react-redux'
import { registerUser, showVerificationModal } from '../../actions/authActions'
import { useTranslation, Trans } from 'react-i18next'
import { Modal, Form, InputGroup } from 'react-bootstrap'
import {
	validateUsername,
	validateEmail,
	validatePassword,
} from '../../utils/validation'
import iconEyeOpen from '../../assets/icon/eye-open.svg'
import iconEyeClose from '../../assets/icon/eye-close.svg'
import styled from 'styled-components'
import StyledButton from '../Button/StyledButton'
import { hoverIconStyle } from '../../styles/commonStyles'

const StyledModal = styled(Modal)`
	.modal-dialog {
		max-width: 500px; // モーダルの幅を調整
		.modal-content {
			min-height: 520px; // 最小高さを指定
		}
	}
	.modal-content {
		padding: 20px; // モーダル内のパディングを追加
	}
`

const ModalHeader = styled.div`
	display: flex;
	align-items: center;
	position: relative
	padding: 0 20px;
`

const Title = styled.div`
	font-size: ${({ theme }) => theme.fontSizes.extraLarge};
	font-weight: bold;
	margin: 20px auto;
	text-align: center;
`

const StyledFormGroup = styled(Form.Group)`
	font-size: ${({ theme }) => theme.fontSizes.medium};
	margin-bottom: 30px;
	.form-label {
		font-size: ${({ theme }) => theme.fontSizes.small};
		font-weight: bold !important;
	}
	.form-control {
		height: 50px;
		padding: 6px 12px;
		&:focus {
			box-shadow: none !important;
		}
	}
`

const CustomFeedback = styled(Form.Control.Feedback)`
	font-size: ${({ theme }) => theme.fontSizes.small};
	margin-top: 0.25rem;
	display: block;
`

const CloseButton = styled.button`
	${hoverIconStyle}
	background: none;
	border: none;
	cursor: pointer;
`

const TermsSection = styled.div`
	font-size: ${({ theme }) => theme.fontSizes.tiny};
	margin: 20px 0 30px;
	text-align: left;
`

const TermsLink = styled.a`
	color: #007bff;
	text-decoration: none;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
`

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end; /* これで子要素を右寄せ */
`

const StyledSignupButton = styled(StyledButton)`
	&&& {
		border-radius: 50px !important;
		width: 30% !important;
		padding: 10px !important;

		&:focus {
			box-shadow: none !important;
		}
	}
`

const DummyElement = styled.div`
	width: 40px;
	height: 40px;
	opacity: 0;
`

const LoginLink = styled.div`
	color: ${({ theme }) => theme.colors.text};
	font-size: ${({ theme }) => theme.fontSizes.medium};
	margin-top: 32px;
	text-align: left;
`

const LoginTextLink = styled.span`
	color: ${({ theme }) => theme.colors.link};
	cursor: pointer;
`

const InputWrapper = styled.div`
	position: relative;
`

const FloatingLabel = styled.label`
	position: absolute;
	left: 12px;
	top: ${(props) => (props.isFocused || props.hasValue ? '0px' : '50%')};
	transform: ${(props) =>
		props.isFocused || props.hasValue
			? 'translateY(-50%) scale(0.85)'
			: 'translateY(-50%)'};
	transition: all 0.1s ease-out;
	color: ${(props) =>
		props.isFocused ? props.theme.colors.black : props.theme.colors.text};
	background-color: ${(props) => props.theme.colors.white};
	padding: 0 4px;
	pointer-events: none;
	z-index: 1;
`

const StyledFormControl = styled(Form.Control)`
	height: 50px;
	padding: 12px;
	border: 1px solid
		${(props) =>
			props.isInvalid
				? props.theme.colors.danger
				: props.theme.colors.outlineGray};
	border-radius: 4px;
	transition: border-color 0.1s ease-in-out;
	font-size: ${(props) => props.theme.fontSizes.regular};
	line-height: 1.5;

	&:focus {
		box-shadow: none;
		border-color: ${(props) =>
			props.isInvalid ? props.theme.colors.danger : props.theme.colors.black};
	}

	&:hover {
		border-color: ${(props) =>
			props.isInvalid
				? props.theme.colors.danger
				: props.theme.colors.darkGray};
	}

	// オートフィル時の背景色を制御
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 30px ${(props) => props.theme.colors.white} inset !important;
		-webkit-text-fill-color: ${(props) => props.theme.colors.text} !important;
	}

	// Firefox用のオートフィル背景色制御
	&:autofill,
	&:autofill:hover,
	&:autofill:focus,
	&:autofill:active {
		box-shadow: 0 0 0 30px ${(props) => props.theme.colors.white} inset !important;
		-webkit-text-fill-color: ${(props) => props.theme.colors.text} !important;
	}
`

const PasswordInputWrapper = styled(InputWrapper)`
	.input-group {
		position: relative;
	}

	.form-control {
		padding-right: 40px; // アイコンのスペースを確保
	}
`

const PasswordToggle = styled(InputGroup.Text)`
	position: absolute;
	right: 18px;
	top: 0;
	bottom: 0;
	z-index: 2;
	background: transparent;
	border: none;
	cursor: pointer;
`

const PasswordCheckboxWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-top: 10px;
`

const PasswordCheckbox = styled.input`
	appearance: none;
	width: 18px;
	height: 18px;
	border: 2px solid ${({ theme }) => theme.colors.outlineGray};
	border-radius: 3px;
	margin-right: 8px;
	cursor: pointer;
	position: relative;
	transition: all 0.2s ease-in-out;

	&:checked {
		background-color: ${({ theme }) => theme.colors.black};
		border-color: ${({ theme }) => theme.colors.black};
	}

	&:checked::after {
		content: '✓';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: white;
		font-size: 12px;
	}

	&:focus {
		outline: none;
	}

	&:hover {
		border-color: ${({ theme }) => theme.colors.black};
	}
`

const PasswordCheckboxLabel = styled.label`
	font-size: ${({ theme }) => theme.fontSizes.small};
	color: ${({ theme }) => theme.colors.text};
	cursor: pointer;
	user-select: none;
`

const Signup = ({ show, handleClose, isMobile, openLoginModal }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [showPassword, setShowPassword] = useState(false)
	const [focusedField, setFocusedField] = useState(null)
	const [formData, setFormData] = useState({
		username: '',
		email: '',
		password: '',
	})
	const [formErrors, setFormErrors] = useState({
		username: '',
		email: '',
		password: '',
	})

	const validateField = (name, value) => {
		let error = ''
		switch (name) {
			case 'username':
				error = validateUsername(value) ? t(validateUsername(value)) : ''
				break
			case 'email':
				error = validateEmail(value) ? t(validateEmail(value)) : ''
				break
			case 'password':
				error = validatePassword(value) ? t(validatePassword(value)) : ''
				break
			default:
				break
		}
		return error
	}

	const handleInputChange = (e) => {
		const { name, value } = e.target
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}))

		// 入力値が変更されるたびにバリデーションを行い、エラーメッセージを更新
		const error = validateField(name, value)
		setFormErrors((prevErrors) => ({
			...prevErrors,
			[name]: error,
		}))
	}

	const handleSubmit = async (e) => {
		e.preventDefault()

		const username = e.target.username.value.trim()
		const email = e.target.email.value.trim()
		const password = e.target.password.value

		const usernameError = validateUsername(username)
			? t(validateUsername(username))
			: ''
		const emailError = validateEmail(email) ? t(validateEmail(email)) : ''
		const passwordError = validatePassword(password)
			? t(validatePassword(password))
			: ''

		const validationErrors = {
			...(usernameError && { username: usernameError }),
			...(emailError && { email: emailError }),
			...(passwordError && { password: passwordError }),
		}

		if (Object.keys(validationErrors).length > 0) {
			setFormErrors(validationErrors)
		} else {
			try {
				await dispatch(registerUser({ username, email, password }))
				dispatch(showVerificationModal(true))
				handleClose()
			} catch (error) {
				if (error.errors) {
					const newFormErrors = {
						username: error.errors.username ? t('usernameUnique') : '',
						email: error.errors.email ? t('emailUnique') : '',
						password: error.errors.password ? t('passwordRequirement') : '',
					}
					setFormErrors(newFormErrors)
				} else {
					console.error('Error in response:', error)
				}
			}
		}
	}

	const handleLoginClick = (e) => {
		e.preventDefault()
		handleClose()
		openLoginModal()
	}

	const handlePasswordVisibilityChange = (e) => {
		setShowPassword(e.target.checked)
	}

	return (
		<StyledModal show={show} centered backdrop="static" keyboard={false}>
			<ModalHeader>
				<CloseButton onClick={handleClose}>✕</CloseButton>
				<Title>{t('signup')}</Title>
				<DummyElement />
			</ModalHeader>
			<Modal.Body>
				<Form onSubmit={handleSubmit} noValidate>
					{/* Username field */}
					<StyledFormGroup controlId="username">
						<InputWrapper>
							<StyledFormControl
								type="text"
								name="username"
								value={formData.username}
								onChange={handleInputChange}
								onFocus={() => setFocusedField('username')}
								onBlur={() => setFocusedField(null)}
								isInvalid={!!formErrors.username}
							/>
							<FloatingLabel
								isFocused={focusedField === 'username'}
								hasValue={formData.username.length > 0}
							>
								{t('username')}
							</FloatingLabel>
						</InputWrapper>
						{formErrors.username && (
							<CustomFeedback type="invalid">
								{formErrors.username}
							</CustomFeedback>
						)}
					</StyledFormGroup>

					{/* Email field */}
					<StyledFormGroup controlId="email">
						<InputWrapper>
							<StyledFormControl
								type="email"
								name="email"
								value={formData.email}
								onChange={handleInputChange}
								onFocus={() => setFocusedField('email')}
								onBlur={() => setFocusedField(null)}
								isInvalid={!!formErrors.email}
							/>
							<FloatingLabel
								isFocused={focusedField === 'email'}
								hasValue={formData.email.length > 0}
							>
								{t('email')}
							</FloatingLabel>
						</InputWrapper>
						{formErrors.email && (
							<CustomFeedback type="invalid">{formErrors.email}</CustomFeedback>
						)}
					</StyledFormGroup>

					{/* Password field */}
					<StyledFormGroup controlId="password">
						<InputWrapper>
							<StyledFormControl
								type={showPassword ? 'text' : 'password'}
								name="password"
								value={formData.password}
								onChange={handleInputChange}
								onFocus={() => setFocusedField('password')}
								onBlur={() => setFocusedField(null)}
								isInvalid={!!formErrors.password}
							/>
							<FloatingLabel
								isFocused={focusedField === 'password'}
								hasValue={formData.password.length > 0}
							>
								{t('password')}
							</FloatingLabel>
						</InputWrapper>
						{formErrors.password && (
							<CustomFeedback type="invalid">
								{formErrors.password}
							</CustomFeedback>
						)}
						<PasswordCheckboxWrapper>
							<PasswordCheckbox
								type="checkbox"
								id="showPassword"
								checked={showPassword}
								onChange={handlePasswordVisibilityChange}
							/>
							<PasswordCheckboxLabel htmlFor="showPassword">
								{t('showPassword')}
							</PasswordCheckboxLabel>
						</PasswordCheckboxWrapper>
					</StyledFormGroup>

					<TermsSection>
						<Trans
							i18nKey="agreement"
							components={[
								<TermsLink
									href={CONFIG.TERMS_URL}
									target="_blank"
									rel="noopener noreferrer"
								>
									{t('termsOfService')}
								</TermsLink>,
								<TermsLink
									href={CONFIG.PRIVACY_POLICY_URL}
									target="_blank"
									rel="noopener noreferrer"
								>
									{t('privacyPolicy')}
								</TermsLink>,
								<TermsLink
									href={CONFIG.TIP_POLICY_URL}
									target="_blank"
									rel="noopener noreferrer"
								>
									{t('tipPolicy')}
								</TermsLink>,
							]}
						/>
					</TermsSection>

					<ButtonContainer>
						<StyledSignupButton variant="primary" type="submit">
							{t('nextButton')}
						</StyledSignupButton>
					</ButtonContainer>

					{isMobile && (
						<LoginLink>
							{t('alreadyHaveAccount')}{' '}
							<LoginTextLink onClick={handleLoginClick}>
								{t('login')}
							</LoginTextLink>
						</LoginLink>
					)}
				</Form>
			</Modal.Body>
		</StyledModal>
	)
}

export default Signup
