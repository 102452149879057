// src/components/HeroSection/HeroSection.jsx

import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { borderBottomStyle } from '../../styles/commonStyles'
import AboutRitatrip from '../Button/AboutRitatrip'
import WavePattern from '../../Japanese_Wave_Pattern.svg'

// import { ReactComponent as Love } from '../../assets/kanji/love.svg'
// import { ReactComponent as Beauty } from '../../assets/kanji/beauty.svg'
// import { ReactComponent as Dream } from '../../assets/kanji/dream.svg'
// import { ReactComponent as Ryu } from '../../assets/kanji/ryu.svg'
// import { ReactComponent as Shinobi } from '../../assets/kanji/shinobi.svg'
// import { ReactComponent as Sakura } from '../../assets/kanji/sakura.svg'

// import { ReactComponent as Aki } from '../../assets/kanji/Aki.svg'
// import { ReactComponent as Cha } from '../../assets/kanji/Cha.svg'
// import { ReactComponent as Fuyu } from '../../assets/kanji/Fuyu.svg'
// import { ReactComponent as Haru } from '../../assets/kanji/Haru.svg'
// import { ReactComponent as Katana } from '../../assets/kanji/Katana.svg'
// import { ReactComponent as Michi } from '../../assets/kanji/Michi.svg'
// import { ReactComponent as Natsu } from '../../assets/kanji/Natsu.svg'
// import { ReactComponent as Samurai } from '../../assets/kanji/Samurai.svg'
// import { ReactComponent as Tabi } from '../../assets/kanji/Tabi.svg'

// import { ReactComponent as Kage } from '../../assets/kanji/Kage.svg'
// import { ReactComponent as Kome } from '../../assets/kanji/Kome.svg'
// import { ReactComponent as Kotobuki } from '../../assets/kanji/Kotobuki.svg'
// import { ReactComponent as Matsuri } from '../../assets/kanji/Matsuri.svg'
// import { ReactComponent as Mukaeru } from '../../assets/kanji/Mukaeru.svg'
// import { ReactComponent as Sora } from '../../assets/kanji/Sora.svg'
// import { ReactComponent as Take } from '../../assets/kanji/Take.svg'
// import { ReactComponent as Wa } from '../../assets/kanji/Wa.svg'

const HeroWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 4rem 0;
	height: calc(500px);
	background-color: #f5f5f5;
	background-image: url(${WavePattern});
	background-size: cover;
	background-position: center;
	${(props) => borderBottomStyle(props.theme)}

	@media (min-width: 768px) {
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
`

const TextContainer = styled.div`
	text-align: start;

	@media (min-width: 768px) {
		width: 70%;
		margin: 70px 70px 0;
	}
`

const CharactersContainer = styled.div`
	display: flex;
	justify-content: flex-end;

	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		display: none;
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
		width: 30%;
	}
`

const MainTitle = styled.h2`
	margin-bottom: 1rem;
	font-weight: bold;
	font-size: ${({ theme }) => theme.fontSizes.title};
	margin: 80px 20px 0;

	@media (min-width: 768px) {
		font-size: ${({ theme }) => theme.fontSizes.titleLarge};
		margin: 0 0 40px;
	}
`

const SubTitle = styled.p`
	// font-weight: bold;
	font-size: ${({ theme }) => theme.fontSizes.extraLarge};
	margin: 30px 20px 0;

	@media (min-width: 768px) {
		font-size: ${({ theme }) => theme.fontSizes.subTitle};
		width: 80%;
	}
`

const FallingCharacter = styled.div`
	position: absolute;
	top: -220px;
	left: 74%;
	width: ${(props) => props.size}px;
	height: ${(props) => props.size}px;

	@keyframes fall {
		0% {
			top: -220px;
			opacity: 1;
		}
		40% {
			opacity: 0.6;
		}
		60% {
			opacity: 0;
		}
		100% {
			top: 90vh;
			opacity: 0;
		}
	}
`

// ローディングインジケータのスタイル
const LoadingIndicatorWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
`

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	margin-top: 40px;
	margin-left: 20px;

	@media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
		//
	}
`

const LoadingIndicator = () => (
	<LoadingIndicatorWrapper>{/* <p>Loading...</p> */}</LoadingIndicatorWrapper>
)

function getRandomSize(min, max, step) {
	const numberOfSteps = Math.floor((max - min) / step) + 1
	const randomStep = Math.floor(Math.random() * numberOfSteps)
	return min + randomStep * step
}

const HeroSection = () => {
	const { t } = useTranslation()
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
	const [randomSvgs, setRandomSvgs] = useState([])

	const svgRefs = useRef([])
	svgRefs.current = []

	const addToRefs = (el) => {
		if (el && !svgRefs.current.includes(el)) {
			svgRefs.current.push(el)
		}
	}

	// SVGリストをランダムに並び替える関数
	const shuffleArray = (array) => {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1))
			;[array[i], array[j]] = [array[j], array[i]] // 要素を交換
		}
	}

	// useEffect(() => {
	// 	// SVGリストをランダムに並び替え
	// 	const shuffledSvgs = [...svgs] // svgs配列のコピーを作成
	// 	shuffleArray(shuffledSvgs)
	// 	setRandomSvgs(shuffledSvgs)
	// }, []) // これはコンポーネントがマウントされた時にのみ実行されます。

	useEffect(() => {
		const startAnimation = (index = 0) => {
			if (svgRefs.current.length === 0) return // Refsが空なら何もしない

			const currentSvg = svgRefs.current[index % svgRefs.current.length]

			currentSvg.style.animation = 'none'
			requestAnimationFrame(() => {
				requestAnimationFrame(() => {
					currentSvg.style.animation = 'fall 20s linear forwards'
					setTimeout(() => startAnimation(index + 1), 7000)
				})
			})
		}

		startAnimation()
	}, [randomSvgs]) // randomSvgsが更新されるたびにこのeffectを実行します。

	// ヘルプセンターのURLを決定する
	const getHelpCenterUrl = () => {
		const userLang = navigator.language || navigator.userLanguage
		return userLang.startsWith('ja')
			? process.env.REACT_APP_ABOUT_RITATRIP_URL_JP
			: process.env.REACT_APP_ABOUT_RITATRIP_URL_EN
	}

	if (isAuthenticated === null) {
		return <LoadingIndicator />
	}

	if (isAuthenticated) {
		return null
	}

	// サイズ範囲を変数で管理
	const minSize = 200
	const maxSize = 300
	const stepSize = 20
	const delayIncrement = 6

	// const svgs = [
	// 	{ component: Love, delay: 0 },
	// 	{ component: Beauty, delay: delayIncrement * 1 },
	// 	{ component: Dream, delay: delayIncrement * 2 },
	// 	{ component: Ryu, delay: delayIncrement * 3 },
	// 	{ component: Shinobi, delay: delayIncrement * 4 },
	// 	{ component: Sakura, delay: delayIncrement * 5 },
	// 	{ component: Aki, delay: delayIncrement * 6 },
	// 	{ component: Cha, delay: delayIncrement * 7 },
	// 	{ component: Fuyu, delay: delayIncrement * 8 },
	// 	{ component: Haru, delay: delayIncrement * 9 },
	// 	{ component: Katana, delay: delayIncrement * 10 },
	// 	{ component: Michi, delay: delayIncrement * 11 },
	// 	{ component: Natsu, delay: delayIncrement * 12 },
	// 	{ component: Samurai, delay: delayIncrement * 13 },
	// 	{ component: Tabi, delay: delayIncrement * 14 },
	// 	{ component: Kage, delay: delayIncrement * 15 },
	// 	{ component: Kome, delay: delayIncrement * 16 },
	// 	{ component: Kotobuki, delay: delayIncrement * 17 },
	// 	{ component: Matsuri, delay: delayIncrement * 18 },
	// 	{ component: Mukaeru, delay: delayIncrement * 19 },
	// 	{ component: Sora, delay: delayIncrement * 20 },
	// 	{ component: Take, delay: delayIncrement * 21 },
	// 	{ component: Wa, delay: delayIncrement * 22 },
	// ]

	return (
		<HeroWrapper>
			<TextContainer>
				<MainTitle>{t('main_title')}</MainTitle>
				<SubTitle>{t('sub_title')}</SubTitle>
				<ButtonContainer>
					<AboutRitatrip
						onClick={() => window.open(getHelpCenterUrl(), '_blank')}
					>
						{t('aboutRitatrip')}
					</AboutRitatrip>
				</ButtonContainer>
			</TextContainer>
			<CharactersContainer>
				{randomSvgs.map((Svg, index) => (
					<FallingCharacter
						key={index}
						ref={addToRefs} // ここでref関数をSVGに適用
						size={getRandomSize(minSize, maxSize, stepSize)}
						// delay={index * delayIncrement}
					>
						<Svg.component
							width={`${getRandomSize(minSize, maxSize, stepSize)}px`}
							height={`${getRandomSize(minSize, maxSize, stepSize)}px`}
						/>
					</FallingCharacter>
				))}
			</CharactersContainer>
		</HeroWrapper>
	)
}

export default HeroSection
