// src/contexts/SnackbarContext.js

import React, { createContext, useContext, useState, useEffect } from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

const SnackbarContext = createContext()

export function useSnackbar() {
	return useContext(SnackbarContext)
}

export const SnackbarProvider = ({ children }) => {
	const [snackPack, setSnackPack] = useState([])
	const [open, setOpen] = useState(false)
	const [messageInfo, setMessageInfo] = useState(undefined)

	useEffect(() => {
		if (snackPack.length && !messageInfo) {
			setMessageInfo({ ...snackPack[0] })
			setSnackPack((prev) => prev.slice(1))
			setOpen(true)
		}
	}, [snackPack, messageInfo, open])

	const showAlert = (message, severity = 'error') => {
		const newSnack = { message, severity, key: new Date().getTime() }
		setSnackPack((prev) => [...prev, newSnack])
	}

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}
		setOpen(false)
	}

	const handleExited = () => {
		setMessageInfo(undefined)
		if (snackPack.length) {
			setMessageInfo({ ...snackPack[0] })
			setSnackPack((prev) => prev.slice(1))
			setOpen(true)
		}
	}

	return (
		<SnackbarContext.Provider value={showAlert}>
			{children}
			<Snackbar
				key={messageInfo ? messageInfo.key : undefined}
				open={open}
				autoHideDuration={5000}
				onClose={handleClose}
				TransitionProps={{ onExited: handleExited }}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				style={{ bottom: '20px' }}
			>
				<MuiAlert
					onClose={handleClose}
					severity={messageInfo ? messageInfo.severity : 'success'}
					elevation={6}
					variant="filled"
					icon={false}
					sx={{
						// severity に応じて背景色を直接色コードで設定
						// backgroundColor: () => {
						// 	// messageInfo?.severity の値に基づいて色を返す
						// 	switch (messageInfo?.severity) {
						// 		case 'error':
						// 			return '#d32f2f' // エラーの場合の色コード
						// 		case 'warning':
						// 			return '#ffa000' // 警告の場合の色コード
						// 		case 'info':
						// 			return '#2979ff' // 情報の場合の色コード
						// 		case 'success':
						// 			return '#2E2F31' // 成功の場合の色コード
						// 		default:
						// 			return '#2E2F31' // デフォルトの色コード
						// 	}
						// },
						fontSize: '0.95rem',
						// 他のスタイルもここに追加できます
					}}
				>
					{messageInfo ? messageInfo.message : undefined}
				</MuiAlert>
			</Snackbar>
		</SnackbarContext.Provider>
	)
}
