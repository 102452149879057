import React from 'react'
import styled from 'styled-components'

const StyledImage = styled.img`
	width: 100%; // 幅はコンテナに合わせる
	height: 100%; // 高さもコンテナに合わせる
	object-fit: cover; // 画像のサイズをコンテナにフィットさせる
	border-radius: 4px;
`

const BigImage = styled.img`
	grid-column: 1 / -1; // 最初の画像が全列を占める
	grid-row: 1;
	width: 100%;
	height: 300px;
	object-fit: cover;
`

const SmallImage = styled.img`
	width: 100%;
	height: 160px;
	object-fit: cover;
`

const ImageContainer = styled.div`
	position: relative;
	width: 100%;
	height: auto;
`

const Overlay = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 2rem; // 文字サイズを大きくする
	// z-index: 1;
`

const ImageGrid = styled.div`
	display: grid;
	grid-gap: 2px;
	margin-top: 14px;

	${({ $imagesLength }) => {
		switch ($imagesLength) {
			case 1:
				return `
          grid-template-columns: 1fr;
          grid-template-rows: auto;
        `
			case 2:
				return `
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 400px;
        `
			case 3:
				return `
            grid-template-columns: 1fr 1fr; 
            grid-template-rows: 260px 160px; // 1段目の高さを固定し、2段目は2枚の画像
            & > :nth-child(1) { grid-column: 1 / -1; } // 2枚目以降を2列目に表示
          `
			case 4:
				return `
            grid-template-columns: 1fr 1fr 1fr; // 2段目に3列
            grid-template-rows: 280px auto;
            & > :nth-child(1) { grid-column: 1 / -1; } // 1枚目を1段目に表示
          `
			case 5:
				return `
            grid-template-columns: repeat(6, 1fr); // 6列のグリッドを設定
            grid-template-rows: 280px auto; // 2行の高さを設定
    
            // 1段目に2枚の画像がそれぞれ3列分の範囲を占める
            & > :nth-child(1) {
              grid-column: 1 / span 3; // 1枚目の画像は1列目から3列分を占める
              grid-row: 1;
            }
            & > :nth-child(2) {
              grid-column: 4 / span 3; // 2枚目の画像は4列目から3列分を占める
              grid-row: 1;
            }
    
            // 2段目に3枚の画像がそれぞれ2列分の範囲を占める
            & > :nth-child(3) {
              grid-column: 1 / span 2; // 3枚目の画像は1列目から2列分を占める
              grid-row: 2;
            }
            & > :nth-child(4) {
              grid-column: 3 / span 2; // 4枚目の画像は3列目から2列分を占める
              grid-row: 2;
            }
            & > :nth-child(5) {
              grid-column: 5 / span 2; // 5枚目の画像は5列目から2列分を占める
              grid-row: 2;
            }
          `
			default:
				// 6枚以上の場合のスタイル
				return `
            grid-template-columns: repeat(6, 1fr); // 6列のグリッドを設定
            grid-template-rows: 280px auto; // 2行の高さを設定
    
            // 1段目に2枚の画像がそれぞれ3列分の範囲を占める
            & > :nth-child(1) {
              grid-column: 1 / span 3; // 1枚目の画像は1列目から3列分を占める
              grid-row: 1;
            }
            & > :nth-child(2) {
              grid-column: 4 / span 3; // 2枚目の画像は4列目から3列分を占める
              grid-row: 1;
            }
    
            // 2段目に3枚の画像がそれぞれ2列分の範囲を占める
            & > :nth-child(3) {
              grid-column: 1 / span 2; // 3枚目の画像は1列目から2列分を占める
              grid-row: 2;
            }
            & > :nth-child(4) {
              grid-column: 3 / span 2; // 4枚目の画像は3列目から2列分を占める
              grid-row: 2;
            }
            & > :nth-child(5) {
              grid-column: 5 / span 2; // 5枚目の画像は5列目から2列分を占める
              grid-row: 2;
            }
         `
		}
	}}
`

const ImageGridComponent = ({ images, onImageClick }) => {
	const imageCount = images.length
	const additionalImagesCount = imageCount > 5 ? imageCount - 5 : 0

	const handleImageClick = (e, index) => {
		e.stopPropagation()
		if (onImageClick) {
			onImageClick(e, index)
		}
	}

	return (
		<ImageGrid $imagesLength={imageCount}>
			{images.slice(0, 5).map((image, index) => {
				const imageUrl = image.image_url || image.image_path
				const key = index
				return (
					<ImageContainer key={key} onClick={(e) => handleImageClick(e, index)}>
						<StyledImage src={imageUrl} alt={`Content image ${index + 1}`} />
						{index === 4 && additionalImagesCount > 0 && (
							<Overlay>+{additionalImagesCount}</Overlay>
						)}
					</ImageContainer>
				)
			})}
		</ImageGrid>
	)
}

export default ImageGridComponent
