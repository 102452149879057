// src/components/TranslateContent/TranslationControls.js

import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { BsTranslate } from 'react-icons/bs'

const TranslationControls = styled.div`
	display: flex;
	align-items: center;
`

const StyledBsTranslate = styled(BsTranslate)`
	color: ${({ theme }) => theme.colors.textTranslation};
	font-size: ${({ theme }) => theme.fontSizes.medium};
	margin-right: 6px;
`

const TranslationText = styled.span`
	color: ${({ theme }) => theme.colors.textTranslation};
	font-size: 13px;
	margin-right: 4px;
`

export const TranslationComponent = ({
	className,
	detectedLanguage,
	showControls = true,
}) => {
	const { t, i18n } = useTranslation()
	const userLanguage = i18n.language

	if (!showControls) {
		return null
	}

	const getLanguageDisplayName = (languageCode) => {
		try {
			const displayNames = new Intl.DisplayNames([userLanguage], {
				type: 'language',
			})
			return displayNames.of(languageCode)
		} catch (error) {
			return 'Invalid Language'
		}
	}

	const translationText = () => {
		if (userLanguage === detectedLanguage) {
			return t('originalBody')
		} else {
			const languageDisplayName = getLanguageDisplayName(detectedLanguage)
			if (languageDisplayName === 'Invalid Language' || !languageDisplayName) {
				return t('translatedFrom', { language: '-' })
			}
			return t('translatedFrom', { language: languageDisplayName })
		}
	}

	return (
		<TranslationControls className={className}>
			<StyledBsTranslate />
			<TranslationText>{translationText()}</TranslationText>
		</TranslationControls>
	)
}
