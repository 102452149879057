// src/components/Sidebar/SidebarRight.jsx

import React from 'react'
import styled from 'styled-components'
import AdComponent from '../Ads/AdComponent'
import obonImage from '../../assets/image/fujisan.jpeg' // 画像をインポート

const ImageWrapper = styled.div`
	margin-top: 0px;
	img {
		width: 98%; // サイドバーの幅に合わせる
		border-radius: 8px; // 必要に応じて画像の角を丸める
	}
`

const SidebarRight = () => {
	return (
		<>
			<ImageWrapper>
				<img src={obonImage} alt="Obon" />
			</ImageWrapper>
		</>
	)
}

export default SidebarRight
