// src/components/Button/SaveDraft.jsx
import React from 'react'
import StyledButton from '../Button/StyledButton'
import styled, { css } from 'styled-components'

const CustomStyledButton = styled(StyledButton)`
	background: none;
	border: none;
	color: #757575 !important;
	${(props) =>
		props.disabled &&
		css`
			background-color: transparent !important;
		`}

	&:hover {
		background-color: transparent !important;
	}
`

const SaveDraft = ({ children, onClick, disabled }) => {
	return (
		<CustomStyledButton
			onClick={() => !disabled && onClick && onClick()}
			disabled={disabled}
			className="btn btn-secondary"
		>
			{children}
		</CustomStyledButton>
	)
}

export default SaveDraft
