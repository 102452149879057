// src/components/Post/FooterComponent.jsx
import React, { useSelector } from 'react'
import LikeButton from '../LikeButton/LikeButton'
import CommentButton from '../CommentButton/CommentButton'
import BookmarkToggle from '../Bookmark/BookmarkToggle'
import styled, { css } from 'styled-components'
import { footerIconAndTextContainerStyle } from '../../styles/commonStyles'

const FooterContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-top: 4px;
`

const IconTextContainer = styled.div`
	${footerIconAndTextContainerStyle};
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
`

// LikeButtonを左端に寄せるスタイル
const LeftAlignedIconTextContainer = styled(IconTextContainer)`
	justify-content: flex-start;
`

// BookmarkToggleを右端に寄せるスタイル
const RightAlignedIconTextContainer = styled(IconTextContainer)`
	justify-content: flex-end;
`

const FooterComponent = ({
	type,
	id,
	post,
	userId,
	onModalChange,
	likes_count,
	commentsCount,
	hasComments,
}) => {
	return (
		<FooterContainer $hasComments={hasComments}>
			<LeftAlignedIconTextContainer>
				<LikeButton
					type={type}
					id={id}
					onModalChange={onModalChange}
					likes_count={likes_count}
				/>
			</LeftAlignedIconTextContainer>
			<IconTextContainer>
				<CommentButton
					type={type}
					postId={id}
					post={post}
					commentsCount={commentsCount > 0 ? commentsCount : undefined}
				/>
			</IconTextContainer>
			<RightAlignedIconTextContainer>
				<BookmarkToggle
					type={type}
					id={id}
					userId={userId}
					onModalChange={onModalChange}
				/>
			</RightAlignedIconTextContainer>
		</FooterContainer>
	)
}

export default FooterComponent
