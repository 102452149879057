// src/components/Comment/CommentFooter.jsx

import React from 'react'
import styled from 'styled-components'
import LikeButton from '../LikeButton/LikeButton'
import CommentButton from '../CommentButton/CommentButton'
import { footerIconAndTextContainerStyle } from '../../styles/commonStyles'

const FooterContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
`

const IconTextContainer = styled.div`
	${footerIconAndTextContainerStyle};
	flex-shrink: 0;
	min-width: 80px;
`

const CommentFooter = React.memo(({ onReplyClick, commentId, type }) => {
	return (
		<FooterContainer>
			<IconTextContainer>
				<LikeButton type={type} id={commentId} />
			</IconTextContainer>
			<IconTextContainer>
				<CommentButton type={type} onClick={onReplyClick}></CommentButton>
			</IconTextContainer>
		</FooterContainer>
	)
})

export default CommentFooter
