// src/services/hashtagApi.js

const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const fetchPopularHashtagsApi = async () => {
	try {
		const response = await fetch(`${BASE_URL}/hashtags/popular`, {
			method: 'GET',
			headers: {},
			credentials: 'include',
		})

		const updatedPost = await response.json()

		return updatedPost
	} catch (error) {
		throw error
	}
}

export const fetchRecommendedHashtagsApi = async (partialHashtag) => {
	try {
		const response = await fetch(
			`${BASE_URL}/hashtags/recommended?query=${encodeURIComponent(
				partialHashtag
			)}`,
			{
				method: 'GET',
				headers: {},
				credentials: 'include',
			}
		)

		const updatedPost = await response.json()

		return updatedPost
	} catch (error) {
		throw error
	}
}
