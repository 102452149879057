// src/components/Profile/ActivityPost.jsx

import React from 'react'
import { useTranslation } from 'react-i18next'
import PostItem from '../Post/PostItem'
import { Divider } from '../../styles/commonStyles'
import styled from 'styled-components'

const ProfilePostWrapper = styled.div`
	padding-bottom: 70px; // MobileNavbarの高さに応じて調整

	@media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
		padding-bottom: 0; // タブレット以上のサイズでは余分なパディングを削除
	}
`

const NoDataMessage = styled.p`
	color: ${({ theme }) => theme.colors.textSecondary};
	font-size: ${({ theme }) => theme.fontSizes.large};
	text-align: center;
	margin: 50px 0;
`

const ActivityPost = React.memo(({ posts, user, userLanguage }) => {
	const { t } = useTranslation()
	const noDataMessage = t('noDataYet')

	return (
		<ProfilePostWrapper>
			{posts?.length > 0 ? (
				posts.map((post, index) => (
					<React.Fragment key={post?.id}>
						<PostItem
							post={post}
							fromPath={`/profile/${user?.id}`}
							userLanguage={userLanguage}
							comments={post?.comments}
						/>
						{index !== posts?.length - 1 && <Divider />}
					</React.Fragment>
				))
			) : (
				<NoDataMessage>{noDataMessage}</NoDataMessage>
			)}
		</ProfilePostWrapper>
	)
})

export default ActivityPost
