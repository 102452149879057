// src/components/Button/AboutRitatrip.jsx
import React from 'react'
import StyledButton from '../Button/StyledButton'
import styled from 'styled-components'

const CustomStyledButton = styled(StyledButton)`
	background-color: ${({ theme }) => theme.colors.dark};
	color: #fff;
	padding: 12px 20px !important;
	font-size: ${({ theme }) => theme.fontSizes.medium} !important;

	@media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
		padding: 15px 30px !important;
		font-size: ${({ theme }) => theme.fontSizes.large} !important;
	}
`

const AboutRitatrip = ({ children, onClick, disabled }) => {
	return (
		<CustomStyledButton
			onClick={() => !disabled && onClick && onClick()}
			variant="dark"
			disabled={disabled}
		>
			{children}
		</CustomStyledButton>
	)
}

export default AboutRitatrip
