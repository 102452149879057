// src/reducers/hashtagReducer.js

import {
	FETCH_POSTS_BY_HASHTAG_REQUEST,
	FETCH_POSTS_BY_HASHTAG_SUCCESS,
	FETCH_POSTS_BY_HASHTAG_FAILURE,
	FETCH_POPULAR_HASHTAGS_REQUEST,
	FETCH_POPULAR_HASHTAGS_SUCCESS,
	FETCH_POPULAR_HASHTAGS_FAILURE,
	FETCH_RECOMMENDED_HASHTAGS_REQUEST,
	FETCH_RECOMMENDED_HASHTAGS_SUCCESS,
	FETCH_RECOMMENDED_HASHTAGS_FAILURE,
} from '../actions/hashtagActions'

const initialState = {
	loading: false,
	popularHashtags: [],
	recommendedHashtags: [],
	posts: [],
	hasMore: false,
	nextOffset: null,
	error: '',
}

const hashtagReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_POSTS_BY_HASHTAG_REQUEST:
			return {
				...state,
				loading: true,
			}
		case FETCH_POSTS_BY_HASHTAG_SUCCESS:
			return {
				loading: false,
				posts: action.payload.posts,
				hasMore: action.payload.hasMore,
				nextOffset: action.payload.nextOffset,
				error: '',
			}
		case FETCH_POSTS_BY_HASHTAG_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			}

		case FETCH_POPULAR_HASHTAGS_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
			}
		case FETCH_POPULAR_HASHTAGS_SUCCESS:
			return {
				...state,
				popularHashtags: action.payload,
				loading: false,
			}
		case FETCH_POPULAR_HASHTAGS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			}

		case FETCH_RECOMMENDED_HASHTAGS_REQUEST:
			return {
				...state,
				loadingRecommended: true,
				errorRecommended: null,
			}
		case FETCH_RECOMMENDED_HASHTAGS_SUCCESS:
			return {
				...state,
				recommendedHashtags: action.payload,
				loadingRecommended: false,
			}
		case FETCH_RECOMMENDED_HASHTAGS_FAILURE:
			return {
				...state,
				loadingRecommended: false,
				errorRecommended: action.payload,
			}

		default:
			return state
	}
}

export default hashtagReducer
