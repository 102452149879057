// src/actions/likeActions.js

import {
	fetchLikesCountFromApi,
	toggleLikeInDatabase,
} from '../services/likeApi'

export const LIKE_ACTION_SUCCESS = 'LIKE_ACTION_SUCCESS'
export const LIKE_ACTION_FAILURE = 'LIKE_ACTION_FAILURE'
export const FETCH_LIKES_COUNT = 'FETCH_LIKES_COUNT'
export const SET_LIKES_DATA = 'SET_LIKES_DATA'

// 成功時のアクションクリエーター（統合版）
export const likeActionSuccess = (type, id, likesCount, isLiked) => ({
	type: LIKE_ACTION_SUCCESS,
	payload: { type, id, likesCount, isLiked },
})

// 失敗時のアクションクリエーター（統合版）
export const likeActionFailure = (type, id, error) => ({
	type: LIKE_ACTION_FAILURE,
	payload: { type, id, error },
})

// いいね数を取得するアクション
export const fetchLikesCount = (type, id, userId) => async (dispatch) => {
	try {
		const data = await fetchLikesCountFromApi(type, id, userId)
		dispatch(likeActionSuccess(type, id, data.likesCount, data.isLikedByUser))
	} catch (error) {
		dispatch(likeActionFailure(type, id, error.message))
	}
}

// いいねの状態をトグルするアクション（ポストとコメントの両方に対応）
export const toggleLike = (type, id, isLiked, userId) => async (dispatch) => {
	try {
		const data = await toggleLikeInDatabase(type, id, isLiked, userId)
		// バックエンドからのレスポンスである data.isLiked を使用
		dispatch(likeActionSuccess(type, id, data.likesCount, data.isLiked))
	} catch (error) {
		dispatch(likeActionFailure(type, id, error.message))
	}
}

export const setLikesData = (likesData) => ({
	type: SET_LIKES_DATA,
	payload: likesData,
})
