// src/components/Settings/Settings.jsx

import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import MainLayout from '../Layout/MainLayout'
import { useAuth } from '../../hooks/useAuth'
import { logoutAction } from '../../actions/authActions'

import styled from 'styled-components'

import { borderBottomStyle } from '../../styles/commonStyles'

const ContentsWrapper = styled.div`
	//
`

const PageTitle = styled.div`
	font-size: ${({ theme }) => theme.fontSizes.extraLarge};
	font-weight: bold;
	margin: 20px 20px 10px;
`

const SettingsContainer = styled.div`
	display: flex;
	flex-direction: column;
`

const EmailContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 20px;
	color: ${({ theme }) => theme.colors.text};
	${(props) => borderBottomStyle(props.theme)}
`

const EmailAddress = styled.div`
	color: ${({ theme }) => theme.colors.textSecondary};
`

const StyledLink = styled(Link)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 20px 20px;
	color: ${({ theme }) => theme.colors.text};
	// ${(props) => borderBottomStyle(props.theme)}

	text-decoration: none;
	&:hover {
		background-color: ${({ theme }) => theme.colors.background};
	}
`

const ArrowIcon = styled.div`
	font-size: 20px;
	&:after {
		content: '>';
	}
`

const PolicyLinksContainer = styled.div`
	display: flex;
	justify-content: left;
	padding: 10px 0px 40px;
`

const PolicyLink = styled.a`
	color: ${({ theme }) => theme.colors.textSecondary};
	text-decoration: none;
	margin: 0px 20px 20px;
	font-size: ${({ theme }) => theme.fontSizes.tiny};

	&:hover {
		text-decoration: underline;
	}
`

const SettingsItem = ({ to, children, external, state, onClick }) => {
	const navigate = useNavigate()
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)

	useEffect(() => {
		if (!isAuthenticated) {
			navigate('/')
			return
		}
	}, [isAuthenticated])

	const handleClick = (e) => {
		// onClick プロパティが提供された場合は、その関数を呼び出す
		if (onClick) {
			e.preventDefault() // デフォルトの振る舞いを防止
			onClick()
		} else if (external && to) {
			// 外部リンクの場合、新しいタブで開く
			window.open(to, '_blank')
		} else if (to) {
			// 内部リンクの場合、navigate を使用して遷移
			e.preventDefault()
			navigate(to, { state })
		}
	}

	return (
		<StyledLink onClick={handleClick}>
			{children}
			<ArrowIcon />
		</StyledLink>
	)
}

const Settings = () => {
	useAuth()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const location = useLocation()
	const user = useSelector((state) => state.user.user)
	const { t } = useTranslation()

	const helpCenterLink = () => {
		// ブラウザの言語設定を取得
		const userLang = navigator.language || navigator.userLanguage
		// 日本語の場合は日本語のサポートページへ、それ以外は英語ページへリダイレクト
		return userLang.startsWith('ja')
			? 'https://ritatrip.com/help/'
			: 'https://ritatrip.com/help/en/'
	}

	// ログアウト処理
	const handleLogout = () => {
		dispatch(logoutAction()).then(() => {
			navigate('/')
		})
	}

	// 利用規約とプライバシーポリシーのURLを環境変数から取得
	const termsUrl = navigator.language.startsWith('ja')
		? process.env.REACT_APP_TERMS_URL_JP
		: process.env.REACT_APP_TERMS_URL_EN
	const privacyPolicyUrl = navigator.language.startsWith('ja')
		? process.env.REACT_APP_PRIVACY_POLICY_URL_JP
		: process.env.REACT_APP_PRIVACY_POLICY_URL_EN

	return (
		<MainLayout>
			<ContentsWrapper>
				<PageTitle>{t('settings')}</PageTitle>
				<SettingsContainer>
					<EmailContainer>
						<div>{t('email')}</div>
						<EmailAddress>{user.email}</EmailAddress>
					</EmailContainer>
					<SettingsItem
						to="/settings/password"
						state={{ from: location.pathname }}
					>
						{t('changePassword')}
					</SettingsItem>
					{/* <SettingsItem to="/settings/account">Your account</SettingsItem> */}
					<SettingsItem to={helpCenterLink()} external={true}>
						{t('helpCenter')}
					</SettingsItem>
					<SettingsItem
						to="/settings/feedback"
						state={{ from: location.pathname }}
					>
						{t('sendFeedback')}
					</SettingsItem>
					<SettingsItem onClick={handleLogout}>{t('logout')}</SettingsItem>

					<SettingsItem
						to="/settings/delete"
						state={{ from: location.pathname }}
					>
						{t('deleteAccount')}
					</SettingsItem>
				</SettingsContainer>
				<PolicyLinksContainer>
					<PolicyLink href={termsUrl} target="_blank">
						{t('termsOfService')}
					</PolicyLink>
					<PolicyLink href={privacyPolicyUrl} target="_blank">
						{t('privacyPolicy')}
					</PolicyLink>
				</PolicyLinksContainer>
			</ContentsWrapper>
		</MainLayout>
	)
}

export default Settings
