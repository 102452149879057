// src/reducers/draftReducer.js
import {
	SAVE_DRAFT_SUCCESS,
	SAVE_DRAFT_FAILURE,
	FETCH_DRAFT_SUCCESS,
	FETCH_DRAFT_FAILURE,
	DELETE_DRAFT_SUCCESS,
	DELETE_DRAFT_FAILURE,
	SET_PROFILE_DRAFTS,
	RESET_PROFILE_DRAFTS,
	ADD_PROFILE_DRAFTS,
} from '../actions/draftAction'

const initialState = {
	draft: null,
	loading: false,
	profileDrafts: [],
	totalProfileDrafts: 0,
	error: null,
}

const draftReducer = (state = initialState, action) => {
	switch (action.type) {
		case SAVE_DRAFT_SUCCESS:
			const updatedDraft = action.payload
			// profileDrafts 内のドラフトを更新または追加
			const draftIndex = state.profileDrafts.findIndex(
				(d) => d.id === updatedDraft.id
			)

			let newProfileDrafts
			if (draftIndex >= 0) {
				// 既存のドラフトを新しい情報で更新
				newProfileDrafts = state.profileDrafts.map((draft, index) =>
					index === draftIndex ? updatedDraft : draft
				)
			} else {
				// 新しいドラフトをリストに追加
				newProfileDrafts = [...state.profileDrafts, updatedDraft]
			}

			return {
				...state,
				profileDrafts: newProfileDrafts,
			}

		case SAVE_DRAFT_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				draft: null, // エラーが発生した場合、ドラフトはnullにリセット
				profileDrafts: state.profileDrafts || [], // ここでプロフィールドラフトのデフォルト値を設定
				totalProfileDrafts: state.totalProfileDrafts || 0, // ここでプロフィールドラフトのデフォルト値を設定
			}

		case FETCH_DRAFT_SUCCESS:
			return {
				...state,
				draft: {
					drafts: action.payload.drafts, // 新しいドラフトリストを使用
					totalDrafts: action.payload.totalDrafts,
				},
				loading: false,
			}

		case FETCH_DRAFT_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				draft: null, // エラーが発生した場合、ドラフトはnullにリセット
			}

		case DELETE_DRAFT_SUCCESS:
			return {
				...state,
				draftDeleted: true,
			}

		case DELETE_DRAFT_FAILURE:
			return {
				...state,
				error: action.payload,
			}

		case SET_PROFILE_DRAFTS:
			return {
				...state,
				profileDrafts: action.payload.drafts,
				totalProfileDrafts: action.payload.totalDrafts,
				draftDeleted: false,
			}

		case RESET_PROFILE_DRAFTS:
			return {
				...state,
				profileDrafts: [],
				totalProfileDrafts: 0,
			}

		case ADD_PROFILE_DRAFTS:
			return {
				...state,
				profileDrafts: [...state.profileDrafts, ...action.payload.drafts],
				totalProfileDrafts: action.payload.totalDrafts,
			}
		default:
			return state
	}
}

export default draftReducer
