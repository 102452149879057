// src/components/Profile/ProfileInfo.jsx

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Divider } from '../../styles/commonStyles'

const InfoSection = styled.div`
	margin: 10px 0px;
`

const InfoTitle = styled.div`
	font-size: ${({ theme }) => theme.fontSizes.large};
	font-weight: bold;
	color: ${({ theme }) => theme.colors.black};
	display: flex;
	align-items: center;
	margin-top: 30px;
`

const InfoContent = styled.div`
	font-size: ${({ theme }) => theme.fontSizes.medium};
	color: ${({ theme }) => theme.colors.text};
	margin: 8px 0px 24px;

	${({ $isFilled, theme }) =>
		$isFilled &&
		css`
			color: ${theme.colors.text};
		`}
	${({ $isFilled, theme }) =>
		!$isFilled &&
		css`
			color: ${theme.colors.textSecondary};
		`}
`

export const ProfileInfo = React.memo(({ user }) => {
	const { t } = useTranslation()
	const userLanguage = useSelector(
		(state) => state.translation?.currentLanguage
	)

	// user オブジェクトと profiles プロパティの存在をチェック
	const profiles = user?.profiles || {}
	const defaultLanguage = user?.default_language || userLanguage

	// 現在の言語のプロフィールを取得、なければデフォルト言語のプロフィールを使用
	const currentProfile =
		profiles[userLanguage] || profiles[defaultLanguage] || {}

	const getProfileInfo = (field) => {
		if (field === 'residence') {
			return currentProfile[field] || t('currentHomePlaceholder')
		}
		return currentProfile[field] || t(`${field}Placeholder`)
	}

	const getUserTypeLabel = (userType) => {
		switch (userType) {
			case 'tourist':
				return t('userTypeTourist')
			case 'resident':
				return t('userTypeResident')
			case 'other':
				return t('userTypeOther')
			default:
				return t('-')
		}
	}

	return (
		<>
			<InfoSection>
				<InfoTitle>{t('bio')}</InfoTitle>
				<InfoContent $isFilled={!!currentProfile.bio}>
					{getProfileInfo('bio')}
				</InfoContent>
			</InfoSection>

			<Divider />

			<InfoSection>
				<InfoTitle>{t('nationality')}</InfoTitle>
				<InfoContent
					$isFilled={
						!!currentProfile.nationality &&
						currentProfile.nationality !== 'Select None'
					}
				>
					{currentProfile.nationality === 'Select None'
						? t('nationalityPlaceholder')
						: getProfileInfo('nationality')}
				</InfoContent>
			</InfoSection>

			<Divider />

			<InfoSection>
				<InfoTitle>{t('currentHome')}</InfoTitle>
				<InfoContent $isFilled={!!currentProfile.residence}>
					{getProfileInfo('residence')}
				</InfoContent>
			</InfoSection>

			<Divider />

			<InfoSection>
				<InfoTitle>{t('userType')}</InfoTitle>
				<InfoContent $isFilled={!!user?.user_type}>
					{getUserTypeLabel(user?.user_type)}
				</InfoContent>
			</InfoSection>

			<Divider />

			<InfoSection>
				<InfoTitle>{t('activity')}</InfoTitle>
			</InfoSection>
		</>
	)
})
