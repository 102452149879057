// src/utils/extractLinks.js

function extractLinks(text, locations) {
	if (typeof text !== 'string') {
		return []
	}

	const urlRegex =
		/(https?:\/\/[^\s\p{sc=Hiragana}\p{sc=Katakana}\p{sc=Han}]+(?:[\w\-._~:/?#\[\]@!$&'()*+,;=]|%[0-9A-Fa-f]{2})*)/gu

	let result = []
	let lastIndex = 0

	text.replace(urlRegex, (match, url, offset) => {
		// トリミングせず、URLをそのまま使用
		if (lastIndex < offset) {
			const textContent = text.slice(lastIndex, offset)
			result.push({ type: 'text', content: textContent })
		}

		const location = locations.find((loc) => loc.shortened_url === url)

		if (location) {
			result.push({
				type: 'url',
				shortened: url,
				full: location.place_link,
			})
		} else {
			result.push({ type: 'url', shortened: url, full: url })
		}

		lastIndex = offset + url.length
	})

	if (lastIndex < text.length) {
		const remainingText = text.slice(lastIndex)
		if (remainingText) {
			result.push({ type: 'text', content: remainingText })
		}
	}

	return result
}

export default extractLinks
