// src/components/Search/SearchItem.jsx

import React, { useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useScrollPosition } from '../../context/ScrollPositionContext'
import PostItem from '../Post/PostItem'
import { handleHashtagClick } from '../../utils/hashtagUtils'
import { handleShortenedUrlClick } from '../../utils/urlUtils'
import styled from 'styled-components'

const SearchItemLayout = styled.div`
	// Add your SearchItemLayout styles here
`

const SearchItem = React.memo(
	({
		data,
		user_data,
		likes_count,
		comments_count,
		locations,
		images,
		selectedHashtag,
	}) => {
		const dispatch = useDispatch()
		const navigate = useNavigate()
		const location = useLocation()
		const { saveScrollPosition } = useScrollPosition()
		const { currentLanguage } = useSelector((state) => state.translation)
		const postId = data.id || data.post_id

		const params = new URLSearchParams(location.search)
		const searchedHashtag = params.get('hashtag')
		const searchedKeyword = params.get('keyword')

		const handlePostClick = () => {
			const currentPath = `${location.pathname}${location.search}`
			const currentScrollPosition = window.scrollY

			saveScrollPosition(currentPath, currentScrollPosition)
			navigate(`/posts/${postId}`, {
				state: {
					from: currentPath,
					prevPaths: [...(location.state?.prevPaths || []), currentPath],
					scrollY: currentScrollPosition,
				},
			})
		}

		const onHashtagClick = (e, hashtag) => {
			e.stopPropagation() // Prevent the click from bubbling up to the SearchItemLayout
			const currentPath = `${location.pathname}${location.search}`
			navigate(`/hashtag/${hashtag.slice(1)}`, {
				state: {
					from: currentPath,
					prevPaths: [...(location.state?.prevPaths || []), currentPath],
				},
			})
		}

		const onLinkClickShortenedUrl = (e, shortenedUrl) => {
			handleShortenedUrlClick(e, shortenedUrl, data.locations)
		}

		const displayText = useMemo(() => {
			switch (currentLanguage) {
				case 'en':
					return data.text_en
				case 'ja':
					return data.text_ja
				case 'zh-TW':
					return data['text_zh-TW']
				default:
					return data.text_en || data.body
			}
		}, [currentLanguage, data.text_en, data.text_ja, data['text_zh-TW']])

		const memoizedPost = useMemo(
			() => ({
				...data,
				body: displayText,
				user: {
					id: user_data?.id,
					username: user_data?.username,
					avatar: user_data?.avatar,
					country_code: user_data?.country_code,
				},
				images: images,
				language: currentLanguage,
				likes_count: likes_count,
				comments_count: comments_count,
				locations: locations,
			}),
			[
				data,
				displayText,
				user_data,
				images,
				currentLanguage,
				likes_count,
				comments_count,
				locations,
			]
		)

		return (
			<SearchItemLayout onClick={handlePostClick}>
				<PostItem
					key={`${data.id}-${currentLanguage}`}
					post={memoizedPost}
					showComments={false}
					showLikesCount={true}
					shortenedUrls={data.shortened_urls || []}
					onLinkClickShortenedUrl={onLinkClickShortenedUrl}
					onHashtagClick={onHashtagClick}
					selectedHashtag={selectedHashtag}
					searchedHashtag={searchedHashtag}
					searchedKeyword={searchedKeyword}
					isSearchResult={true}
				/>
			</SearchItemLayout>
		)
	}
)

export default SearchItem
