// src/services/reportApi.js

export const sendReportApi = async (reportData) => {
	try {
		const response = await fetch('/report', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(reportData),
			credentials: 'include',
		})
		if (!response.ok) {
			throw new Error('Report sending failed.')
		}
		return await response.json()
	} catch (error) {
		throw error
	}
}
