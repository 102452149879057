// src/hooks/useGooglePlaceHandlers.js

import { useCallback } from 'react'
import { generateShortUrl } from '../utils/locationUtils'
import { EditorState, Modifier } from 'draft-js'

const useGooglePlaceHandlers = ({ setSelectedLocations, setIsEdited }) => {
	const handlePlaceSelected = useCallback(
		(location, editorState, setEditorState) => {
			if (location && location.place_id) {
				const shortenedUrl = generateShortUrl(location)
				const currentContent = editorState.getCurrentContent()
				const selection = currentContent.getSelectionAfter()

				const newContent = Modifier.insertText(
					currentContent,
					selection,
					`\n${shortenedUrl}\n`
				)

				const newEditorState = EditorState.push(
					editorState,
					newContent,
					'insert-characters'
				)

				setEditorState(newEditorState)
				setIsEdited(true)

				setSelectedLocations((prevLocations) =>
					prevLocations.some(
						(prevLocation) => prevLocation.place_id === location.place_id
					)
						? prevLocations
						: [...prevLocations, location]
				)

				return newEditorState
			}
			return editorState
		},
		[setIsEdited, setSelectedLocations]
	)

	return { handlePlaceSelected }
}

export default useGooglePlaceHandlers
