// src/services/feedbackApi.js

const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const sendFeedbackApi = async (feedbackData) => {
	try {
		const response = await fetch(`${BASE_URL}/feedback`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(feedbackData),
			credentials: 'include',
		})

		if (!response.ok) {
			throw new Error('Feedback sending failed.')
		}

		const responseData = await response.json()

		return responseData
	} catch (error) {
		throw error
	}
}
