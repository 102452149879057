// PostsContext.js
import React, { createContext, useState, useContext } from 'react';
import { postToDatabase } from '../services/postApi'; // API 関数をインポート

const PostsContext = createContext();

export const usePosts = () => useContext(PostsContext);

export const PostsProvider = ({ children }) => {
  const [posts, setPosts] = useState([]);
  const [comments, setComments] = useState({});
  const [scrollPosition, setScrollPosition] = useState(0);

  // 新規投稿を送信して状態を更新する関数
  const submitNewPost = async (postData) => {
    try {
      const response = await postToDatabase(postData);
      if (response.status === 'success') {
        // 新規投稿データを投稿リストに追加
        setPosts((prevPosts) => [response.post, ...prevPosts]);
      } else {
        console.error('Failed to create post:', response.message);
      }
    } catch (error) {
      console.error('Error submitting new post:', error);
    }
  };

  // コメントを追加する関数
  const addCommentToPost = (postId, comment) => {
    setComments((prevComments) => ({
      ...prevComments,
      [postId]: [...(prevComments[postId] || []), comment],
    }));
  };

  return (
    <PostsContext.Provider
      value={{
        posts,
        setPosts,
        submitNewPost, // 新規投稿関数をコンテキストに追加
        scrollPosition,
        setScrollPosition,
        comments,
        addCommentToPost,
      }}
    >
      {children}
    </PostsContext.Provider>
  );
};

export default PostsProvider;
