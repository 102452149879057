// src/index.js

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { store, persistor } from './store'
import { PersistGate } from 'redux-persist/integration/react'
import Modal from 'react-modal'
import { ThemeProvider } from 'styled-components'
import { theme } from './styles/theme'
import { checkAuthStatus } from './actions/authActions'
import LoadingSpinner from './components/Common/LoadingSpinner'
import './index.css'
import './i18n'
import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/global.css'

Modal.setAppElement('#root') // モーダルが開いている間背景のコンテンツがスクリーンリーダーに読まれなくなるようにする

const root = ReactDOM.createRoot(document.getElementById('root'))

const onBeforeLift = () => {
	const { auth } = store.getState()

	if (!auth.isAuthenticated) {
		store.dispatch(checkAuthStatus())
	}
}

root.render(
	// <React.StrictMode>
	<Provider store={store}>
		<PersistGate
			loading={null}
			persistor={persistor}
			onBeforeLift={onBeforeLift}
		>
			<ThemeProvider theme={theme}>
				<Suspense fallback={<LoadingSpinner />}>
					<App />
				</Suspense>
			</ThemeProvider>
		</PersistGate>
	</Provider>
	// </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
