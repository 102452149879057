// src/components/Notifications/Notifications.jsx

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import NotificationItem from './NotificationItem'
import {
	updateNotifications,
	updateLastFetched,
} from '../../actions/notificationsActions'
import { fetchUserNotifications } from '../../services/notificationsApi'
import MainLayout from '../Layout/MainLayout'
import LoadingSpinner from '../Common/LoadingSpinner'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import { Divider } from '../../styles/commonStyles'
import ManekiNekoImage from '../../assets/icon/maneki-neko.png'

const NotificationsWrapper = styled.div`
	padding-bottom: 70px; // MobileNavbarの高さに応じて調整

	@media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
		padding-bottom: 0; // タブレット以上のサイズでは余分なパディングを削除
	}
`

const NotificationsContainer = styled.div`
	display: flex;
	justify-content: center;
`

const NotificationsContent = styled.div`
	flex: 1;

	@media (max-width: 768px) {
		width: 90%;
	}
	@media (min-width: 768px) {
		width: 580px;
	}
`

const NoDataWrapper = styled.div`
	text-align: center;
	margin-top: 50px;
`

const MessageText = styled.p`
	margin: 20px 0 40px;
	font-size: ${({ theme }) => theme.fontSizes.large};
	font-weight: bold;
`

const StyledNotificationIcon = styled.img`
	width: 120px;
	height: auto;
	margin: 20px auto;
`

const Notifications = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
	const userId = useSelector((state) => state.auth.userId)
	const location = useLocation()
	const backPath = location.state?.from === '/' ? '/' : '/'
	const [isLoading, setIsLoading] = useState(false)
	const { notifications, lastFetched } = useSelector(
		(state) => state.notifications
	)
	const { t } = useTranslation()

	useEffect(() => {
		if (!isAuthenticated) {
			navigate('/')
			return
		}

		const savedPosition = sessionStorage.getItem('scrollPosition')
		window.scrollTo(0, savedPosition ? parseInt(savedPosition, 10) : 0)
	}, [isAuthenticated, navigate])

	useEffect(() => {
		const currentTime = Date.now()
		const shouldFetch =
			!lastFetched ||
			currentTime - lastFetched > 5 * 60 * 1000 ||
			notifications.length === 0

		if (userId && shouldFetch) {
			setIsLoading(true)
			fetchUserNotifications(userId)
				.then((data) => {
					dispatch(updateNotifications(data.notifications))
					dispatch(updateLastFetched(Date.now()))
				})
				.catch((error) => console.error('Error fetching notifications:', error))
				.finally(() => {
					setIsLoading(false)
				})
		}
	}, [userId, lastFetched, notifications.length, dispatch])

	return (
		<MainLayout showBackButton={true} backPath={backPath}>
			<NotificationsWrapper>
				<NotificationsContainer>
					<NotificationsContent>
						{isLoading ? (
							<LoadingSpinner />
						) : notifications.length > 0 ? (
							notifications.map((notification, index) => (
								<React.Fragment key={notification.id}>
									<NotificationItem notification={notification} />
									{index < notifications.length - 1 && <Divider />}
								</React.Fragment>
							))
						) : (
							<NoDataWrapper>
								<MessageText>{t('noNotificationsYet')}</MessageText>
								<StyledNotificationIcon
									src={ManekiNekoImage}
									alt="No Notifications"
								/>
							</NoDataWrapper>
						)}
					</NotificationsContent>
				</NotificationsContainer>
			</NotificationsWrapper>
		</MainLayout>
	)
}

export default Notifications
