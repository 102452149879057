// src/components/Button/BookmarkToggle.jsx

import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { toggleBookmarkAction } from '../../actions/bookmarkAction'
import {
	selectPostBookmarksData,
	selectCommentBookmarksData,
} from '../../selectors/bookmarkSelectors'
import SignupModal from '../Signup/SignupModal'
import styled from 'styled-components'
import {
	footerIconWrapperStyle,
	footerIconContainerStyle,
	footerIconStyle,
	hoverIconStyle,
} from '../../styles/commonStyles'

import { MdBookmarkBorder } from 'react-icons/md'
import { MdBookmark } from 'react-icons/md'

const IconWrapper = styled.div`
	${footerIconWrapperStyle};
`

const IconContainer = styled.div`
	${footerIconContainerStyle};
	${hoverIconStyle}
`

const BookmarkIcon = styled.div`
	${footerIconStyle}
	color: ${({ $isBookmarked, theme }) =>
		$isBookmarked ? theme.colors.primary : theme.colors.textSecondary};
	font-size: 24px;
`

function BookmarkToggle({ type, id, userId, onModalChange }) {
	const dispatch = useDispatch()
	const bookmarkData = useSelector((state) =>
		type === 'post'
			? selectPostBookmarksData(state, id)
			: selectCommentBookmarksData(state, id)
	)
	const isBookmarked = bookmarkData.isBookmarked
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
	const [showSignupModal, setShowSignupModal] = useState(false)

	// 認証されていない場合にサインアップモーダルを表示する関数
	const showSignupModalIfNeeded = () => {
		if (!isAuthenticated) {
			onModalChange(true)
			setShowSignupModal(true)
			return true
		}
		return false
	}

	const handleBookmarkToggle = async (e) => {
		e.stopPropagation()

		if (showSignupModalIfNeeded()) return
		try {
			await dispatch(toggleBookmarkAction(type, id, userId, isBookmarked))
		} catch (error) {
			console.error('Bookmark toggle failed:', error)
		}
	}

	return (
		<>
			<IconWrapper onClick={handleBookmarkToggle}>
				<IconContainer>
					<BookmarkIcon $isBookmarked={isBookmarked}>
						{isBookmarked ? <MdBookmark /> : <MdBookmarkBorder />}
					</BookmarkIcon>
				</IconContainer>
			</IconWrapper>
			<SignupModal
				show={showSignupModal}
				handleClose={() => {
					setShowSignupModal(false)
					onModalChange(false)
				}}
				backdrop="static"
				keyboard={false}
			/>
		</>
	)
}

export default React.memo(BookmarkToggle)
