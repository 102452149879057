// src/reducers/layoutReducer.js

const initialState = {
  showSidebarLeft: true, // 初期値は true または false に設定
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_SIDEBAR_LEFT':
      return {
        ...state,
        showSidebarLeft: action.payload,
      };
    default:
      return state;
  }
};
export default layoutReducer;
