// src/actions/draftAction.js
import {
	saveDraftApi,
	fetchDraftApi,
	deleteDraftApi,
} from '../services/draftApi'

// アクションタイプ
export const SAVE_DRAFT_SUCCESS = 'SAVE_DRAFT_SUCCESS'
export const SAVE_DRAFT_FAILURE = 'SAVE_DRAFT_FAILURE'

export const FETCH_DRAFT_SUCCESS = 'FETCH_DRAFT_SUCCESS'
export const FETCH_DRAFT_FAILURE = 'FETCH_DRAFT_FAILURE'

export const DELETE_DRAFT_SUCCESS = 'DELETE_DRAFT_SUCCESS'
export const DELETE_DRAFT_FAILURE = 'DELETE_DRAFT_FAILURE'

export const SET_PROFILE_DRAFTS = 'SET_PROFILE_DRAFTS'
export const RESET_PROFILE_DRAFTS = 'RESET_PROFILE_DRAFTS'
export const ADD_PROFILE_DRAFTS = 'ADD_PROFILE_DRAFTS'

// ドラフトを保存する非同期アクションクリエーター
export const saveDraftAction = (postId, draftData) => async (dispatch) => {
	try {
		const response = await saveDraftApi(draftData)

		if (response.status === 'success') {
			dispatch(saveDraftSuccess(response.draft))
		} else {
			dispatch(saveDraftFailure('Error saving draft'))
		}
	} catch (error) {
		dispatch(saveDraftFailure(error.message))
	}
}

// ドラフトを取得する非同期アクションクリエーター
export const fetchUserDraftAction = (userId, offset) => async (dispatch) => {
	try {
		const userDrafts = await fetchDraftApi(userId, offset)

		if (offset === 0) {
			dispatch(setProfileDrafts(userDrafts.drafts, userDrafts.totalDrafts))
		} else {
			dispatch(addProfileDrafts(userDrafts.drafts, userDrafts.totalDrafts))
		}
	} catch (error) {
		dispatch(fetchDraftFailure(error.message))
	}
}

export const deleteDraft = (draftId) => async (dispatch) => {
	try {
		const data = await deleteDraftApi(draftId)

		if (data && data.status === 'success') {
			dispatch(deleteDraftSuccess(draftId))
		} else {
			dispatch(deleteDraftFailure(data ? data.message : 'Error deleting draft'))
		}
	} catch (error) {
		dispatch(deleteDraftFailure(error.toString()))
	}
}

// アクションクリエーター
export const saveDraftSuccess = (draft) => ({
	type: SAVE_DRAFT_SUCCESS,
	payload: draft,
})

export const saveDraftFailure = (error) => ({
	type: SAVE_DRAFT_FAILURE,
	payload: error,
})

export const fetchDraftSuccess = (draft) => ({
	type: FETCH_DRAFT_SUCCESS,
	payload: draft,
})

export const fetchDraftFailure = (error) => ({
	type: FETCH_DRAFT_FAILURE,
	payload: error,
})

export const deleteDraftSuccess = () => ({
	type: DELETE_DRAFT_SUCCESS,
})

export const deleteDraftFailure = (error) => ({
	type: DELETE_DRAFT_FAILURE,
	payload: error,
})

export const setProfileDrafts = (drafts, totalDrafts) => {
	return {
		type: SET_PROFILE_DRAFTS,
		payload: {
			drafts,
			totalDrafts,
		},
	}
}

export const resetProfileDrafts = () => {
	return {
		type: RESET_PROFILE_DRAFTS,
	}
}

export const addProfileDrafts = (drafts, totalDrafts) => {
	return {
		type: ADD_PROFILE_DRAFTS,
		payload: {
			drafts,
			totalDrafts,
		},
	}
}
