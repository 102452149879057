// src/components/Comment/ReplyCreationForm.jsx

import React from 'react'
import CommentComposer from './CommentComposer'

const ReplyCreationForm = React.forwardRef(
	({ postId, commentId, username, userId, isFocused, onSubmit }, ref) => {
		return (
			<div ref={ref}>
				<CommentComposer
					postId={postId}
					commentId={commentId}
					avatarSize="comment"
					isReply={true}
					replyToUsername={username}
					replyToUserId={userId}
					isFocused={isFocused}
					onSubmit={onSubmit}
				/>
			</div>
		)
	}
)

export default ReplyCreationForm
