// src/components/Profile/ProfileHeader.jsx

import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import CustomAvatar from '../Avatar/Avatar'

const ProfileContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 20px 20px 0px;
`

const ProfileBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 40px;
	box-shadow: 0 4px 18px rgba(0, 0, 0, 0.1);
	border-radius: 20px;
	margin-bottom: 10px;
	${({ $isLoading }) =>
		$isLoading &&
		css`
			height: 340px;
		`}
`

const AvatarAndEditButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100%;
	margin-bottom: 10px;
`

const EditButton = styled.button`
	position: absolute;
	font-size: ${({ theme }) => theme.fontSizes.tiny};
	font-weight: bold;
	color: ${({ theme }) => theme.colors.white};
	background-color: ${({ theme }) => theme.colors.black};
	padding: 10px 15px;
	border: none;
	border-radius: 50px;
	cursor: pointer;

	@media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
		right: -8%;
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
		right: 4%;
		padding: 10px 15px;
	}
`

const Username = styled.div`
	display: flex;
	justify-content: center;
	margin: 10px 0px 0px;
	font-size: ${({ theme }) => theme.fontSizes.extraLarge};
	font-weight: bold;
`

const ProfileHeader = React.memo(
	({ isCurrentUserProfile, openModal, username, avatar }) => {
		const { t } = useTranslation()

		return (
			<ProfileContainer>
				<ProfileBox>
					<AvatarAndEditButtonContainer>
						<CustomAvatar
							username={username || ''}
							avatar={avatar}
							size="profile"
						/>
						{isCurrentUserProfile && (
							<EditButton onClick={openModal}>{t('editButton')}</EditButton>
						)}
					</AvatarAndEditButtonContainer>
					<Username>{username || ''}</Username>
				</ProfileBox>
			</ProfileContainer>
		)
	}
)

export default ProfileHeader
