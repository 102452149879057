// src/components/Bookmark/BookmarkItem.jsx

import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import {
	TimelineItemWrapper,
	PostAreaWrapper,
	TranslatedText,
} from '../../components/Post/PostItem'
import HeaderComponent from '../Common/HeaderComponent'
import ImageGridComponent from '../Post/ImageGridComponent'
import FooterComponent from '../Post/FooterComponent'
import { TranslationComponent } from '../TranslateContent/TranslationControls'
import RenderTextWithLinks from '../RenderTextWithLinks'
import useReadMore from '../../hooks/useReadMore'
import { StyledReadMore } from '../../styles/commonStyles'
import { fetchCommentsCount } from '../../actions/commentActions'

export const selectCommentCount = createAsyncThunk(
	'comments/fetchCommentCount',
	async (postId, { getState }) => {
		const state = getState()
		const existingCount = state.comments.byPostId[postId]?.ids?.length

		if (existingCount !== undefined) {
			return existingCount
		}

		const count = await fetchCommentsCount(postId)
		return count
	}
)

const BookmarkItem = ({ bookmark, onDelete }) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const userLanguage = useSelector((state) => state.translation.currentLanguage)
	const userId = useSelector((state) => state.auth.userId)
	const [showOriginal, setShowOriginal] = useState(false)
	const [commentCount, setCommentCount] = useState(0)

	const displayText = useMemo(() => {
		const originalText = bookmark.body
		const translations = bookmark.translations || []
		const translation = translations.find(
			(t) => t.language === userLanguage
		)?.translated_content
		return translation || originalText
	}, [bookmark, userLanguage])

	const { resultText, isTruncated, toggleReadMore } = useReadMore(displayText)
	const postPostId = bookmark?.type === 'post' ? bookmark?.id : null
	const forDeletePostId = bookmark?.id
	const detectedLanguage =
		bookmark?.type === 'post' ? bookmark?.language : bookmark?.language

	const [isModalOpen, setIsModalOpen] = useState(false)

	useEffect(() => {
		if (postPostId) {
			dispatch(fetchCommentsCount(postPostId))
				.then((action) => {
					if (action.payload && action.payload.commentsCount !== undefined) {
						setCommentCount(action.payload.commentsCount)
					}
				})
				.catch((error) => {
					console.error('Error fetching comment count:', error)
				})
		}
	}, [dispatch, postPostId])

	const handleModalChange = (open) => {
		setIsModalOpen(open)
	}

	const handleItemClick = (e) => {
		e.stopPropagation()
		if (postPostId) {
			navigate(`/posts/${postPostId}`, { state: { from: '/bookmarks' } })
		}
	}

	const toggleOriginal = (e) => {
		e.stopPropagation()
		setShowOriginal(!showOriginal)
	}

	return (
		<TimelineItemWrapper onClick={handleItemClick}>
			<PostAreaWrapper>
				<HeaderComponent
					username={bookmark?.user?.username}
					avatar={bookmark?.user?.avatar}
					postOwnerId={bookmark?.user?.id}
					createdAt={bookmark?.created_at}
					userId={userId}
					postId={forDeletePostId}
					type={bookmark?.type}
					originalType="bookmark"
					onDelete={() => onDelete(bookmark?.id)}
					countryCode={bookmark?.user?.country_code}
				/>

				<TranslatedText>
					<RenderTextWithLinks text={resultText} post={bookmark} />
					{isTruncated && (
						<StyledReadMore
							onClick={(e) => {
								e.stopPropagation()
								toggleReadMore()
							}}
						>
							Read more
						</StyledReadMore>
					)}
				</TranslatedText>

				<TranslationComponent
					detectedLanguage={detectedLanguage}
					showOriginal={showOriginal}
					toggleText={toggleOriginal}
				/>

				{bookmark?.images && bookmark?.images.length > 0 && (
					<ImageGridComponent
						images={bookmark?.images}
						onImageClick={(e, index) => {}}
					/>
				)}

				<FooterComponent
					type={bookmark?.type}
					id={postPostId}
					userId={userId}
					onModalChange={handleModalChange}
					commentsCount={commentCount}
				/>
			</PostAreaWrapper>
		</TimelineItemWrapper>
	)
}

export default BookmarkItem
