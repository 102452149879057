// context/ScrollPositionContext.js

import React, { createContext, useContext } from 'react'

const ScrollPositionContext = createContext(null)

export const useScrollPosition = () => {
	const context = useContext(ScrollPositionContext)
	if (!context) {
		throw new Error(
			'useScrollPosition must be used within a ScrollPositionProvider'
		)
	}
	return context
}

export const ScrollPositionProvider = ({ children }) => {
	const saveScrollPosition = (path, position) => {
		sessionStorage.setItem(path, position.toString())
	}

	const getScrollPosition = (path) => {
		const scrollY = sessionStorage.getItem(path)
		return scrollY ? parseInt(scrollY, 10) : 0
	}

	return (
		<ScrollPositionContext.Provider
			value={{ saveScrollPosition, getScrollPosition }}
		>
			{children}
		</ScrollPositionContext.Provider>
	)
}
