// src/components/Signup/VerifyModal.jsx

import React, { useState, useEffect } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { verifyCode, resendVerificationCode } from '../../actions/authActions'
import { useTranslation } from 'react-i18next'
import { borderBottomStyle } from '../../styles/commonStyles'
import { hoverIconStyle } from '../../styles/commonStyles'
import StyledButton from '../Button/StyledButton'
import styled from 'styled-components'
import { useSnackbar } from '../../context/SnackbarContext'

const StyledModal = styled(Modal)`
	.modal-dialog {
		max-width: 500px;
		.modal-content {
			min-height: 520px; // 最小高さを指定
		}
	}
	.modal-content {
		padding: 20px;
	}
`

const ModalHeader = styled.div`
	display: flex;
	align-items: center;
	position: relative; // タイトルとクローズボタンの配置に必要
	padding: 0 20px;
	margin-bottom: 10px;
`

const ArrowIcon = styled.div`
	${hoverIconStyle}
	font-size: 20px;
	&:after {
		content: '<';
	}
`

const Title = styled.div`
	font-size: ${({ theme }) => theme.fontSizes.extraLarge};
	font-weight: bold;
	margin: 20px auto;
	text-align: center;
`

const StyledFormGroup = styled(Form.Group)`
	font-size: ${({ theme }) => theme.fontSizes.medium};
	margin-bottom: 12px;
`

const StyledFormControl = styled(Form.Control)`
	height: 50px;
	padding: 6px 12px;
	border: 1px solid ${({ theme }) => theme.colors.outlineGray};

	&:focus {
		box-shadow: none;
		border-color: ${({ theme }) => theme.colors.outlineGray};
	}

	&:hover {
		border-color: ${({ theme }) => theme.colors.darkGray};
	}
`

const ResendCodeWrapper = styled.div`
	text-align: left;
	padding: 0px 0px 20px;
`

const ResendCodeText = styled.span`
	color: #007bff;
	font-size: ${({ theme }) => theme.fontSizes.small};
	display: inline-block;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
`

const StyledSubmitButton = styled(StyledButton)`
	&&& {
		border-radius: 6px !important;
		width: 100% !important;
		padding: 12px !important;

		&:focus {
			box-shadow: none !important;
		}
	}
`

const VerifyModal = ({ show, onHide }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const email = useSelector((state) => state.auth.email)
	const userId = useSelector((state) => state.auth.userId)

	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
	const isVerified = useSelector((state) => state.auth.isVerified)
	const authError = useSelector((state) => state.auth.error)

	const [code, setCode] = useState('')
	const [veriCodeError, setVeriCodeError] = useState(false)
	const [veriCodeErrorMsg, setVeriCodeErrorMsg] = useState('')

	const showAlert = useSnackbar()

	useEffect(() => {
		if (isAuthenticated && isVerified) {
			onHide()
			showAlert(t('signedUpSuccessfully'), 'success')
		} else if (authError) {
			// 認証失敗時にエラーメッセージを設定
			setVeriCodeError(true)
			setVeriCodeErrorMsg(t(authError))
		}
	}, [isAuthenticated, isVerified, authError, onHide, t])

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (!code || code.length < 6) {
			setVeriCodeError(true)
			setVeriCodeErrorMsg(t('invalid_code'))
			return
		}

		// 認証コードの検証を試みる
		dispatch(verifyCode(code, userId))
	}

	const handleResendClick = async () => {
		dispatch(resendVerificationCode(email))
	}

	return (
		<StyledModal
			show={show}
			onHide={onHide}
			centered
			backdrop="static"
			keyboard={false}
		>
			<ModalHeader>
				<ArrowIcon onClick={onHide} />
				<Title>{t('verification')}</Title>
				{/* <DummyElement /> */}
			</ModalHeader>
			<Modal.Body>
				<Form onSubmit={handleSubmit}>
					<StyledFormGroup>
						<Form.Label>{t('verificationCode')}</Form.Label>
						<StyledFormControl
							type="text"
							value={code}
							onChange={(e) => setCode(e.target.value)}
							isInvalid={veriCodeError}
						/>
						<StyledFormControl.Feedback type="invalid">
							{veriCodeErrorMsg}
						</StyledFormControl.Feedback>
					</StyledFormGroup>
					<ResendCodeWrapper>
						<ResendCodeText onClick={handleResendClick}>
							{t('resendCode')}
						</ResendCodeText>
					</ResendCodeWrapper>
					<StyledSubmitButton variant="primary" type="submit" className="mt-3">
						{t('submitButton')}
					</StyledSubmitButton>
				</Form>
			</Modal.Body>
		</StyledModal>
	)
}

export default VerifyModal
