// src/actions/commentActions.js

import * as api from '../services/commentApi'
import { establishSSEConnection } from '../utils/sseUtils'

export const FETCH_COMMENTS = 'FETCH_COMMENTS'
export const ADD_COMMENT = 'ADD_COMMENT'
export const UPDATE_COMMENT = 'UPDATE_COMMENT'
export const UPDATE_COMMENT_TRANSLATION_STATUS =
	'UPDATE_COMMENT_TRANSLATION_STATUS'
export const DELETE_COMMENT = 'DELETE_COMMENT'
export const SET_COMMENT_ERROR = 'SET_COMMENT_ERROR'
export const SET_EDITING_COMMENT = 'SET_EDITING_COMMENT'
export const SET_USER_COMMENTS = 'SET_USER_COMMENTS'
export const ADD_SINGLE_COMMENT = 'ADD_SINGLE_COMMENT'
export const UPDATE_SINGLE_COMMENT = 'UPDATE_SINGLE_COMMENT'
export const FETCH_COMMENTS_COUNT = 'FETCH_COMMENTS_COUNT'

export const fetchComments = (postId) => async (dispatch) => {
	if (!postId) {
		return
	}

	try {
		const { comments, commentsCount } = await api.fetchCommentsByPost(postId)
		dispatch({
			type: FETCH_COMMENTS,
			payload: { postId, comments, commentsCount },
		})
		return { comments, commentsCount }
	} catch (error) {
		dispatch({ type: SET_COMMENT_ERROR, payload: error.message })
		throw error
	}
}

export const addComment = (postId, formData) => async (dispatch) => {
	try {
		if (!(formData instanceof FormData)) {
			throw new Error('Invalid data format: FormData expected')
		}
		formData.append('postId', postId)
		const response = await api.postComment(formData)

		dispatch({
			type: ADD_COMMENT,
			payload: { postId, comment: response.comment },
		})

		if (response.sseUrl) {
			establishSSEConnection(
				response.sseUrl,
				(data) => dispatch(handleCommentSSE(response.comment.id, data)),
				(error) => console.error('SSE Error:', error)
			)
		}

		return response.comment
	} catch (error) {
		dispatch({ type: SET_COMMENT_ERROR, payload: error.message })
		throw error
	}
}

const handleCommentSSE = (commentId, data) => (dispatch) => {
	if (data.status === 'completed' && data.translations) {
		dispatch({
			type: UPDATE_COMMENT_TRANSLATION_STATUS,
			payload: {
				comment: { id: commentId, translations: data.translations },
				postId: data.postId,
			},
		})
	} else if (data.status === 'in_progress') {
		dispatch({
			type: UPDATE_COMMENT_TRANSLATION_STATUS,
			payload: {
				comment: { id: commentId, translationStatus: 'in_progress' },
				postId: data.postId,
			},
		})
	}
}

export const updateComment = (commentId, formData) => async (dispatch) => {
	try {
		const response = await api.updateCommentApi(commentId, formData)
		dispatch({
			type: UPDATE_COMMENT,
			payload: {
				comment: response.comment,
				postId: response.comment.post_id,
			},
		})

		// SSE接続を確立（もしsseUrlが提供されている場合）
		if (response.sseUrl) {
			establishSSEConnection(
				response.sseUrl,
				(data) => dispatch(handleCommentSSE(response.comment.id, data)),
				(error) => console.error('SSE Error:', error)
			)
		}

		return response.comment
	} catch (error) {
		dispatch({ type: SET_COMMENT_ERROR, payload: error.message })
		throw error
	}
}

export const deleteComment = (commentId, postId) => async (dispatch) => {
	try {
		await api.deleteCommentApi(commentId)
		dispatch({ type: DELETE_COMMENT, payload: { commentId, postId } })
	} catch (error) {
		dispatch({ type: SET_COMMENT_ERROR, payload: error.message })
		throw error
	}
}

export const setEditingComment = (comment) => ({
	type: SET_EDITING_COMMENT,
	payload: comment,
})

export const fetchUserCommentsAction = (userId, offset) => async (dispatch) => {
	try {
		const userComments = await api.getUserCommentsAPI(userId, offset)
		dispatch({
			type: SET_USER_COMMENTS,
			payload: {
				comments: userComments.comments,
				totalComments: userComments.totalComments,
				offset,
			},
		})
		return userComments
	} catch (error) {
		dispatch({ type: SET_COMMENT_ERROR, payload: error.message })
		throw error
	}
}

export const fetchCommentsCount = (postId) => async (dispatch) => {
	try {
		const count = await api.fetchCommentsCountApi(postId)
		dispatch({
			type: FETCH_COMMENTS_COUNT,
			payload: { postId, commentsCount: count },
		})
		return { payload: { postId, commentsCount: count } }
	} catch (error) {
		console.error('Error fetching comments count:', error)
	}
}

export const addSingleComment = (comment) => ({
	type: ADD_SINGLE_COMMENT,
	payload: comment,
})

export const updateSingleComment = (comment) => ({
	type: UPDATE_SINGLE_COMMENT,
	payload: comment,
})
