// src/components/Analytics/GoogleAnalytics.jsx

import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function GoogleAnalytics() {
	const location = useLocation()

	useEffect(() => {
		if (typeof window.gtag === 'function') {
			window.gtag('config', 'G-5RKPRZGECK', {
				page_path: location.pathname + location.search,
			})
		}
	}, [location])

	return null
}

export default GoogleAnalytics
